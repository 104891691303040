import React, { useEffect, useRef } from "react";
import headerImg from "../assets/img/24.jpg";
import featureImg from "../assets/img/doctor.png";
import FooterContent from "./FooterContent";
import featureImgBenefits from "../assets/img/3573471.jpg";
import chatImage from "../assets/img/logo3.png";
import { Box } from "@material-ui/core";
import Footer from "./login/Footer.jsx";
import { Link, Typography } from "@mui/material";
import ReactPlayer from "react-player";

import ouratory1 from "../assets/img/about/ourstory1.svg";
import ouratory2 from "../assets/img/about/ourstory2.svg";
import banner1 from "../assets/img/about/Banner12.png";
import mission from "../assets/img/about/mission.jpeg";
import check from "../assets/img/about/check.svg";
import linkedIn from "../assets/img/about/linkedin.svg";
import Founder from "./login/Founder.jsx";

import Founder1 from "../assets/img/founders/Parul Shukla _MD (1).png";
import Founder2 from "../assets/img/founders/Harsh Kanhere.png";
import Founder3 from "../assets/img/founders/Shailesh Shrikhande 2.png";
import Founder4 from "../assets/img/founders/Dhananjay Kulkarni.png";

import MissionVideo from "../assets/video/mission.mp4";
import { useLocation } from "react-router-dom";

const About = () => {
  const missionSection = useRef();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("mission")) {
      missionSection.current.scrollIntoView({ behavior: "smooth" });
      console.log(missionSection.current, location);
    }
  }, [location.pathname]);
  return (
    <Box>
      <Box p={{ xs: 2, sm: 4 }}>
        <Box display={"flex"} justifyContent={"center"}>
          <Box
            height={{ xs: 400, sm: 475, md: 525 }}
            width={{ xs: "90%", md: "80%" }}
            bgcolor={"#D9D9D9"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            className="bg-about"
            borderRadius={12}
          >
            <Typography
              color={"#ffffff"}
              fontSize={{ xs: "1.35rem", sm: "1.5rem", md: "2.5rem" }}
              width={{ xs: "90%", sm: "70%", md: "60%" }}
              textAlign={"center"}
              fontWeight={700}
              fontFamily={"Plus Jakarta Sans"}
            >
              Imagine a world where surgeons connect without boundaries
            </Typography>
          </Box>
        </Box>

        <Box my={6}>
          <Typography
            color={"#031432"}
            fontFamily={"Plus Jakarta Sans"}
            fontSize={{ xs: "1.252rem", sm: "1.5rem", md: "2rem" }}
            textAlign={"center"}
            fontWeight={700}
          >
            Our Story
          </Typography>
          <Box display={"flex"} justifyContent={"center"}>
            <Typography
              width={{ xs: "95%", sm: "80%", md: "70%" }}
              color={"#031432"}
              fontFamily={"Plus Jakarta Sans"}
              fontSize={{ xs: "0.9rem", sm: "1rem", md: "1.25rem" }}
              textAlign={"center"}
              fontWeight={500}
            >
              When 4 world-renowned surgeons from different parts of the world
              discussed their individual journeys to the top of their
              profession, it became clear that:
            </Typography>
          </Box>

          <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
            {storyData.map((dt) => (
              <Box
                border={"1px solid #1681B1"}
                borderRadius={12}
                height={{ xs: 280, sm: 240 }}
                width={{ md: 450 }}
                m={3}
                p={{ sm: 3, xs: 2 }}
                mx={{ xs: 0, sm: 3, md: 8 }}
              >
                <Box
                  height={75}
                  width={75}
                  p={2}
                  bgcolor={dt.color}
                  borderRadius={"50%"}
                >
                  <img src={dt.image} height={"100%"} alt="" />
                </Box>
                <Typography
                  fontSize={"1rem"}
                  fontWeight={600}
                  my={3}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  {dt.paragraph}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box my={4}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              <Box maxWidth={{ md: 400 }} minWidth={300} mx={{ sm: 8, md: 12 }}>
                <Typography
                  color={"#031432"}
                  fontSize={"1.35rem"}
                  fontWeight={600}
                  fontFamily={"Plus Jakarta Sans"}
                  my={3}
                >
                  They decided to build a digital platform that would help
                  surgeons connect with each other. And that’s how
                  SurgeonsforSurgeons was created.
                </Typography>
              </Box>
              <Box
                maxWidth={580}
                minWidth={320}
                height={{ xs: 320, sm: 370 }}
                mx={8}
              >
                <img
                  src={banner1}
                  alt=""
                  height={"100%"}
                  width={"100%"}
                  style={{ objectFit: "cover" }}
                />
              </Box>
            </Box>
          </Box>

          <Box my={2} sx={{ mx: { lg: 6, xs: 0, md: 4 } }} ref={missionSection}>
            <Typography
              color={"#031432"}
              fontSize={{ xs: "1.25rem", sm: "1.5rem", md: "2rem" }}
              fontFamily={"Plus Jakarta Sans"}
              textAlign={"center"}
              fontWeight={700}
              mb={2}
            >
              Our Mission
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", lg: "row", md: "row" },
                border: "1px solid #9FCBDF",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <Box sx={{ minWidth: { xs: "100%", md: "50%", lg: "50%" } }}>
                {/* <video
                  src={MissionVideo}
                  controls={true}
                  muted={true}
                  autoPlay={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                /> */}
                <ReactPlayer
                  url={MissionVideo}
                  controls
                  width={"100%"}
                  height={"100%"}
                  className={"about-video"}
                  muted
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                />
              </Box>
              <Box p={4}>
                <Typography
                  fontSize={{ sm: "1.2rem", xs: "1rem" }}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  Surgeons for Surgeons is an effort to build the world’s
                  largest surgical community The founding surgeons have
                  colleagues, peers, mentors and mentees all over the world.
                  They cherish these connections and feel very grateful and
                  privileged to have them. They conceived Surgeons for Surgeons
                  to help surgeons all over the world create and maintain
                  connections without boundaries.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box my={8}>
            <Typography
              color={"#031432"}
              fontSize={{ xs: "1.25rem", sm: "1.5rem", md: "2rem" }}
              textAlign={"center"}
              fontFamily={"Plus Jakarta Sans"}
              fontWeight={700}
              my={2}
            >
              Our guiding principles
            </Typography>
            <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
              {principlesData.map((dt) => (
                <Box
                  border={"1px solid #1681B1"}
                  borderRadius={12}
                  height={{ xs: 300, sm: 220 }}
                  width={450}
                  m={3}
                  p={{ sm: 3, xs: 2 }}
                  mx={{ xs: 0, sm: 3 }}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  <Box
                    height={75}
                    width={75}
                    p={2}
                    bgcolor={"#1681b12b"}
                    borderRadius={"50%"}
                  >
                    <img src={check} height={"100%"} alt="" />
                  </Box>
                  <Typography
                    fontSize={"1.2rem"}
                    fontWeight={600}
                    fontFamily={"Plus Jakarta Sans"}
                    textAlign={"center"}
                    my={1}
                  >
                    {dt.heading}
                  </Typography>
                  <Typography
                    fontFamily={"Plus Jakarta Sans"}
                    fontSize={"1rem"}
                    fontWeight={400}
                    textAlign={"center"}
                  >
                    {dt.paragraph}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box my={4}>
            <Typography
              color={"#031432"}
              fontSize={{ xs: "1.2rem", sm: "1.5rem", md: "2rem" }}
              fontFamily={"Plus Jakarta Sans"}
              textAlign={"center"}
              fontWeight={700}
            >
              The Founding Team
            </Typography>
            <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"}>
              {foundersData.map((dt) => (
                <Box
                  border={"1px solid #CDD5DD"}
                  borderRadius={12}
                  height={{ xs: 300, sm: 220 }}
                  width={450}
                  m={3}
                  p={{ sm: 1, xs: 2 }}
                  mx={{ xs: 0, sm: 3 }}
                  display={"flex"}
                  justifyContent={"space-evenly"}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                >
                  <Box
                    minWidth={150}
                    sx={{
                      height: { xs: 120, sm: 140 },
                    }}
                  >
                    <img src={dt.img} height={"100%"} alt="" />
                  </Box>
                  <Box mx={1} width={{ xs: "auto", sm: 250 }}>
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={500}
                      color={"#13375B"}
                      fontFamily={"Plus Jakarta Sans"}
                      fontStyle={"italic"}
                      my={1}
                    >
                      "{dt.message}"
                    </Typography>
                    <Typography
                      fontSize={"1rem"}
                      fontWeight={700}
                      fontFamily={"Plus Jakarta Sans"}
                      my={1}
                      color={"#13375B"}
                    >
                      {dt.name}
                    </Typography>
                    <Link
                      href={
                        // "https://www.linkedin.com/company/surgeons-for-surgeons-sfs/"
                        dt.linkedin
                      }
                      underline="none"
                      my={1}
                      target="_blank"
                    >
                      <Box height={25}>
                        <img src={linkedIn} alt="" height={"100%"} />
                      </Box>
                    </Link>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default About;

const storyData = [
  {
    image: ouratory1,
    paragraph:
      "There is a need to create a runway as well as a level playing field for surgeons to connect with each other",
    color: "#855fce30",
  },
  {
    image: ouratory2,
    paragraph:
      "A global network of surgeons would be able to leverage personal experience, friendship, collegiality, fellowship, and contacts to enhance surgical outcomes",
    color: "#27ae602e",
  },
];

const principlesData = [
  {
    heading: "Free membership",
    paragraph: "Open to all surgeons",
  },
  {
    heading: "Effortless ",
    paragraph:
      "Every surgeon should be able to share their work and ideas at the click of a button",
  },
  {
    heading: "Global ",
    paragraph:
      "Any surgeon can connect with another surgeon from anywhere in the world",
  },
  {
    heading: "All surgical specialties should coexist",
    paragraph: "Seamless cross-pollination",
  },
];

const foundersData = [
  {
    name: "Dr. Parul Shukla, Founder",
    img: Founder1,
    message:
      "In the world of surgery, connections lead to breakthroughs. Together, we can push boundaries and redefine healthcare.",
    designation:
      "Regional Chief of Colon & Rectal Surgery at Northwell Health. Surgery Professor. Founder of Surgeons for Surgeons.",
    linkedin: "https://www.linkedin.com/in/parul-shukla-8915b721/",
  },
  {
    name: "Dr. Harsh Kanhere",
    img: Founder2,
    message: "The best way to stay ahead in surgery is through collaboration.",
    designation:
      "Head of upper GI surgery at the royal adelaide hospital and senior consultant surgeon HPB surgery at the queen Elizabeth hospital",
    linkedin: "https://www.linkedin.com/in/harsh-kanhere-0465621b/",
  },
  {
    name: "Prof. Shailesh V. Shrikhande",
    img: Founder3,
    message: "Amplify your impact and connect with leaders in your field.",
    designation:
      "Deputy director of tata memorial hospital head of cancer surgery. Chief of GI & HPB Surgical Service.",
    linkedin: "https://www.linkedin.com/company/surgeons-for-surgeons-sfs/",
  },
  {
    name: "Dr. Dhananjay Kulkarni",
    img: Founder4,
    message:
      "SFS makes it easy to share our skills and knowlegde, making surgery better for everyone.",
    designation:
      "Consultant Oncoplastic Breast Surgeon, Breast Unit, Western General Hospital Edinburgh",
    linkedin: "https://www.linkedin.com/company/surgeons-for-surgeons-sfs/",
  },
];
