const LoadEmitters = () => {


    return {
        showLoader: (obj) => {
            const loaderEvt = new CustomEvent("loaderEvt", { detail: obj });
            // loaderEvt.loaderConfig = obj;
            document.dispatchEvent(loaderEvt);
        }

    }
}

export default LoadEmitters();