import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import apis from "../apis/apis";
import Config from "../configs/Config";
import LoaderEmitters from "../configs/LoaderEmitters";
import EventEmitters from "../configs/EventEmitters";
import UploadProgressModal from "./UploadProgressModal";
import moment from "moment";

const AddExploreModal = ({
  open,
  setShow,
  getExploreData,
  setHasMore,
  pageRef,
}) => {
  const [specialityList, setSpecialityList] = useState([]);
  const [openUploadProgress, setOpenUploadProgress] = useState(false);
  const [progress, setProgress] = useState(0);
  const [previousUploadedBytes, setPreviousUploadedBytes] = useState(0);
  const [estimatedUploadTime, setEstimatedUploadTime] =
    useState("Calculating...");
  let startUploadTime = null;
  const [initialUploadedBytes, setInitialUploadedBytes] = useState(0);
  useEffect(() => {
    getAllSpecialities();
  }, []);

  const getAllSpecialities = async () => {
    const { data } = await apis.getAllSpecialities();
    setSpecialityList(data.specialities);
  };

  const refetch = useCallback(() => {
    formik.resetForm();
    setHasMore(true);
    pageRef.current = 1;
    getExploreData();
    setShow();
    LoaderEmitters.showLoader({
      showLoader: false,
    });
  });

  const initialValues = {
    title: "",
    speciality: "",
    videoFile: null,
  };

  const addVideo = async (postId, video) => {
    startUploadTime = new Date().getTime();
    setOpenUploadProgress(true);
    let tost = {
      message: "",
      type: "",
    };
    try {
      console.log(new Date().getTime());
      let { data } = await apis.addExplorePostVideo(
        postId,
        video,
        handleUploadVideoProgress
      );
      console.log(data);
      if (data.code === 200) {
        tost.type = "success";
        tost.message = data.result;

        setShow();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        setOpenUploadProgress(false);
        refetch();

        return;
      } else {
        tost.type = "error";
        tost.message = data.msg;
      }
    } catch (error) {
      console.log(error);
    }
    setOpenUploadProgress(false);
    setShow();
    LoaderEmitters.showLoader({
      showLoader: false,
    });
    if (tost.message) {
      EventEmitters.showTost(tost);
    }
  };

  const handleUploadVideoProgress = (progressEvent) => {
    let currentProgress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setProgress(currentProgress);
    const currentUploadedBytes = progressEvent.loaded;
    const totalBytes = progressEvent.total;

    // Track uploaded bytes over time
    const currentTime = new Date().getTime();
    const elapsedSeconds = (currentTime - startUploadTime) / 1000;
    const uploadedBytes = currentUploadedBytes - initialUploadedBytes;

    // Calculate average upload speed in bytes per second
    const averageUploadSpeed = uploadedBytes / elapsedSeconds;

    // Calculate remaining bytes
    const remainingBytes = totalBytes - currentUploadedBytes;

    // Calculate estimated upload time in seconds
    const estimatedTimeInSeconds = remainingBytes / averageUploadSpeed;

    // Use Moment.js to format the estimated upload time
    const duration = moment.duration(estimatedTimeInSeconds, "seconds");
    let formattedTime = "";

    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (minutes > 0) {
      formattedTime = `${minutes} minute${minutes > 1 ? "s" : ""}`;
    }

    if (seconds > 0) {
      if (formattedTime !== "") {
        formattedTime += " ";
      }
      formattedTime += `${seconds} second${seconds > 1 ? "s" : ""}`;
    }

    setEstimatedUploadTime(formattedTime);
    console.log(formattedTime);
  };
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    speciality: Yup.string().required("Speciality is required"),
    videoFile: Yup.mixed().required("Video File is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      LoaderEmitters.showLoader({
        showLoader: true,
      });
      try {
        let { data } = await apis.addExplorePost(
          values.title,
          values.speciality,
          Config.getSessionInfo("id")
        );
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        addVideo(data.postId, values.videoFile);
      } catch (error) {
        console.log(error);
        LoaderEmitters.showLoader({
          showLoader: false,
        });
      }
    },
  });

  return (
    <>
      <Modal show={open} onHide={setShow} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Post </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                name="title"
                className={`form-control ${
                  formik.touched.title && formik.errors.title
                    ? "is-invalid"
                    : ""
                }`}
                id="title"
                {...formik.getFieldProps("title")}
              />
              <div className="error error-text">
                {formik.touched.title && formik.errors.title}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="speciality">Speciality</label>
              <select
                name="speciality"
                className={`form-control ${
                  formik.touched.speciality && formik.errors.speciality
                    ? "is-invalid"
                    : ""
                }`}
                id="speciality"
                {...formik.getFieldProps("speciality")}
              >
                <option value="">Select Speciality</option>
                {specialityList.map((speciality) => (
                  <option key={speciality} value={speciality}>
                    {speciality}
                  </option>
                ))}
              </select>
              <div className="error error-text">
                {formik.touched.speciality && formik.errors.speciality}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="videoFile">Attach File </label>
              <input
                type="file"
                name="videoFile"
                accept="video/*"
                className={`form-control ${
                  formik.touched.videoFile && formik.errors.videoFile
                    ? "is-invalid"
                    : ""
                }`}
                id="videoFile"
                onChange={(event) => {
                  const selectedFile = event.currentTarget.files[0];

                  if (selectedFile) {
                    // Check if the selected file size is less than or equal to 1 GB
                    if (selectedFile.size <= 1073741824) {
                      formik.setFieldValue("videoFile", selectedFile);
                    } else {
                      //custom error message for the field
                      formik.setFieldError(
                        "videoFile",
                        "File size exceeds the limit of 1 GB."
                      );

                      // clear the selected file input
                      event.currentTarget.value = null;
                    }
                  }
                }}
              />
              <div
                className="alert-success px-2"
                role="alert"
                style={{ marginTop: "4px", width: "fit-content" }}
              >
                <span>Supported type (Videos - .mp4, .mkv)</span>
                <p>Max file size for upload is 1 GB</p>
              </div>
              <div className="invalid-feedback">
                {formik.touched.videoFile && formik.errors.videoFile}
              </div>
            </div>

            <Button
              variant="primary"
              type="submit"
              disabled={formik.isSubmitting}
            >
              {formik.isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <UploadProgressModal
        open={openUploadProgress}
        setShow={() => setOpenUploadProgress(false)}
        progress={progress}
        estimatedUploadTime={estimatedUploadTime}
      />
    </>
  );
};

export default AddExploreModal;
