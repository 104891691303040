import React, { Fragment, useEffect } from 'react';
import chatImage from '../assets/img/logo3.png';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
import NotificationClient from '../configs/notificationClient';

const Welcome = (props) => {
    useEffect(() => {
        const evt = new Event("setLoginDetailsEvent");
        document.dispatchEvent(evt);
        NotificationClient.register();
    }, []);

    const goToChatNow = () => {
        props.history.push('/chat');
        // window.location.href = "//" + window.location.host + "/#/chat";
    }

    return (
        <Fragment>
            <section className="section section-blogs container-fluid welcome-height" >
                <div className="middle-container">
                    <div className="section-header text-center">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-7 col-lg-6 login-left">
                                <img src={chatImage} className="img-fluid-logo" alt="Doccure Login" />
                            </div>
                        </div>
                        <h1 style={{ color: '#2272a1', lineheight : '33px' }}>
                            Welcome to Surgeons For Surgeons
                        </h1>
                        <p className="sub-title">You can now connect with Surgeons across the World.</p>
                    </div>

                    <div className="row blog-grid-row">
                        <div className="col-md-6 col-lg-3 col-sm-12">
                        </div>
                    </div>
                    <div className="view-all text-center">
                        <div className="view-all">
                            <span className="btn btn-primary" onClick={goToChatNow} >
                                Connect Now
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <FooterContent></FooterContent>
        </Fragment >
    );
}
export default Welcome;                                            