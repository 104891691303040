// import letterImg from '../assets/img/letter.svg';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { Tab } from "semantic-ui-react";
import "../assets/css/custome.css";
import "../assets/css/posts.css";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import {
  setAddClinicalCaseModal,
  setClinicalImagesTab,
} from "../reducers/clinicalCases/actions";
import AddClinicalCase from "./AddClinicalCase";
import ClinicalCases from "./ClinicalCases";
import CommunityCases from "./CommunityCases";
import FooterContent from "./FooterContent";

const Community = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const setClinicalShow = (isVisible) => {
    dispatch(setAddClinicalCaseModal(isVisible));
  };

  const setClinicalImagesFlag = (isVisible) => {
    dispatch(setClinicalImagesTab(isVisible));
  };

  const forceSpecialityFilter = () =>
    location.pathname === "/community" && setSpecialityFilter("All");

  const [show, setShow] = useState(false);

  const [specialityFilter, setSpecialityFilter] = useState("All");

  const [userDetails, setUserDetails] = useState([]);

  // to check whether it is clinical case or clinical images created a flag for conditional rendering
  const handleClose = () => {
    clinicalImagesFlag ? setShow(false) : setClinicalShow(false);
  };
  const handleShow = () => {
    clinicalImagesFlag ? setShow(true) : setClinicalShow(true);
  };
  // initialise the hook
  const {
    addModalVisible: clinicalShow,
    isClinicalImagesTab: clinicalImagesFlag,
  } = useSelector((state) => state.clinicalCases);

  useEffect(() => {
    // setLoginDetails(Config.getSessionInfo());
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    if (!id || isAllDetailsFilled === 0) {
      props.history.push("/login");
      // props.history.push('/communityNonRegister');
    } else {
      // props.history.push('/community');
      // console.log("Config.getSessionInfo()>>>>>>>>", Config.getSessionInfo());
      setUserDetails({
        ...Config.getSessionInfo(),
      });
    }
  }, []);

  const panes = [
    {
      menuItem: "Clinical Images",
      render: () => (
        <Tab.Pane attached={false}>
          <div className="doc-review review-listing">
            <CommunityCases show={show} setShow={setShow} />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Clinical Cases",
      render: () => (
        <Tab.Pane attached={false}>
          <ClinicalCases />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="content">
        <div
          className="container-fluid"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="row">
            <div
              className="col-md-3 col-lg-3 col-xl-3"
              style={{ paddingBottom: 30 }}
            >
              <StickyBox offsetTop={118} offsetBottom={50}>
                <div className="profile-sidebar">
                  <div className="widget-profile pro-widget-content">
                    <div className="profile-info-widget">
                      <span className="booking-doc-img">
                        <img
                          src={Constants.server + userDetails.profile_pic}
                          alt="User Image"
                        />
                      </span>
                      <div className="profile-det-info">
                        <h3>
                          <NavLink
                            to={"/profile/" + userDetails.id}
                            title="View Profile"
                          >
                            Dr. {userDetails.full_name}
                          </NavLink>
                        </h3>

                        <div className="patient-details">
                          <h5 className="mb-0">
                            MBBS
                            {userDetails.specialty_type
                              ? `, MS - (${userDetails.specialty_type})`
                              : ""}
                          </h5>

                          <button
                            className="add__clinical__case"
                            onClick={handleShow}
                            style={{
                              backgroundColor: "#096d9c",
                              color: "#fff",
                              border: "2px solid #096d9c",
                              margin: "10px 10px 0px",
                              cursor: "pointer",
                            }}
                          >
                            {/* This is present in community */}
                            Add a Case 
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-widget">
                    <nav className="dashboard-menu">
                      <ul>
                        <li>
                          <Link to="#" onClick={(e) => e.preventDefault()}>
                            {/* <span>Cases</span> */}
                            <NavLink
                              className="liked"
                              to={"/community"}
                              onClick={forceSpecialityFilter}
                            >
                              <i className="fas fa-columns"></i>
                              {clinicalImagesFlag
                                ? "Feed"
                                : "Clinical Case Feed"}{" "}
                            </NavLink>
                          </Link>
                        </li>
                        <li>
                          <Link to="#" onClick={(e) => e.preventDefault()}>
                            <NavLink to={"/collection"}>
                              <i className="fas fa-calendar-check"></i>
                              {clinicalImagesFlag
                                ? "Collection"
                                : "Clinical case Collection"}{" "}
                            </NavLink>
                          </Link>
                        </li>

                        <li>
                          <Link to="#" onClick={(e) => e.preventDefault()}>
                            <NavLink to={"/myCases"}>
                              {" "}
                              <i className="fas fa-user-injured"></i> My
                              Posts
                            </NavLink>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </StickyBox>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-9">
              <div className="doc-review review-listing">
                <CommunityCases show={show} setShow={setShow} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddClinicalCase show={clinicalShow} handleClose={handleClose} />
      <FooterContent />
    </React.Fragment>
  );
};
export default Community;
