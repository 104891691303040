// @ts-check
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import loginImage from "../../assets/loginImage.png";
// import loginImage from "../../assets/Main article image.png";
import hide from "../../assets/Hide.svg";
import google from "../../assets/googleLogo.svg";
import apple from "../../assets/appleLogo.svg";
import TextField from "./TextField";
import "./login.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Constants from "../../configs/Constants";
import axios from "axios";
import EventEmitters from "../../configs/EventEmitters";
import LoaderEmitters from "../../configs/LoaderEmitters";
import Config from "../../configs/Config";
import apis, { axiosInstance } from "../../apis/apis";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { handleAuthLogin } from "../../reducers/auth/thunk";
import { useGoogleLogin } from "@react-oauth/google";
import { appleAuthHelpers } from "react-apple-signin-auth";
import googleLogo from "../../assets/img/googlePlay.png";
import appleLogo from "../../assets/img/appStore.png";

const Login = () => {
  const history = useHistory();
  const [remeberMe, setRemeberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.auth);

  const handleRemeberMe = () => {
    setRemeberMe(!remeberMe);
  };

  useLayoutEffect(() => {
    let isRemeberMe = localStorage.getItem("rememberMe");
    if (isRemeberMe) {
      formik.setFieldValue("emailId", localStorage.getItem("email"));
      formik.setFieldValue("password", localStorage.getItem("password"));
      setRemeberMe(true);
    }
  }, []);

  const getProfileDetails = (id) => {
    axios.post(Constants.getProfileDetails, { id }).then((response) => {
      if (response.data && response.data.result) {
        let temp = {
          number: response?.data?.result?.profile?.mobile,
          college: response?.data?.result?.qualification?.mbbs_center,
        };
        window.sessionStorage.setItem(
          "session",
          JSON.stringify({
            ...Config.getSessionInfo(),
            ...temp,
          })
        );
      }
    });
  };

  const handleAppleSignIn = async () => {
    console.log("inside apple signin");
    document.addEventListener("AppleIDSignInOnSuccess", (event) => {
      console.log(event);
    });
    try {
      const appleAuth = await appleAuthHelpers.signIn({
        authOptions: {
          clientId: "com.sfsapp.auth.client",
          scope: "name email",
          redirectURI: "https://aws-sfs-dev-temp.web.app",
          state: "origin:web",
          usePopup: true,
        },
        onError: (error) => console.error(error),
        onSuccess: async (data) => {
          console.log(data, data?.authorization?.code);
          let response = await apis.appleSignin({
            authCode: data?.authorization?.code,
          });
          if (response) sessionStorage.setItem("type", "third_party");
          handleLoginSuccess(response);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleGoogleToken(tokenResponse?.access_token);
    },
    onError: (err) => console.log(err),
  });

  const handleGoogleToken = async (access_token) => {
    try {
      let response = await apis.googleSignin({ authCode: access_token });
      if (response) sessionStorage.setItem("type", "third_party");
      handleLoginSuccess(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoginSuccess = async (response) => {
    console.log("response", response);
    let tost = {
      message: "",
      type: "",
    };
    if (response.data.code === 404) {
      tost.message = response.data.msg;
      tost.type = "error";
    } else if (response.data.code === 422) {
      tost.message = response.data.msg;
      tost.type = "error";
    } else if (response.data.code === 400) {
      tost.message = response.data.msg;
      tost.type = "error";
    } else if (response.data.code === 202) {
      tost.message = response.data.msg;
      tost.type = "success";
      sessionStorage.setItem("session", JSON.stringify(response.data.records));
      getProfileDetails(response.data.records.id);
      EventEmitters.loginLogutService();
      dispatch(handleAuthLogin(true));
      history.push(Constants[response.data.records.till_where_filled + 1]);
    } else if (response.data.code === 200) {
      tost.message = response.data.msg;
      tost.type = "success";
      sessionStorage.setItem("session", JSON.stringify(response.data.records));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.records.jwt}`;
      getProfileDetails(response.data.records.id);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.records.jwt}`;
      getProfileDetails(response.data.records.id);
      EventEmitters.loginLogutService();
      dispatch(handleAuthLogin(true));
      history.push("/chat");
    }

    if (tost.message) {
      tost.autoClose = 2000;
      EventEmitters.showTost(tost);
    }

    LoaderEmitters.showLoader({
      showLoader: false,
    });
    setLoading(false);
  };

  const handleSubmit = (data) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    setLoading(true);

    if (remeberMe) {
      localStorage.setItem("rememberMe", "true");
      localStorage.setItem("email", formik.values.emailId);
      localStorage.setItem("password", formik.values.password);
    } else {
      localStorage.removeItem("rememberMe");
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }

    axios
      .post(Constants.login, data)
      .then((response) => {
        sessionStorage.setItem("type", "ID_PASS");
        handleLoginSuccess(response);
      })
      .catch(console.error);
  };

  const validationSchema = Yup.object().shape({
    emailId: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .matches(
        /^[a-z0-9.]+@[a-z0-9]+\.[a-z]{2,}$/,
        "Email must contain only lowercase characters, numbers, and no spaces"
      ),
    password: Yup.string().trim().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      emailId: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Box>
      <Box px={{ xs: 2, sm: 3, md: 4 }} display={"flex"}>
        <Grid container>
          <Grid
            item
            md={6}
            px={{ md: 4 }}
            display={{ xs: "flex", md: "flex" }}
            flexDirection={"column"}
            my={{ xs: 3, md: 0 }}
          >
            <Box
              display={"flex"}
              justifyContent={{ xs: "center", md: "center" }}
              alignItems={"center"}
            >
              <Box
                height={{ xs: "40vh", md: "50vh" }}
                display={"flex"}
                justifyContent={{ xs: "center", md: "start" }}
                alignItems={"center"}
                maxWidth={520}
              >
                <img src={loginImage} alt="" height={"100%"} width={"100%"} />
              </Box>
            </Box>
            <Typography
              mt={2}
              variant={"div"}
              color={"#1681B1"}
              fontSize={{ xs: "1.1rem", sm: "1.2rem", md: "1.5rem" }}
              // textTransform={"capitalize"}
              fontWeight={700}
              textAlign={{ xs: "center", md: "start" }}
              fontFamily={"Plus Jakarta Sans"}
            >
              Join the global network of surgeons, for surgeons
            </Typography>
            <Typography
              mt={1}
              fontSize={{ xs: "1rem", sm: "1.1rem", md: "1rem" }}
              fontFamily={"Plus Jakarta Sans"}
              textAlign={{ xs: "center", md: "start" }}
            >
              Connect with surgeons globally. Upgrade knowledge. Build your
              reputation. Improve outcome.
            </Typography>
            <Box maxWidth={{ xs: "100%", md: "100%" }}>
              <Typography
                sx={{
                  mt: 2,
                  // color: "#ffffff",
                  fontSize: { xs: ".7rem", sm: ".7rem", md: "1rem" },
                  textAlign: { xs: "center", md: "start" },
                }}
                fontFamily={"Plus Jakarta Sans"}
              >
                Download the app and have the ultimate surgeon's companion
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  justifyContent: { xs: "center", md: "flex-start" },
                  display: "flex",
                  gap: 2,
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    aspectRatio: 2.8,
                    maxWidth: "8rem",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      `https://play.google.com/store/apps/details?id=com.sfs_mobile_app`,
                      "_blank"
                    )
                  }
                >
                  <img src={googleLogo} height={"100%"} width={"100%"} />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    aspectRatio: 2.8,
                    maxWidth: "8rem",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open(
                      `https://apps.apple.com/mv/app/surgeons-for-surgeons/id1660956060`,
                      "_blank"
                    )
                  }
                >
                  <img src={appleLogo} height={"100%"} width={"100%"} />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} px={{ xs: 0, sm: 4 }} width={"100%"}>
            <Box px={{ sm: 3 }}>
              <Typography
                color={"#13375B"}
                fontSize={{ xs: "1.35rem", sm: "1.45rem", md: "2.1rem" }}
                fontWeight={600}
                fontFamily={"Plus Jakarta Sans"}
              >
                Welcome back to SFS
              </Typography>
              {/* <Typography
                color={"#96A0AD"}
                fontSize={{ xs: ".8rem", sm: ".9rem", md: "1rem" }}
                fontFamily={"Plus Jakarta Sans"}
              >
                We've missed you! Please sign in to catch up on what you've
                missed
              </Typography> */}
              <Box mt={2}>
                <TextField
                  label="Email"
                  placeholder="Enter your email"
                  name="emailId"
                  type="email"
                  value={formik.values.emailId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.emailId}
                  touched={formik.touched.emailId}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  label="Password"
                  placeholder="Enter your password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  icon={hide}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mt={1}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember Me"
                  sx={{ fontFamily: "Plus Jakarta Sans", margin: 0 }}
                  value={remeberMe}
                  checked={remeberMe}
                  // onClick={handleRemeberMe}
                  onChange={handleRemeberMe}
                />
                <Link to="/forgotPassword" style={{ textDecoration: "none" }}>
                  <Typography
                    sx={{
                      color: "#1681B1",
                      fontSize: ".9rem",
                    }}
                    fontFamily={"Plus Jakarta Sans"}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              </Box>
              <Box mt={1}>
                <Button
                  disabled={loading}
                  variant="contained"
                  sx={{
                    fontFamily: "Plus Jakarta Sans",
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#13375B",
                    py: 1.3,
                    textTransform: "none",
                    ":hover": {
                      backgroundColor: "#13375B",
                      boxShadow: "none",
                    },
                  }}
                  onClick={formik.handleSubmit}
                >
                  {loading ? <CircularProgress /> : "Log in"}
                </Button>
                <Typography
                  mt={1}
                  sx={{
                    // color: "#1681B1",
                    fontSize: ".75rem",
                  }}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  By clicking Continue, you agree to SFS’s{" "}
                  <span style={{ fontWeight: "600" }}>
                    <Link to="/terms_and_conditions">User Agreement</Link>{" "}
                  </span>{" "}
                  and{" "}
                  <span style={{ fontWeight: "600" }}>
                    <Link to="/privacy-policy">Privacy Policy</Link>{" "}
                  </span>
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    display: { xs: "none", sm: "block" },
                    color: "#9FA8B2",
                    fontSize: "1.2rem",
                    textAlign: "center",
                  }}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  or
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    py: 1,
                    textTransform: "none",
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    color: "#000",
                    border: "1px solid #BDC4CD",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    mt: 2,
                    ":hover": {
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handleGoogleSignIn}
                >
                  <img src={google} alt="" /> Continue with Google
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    py: 1,
                    textTransform: "none",
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    color: "#000",
                    border: "1px solid #BDC4CD",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    mt: 2,
                    ":hover": {
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handleAppleSignIn}
                >
                  <img src={apple} alt="" /> Continue with Apple
                </Button>
                <Typography
                  fontFamily={"Plus Jakarta Sans"}
                  mt={1}
                  sx={{
                    // color: "#1681B1",
                    fontSize: {xs:'1.1rem',md:"1.2rem"},
                    textAlign: "center",
                    fontWeight: "400",
                    // letterSpacing: 1,
                  }}
                >
                  Don't have an account ?{" "}
                  <span style={{ color: "#1681B1",fontWeight:700 }}>
                    <Link to="/signup" style={{ color: "#1681B1"}}>
                      Sign Up
                    </Link>
                  </span>{" "}
                </Typography>
                {/* <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    py: 1,
                    textTransform: "none",
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    color: "#000",
                    border: "1px solid #BDC4CD",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    mt: 2,
                    ":hover": {
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    },
                  }}
                  onClick={()=>history.push('/signup')}
                > Sign up
                </Button>
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Login;
