import React from "react";
import Constants from "../configs/Constants";

const DeleteButton = ({ onClick }) => {
    return (
        <div className="col-3">
            <i
                class="far fa-trash-alt text-danger"
                style={{
                    fontSize: "1rem",
                    cursor: "pointer",
                    display: "block",
                    textAlign: "end",
                }}
                onClick={onClick}
            ></i>
        </div>
    );
};

const FileDisplay = ({ file, handleDelete, type, mode }) => {
    if(mode=="edit") {
        if (type === "image") {
            return (
                <div className="col-12 col-md-4">
                    <div className="card">
                        <img
                            className={"card-img-top"}
                            src={Constants.server + file.file_path}
                            alt={file.name}
                        />
                        <div className="row mx-auto justify-content-between align-items-start w-100 mt-2">
                            <p className="card-text col-9">{file.name}</p>
                            <DeleteButton onClick={handleDelete} />
                        </div>
                    </div>
                </div>
            );
        }
    
        if (type === "video") {
            return (
                <div className="col-12 col-md-4">
                    <div className="card">
                        <video
                            className="card-img-top"
                            src={Constants.server + file.file_path}
                            alt={file.name}
                            type={file.mime_type}
                            controls
                        />
                        <div className="row mx-auto justify-content-between align-items-start w-100 mt-2">
                            <p className="card-text col-9">{file.name}</p>
                            <DeleteButton onClick={handleDelete} />
                        </div>
                    </div>
                </div>
            );
        }
    
        if (type === "pdf") {
            return (
                <div className="col-12 col-md-4">
                    <div className="card">
                        <div className="text-center pt-2">
                            <i
                                class="far fa-file-pdf card-img-top"
                                style={{
                                    fontSize: "4rem",
                                    color: "#c3c3c3",
                                }}
                            ></i>
                        </div>
                        <div className="row mx-auto justify-content-between align-items-start w-100 mt-2">
                            <p className="card-text col-9">{file.name}</p>
                            <DeleteButton onClick={handleDelete} />
                        </div>
                    </div>
                </div>
            );
        }
    }
    if (type === "image") {
        return (
            <div className="col-12 col-md-4">
                <div className="card">
                    <img
                        className={"card-img-top"}
                        src={file.previewUrl}
                        alt={file.fileData.name}
                    />
                    <div className="row mx-auto justify-content-between align-items-start w-100 mt-2">
                        <p className="card-text col-9">{file.fileData.name}</p>
                        <DeleteButton onClick={handleDelete} />
                    </div>
                </div>
            </div>
        );
    }

    if (type === "video") {
        return (
            <div className="col-12 col-md-4">
                <div className="card">
                    <video
                        className="card-img-top"
                        src={file.previewUrl}
                        alt={file.fileData.name}
                        type={file.fileData.type}
                        controls
                    />
                    <div className="row mx-auto justify-content-between align-items-start w-100 mt-2">
                        <p className="card-text col-9">{file.fileData.name}</p>
                        <DeleteButton onClick={handleDelete} />
                    </div>
                </div>
            </div>
        );
    }

    if (type === "pdf") {
        return (
            <div className="col-12 col-md-4">
                <div className="card">
                    <div className="text-center pt-2">
                        <i
                            class="far fa-file-pdf card-img-top"
                            style={{
                                fontSize: "4rem",
                                color: "#c3c3c3",
                            }}
                        ></i>
                    </div>
                    <div className="row mx-auto justify-content-between align-items-start w-100 mt-2">
                        <p className="card-text col-9">{file.fileData.name}</p>
                        <DeleteButton onClick={handleDelete} />
                    </div>
                </div>
            </div>
        );
    }

    return <div></div>;
};

export default FileDisplay;
