// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Badge, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import "../assets/css/custome.css";
import "../assets/css/posts.css";
import indemnityBondSurgery from "../assets/docs/Indemnity_Bond_Beyond_Surgery.pdf";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import LoaderEmitters from "../configs/LoaderEmitters";
import FooterContent from "./FooterContent";
import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";

const BeyondSurgeryFollowing = (props) => {
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const beyondFileRef = useRef();
  const [friendList, setFriendList] = React.useState([]);
  //sorted friend list according time
  let friendTimeList;
  // const [friendTimeList,setFriendTimeList] = React.useState([]);
  const [loginDetails, setLoginDetails] = useState({});
  const [showAddOpportunity, setShowAddOpportunity] = useState(false);
  const handleCloseOpportunity = () => setShowAddOpportunity(false);
  const handleShowOpportunity = () => setShowAddOpportunity(true);

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [userDetails, setUserDetails] = useState([]);

  const [numberOfRequest, setNumberOfRequest] = useState(0);

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  // friendTimeList=friendList.sort((a,b)=> a.beyond_surgery_follow_created_time_date - b.beyond_surgery_follow_created_time_date);
  // console.log("friendTimeList",friendTimeList);
  useEffect(() => {
    
    setLoginDetails({ ...Config.getSessionInfo() });
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    if (!id || isAllDetailsFilled === 0) {
      props.history.push("/home");
    } else {
      getCommunityFriendRequestList(id);
      getFriendListBeyondSurgory(id);
    }
  }, []);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const fileSize = Constants.getFileSizeInMB(e.target);
    // alert(fileSize);
    if (fileSize >= Constants.fileSizeLimitBeyondSurgery) {
      EventEmitters.showTost({
        type: "error",
        message:
          "File size should be less than " +
          Constants.fileSizeLimitBeyondSurgery +
          " Mb.",
      });
      reset();
      return;
    }
    const fileName = e.target.files[0];
    // .jpg, .jpeg, .png, .gif, .webm, .ogg, .mp4,
    if (
      fileName.name.search(".png") > -1 ||
      fileName.name.search(".jpeg") > -1 ||
      fileName.name.search(".jpg") > -1 ||
      fileName.name.search(".gif") > -1
    ) {
      setSelectedFileType({
        type: fileName.type,
        tagType: "image",
      });
    } else if (
      fileName.name.search(".mp4") > -1 ||
      fileName.name.search(".webm") > -1 ||
      fileName.name.search(".ogg") > -1
    ) {
      setSelectedFileType({
        type: fileName.type,
        tagType: "video",
      });
    } else {
      EventEmitters.showTost({
        type: "error",
        message: "File type not supported.",
      });
      reset();
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(fileName);
  };
  const onSubmit = (data) => {
    // data.id = Config.getSessionInfo('id');
    console.log("onSubmit data>>>>>", data);

    var xx = document.getElementById("attachFile");
    const formData = new FormData();
    formData.append("beyondFile", xx.files[0]);

    console.log("formData>>>>>>>>>>>", formData);

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    const config = {
      headers: {
        title: data.title,
        id: Config.getSessionInfo("id"),
        filetagtype: selectedFileType && selectedFileType.tagType,
        type: selectedFileType && selectedFileType.type,
      },
    };
    handleCloseOpportunity();

    axios
      .post(Constants.addBeyondSurgoryPost, formData, config)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          // loadDataOfBeyondSurgory();
          handleCloseOpportunity();
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        reset();
      })
      .catch(console.error);
  };

  const getCommunityFriendRequestList = (id) => {
    axios
      .post(Constants.getBeyondSurgoryFriendRequestList, {
        signup_id: id,
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        console.log("loadDataOfCommunity response>>>>>>", response);
        console.log("loadDataOfCommunity  data>>>>>>", response.data);

        setFriendList([...response.data.result].sort((a,b)=> {
          return (
            new Date(b.beyond_surgery_follow_created_time_date).getTime() - new Date(a.beyond_surgery_follow_created_time_date).getTime()
          )
        }));
        setNumberOfRequest(response.data.result.length);
        console.log("response.data.result.length????????????",response.data.result.length)
      })
      .catch(console.error);
  };

  const displayTime = (time) => {
    return moment(time).calendar();
  };

  const onClickAccept = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    const sentToId =
      Config.getSessionInfo("id") === elm.user_one
        ? elm.user_two
        : elm.user_one;
    axios
      .post(Constants.updateFriendListBeyondSurgory, {
        follow_id: elm.beyond_surgery_follow_id,
        status: 1,
        full_name: Config.getSessionInfo("full_name"),
        module: "beyondSurgoryFollowingFriendList",
        route: "beyondSurgoryFollowingFriendList",
        sentToId,
        signup_id: Config.getSessionInfo("id"),
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          getCommunityFriendRequestList(Config.getSessionInfo("id"));
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const onClickCancel = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.cancleFriendListBeyondSurgory, {
        follow_id: elm.beyond_surgery_follow_id,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          getCommunityFriendRequestList(Config.getSessionInfo("id"));
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };
  const [numberOfFriendRequest, setNumberOfFriendRequest] = useState(0);

  const getFriendListBeyondSurgory = (id) => {
    // LoaderEmitters.showLoader({
    //     showLoader: true
    // });

    axios
      .post(Constants.getFriendListBeyondSurgory, {
        signup_id: id,
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        console.log("loadDataOfCommunity response>>>>>>", response);
        // setFriendList([...response.data.result]);
        setNumberOfFriendRequest(response.data.result.length);
      })
      .catch(console.error);
  };
  return (
    <React.Fragment>
      <div className="row align-items-center justify-content-center">
        <div className="col-md-4">
          <div className="card-body">
            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
              <li className="nav-item">
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/beyondSurgoryFollowing"
                >
                  Request
                  <sup>
                    <Badge
                      pill
                      variant="primary"
                      style={{
                        fontSize: "11px",
                        marginLeft: "6px",
                      }}
                    >
                      {numberOfRequest}
                    </Badge>
                  </sup>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/beyondSurgoryFollowingFriendList"
                >
                  Connections
                  <sup>
                    <Badge
                      pill
                      variant="primary"
                      style={{
                        fontSize: "11px",
                        marginLeft: "6px",
                      }}
                    >
                      {numberOfFriendRequest}
                    </Badge>
                  </sup>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <div className="content">
        <div
          className="container-fluid"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="row flex-row-reverse">
            <div
              className="col-md-8 col-lg-8 col-xl-3 theiaStickySidebar order-sm-1"
              style={{ paddingBottom: 30 }}
            >
              <StickyBox offsetTop={20} offsetBottom={50}>
                <div className="profile-sidebar">
                  <div className="widget-profile pro-widget-content">
                    <div className="profile-info-widget">
                      <span className="booking-doc-img">
                        <img
                          src={Constants.server + loginDetails.profile_pic}
                          alt="User Image"
                        />
                      </span>
                      <div className="profile-det-info">
                        <h3>Dr. {loginDetails.full_name}</h3>
                        <div className="patient-details">
                          <h5 className="mb-0">
                            MBBS
                            {loginDetails.specialty_type
                              ? `, MS - (${loginDetails.specialty_type})`
                              : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-widget">
                    <nav className="dashboard-menu">
                      <ul>
                        <li
                          className="nav-item"
                          id="nomorehover"
                          style={{ padding: "10px" }}
                        >
                          <a
                            className="nav-link header-login"
                            onClick={handleShowOpportunity}
                            style={{
                              backgroundColor: "#096d9c",
                              color: "#fff",
                              border: "2px solid #096d9c",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            Add Post{" "}
                          </a>
                        </li>
                        <li>
                          {/* <a href="dr-post1.php"> */}
                          <NavLink to="/beyondSurgery">
                            <i className="fas fa-columns"></i>
                            <span> Feed </span>
                          </NavLink>
                          {/* </a> */}
                        </li>
                        <li>
                          {/* <a href="dr-post1.php"> */}
                          <NavLink to="/beyondSurgeryMyPost">
                            <i className="fas fa-columns"></i>
                            <span> My Posts</span>
                          </NavLink>
                          {/* </a> */}
                        </li>
                        <li>
                          <NavLink to="/beyondSurgeryMyCollection">
                            <i className="fas fa-bookmark"></i>
                            <span>Saved</span>
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to="/beyondSurgoryFollowing">
                            <i className="fas fa-calendar-check"></i>
                            <span className="liked">Request</span>
                            <sup>
                              <Badge
                                pill
                                variant="primary"
                                style={{
                                  fontSize: "11px",
                                  marginLeft: "6px",
                                }}
                              >
                                {numberOfRequest}
                              </Badge>
                            </sup>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/beyondSurgoryFollowingFriendList">
                            <i className="fas fa-user-injured"></i>
                            <span>Connections</span>
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </StickyBox>
            </div>

            <div className="col-md-3 col-lg-3 col-xl-9 order-sm-2">
              <div className="appointments">
                {friendList && friendList.length ? (
                  friendList.map((elm) => {
                    return (
                      <div style={{position:"relative"}} className="appointment-list">
                        <div className="profile-info-widget">
                          <span className="booking-doc-img">
                            <img
                              src={Constants.server + elm.profile_pic}
                              alt="User Image"
                              style={{ width: "120px" }}
                            />
                          </span>
                          <div className="profile-det-info">
                            {loginDetails.id != elm.sent_by ? null :  (
                            <span className="accept-tag">Request Sent</span>
                          )}
                            <h3>
                              <span>Dr. {elm.full_name}</span>
                            </h3>
                            <div className="patient-details">
                              <h5>
                                <i className="fa fa-graduation-cap"></i>{" "}
                                {loginDetails.id == elm.user_two ? elm.sp_type : elm.sp_type_two}
                              </h5>
                              <h5>
                                <i className="far fa-clock"></i>{" "}
                                {displayTime(
                                  elm.beyond_surgery_follow_created_time_date
                                )}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="appointment-action">
                          {loginDetails.id != elm.sent_by ? (
                            <a
                              onClick={onClickAccept.bind(this, elm)}
                              href="javascript:void(0);"
                              className="btn btn-sm bg-success-light"
                            >
                              <i className="fas fa-check"></i> Accept
                            </a>
                          ) : null}

                          <a
                            onClick={onClickCancel.bind(this, elm)}
                            href="javascript:void(0);"
                            className="btn btn-sm bg-danger-light"
                          >
                            <i className="fas fa-times"></i> Cancel
                          </a>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Alert className="alert-no-post" key={1} variant={"primary"}>
                    No New Request Found!
                  </Alert>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={showAddOpportunity}
        onHide={handleCloseOpportunity}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="form-group row">
              <label
                htmlFor="title"
                className="col-md-4 col-form-label text-md-right"
              >
                Title
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Title is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row uploadfrm">
              <label
                htmlFor="attachFile"
                className="col-md-4 col-form-label text-md-right"
              >
                Attach File
              </label>
              <div className="col-md-6">
                <input
                  type="file"
                  id="attachFile"
                  name="beyondFile"
                  accept=".jpg, .jpeg, .png, .mp4, .gif, .webm, .ogg "
                  onChange={onSelectFile}
                  required
                  ref={beyondFileRef}
                  // ref={register({ required: false })}
                />
                <div
                  className="alert-success"
                  role="alert"
                  style={{ marginTop: "4px" }}
                >
                  Supported type (.jpg, .jpeg, .png, .mp4, .gif, .webm, .ogg) &
                  max {Constants.fileSizeLimitBeyondSurgery} MB in Size
                </div>
                {errors.beyondFile && (
                  <div className="error-text" role="alert">
                    File is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6 offset-md-4">
                {selectedFileType &&
                  (selectedFileType.tagType === "image" ? (
                    <div className="d-flex flex-row justify-content-center ">
                      <img src={preview} style={{ width: "100%" }} />

                      {!!preview && (
                        <i
                          style={{ color: "red" }}
                          id="fapaddind"
                          className="far fa-times-circle"
                          onClick={() => {
                            setPreview(undefined);
                            setSelectedFile(undefined);
                            beyondFileRef.current.value = "";
                          }}
                        ></i>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-row justify-content-center ">
                      {!!preview && (
                        <video playsInline width="100%" controls>
                          <source src={preview} type={selectedFileType.type} />
                        </video>
                      )}

                      {!!preview && (
                        <i
                          style={{ color: "red" }}
                          id="fapaddind"
                          className="far fa-times-circle"
                          onClick={() => {
                            setPreview(undefined);
                            setSelectedFile(undefined);
                            beyondFileRef.current.value = "";
                          }}
                        ></i>
                      )}
                    </div>
                  ))}
                {/* // {selectedFile &&  <img src={preview} style={{ width: '100%' }} />} */}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6 offset-md-4">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="clauses"
                      required
                      ref={register({ required: true })}
                      style={{ marginRight: "6px" }}
                    />
                    I hereby understand and provide the following Indemnity.
                    {/* <a
                      href={indemnityBondSurgery}
                      target="_blank"
                      style={{ color: "#007bff" }}
                    >
                      {" "}
                      Click Here to Read{" "}
                    </a> */}
                      <Link
                      to="/terms_and_conditions"
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tap here to read.
                    </Link>
                    {errors.clauses && (
                      <div className="error-text" role="alert">
                        Please select the checkbox
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 offset-md-4">
              <button
                type="submit"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <FooterContent></FooterContent>
    </React.Fragment>
  );
};
export default BeyondSurgeryFollowing;
