import React from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Constants from "../configs/Constants";
// import '../assets/css/override.css';
import FooterContent from "./FooterContent";

const Verify = (props) => {
  const [loadingState, setLoadingState] = React.useState(0);

  React.useEffect(() => {
    const { search } = props.location;
    if (!search) {
      // props.history.push('/');
    }

    axios
      .post(Constants.verify, {
        q: search,
      })
      .then((response) => {
        if (response.data.code === 404) {
          setLoadingState(2);
        } else if (response.data.code === 200) {
          setLoadingState(1);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(2);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="container" style={{ height: "56vh" }}>
        <br />
        {/* <div className="alert alert-success alert-verify" role="alert">
                    <h4 className="alert-heading"> Hello Welcome Back!</h4>
                    <p> Your Email verification is completed. Please login to countinue. </p>
                    <hr />
                    <p className="mb-0">
                        <NavLink to="/login">
                            <strong>Click Here to Login</strong>
                        </NavLink>
                    </p>
                </div> */}
        {loadingState === 0 ? null : loadingState === 1 ? (
          <div className="alert alert-success alert-verify" role="alert">
            <h4 className="alert-heading">
              {" "}
              Hello, Welcome to Surgeons for Surgeons!
            </h4>
            <p>
              {" "}
              Your Email verification is completed. Please login to continue.{" "}
            </p>
            <hr />
            <p className="mb-0">
              <NavLink to="/login">
                <strong>Click Here to Login</strong>
              </NavLink>
            </p>
          </div>
        ) : (
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading"> 402 error!</h4>
            <p> Somthing went wrong. </p>
            <hr />
            <p className="mb-0">
              <NavLink to="/login">Click Here to Login</NavLink>
            </p>
          </div>
        )}
      </div>
      <FooterContent></FooterContent>
    </React.Fragment>
  );
};

export default Verify;
