import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../assets/css/reels.css";
import { Button } from "react-bootstrap";
import apis from "../apis/apis";
import ReelsCard from "./ReelsCard";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Config from "../configs/Config";

const ExploreReels = () => {
  const history = useHistory();
  const location = useLocation();

  const [allExploreData, setAllExploreData] = useState();
  const [userSavedPost, setUserSavedPost] = useState(null);
  const [current, setCurrent] = useState(null);
  // const [inView, setInView] = useState(null)
  const scrollRef = useRef();

  useLayoutEffect(() => {
    if (!location.state) {
      history.push("/explore");
      return;
    } else setAllExploreData(location?.state?.allExploreData);
    console.log(location.state);
    setCurrent(location.state.ele);
    // getExploreData();
    getSavedPost();
  }, []);

  useEffect(() => {
    document
      .querySelector(".parent-container")
      .addEventListener("scroll", () => {
        const elementInView = findElementInView();
        if (elementInView) {
          // Do something with the element in view
          
          console.log("Element in view:", elementInView.id);
          setCurrent(elementInView.id)
        }
        //  else {
        //   // No element in view
        //   // console.log("No element in view.");
        // }
      });
  }, []);

  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  
  function findElementInView() {
    const elementsToCheck = document.querySelectorAll(".reel-card");
    // console.log(elementsToCheck)
    for (const element of elementsToCheck) {
      if (isElementInViewport(element)) {
        return element; // Return the first element found in the viewport
      }
    }
    return null; // Return null if no element is in view
  }

  const getExploreData = async () => {
    try {
      let { data } = await apis.getAllExplorePost(1);
      setAllExploreData(data?.posts);
      if (location.state.filterData) {
        console.log(location.state.filterData, "tiii");
        setAllExploreData(
          data?.posts.filter((item) =>
            location.state.filterData.includes(item.speciality)
          )
        );
        return;
      }
      setCurrent(data?.posts[0].post_id);
    } catch (error) {
      console.log(error);
    }
  };

  const getSavedPost = async () => {
    try {
      let userId = Config.getSessionInfo("id");
      const { data } = await apis.getUserSavedExplorePost(userId);
      let arr = [];
      for (let i = 0; i < data.posts.length; i++) {
        arr.push(data.posts[i].post_id);
      }
      setUserSavedPost(arr);
    } catch (err) {
      console.log(err);
    }
  };
  let scrollDown = (after) => {
    // document.getElementById(after).scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    //   inline: "center",
    // });
    // setCurrent(after);
    document.querySelector(".parent-container").scrollBy({
      top: 100, // Vertical scroll amount (positive for scrolling down)
      behavior: "smooth" // Add smooth behavior
    });

  };
  let scrollUp = (before) => {
    document.querySelector(".parent-container").scrollBy({
      top: -100, // Vertical scroll amount (positive for scrolling down)
      behavior: "smooth" // Add smooth behavior
    });    // document.getElementById(before).scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    //   inline: "center",
    // });
    // setCurrent(before);
  };
  return (
    <div
      className="parent-container"
      style={{ backgroundColor: "black" }}
      ref={scrollRef}
    >
      {allExploreData?.map((item, i) => {
        return (
          <ReelsCard
            key={item.post_id}
            data={item}
            allExploreData={allExploreData}
            // index={i}
            // before={allExploreData[i]?.post_id}
            // after={allExploreData[i + 1]?.post_id}
            current={current}
            // setCurrent={setCurrent}
            savedPosts={userSavedPost}
            scrollDown={scrollDown}
            scrollUp={scrollUp}
            // inView={inView}
            setAllExploreData={setAllExploreData}
          />
        );
      })}
      {/* <ReelsCard /> */}
      <Button className="cancle-btn" onClick={() => history.push("/explore")}>
        Close
        <i className="fas fa-times ml-3"></i>
      </Button>
    </div>
  );
};

export default ExploreReels;
