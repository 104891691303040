import React, { useEffect, useState } from "react";
import Constants from "../configs/Constants";
import apis from "../apis/apis";

const CaseFilterBar = ({
  communityData,
  setSpecialityFilter,
  SpecialityFilter,
}) => {
  const [numberOfCases, setNumberOfCases] = useState(communityData.length);
  const [specialityList, setSpecialityList] = useState([])
  useEffect(() => {
    getAllSpecialities()
    if (SpecialityFilter !== "All") {
      setNumberOfCases(
        communityData.reduce((acc, current) => {
          if (current.speciality === SpecialityFilter) return acc + 1;
          return acc;
        }, 0)
      );
    } else {
      setNumberOfCases(communityData.length);
    }
    return () => {};
  }, [SpecialityFilter]);

  const getAllSpecialities = async () => {
    const { data } = await apis.getAllSpecialities();
    setSpecialityList(data.specialities);
  };


  return (
    <li>
      <div className="comment row d-flex justify-content-between align-items-center">
        <div
          className="col-12 col-md-4 order-2 order-md-1 pt-3 pt-md-0"
          style={{
            color: "rgb(9, 109, 156)",
            fontWeight: "bold",
            fontSize: "1rem",
          }}
        >
          {SpecialityFilter !== "All" &&
            `${numberOfCases} ${SpecialityFilter} ${
              numberOfCases == 1 ? "case" : "cases"
            }`}
          {SpecialityFilter == "All" &&
            `${numberOfCases} ${numberOfCases == 1 ? "case" : "cases"}`}
        </div>
        <div className="d-flex flex-wrap align-items-center justify-content-md-end col-12 col-md-8 order-1 order-md-2">
          <span className="mr-4" style={{ display: "contents" }}>
            Filter by speciality
          </span>
          <form action="#" className="form-inline pt-2 pt-md-0 ml-3">
            <select
              style={{ width: "200px" }}
              className="form-control"
              onChange={(e) => setSpecialityFilter(e.target.value)}
              value={SpecialityFilter}
            >
              <option value="All">All</option>
              {specialityList.map((post) => (
                <option value={post}>{post}</option>
              ))}
            </select>
          </form>
        </div>
      </div>
    </li>
  );
};

export default CaseFilterBar;
