import React, { useState } from "react";
// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import { useForm } from "react-hook-form";
import moment from "moment";
import LoaderEmitters from "../configs/LoaderEmitters";
import EventEmitters from "../configs/EventEmitters";
import { Link } from "react-router-dom";
import Constants from "../configs/Constants";
import Config from "../configs/Config";
import { NavLink } from "react-router-dom";
import FooterContent from "./FooterContent";
import DummyProfile from "../assets/img/dummy-profile.png";
import BannerImage from "../assets/img/01_a_Banner.jpg";
import DocBanner from "../assets/img/Doccare_Banner.jpg";
import Banner3 from "../assets/img/banner3.jpeg";
import Banner2 from "../assets/img/banner2.jpeg";
import Banner1 from "../assets/img/banner1.jpeg";
import "../assets/css/posts.css";
import "../assets/css/custome.css";
import "../assets/css/opportunity.css";
import { Modal, Button } from "react-bootstrap";
import doctorThumb from "../assets/img/doctors/doctor-thumb-02.jpg";
import ReadMoreReact from "read-more-react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import indemnity from "../assets/docs/Indemnity Bond.pdf";
import apis from "../apis/apis";

const Opportunity = (props) => {
  const products = [
    {
      id: 1,
      name: "Product1",
      price: 120,
      action: 4321,
    },
    {
      id: 2,
      name: "Product2",
      price: 80,
      action: 123,
    },
  ];

  const comment = function (id) {
    if (!id) return;
    var x = document.getElementById(id);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showAddOpportunity, setShowAddOpportunity] = useState(false);
  const handleCloseOpportunity = () => setShowAddOpportunity(false);
  const handleShowOpportunity = () => setShowAddOpportunity(true);

  const [showReadMore, setShowReadMore] = useState(false);
  const handleCloseReadMore = () => setShowReadMore(false);
  const handleShowReadMore = () => setShowReadMore(true);
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const [userDetails, setUserDetails] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [applyNowData, setApplyNowData] = useState([]);

  const [curruntApplyPost, setCurruntApplyPost] = useState({});

  const [showApplyNow, setShowApplyNow] = useState(false);
  const handleCloseApplyNow = () => setShowApplyNow(false);
  const [otherSpecility, setOtherSpecility] = useState(false);
  const [specialityList, setSpecialityList] = useState([])

  const handleShowApplyNow = (elm) => {
    setShowApplyNow(true);
    setCurruntApplyPost({
      ...elm,
    });
  };

  React.useEffect(() => {
    const id = Config.getSessionInfo("id");
    const { params } = props.match;

    if (params && params.id && id) {
      setUserDetails({
        ...Config.getSessionInfo(),
      });
      loadDataOfOportunity(params.id);
    } else {
      props.history.push("/opportunities");
      // props.history.push('/community');
      // console.log("Config.getSessionInfo()>>>>>>>>", Config.getSessionInfo());
    }
    getAllSpecialities()
  }, []);
  const getAllSpecialities = async () => {
    const { data } = await apis.getAllSpecialities();
    setSpecialityList(data.specialities);
  };

  const loadDataOfOportunity = (opportunityId) => {
    const id = Config.getSessionInfo("id");
    const { params } = props.match;
    const data = {
      id,
      opportunityId: params.id,
    };

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getOpportunitiesResponseById, data)
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        setCommunityData([...response.data.result[0]]);
        setApplyNowData([...response.data.result[1]]);
      })
      .catch(console.error);
  };

  const onSubmit = (data) => {
    data.id = Config.getSessionInfo("id");
    console.log("onSubmit data>>>>>", data);

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    if (otherSpecility) {
      data.speciality = data.otherSpecialityInput;
      // data.otherSpecialityInput
    }

    axios
      .post(Constants.addOpportunities, data)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          loadDataOfOportunity();
          handleShowOpportunity();
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        reset();
      })
      .catch(console.error);
  };

  const displayTime = (time) => {
    return moment(time).calendar();
  };

  const onSubmitApplyNow = (data) => {
    // data.id = Config.getSessionInfo('id');
    console.log("onSubmit data>>>>>", data);

    var xx = document.getElementById("cv");
    const formData = new FormData();
    formData.append("cv", xx.files[0]);

    // formData.append('name', data.name);
    // formData.append('email', data.email);
    // formData.append('contact', data.contact);
    // formData.append('address', data.address);
    // formData.append('medialCollege', data.medialCollege);
    // formData.append('degree', data.degree);

    console.log("formData>>>>>>>>>>>", formData);

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    const config = {
      headers: {
        name: data.name,
        emailid: data.emailId,
        contact: data.contact,
        address: data.address,
        medialcollege: data.medialCollege,
        degree: data.degree,
        opportunityid: curruntApplyPost.id,
      },
    };
    handleCloseApplyNow();
    axios
      .post(Constants.addApplyNow, formData, config)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;

          // props.history.push('/qualification');
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        reset();
      })
      .catch(console.error);
  };

  const onChangeSpecility = (e) => {
    const specility = e.target.value;
    loadDataOfOportunity(specility);
  };

  function actionFormatter(cell, row) {
    const [id, cv, flag] = cell.split("$@");
    console.log(id, "====", cv);
    const cvPath = Constants.server + "applynow/" + cv;
    return (
      '<a href="' +
      cvPath +
      '" class="btn btn-sm bg-success-light" download target="_blank"> <i class="fas fa-eye"></i> View CV </a>'
    );
    // if (flag == 1) {
    //     return '<a href="' + cvPath + '" class="btn btn-sm bg-success-light" download target="_blank"> <i class="fas fa-eye"></i> View CV </a> <hr/>\
    //         <button class="btn btn-sm bg-success-light" onclick="window.acceptApplication(this,'+ id + ' , 2)"> <i class="fas fa-check"></i> Accept </button> <hr/> \
    //         <button class="btn btn-sm bg-danger-light" onclick="window.acceptApplication(this, '+ id + ' , 3)"> <i class="fas fa-times"></i> Reject </button> ';
    // } else {
    //     let status = '<a href="' + cvPath + '" class="btn btn-sm bg-success-light" download target="_blank"> <i class="fas fa-eye"></i> View CV </a> <hr/>';
    //     if (flag == 2) {
    //         status += '<button type="button" class="btn btn-sm bg-success-light" title="Accepted" > Accepted  </button>';
    //     } else if (flag == 3) {
    //         status += '<button type="button" class="btn btn-sm bg-danger-light" title="Rejected">  Rejected  </button>';
    //     }
    //     return status;
    // }
  }

  window.acceptApplication = function (e, id, whatToDo) {
    console.log(e, id, whatToDo);
    const msgName = whatToDo == 2 ? "Accept" : "Cancle";
    if (
      window.confirm(
        "Are you sure you want to " + msgName + " the application?"
      )
    ) {
      axios
        .post(Constants.handleApplicationResponse, {
          opportunityId: id,
          whatToDo,
        })
        .then((response) => {
          LoaderEmitters.showLoader({
            showLoader: false,
          });

          // reset();
          let tost = {
            message: "",
            type: "",
          };
          console.log("response>>>>>>", response);
          if (response.data.code === 200) {
            tost.type = "success";
            tost.message = response.data.msg;
            loadDataOfOportunity();
          } else {
            tost.type = "error";
            tost.message = response.data.msg;
          }

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
          reset();
        })
        .catch(console.error);
    }
  };

  const onChangeSpeciality = (e) => {
    if (e && e.target && e.target.value === "Other") {
      setOtherSpecility(true);
    } else {
      setOtherSpecility(false);
    }
  };

  return (
    <React.Fragment>
      {/* <nav className="navbar navbar-expand-lg header-nav">
                <ul className="nav text-center sub-head">
                    <li className="nav-item">
                        <NavLink className="nav-link header-login" to={'/opportunities-non'} style={{ margin: '0 10px' }}>Non Register user</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link header-login" to={'/opportunities'} style={{ margin: '0 10px' }}> Registered user </NavLink>
                    </li>

                </ul>
            </nav> */}
      <div className="content">
        <div
          className="container-fluid"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div className="profile-sidebar">
                <div className="widget-profile pro-widget-content">
                  <div className="profile-info-widget">
                    <span className="booking-doc-img">
                      <img
                        src={Constants.server + userDetails.profile_pic}
                        alt="User Image"
                      />
                    </span>
                    <div className="profile-det-info">
                      <h3>Dr. {userDetails.full_name}</h3>

                      <div className="patient-details">
                        <h5 className="mb-0">
                          MBBS
                          {userDetails.specialty_type
                            ? `, MS - (${userDetails.specialty_type})`
                            : ""}
                        </h5>
                        {/* <li className="nav-item"> */}
                        <a
                          className="nav-link header-login pointer"
                          onClick={handleShowOpportunity}
                          style={{
                            backgroundColor: "#096d9c",
                            color: "#fff",
                            border: "2px solid #096d9c",
                            margin: "0 10px",
                          }}
                        >
                          {" "}
                          Add an Opportunity{" "}
                        </a>
                        {/* </li> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboard-widget">
                  <nav className="dashboard-menu">
                    <ul>
                      <li>
                        <NavLink to="/opportunities">
                          <i className="fas fa-columns"></i>
                          <span>All Opportunities </span>
                        </NavLink>
                      </li>
                      <li>
                        {/* <a href="opportunity_posted.php"> */}
                        <NavLink to="/opportunityPosted">
                          <i className="fas fa-columns"></i>
                          <span>Opportunities Posted</span>
                        </NavLink>
                        {/* </a> */}
                      </li>
                      {/* <li>
                                                <a href="response.php">
                                                    <i className="fas fa-calendar-check"></i>
                                                    <span>Application Received</span>
                                                </a>
                                            </li> */}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="dashboard-widget filters">
                <nav className="dashboard-menu">
                  <ul>
                    {/* <li>
                                            <div className="col-md-12" id="">
                                                <label for="Select country" className="col-form-label text-md-right">Select country</label>
                                                <select onchange="myFunction(this.value)" className="form-control">
                                                    <option value="Asia">Asia</option>
                                                    <option value="Africa">Africa</option>
                                                    <option value="USA">USA</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="Carribbean">Carribbean</option>
                                                    <option value="Middle East">Middle East</option>
                                                    <option value="Etc">Etc</option>
                                                </select>

                                            </div>
                                        </li> */}

                    {/* <li>
                                            <div className="col-md-12" id="nedmartop1">
                                                <label for="Select specialty" className="col-form-label text-md-right"> Select specialty</label>
                                                <select className="form-control" onChange={onChangeSpecility.bind(this)}>
                                                    <option value="all">All</option>
                                                    <option value="Neurology">Neurology</option>
                                                    <option value="Oncology">Oncology</option>
                                                    <option value="Gynecology">Gynecology</option>
                                                </select>

                                            </div>
                                        </li> */}
                  </ul>
                </nav>
              </div>
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="doc-review review-listing">
                {/* <p id="demo"></p> */}

                {communityData && communityData.length ? (
                  <div className="card">
                    <div className="card-body">
                      <div className="doctor-widget">
                        <div className="doc-info-left col-md-12">
                          <div className="doc-info-cont col-md-7">
                            <h3 className="doc-name">
                              {communityData[0].title}
                            </h3>
                            <p className="doc-speciality">
                              Dr. {communityData[0].full_name}
                            </p>

                            <div className="clinic-details">
                              <p className="doc-location">
                                {/* {elm.description} */}

                                <ReadMoreReact
                                  text={communityData[0].description}
                                  min={10}
                                  ideal={200}
                                  max={250}
                                  readMoreText="Read more"
                                />
                              </p>
                              {/* <a className="read_more" data-toggle="modal" data-target="#about_opp">Read More</a> */}
                              {/* <a className="read_more" data-toggle="modal" data-target="#about_opp">Read More</a> */}
                            </div>
                          </div>
                          <div className="doc-info-right col-md-5">
                            <div className="clini-infos">
                              <ul>
                                <li title="Job Location">
                                  <i className="fas fa-map-marker-alt"></i>
                                  {communityData[0].job_location_city}-
                                  {communityData[0].job_location_country}
                                </li>
                                <li title="Duration">
                                  <i className="far fa-clock"></i>&nbsp;{" "}
                                  {communityData[0].duration}{" "}
                                </li>
                                <li title="Posted Date">
                                  <i className="far fa-calendar"></i>
                                  {/* &nbsp;{displayTime(communityData[0].date)} */}
                                  <span>
                                    {Constants.getOppDate(
                                      communityData[0].from_date
                                    )}
                                    -
                                    {Constants.getOppDate(
                                      communityData[0].to_date
                                    )}
                                  </span>
                                </li>
                                <li title="Specility">
                                  <i className="fa fa-id-card"></i>&nbsp;
                                  {communityData[0].speciality}{" "}
                                </li>
                                <li title="Specility">
                                  <i className="fa fa-podcast"></i>&nbsp;
                                  {communityData[0].opportunity_type}{" "}
                                </li>
                                <li title="Qualifications">
                                  <i className="fa fa-graduation-cap"></i>
                                  &nbsp;{communityData[0].qualifications}
                                </li>
                                <li title="Experience">
                                  <i className="fa fa-hourglass-start"></i>
                                  &nbsp;{communityData[0].experience}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {applyNowData && applyNowData.length ? (
                <BootstrapTable data={applyNowData} pagination hover>
                  <TableHeaderColumn dataField="name" isKey columnTitle>
                    Name
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="email" columnTitle>
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="contact"
                    dataAlign="right"
                    columnTitle
                  >
                    Contact
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="medial_college" columnTitle>
                    Medial College
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="degree" columnTitle>
                    Degree
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="address" columnTitle>
                    Address
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="cvId"
                    dataFormat={actionFormatter}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              ) : (
                <div className="alert alert-info">
                  {" "}
                  No response received yet.{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={showAddOpportunity}
        onHide={handleCloseOpportunity}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add an Opportunity Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="form-group row">
              <label for="title" className="col-md-4 col-form-label">
                Job Title
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Title is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="name" className="col-md-4 col-form-label">
                Doctor Name
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  value={Config.getSessionInfo("full_name")}
                  name="drName"
                  readOnly={true}
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Doctor name is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="location" className="col-md-4 col-form-label">
                Job Location
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="jobLocation"
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Location is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="duration" className="col-md-4 col-form-label">
                Duration of the Opportunity
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="duration"
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Duration of the Opportunity is required.
                  </div>
                )}
              </div>
            </div>

            {/* <div className="form-group row">
                            <label for="date" className="col-md-4 col-form-label">Date</label>
                            <div className="col-md-6">
                                <input type="date" className="form-control datepicker-decades" name="dateOfOpportunity" required ref={register({ required: true })} />
                                {errors.dateOfOpportunity &&
                                    <div className="error-text" role="alert">
                                        Duration of the Opportunity is required.
                                                </div>}
                            </div>
                        </div> */}

            <div className="form-group row">
              <label for="date" className="col-md-4 col-form-label">
                From Date
              </label>
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control datepicker-decades"
                  name="fromDateOfOpportunity"
                  required
                  ref={register({ required: true })}
                />

                {errors.fromDateOfOpportunity && (
                  <div className="error-text" role="alert">
                    From Date is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label for="date" className="col-md-4 col-form-label">
                To Date
              </label>
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control datepicker-decades"
                  name="toDateOfOpportunity"
                  required
                  ref={register({ required: true })}
                />

                {errors.toDateOfOpportunity && (
                  <div className="error-text" role="alert">
                    To Date is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label for="description" className="col-md-4 col-form-label">
                Description
              </label>
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  name="description"
                  ref={register({ required: true })}
                ></textarea>
                {errors.description && (
                  <div className="error-text" role="alert">
                    Description is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="stipend" className="col-md-4 col-form-label">
                Speciality
              </label>
              <div className="col-md-6">
                {/* <input type="text" className="form-control" name="speciality" required ref={register({ required: true })} /> */}
                <select
                  className="form-control"
                  name="speciality"
                  ref={register({ required: true })}
                  onChange={onChangeSpeciality}
                >
                  <option value="">Select Speciality</option>
                  {specialityList?.map((elm) => {
                    return <option value={elm}>{elm}</option>;
                  })}
                  <option value="Other">Other</option>
                </select>
                <br></br>
                {otherSpecility ? (
                  <input
                    type="text"
                    className="form-control"
                    name="otherSpecialityInput"
                    required
                    ref={register({ required: true })}
                    placeholder="Please feel the other speciality"
                  />
                ) : null}

                {errors.speciality && (
                  <div className="error-text" role="alert">
                    Speciality is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label for="stipend" className="col-md-4 col-form-label">
                Opportunity Type
              </label>
              <div className="col-md-6">
                {/* <input type="text" className="form-control" name="speciality" required ref={register({ required: true })} /> */}
                <select
                  className="form-control"
                  name="opportunityType"
                  ref={register({ required: true })}
                >
                  <option value="">Select Opportunity type</option>
                  <option value="Visitor">Visitor</option>
                  <option value="Observer">Observer</option>
                  <option value="Paid training position">
                    Paid training position
                  </option>
                  <option value="Unpaid training position">
                    Unpaid training position
                  </option>
                  <option value="Paid Research position">
                    Paid Research position
                  </option>
                  <option value="Unpaid research position">
                    Unpaid research position
                  </option>
                </select>
                {errors.opportunityType && (
                  <div className="error-text" role="alert">
                    Opportunity Type is required.
                  </div>
                )}
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6 offset-md-4">
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="checkbox"
                      required
                      ref={register({ required: true })}
                      style={{ marginRight: "6px" }}
                    />
                    This data entered is relevant to the platform.
                    {errors.checkbox && (
                      <div className="error-text" role="alert">
                     Please select check box for Terms/Privacy   
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6 offset-md-4">
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="clauses"
                      required
                      ref={register({ required: true })}
                      style={{ marginRight: "6px" }}
                    />
                    I hereby understand and provide the following Indemnity.
                    {/* <a
                      href={indemnity}
                      target="_blank"
                      style={{ color: "#007bff" }}
                    >
                      {" "}
                      Click Here to Read{" "}
                    </a> */}
                      <Link
                      to="/terms_and_conditions"
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tap here to read.
                    </Link>
                    {errors.checkbox && (
                      <div className="error-text" role="alert">
                       Please select check box for Terms/Privacy
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 offset-md-4">
              <button
                type="submit"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                Apply Now
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <FooterContent></FooterContent>
    </React.Fragment>
  );
};
export default Opportunity;
