import React from "react";
import patientImage from "../../assets/img/dummy-profile.png";
// import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import Constants from "../../configs/Constants";
import axios from "axios";
import EventEmitters from "../../configs/EventEmitters";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Config from "../../configs/Config";
import moment from "moment";
import Autosuggest from "react-autosuggest";
import { Spinner, Modal, Button } from "react-bootstrap";
import "react-datez/dist/css/react-datez.css";
import { ReactDatez } from "react-datez";
import FooterContent from "../FooterContent";
import Select from "react-select";
import Compress from "compress.js";

// 'assets/img/patients/patient.jpg'
const PersonalInformation = (props) => {
  React.useEffect(() => {
    setLoginDetails(Config.getSessionInfo());
    const id = Config.getSessionInfo("id");
    if (!id) {
      props.history.push("/");
    } else {
      loadAllCountries();
      loadAllCities();
    }
  }, []);

  const [profilePic, setProfilePic] = React.useState(patientImage);
  const [loginDetails, setLoginDetails] = React.useState({});
  const [startDate, setStartDate] = React.useState();
  const { register, handleSubmit, errors } = useForm(); // initialise the hook

  const [selectedState, setSelectedState] = React.useState();

  const [suggestions, setSuggestions] = React.useState([]);
  const [value, setValue] = React.useState("");

  const [valueCityId, setValueCityId] = React.useState("");

  const [suggestionsOfOrigin, setSuggestionsOfOrigin] = React.useState([]);
  const [valueOfOrigin, setValueOfOrigin] = React.useState("");
  const [valueCityIdOfOrigin, setValueCityIdOfOrigin] = React.useState("");

  const [imageLoading, setImageLoading] = React.useState(false);

  const [error, SetError] = React.useState(false);

  const [showGenderRadioInput, setShowGenderRadioInput] = React.useState(false);

  const [countryCode, setCountryCode] = React.useState([]);

  const [cities, setCities] = React.useState([]);
  // const Compress = require("Compress.js");
  // const Compress = new Compress();
  const [hideModal, setHideModal] = React.useState(false);

  const handleChange = (date) => {
    // var now = new Date();
    // var selectdated = new Date(date.target.value)
    // if(now-selectdated > 643048021123){
    // console.log(now-selectdated)
    setStartDate(date.target.value);
    // }
    // else{
    //     console.log("Diff>>>>",now-selectdated  )
    //     alert("DOB can’t be less than 21 years")

    // }
  };
  console.log(moment(startDate).format("YYYY-MM-DD"));
  const loadAllCountries = () => {
    axios
      .post(Constants.getAllCountries, {})
      .then((response) => {
        setCountryCode([...response.data.result]);
      })
      .catch(console.error);
  };

  const loadAllCities = () => {
    axios
      .post(Constants.getAllCities, {})
      .then((response) => {
        setCities([...response.data.result]);
      })
      .catch(console.error);
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    return axios.post(Constants.searchCityDetails, {
      searchKey: value,
    });
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestionsOfOrigin = (value) => {
    return axios.post(Constants.searchCityDetails, {
      searchKey: value,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.

  // Autosuggest will call this function every time you need to clear suggestions.

  // Autosuggest will call this function every time you need to clear suggestions.

  const showModals = () => {
    return (
      <Modal
        show={hideModal}
        onHide={() => setHideModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Hello</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setHideModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const onSubmit = (data) => {
    // setHideModal(true);
    // if(value === '')
    // {
    //
    //     return false;
    // }

    // else
    if (!valueCityId) {
      SetError(true);
      return false;
    }
    if (!window.confirm("I confirm that I am a Surgeon.")) {
      return false;
    }

    console.log("country code", data.country_code.split("\r"));

    data.dob = moment(startDate).format("YYYY-MM-DD");
    data.id = Config.getSessionInfo("id");
    data.page_id = 1;
    data.cityName = selectedState;
    data.cityId = valueCityId;
    data.cityOfOrigin = valueOfOrigin;
    data.cityOfOriginId = valueCityIdOfOrigin;
    data.curruentTab = 1;
    data.mobile = data.mobile;
    data.country_code = data.country_code.split("\r")[0];
    data.updateSignupNumber = true;
    if (!loginDetails?.firstName) {
      // this means third party signin
      data.signup_type = 1;
      data.password = "third party signin";
    }

    axios
      // .post("http://localhost:3001/personal", data)
      .post(Constants.personal, data)
      .then((response) => {
        // reset();
        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 404) {
          tost.message = response.data.msg;
          tost.type = "error";
        } else if (response.data.code === 422) {
          tost.message = response.data.msg;
          tost.type = "error";
        } else if (response.data.code === 202) {
          tost.message = response.data.msg;
          tost.type = "success";
          props.history.push("/signup");
        } else if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          if (!loginDetails?.firstName) {
            let prevDetails = JSON.parse(sessionStorage.getItem("session"));
            sessionStorage.setItem(
              "session",
              JSON.stringify({
                ...prevDetails,
                full_name: data?.firstName + " " + data?.lastName,
              })
            );
          }
          props.history.push("/qualification");
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch((error) => {
        EventEmitters.showTost({
          type: "error",
          message: error?.response?.data?.msg,
        });
        console.log(error);
      });
  };

  // const onChangeValue = ev => {
  //     // console.log("suggestion>>>>>>>", suggestion);
  //     setValueCityId(ev.target.value);
  //     // return suggestion.name;
  // }

  // const getSuggestionOfOriginValue = suggestion => {

  //     return suggestion.name;
  // }

  const onChange = (event, { newValue }) => {
    if (newValue != "") {
      SetError(false);
    }
    setValue(newValue);
  };

  // const onChangeOfOrigin = (event, val) => {
  //     setValueOfOrigin(val.newValue);
  // };

  const inputProps = {
    placeholder: "Type min 3 characters to search",
    value,
    onChange: onChange,
    className: "form-control",
    autoComplete: "off",
  };

  // const inputPropsOfOrigin = {
  //     placeholder: 'Type min 3 characters to search',
  //     value: valueOfOrigin,
  //     onChange: onChangeOfOrigin,
  //     className: "form-control",
  //     autoComplete: "off",
  //     required: true
  // };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const onChangeUploadFile = (e) => {
    const files = [...e.target.files];
    const file = e.target && e.target.files[0];
    var t = file.type.split("/").pop().toLowerCase();
    if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
      alert("Please select a valid image file");
      // document.getElementById("img").value = '';
      return false;
    }

    const config = {
      headers: {
        token: Config.getSessionInfo("token"),
        id: Config.getSessionInfo("id"),
      },
    };

    const formData = new FormData();
    formData.append("profile_pic", files[0]);

    axios
      .post(Constants.uploadPic, formData, config)
      .then((response) => {
        console.log("response>>>>>>>>>>", response);
        // setImageLoading(false);
        console.log(
          "profile pic info>>>",
          Constants.server + response.data.fileInfo
        );
        setProfilePic(Constants.server + response.data.fileInfo);
      })
  };

  const onChangeGender = (e) => {
    // if (e.target.value == "F") {
    setShowGenderRadioInput(e.target.value == "F");
    // }
  };

  const option = cities.map((row) => {
    return { value: row.id, label: row.name };
  });

  return (
    <React.Fragment>
      <div className="content">
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span className="white">Step 1</span>
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Personal Information</h2>
                {showModals()}
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-8 col-xl-9">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="row form-row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <div className="change-avatar">
                            <div className="profile-img">
                              <img src={profilePic} alt="User Image" />
                            </div>
                            <div className="upload-img">
                              <div className="change-photo-btn">
                                {/* {
                                                                    imageLoading ?
                                                                        <div>
                                                                            {/* <Spinner
                                                                                as="span"
                                                                                animation="grow"
                                                                                size="lg"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                            /> */}
                                {/* <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                         */}
                                <span>
                                  <i className="fa fa-upload"></i> Upload Photo
                                </span>

                                <input
                                  type="file"
                                  accept="image/*"
                                  id="image"
                                  className="upload"
                                  onChange={onChangeUploadFile}
                                />
                              </div>
                              <small className="form-text text-muted">
                                Allowed JPG, GIF or PNG.
                                {/* Max size of 2MB */}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>First Name *</label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            ref={register({ required: true })}
                            {...(loginDetails?.firstName && {
                              value: loginDetails.firstName,
                            })}
                            disabled={loginDetails && loginDetails.firstName}
                            autoFocus
                          />
                        </div>
                        {errors.firstName && (
                          <div className="error-text" role="alert">
                            First name is required.
                          </div>
                        )}
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>Last Name *</label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            {...(loginDetails?.lastName && {
                              value: loginDetails.lastName,
                            })}
                            disabled={loginDetails && loginDetails.lastName}
                            ref={register({ required: true })}
                          />
                        </div>
                        {errors.lastName && (
                          <div className="error-text" role="alert">
                            Last name is required.
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>Date of Birth</label>
                          <div className="">
                            {/* <input name="dob" type="text" className="form-control datetimepicker"
                                                        />
                                                         */}
                            {/* <DatePicker
                                                            className="form-control datetimepicker"
                                                            name="dob"
                                                            ref={register({ required: true })}
                                                            selected={startDate}
                                                            onChange={handleChange}
                                                        /> */}
                            {/* <ReactDatez
                                                            disabled={false}
                                                            className="rdatez-custom"
                                                            inputClassName="form-control datetimepicker datetimepicker-input"
                                                            name="dob"
                                                            allowPast={true}
                                                            allowFuture={false}
                                                            handleChange={handleChange}
                                                            placeholder="Date of Birth"
                                                            value={startDate} /> */}
                            <input
                              type="date"
                              className="form-control datetimepicker datetimepicker-input"
                              name="dob"
                              onChange={handleChange}
                              placeholder="Date of Birth"
                              value={startDate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="login-or">
                        <span className="or-line1"></span>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>Email ID *</label>
                          <input
                            type="email"
                            name="emailId"
                            className="form-control"
                            value={loginDetails && loginDetails.email}
                            disabled
                            ref={register({ required: true })}
                          />
                        </div>
                        {errors.emailId && (
                          <div className="error-text" role="alert">
                            Email Id is required.
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          {/* <label>Current Location (City) *</label>
                                                    <Autosuggest

                                                        suggestions={suggestions}
                                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                        onSuggestionSelected={onSuggestionSelected}
                                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}

                                                    /> */}
                          {/* <input type="text" name="curruntLocation" className="form-control" /> */}
                          <label>
                            Current Location (City) *
                            <small>
                              (Please select "other", if city is not present)
                            </small>
                          </label>
                          {/* <select
                                                        name="city"
                                                        ref={register({ required: true })}
                                                        className="select form-control">
                                                            
                                                        <option value="" onChange={(ev)=>{onChangeValue(ev)}} selected="selected">Current Location (City) *</option>
                                                        <input onFocus={true} />
                                                        {
                                                            cities &&
                                                            cities.length &&
                                                            cities.map((opt) => {
                                                                return (
                                                                   
                                                                    <option value={opt.country_id}>{opt.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> */}
                          <Select
                            value={option.value}
                            name="city"
                            ref={register({ required: true })}
                            onChange={(option) => {
                              setSelectedState(option.label);
                              setValueCityId(option.value);
                            }}
                            options={option}
                            defaultInputValue={selectedState}
                          />
                        </div>

                        {error && (
                          <div className="error-text" role="alert">
                            Please enter current location
                          </div>
                        )}
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>City/Town of Origin (City)</label>
                          {/* <input name="cityTownOfOrigin" type="text" className="form-control" /> */}
                          {/* <Autosuggest
                                                        suggestions={suggestionsOfOrigin}
                                                        onSuggestionsFetchRequested={onSuggestionsOfOriginFetchRequested}
                                                        onSuggestionsClearRequested={onSuggestionsOfOriginClearRequested}
                                                        getSuggestionValue={getSuggestionOfOriginValue}
                                                        renderSuggestion={renderSuggestionOfOrigin}
                                                        inputProps={inputPropsOfOrigin}
                                                    /> */}
                          <Select
                            value={option.value}
                            onChange={(option) => {
                              setValueOfOrigin(option.label);
                              setValueCityIdOfOrigin(option.value);
                            }}
                            options={option}
                            defaultInputValue={selectedState}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>Country Code *</label>
                          <select
                            name="country_code"
                            ref={register({ required: true })}
                            className="select form-control"
                          >
                            <option value="" selected="selected">
                              Select Country Code
                            </option>
                            {countryCode &&
                              countryCode.length &&
                              countryCode.map((opt) => {
                                return (
                                  <option value={opt.phone_code}>
                                    {opt.name + " (" + opt.phone_code + ")"}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        {errors.country_code && (
                          <div className="error-text" role="alert">
                            Country Code is required.
                          </div>
                        )}
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>Mobile *</label>
                          <input
                            type="number"
                            name="mobile"
                            className="form-control"
                            ref={register({ required: true, maxLength: 12 })}
                          />
                        </div>
                        {errors.mobile && (
                          <div className="error-text" role="alert">
                            Mobile number is required.
                          </div>
                        )}
                      </div>

                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <label>Gender *</label>
                          <select
                            name="gender"
                            ref={register({ required: true })}
                            className="select form-control"
                            onChange={onChangeGender.bind(this)}
                          >
                            <option value="" selected="selected">
                              Select Gender
                            </option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                          </select>
                        </div>
                        {errors.gender && (
                          <div className="error-text" role="alert">
                            Gender is required.
                          </div>
                        )}
                      </div>

                      {showGenderRadioInput ? (
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <span>
                              {" "}
                              Do you want to be a part of the Women in surgery
                              Group?{" "}
                            </span>
                            <br />
                            <br />
                            <input
                              id="womenGroupYes"
                              type="radio"
                              name="femaleGroupAllow"
                              className=""
                              value={1}
                              checked={true}
                              ref={register({ required: true, maxLength: 10 })}
                              style={{ marginRight: "4px" }}
                            />
                            <label
                              style={{ marginRight: "16px" }}
                              for="womenGroupYes"
                            >
                              {" "}
                              Yes{" "}
                            </label>

                            <input
                              id="womenGroupNo"
                              style={{ marginLeft: "10px" }}
                              type="radio"
                              name="femaleGroupAllow"
                              className=""
                              value={0}
                              style={{ marginRight: "4px" }}
                              ref={register({ required: true, maxLength: 10 })}
                            />
                            <label for="womenGroupNo"> No </label>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <br />
                    <div className="form-group">
                      <div className="row form-row">
                        {/* <div className="submit-section" >
                                                    <button type="submit" className="btn btn-primary submit-btn">Save
													Changes</button>
                                                </div> */}
                        <div
                          style={{ marginLeft: "5px" }}
                          className="submit-section"
                        >
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                          >
                            {/* <NavLink to="/qualification"> */}
                            Save Changes & Next
                            {/* </NavLink> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent></FooterContent>
    </React.Fragment>
  );
};

export default PersonalInformation;
