// chat-slide
import React, { useState } from "react";
import Constants from "../configs/Constants";
import axios from "axios";
// import EventEmitters from '../configs/EventEmitters';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Config from "../configs/Config";
import NotificationClient from "../configs/notificationClient";
import moment from "moment";
import chatImage from "../assets/img/TM-white.png";
import socketIOClient from "socket.io-client";
// import Popup from "reactjs-popup";
import attachment from "../assets/img/attach.png";
import { Modal, Button, ListGroup, ProgressBar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import FooterContent from "./FooterContent";
import LoaderEmitters from "../configs/LoaderEmitters";
import { toast } from "react-toastify";
import EventEmitters from "../configs/EventEmitters";
import ConfirmationActionModal from "./ConfirmationActionModal";

let socket;
let chatRoomConfig = window.sessionStorage.getItem("chatRoomConfig");
var lastChatMsg = {};
var unReadMessages = {};

const Chat = (props) => {
  const [chatRooms, setChatRooms] = useState("");
  const [loginDetails, setLoginDetails] = useState({});
  const [curruntActiveChatName, setCurruntActiveChatName] = useState({});
  const [chatSendText, setChatSendText] = useState("");
  const [curruntChatRoomMessages, setCurruntChatRoomMessages] = useState([]);
  // const [uploadLoading, setUploadLoading] = React.useState(false);
  // const [chatUploadFile, setChatUploadFile] = React.useState('');
  const [hideModal, setHideModal] = React.useState(false);
  const [chatRoomDetails, setChatRoomDetails] = React.useState(false);
  const [uploadPercentage, setUploadPercentage] = React.useState(null);
  const [activeIdOfChat, setActiveIdOfChat] = React.useState(null);
  const [activeNameOfChat, setActiveNameOfChat] = React.useState(null);
  const [deleteMsgItem, setdeleteMsgItem] = useState(null);
  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  // const [lastChatMsg, setLastChatMsg] = React.useState({});

  const chatWindow = React.useRef();

  const deleteMsgFunction=()=>{}

  React.useEffect(() => {
    setLoginDetails(Config.getSessionInfo());
    const id = Config.getSessionInfo("id");

    console.log("chatRooms>>>>>>>>>>", chatRooms);
    console.log("NotificationClient>>>>>>>>>>", NotificationClient);
    // EventEmitters.showLoader({
    // 	loaderConfig: true
    // });
    // setTimeout(() => {
    // 	EventEmitters.showLoader({
    // 		loaderConfig: false
    // 	});
    // }, 5000);

    socket = socketIOClient(Constants.server);
    socket.on("update chat messages", (col) => {
      if (
        chatRoomConfig &&
        chatRoomConfig.indexOf(col.chatCategory + col.chatRoomId) > -1
      ) {
        // if(id===col.id){
        // if(col.chatCategory === "I") {
        getChartRoomDetails(id);
        // } else  {
        // getChatMessages(col);
        // }
        // }
      }
      // if (col.chatCategory === "G") {
      // } else if (col.chatCategory === "I") {
      // if (col.id == id || col.reciverId == id) {
      // getChatMessages(col);
      // }
      // }
      // else {
      // getChatMessages(col.chatRoomId + "#" + col.chatRoomName);
      // }
    });

    if (!id) {
      props.history.push("/login");
    } else {
      getChartRoomDetails(id);
      getUnReadMessages();
      // setTimeout()
      NotificationClient.register();
    }
  }, []);
  // React.useEffect(()=>{
  // 	chatRoomDetails && chatRoomDetails.chatRoomData && chatRoomDetails.chatRoomData.sort();
  // 	console.log(chatRoomDetails)
  // 	chatRoomDetails.chatRoomData.sort(compare);
  // },[chatRoomDetails])
  const getUnReadMessages = () => {
    const id = Config.getSessionInfo("id");

    axios.post(Constants.unReadCount, { id }).then((response) => {
      if (response && response.data && response.data.result)
        console.log("response getUnReadMessages>>>>>>>>>>>>", response);
      response.data.result.map((elm) => {
        unReadMessages[elm.chatRoomId] = elm.unread;
      });
      console.log("unReadMessages>>>>>>>>>>>>>>>>>>>", unReadMessages);
    });
  };

  const send = (activeChatName) => {
    socket.emit("update chat messages", {
      // chatRoomName: activeChatName.chatRoomName || curruntActiveChatName.chatRoomName,
      ...curruntActiveChatName,
      id: loginDetails.id,
      // socketToId:
    }); // change 'red' to this.state.color
    // getChartRoomDetails(loginDetails.id);
  };

  const getChartRoomDetails = (id) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    axios
      .post(Constants.chatRooms, {
        id: id,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        console.log("chat rooms>>>>>>>>>>>>>>>", response);
        setChatRooms(response.data.chatRooms);

        const tempChatRoomCoinfig = [];
        // const lastChatMsgTemp = {};

        response.data.chatRooms.map((elm) => {
          elm.chatRoomId = isNaN(elm.chatRoomId)
            ? elm.chatRoomId.trim()
            : elm.chatRoomId;
          tempChatRoomCoinfig.push(elm.chatCategory.trim() + elm.chatRoomId);
          // lastChatMsgTemp[elm.chatRoomId] = elm.lastMessage;
          lastChatMsg[elm.chatRoomId] = elm.lastMessage;
        });

        window.sessionStorage.setItem("chatRoomConfig", tempChatRoomCoinfig);
        chatRoomConfig = tempChatRoomCoinfig;
        if (response.data.chatRooms && response.data.chatRooms[0]) {
          setCurruntActiveChatName({ ...response.data.chatRooms[0] });
          // debugger;
          // setLastChatMsg({...lastChatMsgTemp});
          lastChatMsg = {
            ...lastChatMsg,
          };
          getChatMessages(response.data.chatRooms[0]);
        }
      });
  };

  const getChatMessages = (elm) => {
    // const { chatRoomId, chatRoomName, chatCategory } = elm;
    // debugger;

    setCurruntActiveChatName({
      ...elm,
      // lastMessage: chatMessagesLastObj.message
    });

    LoaderEmitters.showLoader({
      showLoader: true,
    });
    axios
      .post(Constants.getChatMessages, {
        // chatRoomId,
        // chatRoomName,
        // chatCategory
        id: loginDetails.id,
        ...elm,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        console.log("response getChatMessages>>>>>>>>", response);
        const { chatMessages } = response.data;
        if (chatMessages && chatMessages.length) {
          const chatMessagesLastObj = chatMessages[chatMessages.length - 1];
          // debugger;
          // setLastChatMsg({
          // 	...lastChatMsg,
          // 	[chatMessagesLastObj.chat_room_solo_id] : chatMessagesLastObj.message
          // });

          // lastChatMsg = {
          // 	...lastChatMsg,
          // 	[chatMessagesLastObj.chat_room_solo_id]: chatMessagesLastObj.message
          // }
          // setCurruntActiveChatName({
          // 	...elm,
          // 	lastMessage: chatMessagesLastObj.message
          // });
          unReadMessages = {
            ...unReadMessages,
            [chatMessagesLastObj.chat_room_solo_id]: 0,
          };
          // setReadNotifications(chatMessagesLastObj.chat_room_solo_id);

          console.log("unReadMessages>>>>>>>>>>", unReadMessages);
        }
        setCurruntChatRoomMessages(chatMessages);

        setTimeout(() => {
          const objDiv = document.getElementById("chatScroll");
          if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
          }
        }, 300);
      });
  };

  const onChangeTextBox = (e) => {
    const { value } = e.target;

    if (value) {
      setChatSendText(value);
    } else {
      setChatSendText("");
    }
  };

  const sendMessage = (type = 1) => {
    //1=text, 2= docuement, 3 = image

    LoaderEmitters.showLoader({
      showLoader: true,
    });
    // curruntActiveChatName.chatRoomId
    // setLastChatMsg({
    // 	...lastChatMsg,
    // 	[curruntActiveChatName.chatRoomId]: chatSendText
    // })
    lastChatMsg = {
      ...lastChatMsg,
      [curruntActiveChatName.chatRoomId]: chatSendText,
    };

    axios
      .post(Constants.sendChatMessage, {
        // curruntActiveChatName: curruntActiveChatName.chatRoomName,
        ...curruntActiveChatName,
        chatSendText,
        sender: loginDetails.full_name,
        id: loginDetails.id,
        type,
      })
      .then((response) => {
        console.log("response sendMessage>>>>>>>>", response);
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        setChatSendText("");
        // getChatMessages(curruntActiveChatName);
        send(curruntActiveChatName);
      });
  };

  const setReadNotifications = (chatRoomId) => {
    // updateUnReadCount
    axios.post(Constants.updateUnReadCount, {
      chatRoomId,
    });
  };

  const onEnterChatTextBox = (e) => {
    if (e.key === "Enter") {
      sendMessage(1);
    }
  };

  const displayTime = (time) => {
    if (moment().isSame(time, "day")) {
      return moment(time).format("LT") + " ( " + moment(time).fromNow() + " )";
    } else {
      return moment(time).format("LLL");
    }
  };

  const getChatRoomDetails = (elm) => {
    const { chatRoomName, chatCategory } = elm;
    if (chatCategory === "I") {
      props.history.push("/profile/" + elm.reciverId);
      return;
    }
    if (chatCategory !== "G" && chatCategory != "S") {
      return;
    }

    LoaderEmitters.showLoader({
      showLoader: true,
    });
    axios
      .post(Constants.getChatRoomDetails, {
        id: loginDetails.id,
        ...elm,
        // chatRoomId,
        // chatRoomName,
        // chatCategory
      })
      .then((response) => {
        console.log("response getChatRoomDetails>>>>>>>>", response);
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        setHideModal(true);
        getNumbers(elm);
        setChatRoomDetails({
          chatRoomData: response.data.result.sort(dynamicSort("full_name")),
          chatRoomName,
          leaveGroupData: {
            ...elm,
            id: loginDetails.id,
          },
        });
        // chatRoomDetails.chatRoomData;
        // const { chatMessages } = response.data;
        // setCurruntChatRoomMessages(chatMessages);
        // setTimeout(() => {
        // 	const objDiv = document.getElementById("chatScroll");
        // 	objDiv.scrollTop = objDiv.scrollHeight;
        // }, 300);
      });
  };

  // const getChatNames = (elm) => {
  // 	// console.log("getChatNames>>>>>>>>", elm);
  // 	if (!elm.indexOf) return;
  // 	if (elm.indexOf("#") > -1) {
  // 		const tempChantName = elm.split("#");
  // 		return {
  // 			chatRoomId: tempChantName[0],
  // 			chatRoomName: tempChantName[1],
  // 			chatCategory: 'S'
  // 		};
  // 	} else {
  // 		return {
  // 			chatRoomId: elm,
  // 			chatRoomName: elm,
  // 			chatCategory: 'G'
  // 		};
  // 	}
  // }

  const onChangeFile = (e) => {
    const file = e.target && e.target.files[0];
    let toastId = null;
    if (file && file.size && file.size / 1048576 > 20) {
      const tost = {};
      tost.type = "error";
      tost.message = "File size should not be exceed more than 20MB.";
      EventEmitters.showTost(tost);
      return;
    }
    if (file) {
      // setUploadLoading(true);
      LoaderEmitters.showLoader({
        showLoader: true,
      });

      const formData = new FormData();
      formData.append("chatFile", file);
      let type = 2;
      if (file.type.search(/jpeg|jpg|png|gif/) > -1) {
        type = 3;
      }
      const config = {
        headers: {
          token: Config.getSessionInfo("token"),
          ...curruntActiveChatName,
          id: loginDetails.id,
          type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log("onUploadProgress p>>>>>>", progressEvent);
          // console.log("onUploadProgress p.loaded / p.total>>>>>>", progressEvent.loaded / progressEvent.total);
          // console.log("onUploadProgress p.loaded / p.total>>>>>>", percentCompleted);
          setUploadPercentage(percentCompleted);
          // if(toastId === null){
          // 	toastId = toast.info('Upload in Progress', {
          // 	progress: percentCompleted,
          // 	autoClose: false,
          // 	closeOnClick: true
          // 	});
          // } else {
          // 	toast.update(toastId, {
          // 	progress: percentCompleted
          // 	})
          // }
        },
      };

      axios
        .post(Constants.uploadChatFile, formData, config)
        .then((response) => {
          // console.log("response>>>>>>>>>>", response);

          // setUploadLoading(false);
          // setChatUploadFile(Constants.chatFilePath + response.data.fileInfo);
          setTimeout(() => {
            send(curruntActiveChatName);
          }, 300);
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          toast.done(toastId);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const download = (filepath) => {
    //  = filepath.split(".")[1];
    var splitArr = filepath.split(".");
    const ext = splitArr[splitArr.length - 1];
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    axios({
      url: filepath, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      LoaderEmitters.showLoader({
        showLoader: false,
      });
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "file_" + moment().format("d-M-Y") + "." + ext;
      link.click();
    });
  };

  const viewUserProfile = (elm) => {};

  const chatNowUser = (elm) => {
    // console.log("elm>>>>>>>>>>>", elm);
    debugger;
    // setActiveOfChatPerson(elm);
    if (elm.signup_id === loginDetails.id) {
      props.history.push("/profile/" + loginDetails.id);
    } else {
      LoaderEmitters.showLoader({
        showLoader: true,
      });
      axios
        .post(Constants.chatRoomSolo, {
          senderId: loginDetails.id,
          reciverId: elm.signup_id,
        })
        .then((response) => {
          console.log("response getChatRoomDetails>>>>>>>>", response);
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          setHideModal(false);
          getChartRoomDetails(loginDetails.id);
        });
    }
  };

  const leaveGroup = (chatRoomDetails) => {
    if (window.confirm("Are you sure you want to leave the group?")) {
      axios
        .post(Constants.leaveGroup, {
          ...chatRoomDetails.leaveGroupData,
        })
        .then((response) => {
          console.log("response leaveGroup>>>>>>>>", response);
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          setHideModal(false);
          getChartRoomDetails(loginDetails.id);
        });
    }
  };

  const getNumbers = () => {
    return chatRoomDetails &&
      chatRoomDetails.chatRoomData &&
      chatRoomDetails.chatRoomData.length === 1
      ? " 1 Member "
      : (+" " + chatRoomDetails.chatRoomData &&
          chatRoomDetails.chatRoomData.length) + " Members ";
  };
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const showModals = () => {
    return (
      <Modal
        show={hideModal}
        onHide={() => setHideModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {chatRoomDetails.chatRoomName}({getNumbers()})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Centered Modal</h4> */}
          <ListGroup>
            {chatRoomDetails &&
              chatRoomDetails.chatRoomData &&
              chatRoomDetails.chatRoomData.map((elm, idx) => {
                return (
                  <>
                    {elm.signup_id === loginDetails.id ? (
                      <ListGroup.Item className="fs-14 chat-modal-list">
                        <div className="avatar avatar-online">
                          <img
                            src={Constants.server + elm.profile_pic}
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <Button
                          variant="link"
                          onClick={chatNowUser.bind(this, elm)}
                        >
                          {elm.full_name}
                        </Button>

                        <Button
                          className="push-right"
                          onClick={leaveGroup.bind(this, chatRoomDetails)}
                          variant="link"
                        >
                          Leave Group
                        </Button>
                      </ListGroup.Item>
                    ) : null}
                  </>
                );
              })}

            {chatRoomDetails &&
              chatRoomDetails.chatRoomData &&
              chatRoomDetails.chatRoomData.map((elm, idx) => {
                return (
                  <>
                    {elm.signup_id != loginDetails.id ? (
                      <ListGroup.Item className="fs-14 chat-modal-list">
                        <React.Fragment>
                          <div className="avatar avatar-online">
                            <img
                              src={Constants.server + elm.profile_pic}
                              alt="User Image"
                              className="avatar-img rounded-circle"
                            />
                          </div>
                          <Button
                            variant="link"
                            onClick={chatNowUser.bind(this, elm)}
                          >
                            {elm.full_name}
                          </Button>
                          {/* // <Button className="push-right" onClick={leaveGroup.bind(this, chatRoomDetails)} variant="link" >Leave Group</Button> */}

                          <Button
                            style={{
                              border: "2px solid #0ddbfb",
                              marginLeft: "10px",
                            }}
                            className="push-right"
                            variant="link"
                            onClick={viewUserProfile.bind(this, elm)}
                          >
                            <NavLink
                              style={{ color: "#121212" }}
                              to={"/profile/" + elm.signup_id}
                            >
                              {" "}
                              View Profile{" "}
                            </NavLink>
                          </Button>
                          <Button
                            style={{ border: "2px solid #0ddbfb" }}
                            className="push-right"
                            variant="link"
                            onClick={chatNowUser.bind(this, elm)}
                          >
                            Chat Now
                          </Button>
                        </React.Fragment>
                      </ListGroup.Item>
                    ) : null}
                  </>
                );
              })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setHideModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const setActiveOfChatPerson = (elm, e) => {
    // const { currentTarget } = e;
    // if(currentTarget) {
    // currentTarget.classList.add('active');
    chatWindow.current.classList.add("chat-slide");
    // }
    setActiveIdOfChat(elm.chatRoomId);
    setActiveNameOfChat(elm.chatRoomName);
  };

  const closeChatWindowOnMobile = () => {
    chatWindow.current.classList.remove("chat-slide");
  };

  const deleteMsg = (elm, idx, ) => {
    console.log("curruntActiveChatName>>>>>>>>>>>", curruntActiveChatName);
    // if (window.confirm("Are you sure you want to delete the message?")) {
      axios
        .post(Constants.deleteMsg, {
          ...curruntActiveChatName,
          msgId: deleteMsgItem.id,
        })
        .then((response) => {
          console.log("deleteMsg>>>>>>>>>>>>>>>>>>>>", response);
          let curruntChatRoomMessagesTemp = [...curruntChatRoomMessages];
          curruntChatRoomMessagesTemp.splice(deleteMsgItem.idx, 1);
          setCurruntChatRoomMessages([...curruntChatRoomMessagesTemp]);
          if (curruntActiveChatName.lastMessage == deleteMsgItem.message) {
            lastChatMsg[deleteMsgItem.chat_room_solo_id] = null;
          }
          // send();
        });
		setDeleteConfirmationVisible(false)
    // }
  };

  const deleteChatRommsSolo = (elm) => {
    if (window.confirm("Are you sure you want to delete the chat?")) {
      axios
        .post(Constants.deleteChatRommsSolo, {
          ...elm,
        })
        .then((response) => {
          console.log("deleteChatRommsSolo>>>>>>>>>>>>>>>>>>>>", response);
          getChartRoomDetails(loginDetails.id);
        });
    }
  };

  return (
    <React.Fragment>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div className="chat-window" ref={chatWindow}>
                {showModals()}
                <div className="chat-cont-left">
                  <div className="chat-header">
                    <span>Chats</span>
                    {/* <a href="" className="chat-compose">
											<i className="material-icons">control_point</i>
										</a> */}
                  </div>
                  {/* <form className="chat-search" autoComplete="off">
										<div className="input-group">
											<div className="input-group-prepend">
												<i className="fas fa-search"></i>
											</div>
											<input type="text" className="form-control" placeholder="Search" />
										</div>
									</form> */}
                  <div className="chat-users-list">
                    <div className="chat-scroll">
                      {chatRooms &&
                        chatRooms.length &&
                        chatRooms.map((elm, idx) => {
                          return (
                            <div
                              key={idx}
                              className={
                                "media read-chat" +
                                (elm.chatRoomId === activeIdOfChat
                                  ? "active"
                                  : "")
                              }
                              onClick={setActiveOfChatPerson.bind(this, elm)}
                            >
                              <div
                                className="media-img-wrap"
                                onClick={getChatRoomDetails.bind(this, elm)}
                              >
                                <div className="avatar avatar-online">
                                  {elm.chatCategory === "I" ? (
                                    // <NavLink to={'/profile/' + elm.reciverId}>
                                    <img
                                      src={Constants.server + elm.profilePic}
                                      alt="User Image"
                                      className="avatar-img rounded-circle"
                                    />
                                  ) : (
                                    // </NavLink>
                                    <img
                                      src={chatImage}
                                      alt="User Image"
                                      className="avatar-img rounded-circle"
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                className="media-body"
                                onClick={getChatMessages.bind(this, elm)}
                              >
                                {/* {elm.chatRoomName != "" ? ( */}
                                <div>
                                  <div className="user-name">
                                    {elm.chatRoomName != null &&
                                    elm.chatRoomName.includes("#")
                                      ? elm.chatRoomName.slice(
                                          0,
                                          elm.chatRoomName.length - 1
                                        )
                                      : elm.chatRoomName}
                                  </div>
                                  <div className="user-last-chat">
                                    {/* Here is group of {elm.chatRoomName} */}
                                    {  elm.chatCategory == "I" ? (
                                     elm?.lastMessage?decodeURIComponent(elm.lastMessage):""
                                    ) : (
                                      <>
                                        { decodeURIComponent(elm.lastMessage) ||
                                        "Here is group of " +
                                          elm.chatRoomName.includes("#")
                                          ? elm.chatRoomName.slice(
                                              0,
                                              elm.chatRoomName.length - 1
                                            )
                                          : elm.chatRoomName}
                                      </>
                                    )}
                                  </div>
                                </div>
                                {/* ):null} */}
                                <div>
                                  <div className="last-chat-time block">
                                    {/* 2 min */}
                                  </div>
                                  <div className="badge badge-success badge-pill">
                                    {unReadMessages[elm.chatRoomId] || null}
                                  </div>
                                </div>
                                <React.Fragment>
                                  {elm.chatCategory == "I" ? (
                                    // <span className="fa fa-trash"
                                    // 	onClick={deleteChatRommsSolo.bind(this, elm)}
                                    // 	style={{ color: '#ff5722', marginTop: '12px' }}></span>
                                    <div></div>
                                  ) : null}
                                </React.Fragment>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="chat-cont-right">
                  <div className="chat-header" style={{ marginTop: "8px" }}>
                    <span
                      id="back_user_list"
                      href=""
                      className="back-user-list"
                      onClick={closeChatWindowOnMobile}
                    >
                      <i className="material-icons">chevron_left</i>
                    </span>
                    <div
                      className="media"
                      onClick={getChatRoomDetails.bind(
                        this,
                        curruntActiveChatName
                      )}
                    >
                      <div className="media-img-wrap">
                        <div className="avatar avatar-online">
                          {curruntActiveChatName.profilePic ? (
                            <img
                              src={
                                Constants.server +
                                curruntActiveChatName.profilePic
                              }
                              alt="User Image assssss"
                              className="avatar-img rounded-circle"
                            />
                          ) : (
                            <img
                              src={chatImage}
                              alt="User Image assssss"
                              className="avatar-img rounded-circle"
                            />
                          )}

                          {/* <img src={Constants.server + curruntActiveChatName.profilePic} alt="User Image assssss" className="avatar-img rounded-circle" /> */}
                        </div>
                        {console.log(
                          curruntActiveChatName.chatRoomName
                            ? curruntActiveChatName.chatRoomName.length
                            : null
                        )}
                        <div className="user-name-chat">
                          {curruntActiveChatName.chatRoomName
                            ? curruntActiveChatName.chatRoomName.includes("#")
                              ? curruntActiveChatName.chatRoomName.slice(
                                  0,
                                  curruntActiveChatName.chatRoomName.length - 1
                                )
                              : curruntActiveChatName.chatRoomName
                            : null}
                        </div>
                      </div>
                      <div className="media-body">
                        {/* <div className="user-name">{curruntActiveChatName.chatRoomName}</div> */}
                        {/* <div className="user-status">online</div> */}
                      </div>
                    </div>
                    {/* <div className="chat-options">
											<a href="" data-toggle="modal" data-target="#voice_call">
												<i className="material-icons">local_phone</i>
											</a>
											<a href="" data-toggle="modal" data-target="#video_call">
												<i className="material-icons">videocam</i>
											</a>
											<a href="">
												<i className="material-icons">more_vert</i>
											</a>
										</div> */}
                  </div>
                  <div className="chat-body">
                    {uploadPercentage !== null && uploadPercentage !== 100 ? (
                      <ProgressBar
                        animated
                        now={uploadPercentage}
                        label={`Uploading...${uploadPercentage}%`}
                      />
                    ) : null}
                    <div className="chat-scroll" id="chatScroll">
                      <ul className="list-unstyled">
                        {curruntChatRoomMessages &&
                        curruntChatRoomMessages.length ? (
                          curruntChatRoomMessages.map((elm, idx) => {
                            console.log("elm chat", elm, loginDetails);
                            // curruntChatRoomMessages.slice(0).reverse().map((elm, idx) => {
                            return elm.sender_id === loginDetails.id ? (
                              <li className="media sent" key={idx}>
                                <div className="media-body">
                                  <sup
                                    className="delete-chat"
                                    onClick={()=>{
										setdeleteMsgItem({...elm,idx})
										setDeleteConfirmationVisible(true)
									}}
                                  >
                                    <i className="far fa-times-circle"></i>
                                    {/* <i class="fas fa-trash"></i> */}
                                  </sup>
                                  <div className="msg-box">
                                    {elm.type === 1 ? (
                                      <div>
                                        <p>{decodeURIComponent(elm.message)}</p>

                                        <ul className="chat-msg-info">
                                          <li>
                                            <div className="chat-time">
                                              <span>
                                                {displayTime(
                                                  elm.created_time_date
                                                )}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : elm.type === 3 ? (
                                      <div>
                                        <div className="chat-msg-attachments">
                                          <div className="chat-attachment">
                                            <img
                                              src={
                                                Constants.chatFilePath +
                                                elm.message
                                              }
                                              alt="Attachment"
                                            />
                                            <div className="chat-attach-caption"></div>
                                            <span
                                              onClick={download.bind(
                                                this,
                                                Constants.chatFilePath +
                                                  elm.message
                                              )}
                                              className="chat-attach-download"
                                            >
                                              <i className="fas fa-download"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <ul className="chat-msg-info">
                                          <li>
                                            <div className="chat-time">
                                              <span>
                                                {displayTime(
                                                  elm.created_time_date
                                                )}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="chat-msg-attachments">
                                          <div className="chat-attachment">
                                            <img
                                              src={attachment}
                                              alt="Attachment"
                                            />
                                            <div className="chat-attach-caption">
                                              {decodeURIComponent(elm.message)}
                                            </div>
                                            <span
                                              onClick={download.bind(
                                                this,
                                                Constants.chatFilePath +
                                                  elm.message
                                              )}
                                              className="chat-attach-download"
                                            >
                                              <i className="fas fa-download"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <ul className="chat-msg-info">
                                          <li>
                                            <div className="chat-time">
                                              <span>
                                                {displayTime(
                                                  elm.created_time_date
                                                )}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ) : // activeIdOfChat != null && elm.full_name === activeNameOfChat ?
                            true ? (
                              <li className="media received" key={idx}>
                                <div className="avatar">
                                  {/* chatImage */}
                                  <img
                                    src={Constants.server + elm.profile_pic}
                                    alt="User Image"
                                    className="avatar-img rounded-circle"
                                  />
                                </div>
                                <div className="media-body">
                                  <div className="msg-box">
                                    {elm.type === 1 ? (
                                      <div>
                                        <NavLink
                                          to={"/profile/" + elm.sender_id}
                                        >
                                          <small className="strong">
                                            {elm.full_name}
                                          </small>
                                        </NavLink>
                                        <p>{decodeURIComponent(elm.message)}</p>
                                        <ul className="chat-msg-info">
                                          <li>
                                            <div className="chat-time">
                                              <span>
                                                {displayTime(
                                                  elm.created_time_date
                                                )}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : elm.type === 3 ? (
                                      <div>
                                        <div className="chat-msg-attachments">
                                          <div className="chat-attachment">
                                            <img
                                              src={
                                                Constants.chatFilePath +
                                                elm.message
                                              }
                                              alt="Attachment"
                                            />
                                            <div className="chat-attach-caption"></div>
                                            <span
                                              onClick={download.bind(
                                                this,
                                                Constants.chatFilePath +
                                                  elm.message
                                              )}
                                              className="chat-attach-download"
                                            >
                                              <i className="fas fa-download"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <ul className="chat-msg-info">
                                          <li>
                                            <div className="chat-time">
                                              <span>
                                                {displayTime(
                                                  elm.created_time_date
                                                )}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="chat-msg-attachments">
                                          <div className="chat-attachment">
                                            <img
                                              src={attachment}
                                              alt="Attachment"
                                            />
                                            <div className="chat-attach-caption">
                                              {decodeURIComponent(elm.message)}
                                            </div>
                                            <span
                                              onClick={download.bind(
                                                this,
                                                Constants.chatFilePath +
                                                  elm.message
                                              )}
                                              className="chat-attach-download"
                                            >
                                              <i className="fas fa-download"></i>
                                            </span>
                                          </div>
                                        </div>
                                        <ul className="chat-msg-info">
                                          <li>
                                            <div className="chat-time">
                                              <span>
                                                {displayTime(
                                                  elm.created_time_date
                                                )}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            ) : null;
                          })
                        ) : (
                          <div className="well text-center">
                            <p className="alert alert-light">
                              There are no messages in this group
                            </p>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="chat-footer">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="btn-file btn">
                          <i className="fa fa-paperclip"></i>
                          <input
                            type="file"
                            name="chatFile"
                            onChange={onChangeFile}
                          />
                        </div>
                      </div>
                      <input
                        type="text"
                        value={chatSendText}
                        onChange={onChangeTextBox}
                        onKeyUp={onEnterChatTextBox}
                        className="input-msg-send form-control"
                        placeholder="Type something"
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          onClick={sendMessage.bind(this, 1)}
                          className="btn msg-send-btn"
                          id="toggle"
                        >
                          <i className="fab fa-telegram-plane"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <ConfirmationActionModal
        modalTitle={"Delete Message"}
        actionHandler={deleteMsg}
        isVisible={isDeleteConfirmationVisible}
        setVisible={() => setDeleteConfirmationVisible((oldState) => !oldState)}
        actionBtnText={"Delete"}
      >
        <div style={{ fontSize: "1rem" }}>
          Are you sure you want to delete this message?
        </div>
      </ConfirmationActionModal>
      <FooterContent></FooterContent>
    </React.Fragment>
  );
};

export default Chat;

{
  /* <div className="msg-box">
	<div>
		<div className="chat-msg-attachments">
			<div className="chat-attachment">
				<img src="assets/img/img-02.jpg" alt="Attachment" />
				<div className="chat-attach-caption"></div>
				<a href="" className="chat-attach-download">
					<i className="fas fa-download"></i>
				</a>
			</div>
			<div className="chat-attachment">
				<img src="assets/img/img-03.jpg" alt="Attachment" />
				<div className="chat-attach-caption"></div>
				<a href="" className="chat-attach-download">
					<i className="fas fa-download"></i>
				</a>
			</div>
		</div>
		<ul className="chat-msg-info">
			<li>
				<div className="chat-time">
					<span>8:41 AM</span>
				</div>
			</li>
		</ul>
	</div>
</div> */
}
