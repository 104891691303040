import React, { Fragment, useEffect, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
// import ReactPlayer from "react-player";
import doctorThumbImage from '../assets/img/doctors/doctor-thumb-01.jpg';

const Meeting = (props) => {

    const videoSection = createRef();

    return (
        <Fragment>
            <div className="main-wrapper" ref={videoSection}>
                <div className="banner-header text-center">
                    {/* <h1 style={{ color: '#2272a1', textTransform: 'unset', fontSize: ' 3.25rem', marginTop: '25px', marginBottom: '25px' }}>
                        Media
                </h1> */}
                </div>

                <section className="section section-blogs" ref={videoSection}>
                    <div className="container-fluid">
                        <div className="section-header text-center">
                            <h2 style={{ color: '#2272a1', textTransform: 'unset', fontSize: ' 3.25rem' }}> Academics </h2>


                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                            <li className="nav-item">
                                                <NavLink activeClassName="active" className="nav-link" to="/media">
                                                    Images
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/video">
                                                    Video
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/event">
                                                    Event
                                                    </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/meeting">
                                                    Meetings
                                                        </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <p className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                        <li className="nav-item">
                                            <NavLink  className="nav-link" to="/meeting">
                                                Past Meetings
                                                </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink activeClassName="active" className="nav-link" to="/live">
                                                Ongoing Live Meetings
                                                </NavLink>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row align-items-center justify-content-center">

                            <div className="col-md-4">

                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        {/* <ReactPlayer
                                            url="https://www.youtube.com/watch?v=0MISwf-t0Xw"
                                        /> */}
                                        <div class="embed-responsive embed-responsive-16by9">
                                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/0MISwf-t0Xw" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <span><h3 className="blog-title"><a href="blog-details.html">Meeting Title :</a></h3></span>
                                                </div>
                                            </li>
                                            <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                        </ul>
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Acute care Surgery</a></h3> */}
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Doccure – Making your clinic painless
										visit?</a></h3> */}
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">

                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        {/* <ReactPlayer
                                            url="https://www.youtube.com/watch?v=iW4E4f-eag4"
                                        /> */}
                                        <div class="embed-responsive embed-responsive-16by9">
                                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/iW4E4f-eag4" allowfullscreen></iframe>
                                        </div>
                                    </div>
                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <span><h3 className="blog-title"><a href="blog-details.html">Meeting Title :</a></h3></span>
                                                </div>
                                            </li>
                                            <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                        </ul>
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Acute care Surgery</a></h3> */}
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Doccure – Making your clinic painless
										visit?</a></h3> */}
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-all text-center">
                            <a href="blog-list.html" className="btn btn-primary">View All</a>
                        </div>
                    </div>

                </section>
            </div>
            <FooterContent></FooterContent>
        </Fragment >
    )
}

export default Meeting;