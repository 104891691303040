import React from "react";
import { Tab } from "semantic-ui-react";
import "../assets/css/custome.css";
import Constants from "../configs/Constants";
import UploadedData from "./UploadedData";

const getPanes = ({ images, videos, reports, scans }) => [
    {
        menuItem: "Images",
        render: () => (
            <Tab.Pane attached={false}>
                <UploadedData
                    type="image"
                    data={images}
                    contents={images.map((image) => (
                        <>
                            <div class="content2-overlay"></div>
                            <img
                                style={{ width : "100%" }}
                                class="content2-image"
                                src={Constants.server + image.file_path}
                            />{" "}
                            <div class="content2-details fadeIn-bottom">
                                <p class="content2-text">View Image</p>
                            </div>
                        </>
                    ))}
                />
            </Tab.Pane>
        ),
    },
    {
        menuItem: "Videos",
        render: () => (
            <Tab.Pane attached={false}>
                <UploadedData
                    contents={videos.map((video) => (
                        <video
                            width="100%"
                            height="200"
                            controls
                            style={{ borderRadius: "10px" }}
                        >
                            <source
                                src={Constants.server + video.file_path}
                                type={video.mime_type}
                            />
                        </video>
                    ))}
                />
            </Tab.Pane>
        ),
    },
    {
        menuItem: "Reports",
        render: () => (
            <Tab.Pane attached={false}>
                <UploadedData
                    style={{ margin: "20px" }}
                    contents={reports.map((report) => (
                        <button
                            style={{
                                borderRadius: "5px",
                                backgroundColor: "white",
                                color: "#096d9c",
                                border: "2px solid #096d9c",
                                padding: "20px",
                                width: "80%",
                                marginBottom: "20px",
                            }}
                        >
                            <a
                                style={{ color: "#096d9c" }}
                                href={Constants.server + report.file_path}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {report.name}
                            </a>
                        </button>
                    ))}
                />
            </Tab.Pane>
        ),
    },
    // {
    //     menuItem: "Scans",
    //     render: () => (
    //         <Tab.Pane attached={false}>
    //             <UploadedData
    //                 contents={scans.map((scan) => (
    //                     <button
    //                         style={{
    //                             borderRadius: "5px",
    //                             backgroundColor: "white",
    //                             color: "#096d9c",
    //                             border: "2px solid #096d9c",
    //                             padding: "20px",
    //                             width: "80%",
    //                             marginBottom: "20px",
    //                         }}
    //                     >
    //                         <a
    //                             style={{ color: "#096d9c" }}
    //                             href={Constants.server + scan.file_path}
    //                             target="_blank"
    //                             rel="noopener noreferrer"
    //                         >
    //                             {scan.name}
    //                         </a>
    //                     </button>
    //                 ))}
    //                 // content={
    //                 //   <>
    //                 //     <iframe
    //                 //       style={{ height: "200px" }}
    //                 //       class="content2-image"
    //                 //       src="https://images.unsplash.com/photo-1433360405326-e50f909805b3?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&w=1080&fit=max&s=359e8e12304ffa04a38627a157fc3362"
    //                 //     ></iframe>
    //                 //   </>
    //                 // }
    //             />
    //         </Tab.Pane>
    //     ),
    // },
];

const getPanes2 = (diagnosis, history, questions) => [
    {
        menuItem: "Diagnosis",
        render: () => (
            <Tab.Pane attached={false}>
                <div className="doc-review" style={{ paddingTop: "20px" }}>
                    <h3 style={{ fontSize: "20px" }}>Diagnosis</h3>
                    <p style={{ fontSize: "13px" }}>
                        {!!diagnosis ? diagnosis : "No diagnosis Available"}
                    </p>
                </div>
            </Tab.Pane>
        ),
    },
    {
        menuItem: "History",
        render: () => (
            <Tab.Pane attached={false}>
                <div className="doc-review" style={{ paddingTop: "20px" }}>
                    <h3 style={{ fontSize: "20px" }}>History</h3>
                    <p style={{ fontSize: "13px" }}>
                        {!!history ? history : "No history Available"}
                    </p>
                </div>
            </Tab.Pane>
        ),
    },
    {
        menuItem: "Questions",
        render: () => (
            <Tab.Pane attached={false}>
                <div className="doc-review" style={{ paddingTop: "20px" }}>
                    <h3 style={{ fontSize: "20px" }}>Questions</h3>
                    <div>
                        <p style={{ fontSize: "13px", marginBottom: "3px" }}>
                            {!!questions
                                ? questions
                                      .split("\n")
                                      .map((line) => <div>{line}</div>)
                                : "No questions Available"}
                        </p>
                    </div>
                </div>
            </Tab.Pane>
        ),
    },
];

const CaseDetailTabs = ({
    diagnosis,
    questions,
    history,
    images,
    videos,
    reports,
    scans,
}) => {
    return (
        <div className="file-carousel">
            <Tab
                className="file-upload-tabs"
                menu={{ attached: false }}
                panes={getPanes({ images, videos, reports, scans })}
            />
            <Tab
                className="file-upload-tabs"
                menu={{ attached: false }}
                panes={getPanes2(diagnosis, history, questions)}
            />
        </div>
    );
};

export default CaseDetailTabs;
