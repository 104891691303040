import React, { Fragment, useEffect, createRef, useState } from "react";
import { NavLink } from "react-router-dom";
import FooterContent from "./FooterContent";
import blogTwoImage from "../assets/img/pngwave (1).png";
import sfs1 from "../assets/img/SFS_EVENT.jpeg";
import sfs2 from "../assets/img/SFS_EVENT-2.jpeg";
import eventImg from "../assets/img/upcoming_events/6-11.png";
import apis from "../apis/apis";
import sfs3 from "../assets/img/sfs_03.jpg";
import { Link } from "react-router-dom";
// import ReactPlayer from "react-player";
import Config from "../configs/Config";
import { Modal, Button, Alert } from "react-bootstrap";
import Constants from "../configs/Constants";
import moment from "moment-timezone";
import LoaderEmitters from "../configs/LoaderEmitters";
import EventEmitters from "../configs/EventEmitters";

const Upcoming = (props) => {
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [secondEvent, setSecondEvent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAllEvents, setShowAllEvents] = useState(null);
  const [joinedEvents, setJoinedEvents] = useState(null);
  const [modalData, setModalData] = useState("");
  const [userTimezone, setUserTimezone] = useState(null);

  const handleShowModalHide = () => {
    setShowModalPopup(false);
  };
  const open_modal = (eventId) => {
    // getAllEvents(eventId)
    setShowModalPopup(true);
  };

  useEffect(() => {
    // Detect the user's timezone when the component mounts
    const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setUserTimezone(detectedTimezone);
    console.log(detectedTimezone);
  }, []);

  const videoSection = createRef();
  useEffect(() => {
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    if (!id || isAllDetailsFilled === 0) {
      // if (!id) {
      props.history.push("/login");
    } else {
      getAllEvents(id);
    }

    function redirect() {}
  }, []);

  const getAllEvents = async (id) => {
    try {
      setIsLoading(true);
      let data = await apis.getAllUpcomingEvents(id);
      let arr = [];
      setShowAllEvents(data.data.event);
      for (let i = 0; i < data.data.registeredEvents?.length; i++) {
        arr.push(data.data.registeredEvents[i].event_id);
      }
      setJoinedEvents(arr);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const registerUser = async (eventId) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    try {
      let toast = {
        type: "info",
        message: "",
      };
      let data = await apis.registerForEvent(
        eventId,
        Config.getSessionInfo("id")
      );
      setJoinedEvents([...joinedEvents, eventId]);
      toast.message = data.data.message;
      if (toast.message) {
        EventEmitters.showTost(toast);
      }
    } catch (error) {
      console.log(error);
    }
    handleShowModalHide();
    LoaderEmitters.showLoader({
      showLoader: false,
    });
  };

  const eventDetails = async (eventId, isRegistered) => {
    try {
      let data = await apis.getEventDetails(eventId);
      setModalData({ ...data.data.event[0], isRegistered });
      open_modal();
    } catch (error) {
      console.log(error);
    }
  };

  function convertToUserTimezone(dateString, timeString) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!dateString || !timeString || !userTimezone) {
      return null;
    }

    // Create a Moment.js object in IST
    const istDateTime = moment.tz(
      `${dateString} ${timeString}`,
      "DD/MM/YYYY HH:mm",
      "Asia/Kolkata"
    );

    // Convert to the user's time zone and format in 12-hour format with AM/PM
    const userFormattedDateTime = istDateTime
      .tz(userTimezone)
      .format("DD/MM/YYYY hh:mm A");
    console.log(userFormattedDateTime);
    // Extract the new date and time
    const [newDate, newTime, type] = userFormattedDateTime.split(" ");

    return { newDate, newTime, type };
  }

  function checkForTime(dateString, timeString) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!dateString || !timeString || !userTimezone) {
      return false;
    }

    // Create a Moment.js object for the provided date and time in IST
    const istDateTime = moment.tz(
      `${dateString} ${timeString}`,
      "DD/MM/YYYY HH:mm",
      "Asia/Kolkata"
    );

    // Convert to the user's time zone
    const userDateTime = istDateTime.tz(userTimezone);

    // Calculate the current time in the user's time zone
    const currentTime = moment().tz(userTimezone);

    // Calculate the difference in minutes between the provided date/time and the current time
    const timeDifferenceMinutes = userDateTime.diff(currentTime, "minutes");

    // Check if the difference is less than or equal to 60 minutes (1 hour)
    return timeDifferenceMinutes <= 60;
  }

  return (
    <Fragment>
      <div className="main-wrapper" ref={videoSection}>
        <div className="banner-header text-center">
          {/* <h1 style={{ color: '#2272a1', textTransform: 'unset', fontSize: ' 3.25rem', marginTop: '25px', marginBottom: '25px' }}>
                        Media
                </h1> */}
        </div>

        <section className="section section-blogs" ref={videoSection}>
          <div className="container-fluid">
            <div className="section-header text-center">
              <h2
                style={{
                  color: "#2272a1",
                  textTransform: "unset",
                  fontSize: " 3.25rem",
                }}
              >
                Events
              </h2>

              {/* <div className="row align-items-center justify-content-center">
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                            <li className="nav-item">
                                                <NavLink
                                                    activeClassName="active"
                                                    className="nav-link"
                                                    to="/media"
                                                >
                                                    Images
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="nav-link"
                                                    to="/video"
                                                >
                                                    Videos
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="nav-link"
                                                    to="/upcoming"
                                                >
                                                    Events
                                                </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink className="nav-link" to="/meeting">
                                                    Meetings
                                                        </NavLink>
                                            </li>/}
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

              {/* <p className="sub-title">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p> */}
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-md-6">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/upcoming"
                      >
                        Upcoming Events
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/event">
                        Past Events
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                      <NavLink className="nav-link" to="/ongoing">
                        Ongoing Event
                      </NavLink>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <br></br>

            <div className="row justify-content-center">
              {showAllEvents?.map((item) => {
                let { newDate, newTime, type } = convertToUserTimezone(
                  item?.date,
                  item?.start_time
                );
                // console.log(convertToUserTimezone(item.date,'0:00'))
                return (
                  <div className="col-md-6 col-lg-4 mb-5 " key={item?.id}>
                    <div className="blog grid-blog h-100 mb-0 ">
                      <div className="blog-image">
                        <img
                          className="img-fluid1"
                          // src={item?.banner}
                          src={Constants.server + "events/" + item?.banner}
                          // src={eventImg}
                          alt="Post Image"
                          style={{ height: "280px" }}
                        />
                      </div>
                      <div className="blog-content">
                        <ul className="entry-meta meta-item">
                          <li className="w-100">
                            <div className="post-author event-title">
                              <span className="event-title">{item?.title}</span>
                            </div>
                            {/* <p className="mb-0 event-desc-main">
                              <span
                                style={{
                                  color: "#20c0f3",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  open_modal();
                                  setSecondEvent(true);
                                  setEventNo(0);
                                }}
                              >
                                View Details
                              </span>
                            </p> */}
                            <p className="mb-0 event-desc-main">
                              <span
                                style={{
                                  color: "#20c0f3",
                                }}
                              ></span>
                            </p>
                          </li>

                          <li className="event-date w-100">
                            <span className="eventdate">
                              <i className="far fa-calendar"></i>
                              &nbsp;{newDate}
                            </span>
                          </li>
                          <li className="event-date w-100">
                            <span className="eventdate">
                              <i className="far fa-clock"></i>
                              &nbsp;{newTime} {type}
                            </span>
                          </li>
                          {/* <li className="event-date w-100">
                            <span className="eventdate">
                              <i className="far fa-clock"></i>
                              &nbsp;{time}
                            </span>
                          </li> */}
                        </ul>
                        <h3 className="blog-title event-desc">
                          <span class="cust-bold">Venue :</span>{" "}
                          {item?.location}
                        </h3>
                        {/* <h3 className="blog-title event-desc">
                          New York 7AM | London 12PM | Mumbai 5:30PM | Adelaide
                          10:30PM | Dubai 4PM
                        </h3> */}
                        {/* <h3 className="blog-title event-desc mt-3">
                                            <span class="cust-bold">
                                                Meeting ID
                                            </span>{" "}
                                            : 847 3672 0707
                                            <br />
                                            <span class="cust-bold">
                                                Passcode
                                            </span>{" "}
                                            : 566754
                                        </h3> */}
                        <div style={{ textAlign: "center" }}>
                          <br></br>
                          {checkForTime(item.date, item.start_time) &&
                          joinedEvents?.includes(item?.id) &&
                          item?.event_link ? (
                            <a href={item.event_link} target="_blank">
                              <Button
                                variant="info"
                                id={`${item.id}_join`}
                                className={`align-self-end  `}
                              >
                                Join
                              </Button>
                            </a>
                          ) : !joinedEvents?.includes(item.id) ? (
                            <Button
                              id={`${item.id}_false`}
                              onClick={() => {
                                eventDetails(item?.id);
                              }}
                              variant="primary"
                              className={`align-self-end`}
                            >
                              Register
                            </Button>
                          ) : (
                            <Button
                              variant="success"
                              id={`${item?.id}_true`}
                              // onClick={() => {
                              //   eventDetails(item.id, isRegistered);
                              // }}
                              className={`align-self-end `}
                            >
                              Registered
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div className="col-md-4">
                <div className="blog grid-blog">
                  <div className="blog-image">
                    <a href="javascript:void(0)">
                      <img className="img-fluid1" src={sfs1} alt="Post Image" />
                    </a>
                  </div>
                  <div className="blog-content">
                    <ul className="entry-meta meta-item">
                      <li style={{ minWidth: "350px" }}>
                        <div className="post-author event-title">
                          <span>International Collabrations In Surgery.</span>
                        </div>
                        <p className="mb-0 event-desc-main">
                        This scientific meeting provides a forum where practical integration of newer evidence and newer interventional techniques will be discussed in-depth. 
                        The meeting provides oncologists, and clinical specialists interested in the field of GI cancers with the opportunity to dialogue and network with experts in the field of GI cancers.
                        <span style={{ color: "#20c0f3",cursor:"pointer" }} onClick={()=>{open_modal();setSecondEvent(false)}}>View Details</span>
                        </p>
                        <p className="mb-0 event-desc-main">
                          <span style={{ color: "#20c0f3" }}>
                            
                          </span>
                        </p>
                      </li>
                      <span style={{ color: "#20c0f3",paddingBottom:"10px" }}>Pancreatic Cancer , Hepatocellular Carcinoma</span>
                      <span></span>
                      <li className="event-date">
                       <p> <i className="far fa-clock"></i>  &nbsp;<span className="eventdate">Friday & Saturday, May 21 - 22, 2021</span>
                                               <p><span style={{ color: "#20c0f3",display:"Block",paddingTop:"10px" }}>Hall A </span></p> 
                      </p>
                      <p style={{display:"block",scrollPaddingBottom:"5px"}}><span style={{color:"black"}}>Meeting ID: </span> 811 2297 9074   &nbsp;&nbsp;&nbsp;<span style={{color:"black",paddingLeft:"27px"}}>Passcode:</span> 1234 </p>
                      <a href="https://us02web.zoom.us/meeting/register/tZYrce6gqTsvG9FFT5FZ1_b6__iDBmVtUAf1">
                      <Button className="joinbtn" style={{padding:"5px"}} variant="primary" >Join Meeting</Button>
                      </a>
                      <p><i className="far fa-clock"></i>  &nbsp;<span className="eventdate">Friday & Saturday, May 21 - 22, 2021</span>
                      <p><span style={{ color: "#20c0f3" }}>Hall B </span></p>
                      <p style={{display:"inline"}}><span style={{color:"black"}}>Meeting ID: </span> 812 8663 8228  &nbsp;&nbsp;&nbsp;<span style={{color:"black",paddingLeft:"25px"}}>Passcode:</span> 1234 </p>
                      </p>
                      <a href="https://us02web.zoom.us/j/81286638228?pwd=L3UrSjZoZTJ4dXdZUU9OUjAxZHZFQT09">
                      <Button className="joinbtn" style={{padding:"5px"}} variant="primary" >Join Meeting</Button>
                      </a>
                      </li>
                      <li className="event-date">
                        <span className="eventdate"><i className="far fa-calendar"></i>&nbsp;10th July'21</span>
                        </li>
                    </ul>
                    <h3 className="blog-title event-desc">
                      <span class="cust-bold">Venue :</span> Zoom Link
                    </h3>
                    <h3 className="blog-title event-desc">
                    Sydney 10PM | Seoul 9PM | Mumbai 5:30PM | Dubai 4PM | London 1PM | New York 8AM
                    Duration – 2 hours
                    </h3>
                    <div style={{ textAlign: "center" }}>
                      <span>
                        <h3 className="blog-title event-desc">
                          <a href="blog-details.html">
                            Meeting Title : Inaugural Webinar of Surgeons For
                            Surgeons (SFS)
                          </a>
                        </h3>
                      </span>
                      <h3 className="blog-title event-desc">
                        <a href="blog-details.html">Zoom Links :</a>
                      </h3>
                      <p className="mb-0 event-desc">
                        Lorem ipsum dolor sit amet, consectetur em adipiscing
                        elit, sed do eiusmod tempor.
                      </p>
                      <br></br>
                      <a
                        href="https://us02web.zoom.us/j/82667870515?pwd=VU5ZRGk1SkpLZjJ4akFDUFRndlZ3QT09"
                        target="_blank"
                      >
                        <Button variant="primary">Join Now</Button>
                      </a>
                      
                    </div>
                    <h3 className="blog-title"><a href="blog-details.html">Doccure – Making your clinic painless
										visit?</a></h3>
                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p>
                  </div>
                </div>
              </div> */}
              {/*----------  Event card  ----------*/}
              {/*                             <div className="col-md-4">
                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        <img
                                            className="img-fluid1"
                                            src={eventImg}
                                            alt="Post Image"
                                            style={{ height: "280px" }}
                                        />
                                    </div>
                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li style={{ minWidth: "350px" }}>
                                                <div className="post-author event-title">
                                                    <span>
                                                    Preoperative optimization of patients for surgery
                                                    </span>
                                                </div>
                                                <p className="mb-0 event-desc-main">
                                                    <span
                                                        style={{
                                                            color: "#20c0f3",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            open_modal();
                                                            setSecondEvent(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        View Details
                                                    </span>
                                                </p>
                                                <p className="mb-0 event-desc-main">
                                                    <span
                                                        style={{
                                                            color: "#20c0f3",
                                                        }}
                                                    ></span>
                                                </p>
                                            </li>

                                            <li className="event-date">
                                                <span className="eventdate">
                                                    <i className="far fa-calendar"></i>
                                                    &nbsp;6th November, 2021
                                                </span>
                                            </li>
                                        </ul>

                                        <h3 className="blog-title event-desc">
                                            <span class="cust-bold">
                                                Venue :
                                            </span>{" "}
                                            Zoom Link
                                        </h3>
                                        <h3 className="blog-title event-desc">
                                            New York 7AM | London 11AM | Dubai 3PM | Mumbai 4:30PM | Seoul 9PM | Sydney 10PM
                                        </h3>
                                    <h3 className="blog-title event-desc mt-3">
                                            <span class="cust-bold">
                                                Meeting ID
                                            </span>{" "}
                                            : 847 3672 0707
                                            <br />
                                            <span class="cust-bold">
                                                Passcode
                                            </span>{" "}
                                            : 566754
                                        </h3>
                                        <div style={{ textAlign: "center" }}>
                                            <br></br>
                                            <a
                                                href="https://us02web.zoom.us/j/82857259934 "
                                                target="_blank"
                                            >
                                                <Button variant="primary">
                                                    Join Now
                                                </Button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div> 

              {/*=====  End of Event card  ======*/}

              {/* <div className="col-md-4">
                <div className="blog grid-blog">
                  <div className="blog-image">
                    <a href="javascript:void(0)">
                      <img
                        className="img-fluid1"
                        src={sfs3}
                        alt="Post Image"
                      />
                    </a>
                  </div>
                  <div className="blog-content">
                    <ul className="entry-meta meta-item">
                      <li>
                        <div className="post-author event-title">
                          <span>Event Name : Duis pulvinar odio id leo</span>
                        </div>
                      </li>
                      <li className="event-date">
                        <i className="far fa-clock"></i> 15 July 2020
                      </li>
                    </ul>
                    <h3 className="blog-title event-desc">
                      <a href="blog-details.html">
                        Venue : Pellentesque fringilla enim
                      </a>
                    </h3>
                    <div className="post-author">
                      <span>
                        <h3 className="blog-title event-desc">
                          <a href="blog-details.html">
                            Meeting Title : Lorem ipsum dolor sit amet
                          </a>
                        </h3>
                      </span>
                      <h3 className="blog-title event-desc">
                        <a href="blog-details.html">Zoom Links :</a>
                      </h3>
                      <p className="mb-0 event-desc">
                        Lorem ipsum dolor sit amet, consectetur em adipiscing
                        elit, sed do eiusmod tempor.
                      </p>
                      <br></br>
                      <Button onClick={props.onHide}>Join Meeting</Button>
                    </div>
                  </div>
                </div>
              </div>  */}
              {showAllEvents?.length === 0 && !isLoading && (
                <div
                  className="insta fade-scroll"
                  style={{ position: "relative" }}
                >
                  <Alert
                    className="alert-no-post text-center"
                    key={1}
                    variant={"primary"}
                  >
                    No Upcoming Events
                  </Alert>
                </div>
              )}
            </div>
            {/* <div className="view-all text-center">
              <a href="blog-list.html" className="btn btn-primary">
                View All
              </a>
            </div> */}
          </div>
        </section>
        <Modal show={showModalPopup} onHide={handleShowModalHide} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12 col-lg-12 col-12">
              <div className="blog grid-blog h-100 mb-0 ">
                <div className="blog-image">
                  <img
                    className="img-fluid1"
                    // src={modalData?.banner}
                    // src={Constants.server+modalData.event_image}
                    src={Constants.server + "events/" + modalData.banner}
                    alt="Post Image"
                    style={{ height: "280px" }}
                  />
                </div>
                <div className="blog-content d-flex flex-column">
                  <ul className="entry-meta meta-item">
                    <li className="w-100">
                      <div className="post-author event-title">
                        <span className="event-title">{modalData?.title}</span>
                      </div>
                      <p className="mb-0 event-desc-main">
                        <span
                          style={{
                            color: "#20c0f3",
                          }}
                        ></span>
                      </p>
                    </li>

                    <li className="event-date w-100">
                      <span className="eventdate">
                        <i className="far fa-calendar"></i>
                        &nbsp;
                        {
                          convertToUserTimezone(
                            modalData.date,
                            modalData.start_time
                          )?.newDate
                        }
                      </span>
                    </li>
                    <li className="event-date">
                      <span className="eventdate">
                        <i className="far fa-clock"></i>
                        &nbsp;
                        {convertToUserTimezone(
                          modalData.date,
                          modalData.start_time
                        )?.newTime + ""}{" "}
                        {
                          convertToUserTimezone(
                            modalData.date,
                            modalData.start_time
                          )?.type
                        }
                      </span>
                    </li>
                  </ul>
                  <h3 className="blog-title event-desc ">
                    <span class="cust-bold">Venue :</span> {modalData?.location}
                  </h3>
                  {/* <h3 className="blog-title event-desc">
                          New York 7AM | London 12PM | Mumbai 5:30PM | Adelaide
                          10:30PM | Dubai 4PM
                        </h3> */}
                  {/* <h3 className="blog-title event-desc mt-3">
                                            <span class="cust-bold">
                                                Meeting ID
                                            </span>{" "}
                                            : 847 3672 0707
                                            <br />
                                            <span class="cust-bold">
                                                Passcode
                                            </span>{" "}
                                            : 566754
                                        </h3> */}

                  <div
                    style={{
                      textAlign: "center",
                      maxWidth: "50%",
                      gap: ".5rem",
                      minWidth: "60%",
                      alignSelf: "center",
                    }}
                    className="d-flex flex-column justify-self-center"
                  >
                    <br></br>

                    <Button
                      id={`${modalData?.id}_false`}
                      onClick={() => registerUser(modalData.id)}
                      variant="primary"
                    >
                      Confirm Registration
                    </Button>
                    <Button
                      onClick={() => handleShowModalHide()}
                      variant="outline-primary"
                      id={`${modalData?.id}_true`}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        {/* <Modal show={showModalPopup} onHide={handleShowModalHide} size={"lg"}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "-webkit-fill-available",
                  height: "auto",
                }}
                src={secondEvent ? eventImg : sfs1}
                height="400px"
              ></img>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal> */}
      </div>
      <FooterContent></FooterContent>
    </Fragment>
  );
};

export default Upcoming;
