import React from "react";
import LoginImage from "../../assets/img/3343994.jpg";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import Constants from "../../configs/Constants";
import axios from "axios";
import EventEmitters from "../../configs/EventEmitters";
import FooterContent from "../FooterContent";
import indemnityBondSurgery from "../../assets/docs/Indemnity_Bond_Beyond_Surgery.pdf";
import termsOfUse from "../../assets/docs/Terms of Use.pdf";
import { Box } from "@mui/material";
import Founder from "../login/Founder";
import BenefitsCarousel from "../login/BenefitsCarousel";
import GlobalSurgicalCommunity from "../login/GlobalSurgicalCommunity";
import SurgeonsTestimonials from "../login/SurgeonsTestimonials";
import DiveDeepSection from "../login/DiveDeepSection";
import OperatingRoom from "../login/OperatingRoom";
import Footer from "../login/Footer";
import Signup from './Signup.jsx'

const Singup = () => {
  const firstLetterCapital = (setStringName) => {
    return `${setStringName[0].toUpperCase()}${setStringName.substring(1)}`;
  };
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const onSubmit = (data) => {
    // console.log(data);
    data.firstName =
      firstLetterCapital(data.firstName.trim()) +
      " " +
      firstLetterCapital(data.lastName.trim());
    axios
      .post(Constants.signup, data)
      .then((response) => {
        reset();
        console.log("response", response);
        let tost = {
          message: "",
          type: "",
        };

        if (response.data.code === 409) {
          tost.message = response.data.msg;
          tost.type = "error";
        } else if (response.data.code === 200) {
          tost.message = response.data.msg;
          tost.type = "success";
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }

        // EventEmitters.showTost({
        //     message: "An Email has been sent to your register emailId, Please Verify your acount.",
        //     type: "success"
        // });
      })
      .catch(console.error);
  };

  return (
    <Box>
      {/* <Login /> */}
      <Signup/>
      {/* <Founder />
      <BenefitsCarousel />
      <GlobalSurgicalCommunity />
      <SurgeonsTestimonials />
      <DiveDeepSection />
      <OperatingRoom /> */}
      <Footer />
    </Box>
  );
};

export default Singup;
