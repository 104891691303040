import React from 'react';
import { NavLink } from 'react-router-dom';
import LoginBanner from '../assets/img/3343994.jpg';
import { useForm } from 'react-hook-form';
import Constants from '../configs/Constants';
import axios from 'axios';
import EventEmitters from '../configs/EventEmitters';
import LoaderEmitters from '../configs/LoaderEmitters';
import FooterContent from './FooterContent';

const ForgotPassword = (props) => {

    const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
    const [selectedEmailId, setSelectedEmailId] = React.useState(null);

    const {
        register: register2,
        errors: errors2,
        handleSubmit: handleSubmit2,
        reset: reset2
    } = useForm();

    const onSubmitOtp = data => {
        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.sendOtp, data)
            .then((response) => {

                // reset();
                console.log("response", response);
                let tost = {
                    message: "",
                    type: ""
                };
                if (response.data.code === 404) {
                    tost.message = response.data.msg;
                    tost.type = "error";
                } else if (response.data.code === 422 || response.data.code === 400) {
                    tost.message = response.data.msg;
                    tost.type = "error";
                } else if (response.data.code === 202) {
                    tost.message = response.data.msg;
                    tost.type = "success";
                    // sessionStorage.setItem('session', JSON.stringify(response.data.records));
                    // EventEmitters.loginLogutService();
                    // props.history.push(Constants[response.data.records.till_where_filled + 1]);
                } else if (response.data.code === 200) {
                    tost.message = response.data.msg;
                    tost.type = "success";
                    setSelectedEmailId(data.emailId);
                    // sessionStorage.setItem('session', JSON.stringify(response.data.records));
                    // EventEmitters.loginLogutService();
                    // props.history.push('/chat');
                }

                if (tost.message) {
                    tost.autoClose = 2000;
                    EventEmitters.showTost(tost);
                }

                LoaderEmitters.showLoader({
                    showLoader: false
                });
            })
            .catch(console.error);

    };

    const onSubmitChangePassword = data => {
        LoaderEmitters.showLoader({
            showLoader: true
        });

        if (data.newPassword !== data.confirmPassword) {

            EventEmitters.showTost({
                message: "The New Password & Confirm Password should be Same",
                type: "error",
                autoClose: 3000
            });

            LoaderEmitters.showLoader({
                showLoader: false
            });
            return false;
        }

        data.emailId = selectedEmailId;

        axios.post(Constants.verifyOtp, data)
            .then((response) => {

                reset2();
                console.log("response", response);
                let tost = {
                    message: "",
                    type: ""
                };
                if (response.data.code === 404) {
                    tost.message = response.data.msg;
                    tost.type = "error";
                } else if (response.data.code === 200) {
                    tost.message = response.data.msg;
                    tost.type = "success";
                    // sessionStorage.setItem('session', JSON.stringify(response.data.records));
                    // EventEmitters.loginLogutService();
                    // props.history.push('/chat');
                }

                if (tost.message) {
                    tost.autoClose = 2000;
                    EventEmitters.showTost(tost);
                }

                LoaderEmitters.showLoader({
                    showLoader: false
                });
            })
            .catch(console.error);
    }

    return (
        <React.Fragment>
            <div className="content p-0">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-4">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                <li className="nav-item">

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="account-content">
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-md-7 col-lg-6 login-left">
                                        <img src={LoginBanner} className="img-fluid" alt="Doccure Login" />
                                    </div>
                                    <div className="col-md-12 col-lg-6 login-right">
                                        <div className="login-header">
                                            <h3> Forgot Password </h3>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmitOtp)} noValidate autoComplete="off">
                                            <div className="form-group form-focus">
                                                <input type="email" name="emailId" className="form-control floating" ref={register({ required: true })} autoFocus />
                                                <label className="focus-label">Email</label>
                                            </div>
                                            {errors.emailId &&
                                                <div className="error-text" role="alert">
                                                    Email Id is required.</div>}
                                            <button className="btn btn-warning btn-block"
                                                type="submit">Send OTP</button>

                                            <hr />
                                        </form>

                                        <form onSubmit={handleSubmit2(onSubmitChangePassword)} noValidate autoComplete="off">
                                            <React.Fragment>
                                                <div className="form-group form-focus">
                                                    <input type="text" name="otp" className="form-control floating" ref={register2({ required: true })} />
                                                    <label className="focus-label">OTP</label>
                                                </div>
                                                {errors2.otp &&
                                                    <div className="error-text" role="alert">
                                                        OTP is required.</div>}
                                                <div className="form-group form-focus">
                                                    <input type="password" name="newPassword" className="form-control floating" ref={register2({ required: true })} />
                                                    <label className="focus-label">New Password</label>
                                                </div>
                                                {errors2.newPassword &&
                                                    <div className="error-text" role="alert">
                                                        New password is required.</div>}

                                                <div className="form-group form-focus">
                                                    <input type="password" name="confirmPassword" className="form-control floating" ref={register2({ required: true })} />
                                                    <label className="focus-label">Confirm Password</label>
                                                </div>
                                                {errors2.confirmPassword &&
                                                    <div className="error-text" role="alert">
                                                        Confirm password is required.
                                             </div>}

                                                <button className="btn btn-primary btn-block btn-lg login-btn"
                                                    type="submit">Change Password</button>

                                            </React.Fragment>
                                            <div className="text-center dont-have">
                                                {/* <span style={{ marginRight: '8px' }}>
                                                    
                                                </span> */}
                                                <NavLink to="/login">
                                                    Click here to Login
                                            </NavLink>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent></FooterContent>
        </React.Fragment>
    )
}

export default ForgotPassword;