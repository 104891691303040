import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Constants from "../configs/Constants";
import apis from "../apis/apis";

const ExploreFilterModal = ({ open, setShow, filterList, setFilterList }) => {
  console.log(filterList)
  const [currentList, setCurrentList] = useState(filterList);
  const [specialityList, setSpecialityList] = useState([]);
  useEffect(() => {
    getAllSpecialities();
  }, []);

  const handleClick = (item) => {
    if (currentList.includes(item)) {
      setCurrentList(currentList.filter((val) => val !== item));
    } else setCurrentList([...currentList, item]);
  };

  const handleFilterClick = () => {
    setFilterList([...currentList]);
    setShow();
  };

  const handleHideClick = () => {
    setCurrentList([]);
    setShow();
  };

  const getAllSpecialities = async () => {
    const { data } = await apis.getAllSpecialities();
    setSpecialityList(data.specialities);
  };

  return (
    <Modal show={open} onHide={handleHideClick} size="lg">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {specialityList.map((item) => {
          return (
            <Button
              onClick={() => handleClick(item)}
              variant={
                currentList.includes(item) ? "primary" : "outline-primary"
              }
              className="m-2"
            >
              {item}
            </Button>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <div className="row justify-content-around gap-1 w-100">
          <Button className="col-5" onClick={handleFilterClick}>
            Apply
          </Button>
          <Button
            variant="outline-danger"
            className="col-5"
            onClick={() => {
              setCurrentList([]);
              setFilterList([]);
              setShow();
            }}
          >
            Clear
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ExploreFilterModal;
