// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "../assets/css/list-surgen.css";
import "../assets/css/posts.css";
import "../assets/css/surgery-post-view.css";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import LoaderEmitters from "../configs/LoaderEmitters";

const Comment = ({
  setPostData,
  elm,
  CommentType,
  isInputVisible = true,
  setCommentToDeleted,
  setModalVisible,
  ...props
}) => {
  const [CommentData, setCommentData] = useState([]);
  const [curruntComment, setCurruntComment] = useState([]);

  useEffect(() => {
    // console.log("comment elm", elm, CommentType);
    if (elm != undefined) {
      if (CommentType == "beyoundSurgory") {
        getCommentForBeyondSurgoryId(elm);
      }
      if (CommentType == "community") {
        getCommentForCommunityId(elm);
      }
      if (CommentType == "clinicalcase") {
        getCommentsOnClinicalCase(elm);
      }
    }
  }, [props.CommentData, setCommentToDeleted]);

  const displayTime = (time) => {
    return moment(time).startOf("seconds").fromNow();
    // moment(time).calendar();
  };

  const getCommentForBeyondSurgoryId = (elm) => {
    // setCommentData();

    // LoaderEmitters.showLoader({
    //   showLoader: true,
    // });

    axios
      .post(Constants.getCommentForBeyondSurgoryId, {
        beyond_surgery_id: elm.id,
      })
      .then((response) => {
        // reset();
        // LoaderEmitters.showLoader({
        //   showLoader: false,
        // });

        console.log("getCommentForBeyondSurgory response>>>>>>", response);
        setCommentData([...response.data.result]);
      })
      .catch(console.error);
  };
  const getCommentsOnClinicalCase = (elm) => {
    // setCommentData();

    // LoaderEmitters.showLoader({
    //   showLoader: true,
    // });

    axios
      .get(Constants.getCommentsOnClinicalCase + "/" + elm)
      .then((response) => {
        // reset();
        // LoaderEmitters.showLoader({
        //   showLoader: false,
        // });

        console.log("getCommentForClinicalCases response>>>>>>", response);
        setCommentData([...response.data]);
        props.setCommentCount(response.data.length);
      })
      .catch(console.error);
  };
  const getCommentForCommunityId = (elm) => {
    // setCommentData();

    // LoaderEmitters.showLoader({
    //   showLoader: true,
    // });

    axios
      .post(Constants.getCommentForCommunityId, {
        community_id: elm.id,
      })
      .then((response) => {
        // reset();
        // LoaderEmitters.showLoader({
        //   showLoader: false,
        // });

        // console.log("loadDataOfCommunity response>>>>>>", response);
        setCommentData([...response.data.result]);
      })
      .catch(console.error);
  };

  //   add comment
  const commentBoxInput = (e) => {
    const { value } = e.target;
    setCurruntComment(value);
    // console.log(value);
  };

  const addComment = (elm) => {
    if (curruntComment) {
      // LoaderEmitters.showLoader({
      //   showLoader: true,
      // });
      // console.log("add Comment>>>>>>>>", curruntComment);
      if (CommentType == "beyoundSurgory") {
        axios
          .post(Constants.addBeyondSurgoryPostAddComment, {
            signup_id: Config.getSessionInfo("id"),
            beyond_surgery_id: elm.id,
            curruntComment,
            elm,
            full_name: Config.getSessionInfo("full_name"),
            module: "beyondSurgery",
            route: "beyondSurgery/comment",
          })
          .then((response) => {
            if (response.data.code === 200) {
              setPostData((oldData) =>
                oldData.map((beyond) => {
                  if (beyond.id === elm.id && beyond.comment_count) {
                    beyond.comment_count++;
                  } else if (beyond.id == elm.id) {
                    beyond["comment_count"] = 1;
                  }
                  return beyond;
                })
              );
            }
            LoaderEmitters.showLoader({
              showLoader: false,
            });
            setCurruntComment("");
            getCommentForBeyondSurgoryId(elm);
            // reset();
            let tost = {
              message: "",
              type: "",
            };
            // console.log("response>>>>>>", response);
            if (response.data.code === 200) {
              tost.type = "success";
              tost.message = response.data.msg;
              // loadDataOfBeyondSurgory(Config.getSessionInfo("id"));
              // loadDataOfCommunity(Config.getSessionInfo('id'));
              // handleClose();
              // history.push('/qualification');
            } else {
              tost.type = "error";
              tost.message = response.data.msg;
            }

            if (tost.message) {
              EventEmitters.showTost(tost);
            }
          })
          .catch(console.error);
      }
      if (CommentType == "clinicalcase") {
        console.log("Clinical case ID", props.userID);
        axios
          .post(Constants.commentOnClinicalCase, {
            signupId: props.userID,
            clinicalCaseId: elm,
            comment: curruntComment,
          })
          .then((response) => {
            if (response.data.code === 200) {
              // setPostData((oldData)=>
              //   oldData.map((beyond) => {
              //     if (beyond.id === elm.id && beyond.comment_count) {
              //       beyond.comment_count++;
              //     }else if(beyond.id == elm.id){
              //       beyond["comment_count"] = 1;
              //     }
              //     return beyond;
              //   })
              // )
            }
            LoaderEmitters.showLoader({
              showLoader: false,
            });
            setCurruntComment("");
            getCommentsOnClinicalCase(elm);
            // reset();
            let tost = {
              message: "",
              type: "",
            };
            // console.log("response>>>>>>", response);
            if (response.data.code === 200) {
              tost.type = "success";
              tost.message = response.data.msg;
              // loadDataOfBeyondSurgory(Config.getSessionInfo("id"));
              // loadDataOfCommunity(Config.getSessionInfo('id'));
              // handleClose();
              // history.push('/qualification');
            } else {
              tost.type = "error";
              tost.message = response.data.msg;
            }

            if (tost.message) {
              EventEmitters.showTost(tost);
            }
          })
          .catch(console.error);
      }
      if (CommentType == "community") {
        axios
          .post(Constants.addCommunityPostAddComment, {
            signup_id: Config.getSessionInfo("id"),
            community_id: elm.id,
            curruntComment,
            elm,
            full_name: Config.getSessionInfo("full_name"),
            module: "community",
            route: "community/comment",
          })
          .then((response) => {
            if (response.data.code === 200) {
              setPostData((oldData) =>
                oldData.map((Case) => {
                  if (Case.id === elm.id && Case.comment_count) {
                    Case.comment_count++;
                  } else if (Case.id == elm.id) {
                    Case["comment_count"] = 1;
                  }
                  return Case;
                })
              );
            }
            LoaderEmitters.showLoader({
              showLoader: false,
            });
            setCurruntComment("");
            getCommentForCommunityId(elm);
            // reset();
            let tost = {
              message: "",
              type: "",
            };
            console.log("response>>>>>>", response);
            if (response.data.code === 200) {
              tost.type = "success";
              tost.message = response.data.msg;
              //   loadDataOfCommunity(Config.getSessionInfo("id"));
              // loadDataOfCommunity(Config.getSessionInfo('id'));
              // handleClose();
              // history.push('/qualification');
            } else {
              tost.type = "error";
              tost.message = response.data.msg;
            }

            if (tost.message) {
              EventEmitters.showTost(tost);
            }
          })
          .catch(console.error);
      }
    }
  };

  // console.log("commentData", CommentData);
  return (
    <>
      <ul
        className="comments-reply"
        id={
          CommentType != "clinicalcase"
            ? `comment-d${elm?.id}`
            : `comment-d${elm}`
        }
        style={{ display: "none" }}
      >
        <li>
          {isInputVisible && (
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="add-cmt"
                  placeholder="Add a Comment"
                  value={curruntComment}
                  onChange={commentBoxInput.bind(this)}
                />
              </div>
              <div className="col-md-4 my-2 my-sm-0">
                <button
                  id="add-cmt"
                  className="btn-facebook"
                  onClick={addComment.bind(this, elm)}
                >
                  Add Comment
                </button>
              </div>
            </div>
          )}
          {CommentData &&
            CommentData.map((element, idx) => (
              <div className="comment" key={idx}>
                <img
                  className="avatar rounded-circle"
                  alt="User Avatar"
                  src={Constants.server + element.profile_pic}
                />
                <div className="comment-body flex-grow-1">
                  <div className="meta-data">
                    <div className="comment-author d-flex w-100 justify-content-between">
                      {/* Dr. {element.full_name} */}
                      <NavLink to={"/profile/" + element.signup_id}>
                        Dr. {element.full_name}
                      </NavLink>
                      {element.signup_id === props.userID && (
                        <i
                          class="far fa-trash-alt mr-3 d-inline-block"
                          style={{ color: "#f44336", cursor: "pointer" }}
                          onClick={() => {
                            setModalVisible();
                            setCommentToDeleted({
                              comment: element,
                              elm: elm,
                              comment_id: element.id,
                            });

                            getCommentsOnClinicalCase(elm);
                          }}
                        ></i>
                      )}
                    </div>

                    <span className="comment-date">
                      {" "}
                      <small>
                        Posted{" "}
                        {displayTime(
                          CommentType != "clinicalcase"
                            ? element.created_time_date
                            : element.created_at
                        )}
                      </small>{" "}
                    </span>
                  </div>
                  <p className="comment-content">{element.comment}</p>
                </div>
              </div>
            ))}
        </li>
      </ul>
    </>
  );
};
export default Comment;
