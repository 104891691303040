import axios from "axios";

const eventAPIs = {};
const clinicalCasesAPIs = {};
const communityAPIs = {};
const authAPIs = {};
const miscellaneousAPIs = {};
const learnWithSFSAPIs = {};
const exploreAPIs = {};
const blogAPIs = {};

const new_AWS_PROD_WITH_SSL = "https://aws-prod-api.surgeonsforsurgeons.com/";
const new_AWS_DEV = "https://aws-api.surgeonsforsurgeons.com/";

export const axiosInstance = axios.create({
  baseURL: new_AWS_PROD_WITH_SSL,
  // baseURL: new_AWS_DEV,
});

/**
 * Get all past events
 * @returns list of past events
 */

authAPIs.googleSignin = (data) =>
  axiosInstance.post("login/googleSignIn/web", data);
authAPIs.appleSignin = (data) =>
  axiosInstance.post("login/appleSignIn/web", data);

eventAPIs.getAllPastEvents = () => axiosInstance.get("pastEvents");

/**
 * Get all upcoming events
 * @param {number} user_id
 * @returns list of upcoming events
 */
eventAPIs.getAllUpcomingEvents = (user_id, page) =>
  axiosInstance.get(`upComingEvents?id=${user_id}&page=${page ?? 1}`);

/**
 * Get event details by ID
 * @param {number} event_id
 * @returns Event details
 */
eventAPIs.getEventDetails = (event_id) =>
  axiosInstance.get(`eventDetails/${event_id}`);

/**
 * Join / register an event
 * @param {number} event_id
 * @param {number} user_id
 * @returns
 */
eventAPIs.registerForEvent = (event_id, user_id) =>
  axiosInstance.put(`events/join/${event_id}`, {
    id: user_id,
  });

clinicalCasesAPIs.createClinicalImage = (caseData, userId) => {
  const { title, description, speciality, cummunityfile } = caseData;
  const formData = new FormData();
  formData.append("cummunityfile", cummunityfile);
  return axiosInstance.post(`addCommunityPost`, formData, {
    headers: {
      type: 1,
      id: userId,
      title,
      description,
      speciality,
    },
  });
};

clinicalCasesAPIs.createClinicalCase = (caseData, userId) => {
  const {
    age,
    gender,
    speciality,
    diagnosis,
    history,
    clinical_question,
    cummunityfile,
  } = caseData;
  var formData = new FormData();
  const originalName = cummunityfile.name;
  const newName =
    Math.floor(Math.random() * 90000 + 10000) +
    originalName.replace(/[\s()]+/g, "");
  console.log(newName);
  const newFile = new File([cummunityfile], newName, {
    type: cummunityfile.type,
  });
  formData.append("cummunityfile", newFile);
  console.log(newFile, formData);
  return axiosInstance.post(`addCommunityPost`, formData, {
    headers: {
      type: 2,
      id: userId,
      age,
      gender,
      specialty: speciality,
      diagnosis: encodeURIComponent(diagnosis),
      history: encodeURIComponent(history),
      clinical_question,
    },
  });
};

learnWithSFSAPIs.getAllLearnImages = () => {
  return axiosInstance.get("lwsfs/images");
};
learnWithSFSAPIs.getAllLearnVideos = () => {
  return axiosInstance.get("lwsfs/videos");
};

communityAPIs.createCommunityPost = (postData) => axiosInstance.post(postData);

exploreAPIs.getAllExplorePost = (page, specialities, id) => {
  const endpoint = `explore?specialities=${specialities}&page=${page}&id=${id}`;
  console.log(endpoint);
  return axiosInstance.get(
    `explore?specialities=${specialities}&page=${page}&id=${id}`
  );
};
exploreAPIs.getUserSavedExplorePost = (user_id) =>
  axiosInstance.get(`explore/saved/posts?id=${user_id}`);

exploreAPIs.getExplorePostDetails = (post_id) =>
  axiosInstance.get(`explore/post?id=${post_id}`);

exploreAPIs.saveExplorePost = (user_id, post_id) =>
  axiosInstance.put(`explore/save`, { id: user_id, post_id: post_id });

exploreAPIs.addExplorePost = (title, speciality, id) =>
  axiosInstance.post(`createExplorePost`, {
    title: title,
    speciality: speciality,
    id: id,
  });

// exploreAPIs.filterExplorePost((specialities, id) =>
//   axiosInstance.get(`explore?specialities=${specialities}&id=${id}`)
// );

exploreAPIs.addExplorePostVideo = (postId, video, onUploadProgress) => {
  const formData = new FormData();
  // remove space from fileName
  const originalName = video.name;
  // only space and round bracket
  const newName =
    Math.floor(Math.random() * 90000 + 10000) +
    originalName.replace(/[\s()]+/g, "");

  //keep only alphabets
  // const newName = originalName.replace(/[^a-zA-Z]+/g, "");
  const newFile = new File([video], newName, {
    type: video.type,
  });
  formData.append("video", newFile);
  console.log(newFile);

  //Change name of file to number
  // const originalName = video.name;
  // const newName = `${Math.floor((Math.random() * 10000) + 1)}${postId}}`
  // const newFile = new File([video], newName, {
  //   type: video.type,
  // });
  // formData.append("video", newFile);
  // formData.append('thumbnail',video)
  // console.log(newFile)

  // Default
  // formData.append("video", video);
  // console.log(video)
  return axiosInstance.post(`uploadExploreVideo`, formData, {
    headers: {
      id: postId,
    },
    onUploadProgress,
  });
};

exploreAPIs.deleteExplorePost = (user_id, post_id) =>
  axiosInstance.post("explore/delete", { user_id, post_id });

miscellaneousAPIs.getAllSpecialities = () =>
  axiosInstance.get("specialityGroups");

miscellaneousAPIs.getCityDetailsByCountryId = (countryId) =>
  axiosInstance.post("getCityDetailsByCountryId", { countryId });

// Blogs APIs

blogAPIs.getAllBlogs = () =>
  axiosInstance.get("blogs/images/getAllBlogs?page=1&pageSize=100");
blogAPIs.getBlogById = (id) =>
  axiosInstance.get(`blogs/images/getBlogsById/${id}`);

const apis = {
  ...authAPIs,
  ...eventAPIs,
  ...clinicalCasesAPIs,
  ...communityAPIs,
  ...miscellaneousAPIs,
  ...learnWithSFSAPIs,
  ...exploreAPIs,
  ...blogAPIs,
};

export default apis;
