export const SET_ADD_CLINICAL_CASE_MODAL = "cases/setAddClinicalCaseModal";
export const SET_CLINICAL_CASES = "cases/setClinicalCases";
export const SET_CLINICAL_IMAGES_TAB = "cases/setClinicalCasesTab";
export const SET_CLINICAL_CASES_COLLECTION = "cases/setClinicalCasesCollection";
export const SET_SELF_CLINICAL_CASES = "cases/setSelfClinicalCases";
export const SET_SELECTED_CL_CASE = "cases/setSelectedClCase";

export const setAddClinicalCaseModal = (isVisible) => ({
    type: SET_ADD_CLINICAL_CASE_MODAL,
    payload: isVisible,
});

export const setClinicalImagesTab = (isVisible) => ({
    type: SET_CLINICAL_IMAGES_TAB,
    payload: isVisible,
});

export const setClinicalCases = (payload) => ({
    type: SET_CLINICAL_CASES,
    payload: payload,
});

export const setClinicalCasesCollection = (payload) => ({
    type: SET_CLINICAL_CASES_COLLECTION,
    payload: payload,
});

export const setSelfClinicalCases = (payload) => ({
    type: SET_SELF_CLINICAL_CASES,
    payload: payload,
});

export const setSelectedClCase = (payload) => ({
    type: SET_SELECTED_CL_CASE,
    payload: payload,
});
