import { Box, Button, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.png";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();



  

  return (
    <Box my={4}>
      <Box
        px={{ xs: 2, sm: 3, md: 4 }}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box
          display={"flex"}
          gap={2}
          alignItems={"center"}
          width={{ xs: "100%", sm: "unset" }}
          justifyContent={"space-between"}
        >
          <Box height={40} mr={3} onClick={() => history.push(`/`)}>
            <img src={logo} alt="" height={"100%"} />
          </Box>
          <Box display={"flex"} gap={2}>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <Typography
                color={"#5CA7C8"}
                fontFamily={"Plus Jakarta Sans"}
                fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.2rem" }}
              >
                About us
              </Typography>
            </Link>
            <Link to="/blogs" style={{ textDecoration: "none" }}>
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              color={"#5CA7C8"}
              fontSize={{ xs: "1rem", sm: "1.1rem", md: "1.2rem" }}
            >
              Blog
            </Typography>
            </Link>
          </Box>
        </Box>
        <Box display={{ xs: "none", sm: "flex" }} gap={2} alignItems={"center"}>
          <Button
            onClick={() => history.push(`/login`)}
            variant="outlined"
            sx={{
              background: `${location.pathname !=='/signup'?"#1681B1":"transparent"}`,
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans",
              outline: "#1681B1",
              color:`${location.pathname !=='/signup'?"#ffffff":"#1681B1"}`,
              ":hover": {
                background: `${location.pathname !=='/signup'?"#1681B1":"transparent"}`,
              },
            }}
          >
            Log in
          </Button>
          <Button
            onClick={() => history.push(`/signup`)}
            variant="outlined"
            sx={{
              outline: "#1681B1",
              color: `${location.pathname ==='/signup'?"#ffffff":"#1681B1"}`,
              background: `${location.pathname ==='/signup'?"#1681B1":"transparent"}`,
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans",
              ":hover":{
                background: `${location.pathname ==='/signup'?"#1681B1":"transparent"}`,
              }
            }}
          >
            Sign up
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
