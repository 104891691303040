
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ComfirmationModal({setModalVisible, isModalVisible, CommentType, handleCommentDelete, commentToDeleted , elm}) {
    return (
        <Modal show={isModalVisible} onHide={setModalVisible} >
        <Modal.Header toggle={setModalVisible}>Delete Comment</Modal.Header>
        <Modal.Body>
          Are you sure you want to delete comment?
        </Modal.Body>
        <Modal.Footer>
          <Button color="primary" onClick={()=>{
            if (CommentType === "community") {
              handleCommentDelete(
                elm.id, // case id
                commentToDeleted.id,
                commentToDeleted.community_id
              );
            } else {
              handleCommentDelete(
                elm.id, // case id
                commentToDeleted.id,
                commentToDeleted.beyond_surgery_id
              );
            }
          }}>Yes</Button>{' '}
          <Button color="secondary" onClick={setModalVisible}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ComfirmationModal
