import React from 'react';
// import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Constants from '../../configs/Constants';
import axios from 'axios';
import Config from '../../configs/Config';
import EventEmitters from '../../configs/EventEmitters';
import FooterContent from '../FooterContent';

const MembershipAssociation = (props) => {

    React.useEffect(() => {
        const id = Config.getSessionInfo('id');
        if (!id) {
            props.history.push('/');
        }
    }, []);

    // errors, reset
    const { register, handleSubmit, errors } = useForm(); // initialise the hook

    const onSubmit = data => {

        console.log("data>>>>>>>>>>>", data);
        data.curruentTab = 3;
        data.id = Config.getSessionInfo('id');
        // if (data.memberShip.length === 0 && !data.otherInternational) {
        //     // EventEmitters.showTost({
        //     //     message: "Please fill at least one checkbox or fill other to procced",
        //     //     type: "error"
        //     // });
        //     props.history.push('/mediRegistration');
        // } else {
            if (data.otherInternational) {
                if (data.memberShip.length) {
                    data.memberShip.push(data.otherInternational);
                } else {
                    data.memberShip = [data.otherInternational];
                }
            }
            axios.post(Constants.membershipAssociation, data)
                .then((response) => {
                    let tost = {
                        message: "",
                        type: ""
                    };
                    if (response.data.code === 404) {
                        tost.message = response.data.msg;
                        tost.type = "error";
                    } else if (response.data.code === 202) {
                        tost.message = response.data.msg;
                        tost.type = "success";
                        props.history.push('/signup');
                    } else if (response.data.code === 200) {
                        tost.type = "success";
                        tost.message = response.data.msg;
                        props.history.push('/mediRegistration');
                    }

                    if (tost.message) {
                        EventEmitters.showTost(tost);
                    }
                })
                .catch(console.error);
        // }
    }
   
    return (
        <React.Fragment>
            <div className="content">
                <div className="breadcrumb-bar">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-12">
                                <nav aria-label="breadcrumb" className="page-breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="">Step 3</a></li>
                                    </ol>
                                </nav>
                                <h2 className="breadcrumb-title">Membership of Association </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-9">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                                        <div className="card-body">
                                            <div className="education-info">
                                                <div className="row form-row education-cont">
                                                    <div className="col-12 col-md-12 col-lg-11">
                                                        <div className="row form-row">
                                                            <div className="col-12 col-md-6 col-lg-6 d-none">
                                                                <div className="form-group">
                                                                    <select className="select form-control">
                                                                        <option selected="selected" disabled>Indian*</option>
                                                                    </select>
                                                                </div>

                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input ref={register({ required: false })} name="memberShip" value="Association of Surgeons" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">Association of Surgeons</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="AMASI" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">AMASI</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="IAGES" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">IAGES</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="Indian Chopter of IHPBA" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">Indian Chopter of IHPBA</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="ACRSI" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">ACRSI</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="IASO" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">IASO</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="IASG" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">IASG</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Other &nbsp;</label>
                                                                            {/* name="otherIndian" */}
                                                                            <input type="text" name="memberShip" className="" ref={register()} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-12 col-md-6 col-lg-6">
                                                                <div className="form-group" >
                                                                    <select className="select form-control">
                                                                        <option disabled selected="selected">International*</option>

                                                                    </select>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="ACS" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">ACS</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input ref={register({ required: false })} type="checkbox" name="memberShip" value="IHPBA" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">IHPBA</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="SAGES" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">SAGES</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="ASCRS" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">ASCRS</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="ASGBI" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">ASGBI</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <label>
                                                                                <input name="memberShip" ref={register({ required: false })} value="SSAT" type="checkbox" />
                                                                                <span className="checkmark"></span>
                                                                                <span className="mr-6">SSAT</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row form-row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label>Other &nbsp;</label>
                                                                            <input type="text" name="otherInternational" className="" ref={register()} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="form-group">
                                            {errors.memberShip &&
                                                <div className="error-text" role="alert">
                                                    Please select the at least one Association.
                                                </div>}
                                            <div className="row form-row">


                                                <div className="submit-section">
                                                    <button type="submit" className="btn btn-primary submit-btn">Save
													Changes & Next</button>
                                                    <button type="submit" className="btn btn-primary submit-btn">
                                                        {/* <NavLink to="/mediRegistration"> */}
                                                            Skip
                                                        {/* </NavLink> */}
                                                    </button>
                                                </div>
                                                {/* <div style={{ marginLeft: '5px' }} className="submit-section">
                                                    
                                                </div> */}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent></FooterContent>
        </React.Fragment >
    )
}

export default MembershipAssociation;