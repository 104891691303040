import React, { Fragment, useEffect, createRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
import blogTwoImage from '../assets/img/Puestow pancreatic duct exposed Pic 1.png';
import blogThreeImage from '../assets/img/Puestow pic 2 Anastomosis in progress.png';
import blogFourImage from '../assets/img/Puestow Anastomosis complete Pic3.png';
import blogDefaultImage from '../assets/img/OQ6UTW0.jpg';

import doctorThumbTwoImage from '../assets/img/doctors/doctor-thumb-02.jpg';
import doctorThumbThreeImage from '../assets/img/doctors/doctor-thumb-03.jpg';

import { Dropdown, FormControl } from 'react-bootstrap';

import blogOne from '../assets/img/blog/blog-01.jpg';
import doctorThumb from '../assets/img/doctors/doctor-thumb-01.jpg';
import annouceMentImg from '../assets/img/blog/blog-thumb-01.jpg';

const News = (props) => {

    // const mediaSection = createRef();

    const [modalShow, setModalShow] = React.useState(false);
    const [activeImage, setActiveImage] = React.useState(null);
    const [activeDescription, setActiveDescription] = React.useState(null);

    const setModalImage = (image, description) => {
        setActiveImage(image);
        setActiveDescription(description);
        setModalShow(true);
    }

    const [value, setValue] = useState('');

    // forwardRef again here!
    // Dropdown needs access to the DOM of the Menu to measure it
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {


            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                            (child) =>
                                !value || child.props.children.toLowerCase().startsWith(value),
                        )}
                    </ul>
                </div>
            );
        },
    );
    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
    &#x25bc;
        </a>
    ));

    return (
        <Fragment>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                activeImage={activeImage}
                activeDescription={activeDescription}
            />
            <div className="main-wrapper" >
                <div className="banner-header text-center">
                </div>

                <section className="section section-blogs">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-lg-8 col-md-12">

                                <div className="row blog-grid-row">
                                    <div className="col-md-6 col-sm-12">

                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Ruby Perrin</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">Doccure – Making your clinic painless visit?</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6 col-sm-12">

                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Darren Elder</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 3 Dec 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">What are the benefits of Online Doctor Booking?</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12">


                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Deborah Angel</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 3 Dec 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">Benefits of consulting with an Online Doctor</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12">


                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Sofia Brient</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 2 Dec 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">5 Great reasons to use an Online Doctor</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12">


                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Marvin Campbell</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 1 Dec 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">Online Doctor Appointment Scheduling</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12">


                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Katharine Berthold</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 30 Nov 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">Simple steps to make your doctor visits exceptional!</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12">


                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Linda Tobin</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 28 Nov 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">Choose your own Online Doctor Appointment</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12">


                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Paul Richard </span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 25 Nov 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">Simple steps to visit your doctor today</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6 col-sm-12">


                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. John Gibbs</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 24 Nov 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">5 Great reasons to use an Online Doctor</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-6 col-sm-12">

                                        <div className="blog grid-blog">
                                            <div className="blog-image">
                                                <a href="blog-details.html"><img className="img-fluid" src={blogOne} alt="Post Image" /></a>
                                            </div>
                                            <div className="blog-content">
                                                <ul className="entry-meta meta-item">
                                                    <li>
                                                        <div className="post-author">
                                                            <a href="doctor-profile.html"><img src={doctorThumb} alt="Post Author" /> <span>Dr. Olga Barlow</span></a>
                                                        </div>
                                                    </li>
                                                    <li><i className="far fa-clock"></i> 23 Nov 2019</li>
                                                </ul>
                                                <h3 className="blog-title"><a href="blog-details.html">Online Doctoral Programs</a></h3>
                                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="blog-pagination">
                                            <nav>
                                                <ul className="pagination justify-content-center">
                                                    <li className="page-item disabled">
                                                        <a className="page-link" href="#" tabindex="-1"><i className="fas fa-angle-double-left"></i></a>
                                                    </li>
                                                    <li className="page-item active">
                                                        <a className="page-link" href="#">1 <span className="sr-only">(current)</span></a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">2 </a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#"><i className="fas fa-angle-double-right"></i></a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">

                                {/* <div className="card search-widget">
                                    <div className="card-body">
                                        <form className="search-form">
                                            <div className="input-group">
                                                <input type="text" placeholder="Search..." className="form-control" />
                                                <div className="input-group-append">
                                                    <button type="submit" className="btn btn-primary"><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}

                                <div className="card post-widget">
                                    <div className="card-header">
                                        <h4 className="card-title">Latest Announcements</h4>
                                    </div>
                                    <div className="card-body">
                                        <ul className="latest-posts">
                                            <li>
                                                <div className="post-thumb">
                                                    <a href="blog-details.html">
                                                        <img className="img-fluid" src={annouceMentImg} alt="" />
                                                    </a>
                                                </div>
                                                <div className="post-info">
                                                    <h4>
                                                        <a href="blog-details.html">Doccure – Making your clinic painless visit?</a>
                                                    </h4>
                                                    <p>4 Dec 2019</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="post-thumb">
                                                    <a href="blog-details.html">
                                                        <img className="img-fluid" src={annouceMentImg} alt="" />
                                                    </a>
                                                </div>
                                                <div className="post-info">
                                                    <h4>
                                                        <a href="blog-details.html">What are the benefits of Online Doctor Booking?</a>
                                                    </h4>
                                                    <p>3 Dec 2019</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="post-thumb">
                                                    <a href="blog-details.html">
                                                        <img className="img-fluid" src={annouceMentImg} alt="" />
                                                    </a>
                                                </div>
                                                <div className="post-info">
                                                    <h4>
                                                        <a href="blog-details.html">Benefits of consulting with an Online Doctor</a>
                                                    </h4>
                                                    <p>3 Dec 2019</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="post-thumb">
                                                    <a href="blog-details.html">
                                                        <img className="img-fluid" src={annouceMentImg} alt="" />
                                                    </a>
                                                </div>
                                                <div className="post-info">
                                                    <h4>
                                                        <a href="blog-details.html">5 Great reasons to use an Online Doctor</a>
                                                    </h4>
                                                    <p>2 Dec 2019</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="post-thumb">
                                                    <a href="blog-details.html">
                                                        <img className="img-fluid" src={annouceMentImg} alt="" />
                                                    </a>
                                                </div>
                                                <div className="post-info">
                                                    <h4>
                                                        <a href="blog-details.html">Online Doctor Appointment Scheduling</a>
                                                    </h4>
                                                    <p>1 Dec 2019</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <div className="card category-widget">
                                    <div className="card-header">
                                        <h4 className="card-title">Blog Categories</h4>
                                    </div>
                                    <div className="card-body">
                                        <ul className="categories">
                                            <li><a href="#">Cardiology <span>(62)</span></a></li>
                                            <li><a href="#">Health Care <span>(27)</span></a></li>
                                            <li><a href="#">Nutritions <span>(41)</span></a></li>
                                            <li><a href="#">Health Tips <span>(16)</span></a></li>
                                            <li><a href="#">Medical Research <span>(55)</span></a></li>
                                            <li><a href="#">Health Treatment <span>(07)</span></a></li>
                                        </ul>
                                    </div>
                                </div> */}

                                {/* <div className="card tags-widget">
                                    <div className="card-header">
                                        <h4 className="card-title">Tags</h4>
                                    </div>
                                    <div className="card-body">
                                        <ul className="tags">
                                            <li><a href="#" className="tag">Children</a></li>
                                            <li><a href="#" className="tag">Disease</a></li>
                                            <li><a href="#" className="tag">Appointment</a></li>
                                            <li><a href="#" className="tag">Booking</a></li>
                                            <li><a href="#" className="tag">Kids</a></li>
                                            <li><a href="#" className="tag">Health</a></li>
                                            <li><a href="#" className="tag">Family</a></li>
                                            <li><a href="#" className="tag">Tips</a></li>
                                            <li><a href="#" className="tag">Shedule</a></li>
                                            <li><a href="#" className="tag">Treatment</a></li>
                                            <li><a href="#" className="tag">Dr</a></li>
                                            <li><a href="#" className="tag">Clinic</a></li>
                                            <li><a href="#" className="tag">Online</a></li>
                                            <li><a href="#" className="tag">Health Care</a></li>
                                            <li><a href="#" className="tag">Consulting</a></li>
                                            <li><a href="#" className="tag">Doctors</a></li>
                                            <li><a href="#" className="tag">Neurology</a></li>
                                            <li><a href="#" className="tag">Dentists</a></li>
                                            <li><a href="#" className="tag">Specialist</a></li>
                                            <li><a href="#" className="tag">Doccure</a></li>
                                        </ul>
                                    </div>
                                </div> */}

                            </div>

                        </div>

                    </div>
                </section>
            </div >
            <FooterContent></FooterContent>
        </Fragment >
    )
}

export default News;

function MyVerticallyCenteredModal(props) {
    console.log("props<<<<<<<<<<<>>>>>>>>>>>>>>>>", props);
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
            <Modal.Body>
                <img className="img-fluid1" src={props.activeImage}
                    alt="Post Image" />
                <p className="mb-0" style={{ fontSize: '15px', marginTop: '25px', marginBottom: '25px' }}>
                    {props.activeDescription}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}