import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Tab } from "semantic-ui-react";
import "../assets/css/custome.css";
import indemnity from "../assets/docs/Indemnity Bond.pdf";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import LoaderEmitters from "../configs/LoaderEmitters";
import { fetchClinicalCases } from "../reducers/clinicalCases/thunk";
import FileUpload from "./FileUpload";
import { Link } from "react-router-dom";

function AddClinicalCase(props) {
  const { register, handleSubmit, errors } = useForm();
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [report, setReport] = useState([]);
  const [scans, setScans] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const dispatch = useDispatch();

  const handleClose = () => {
    [images, videos, report, scans].forEach((files) => {
      files.forEach((file) => URL.revokeObjectURL(file.previewUrl));
    });
    setImages([]);
    setReport([]);
    setScans([]);
    setVideos([]);
    props.handleClose();
  };

  const onSubmitClinicalCase = async (data) => {
    let uploadData = new FormData();
    uploadData.append("speciality", data.specialtyId);
    uploadData.append("age", data.Age);
    uploadData.append("gender", data.Gender);
    uploadData.append("diagnosis", data.diagnosis);
    uploadData.append("history", data.history);
    uploadData.append("clinicalQuestions", data.questions);
    uploadData.append("signup_id", Config.getSessionInfo("id").toString());

    let fileTypes = [];
    let fileLabels = ["IMAGE", "VIDEO", "REPORT", "SCAN"];
    let selectedFiles = [images, videos, report, scans];
    selectedFiles.forEach((filesArray, INDEX) => {
      filesArray.forEach((file) => {
        uploadData.append("files", file.fileData);
      });
      for (let i = 0; i < filesArray.length; i++) {
        fileTypes.push(fileLabels[INDEX]);
      }
    });

    uploadData.set("fileTypes", JSON.stringify(fileTypes));

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    await axios
      .post(Constants.createClinicalCase, uploadData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async function (response) {
        await dispatch(fetchClinicalCases());
        if (typeof props.onCaseCreate === "function") {
          await props.onCaseCreate();
        }
        EventEmitters.showTost({
          type: "success",
          message: "Clinical Case created successfully",
        });
        props.handleClose();
      })
      .catch(function (response) {
        console.log(response);
        EventEmitters.showTost({
          type: "error",
          message: "Failed to create clinical case",
        });
      })
      .finally(() => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });
      });
  };

  const panes = [
    {
      menuItem: "Images",
      render: () => (
        <Tab.Pane attached={false}>
          <div className="doc-review review-listing">
            <div class="alert alert-success mt-3" role="alert">
              Supported type (.jpeg, .jpg, .png) & max 5 MB in Size
            </div>
            <FileUpload
              fileSizeLimit={5 * 1024 * 1024}
              acceptExt=".jpeg, .jpg, .png"
              handleFileChanges={setImages}
              setImages={setImages}
              files={images}
              fileTypes={"image"}
              tabValue={tabValue}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Videos",
      render: () => (
        <Tab.Pane attached={false}>
          <div className="doc-review review-listing">
            <div class="alert alert-success mt-3" role="alert">
              Supported type (.mp4) & max 10 MB in Size
            </div>
            <FileUpload
              fileSizeLimit={10 * 1024 * 1024}
              handleFileChanges={setVideos}
              acceptExt=".mp4"
              setImages={setVideos}
              files={videos}
              fileTypes={"video"}
              tabValue={tabValue}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Reports",
      render: () => (
        <Tab.Pane attached={false}>
          <div className="doc-review review-listing">
            <div class="alert alert-success mt-3" role="alert">
              Supported type (.pdf)
            </div>
            <FileUpload
              handleFileChanges={setReport}
              setReport={setReport}
              files={report}
              acceptExt=".pdf"
              fileTypes={"pdf"}
              tabValue={tabValue}
            />
          </div>
        </Tab.Pane>
      ),
    },
    // {
    //     menuItem: "Scans",
    //     render: () => (
    //         <Tab.Pane attached={false}>
    //             <div className="doc-review review-listing">
    //                 <FileUpload
    //                     handleFileChanges={setScans}
    //                     setScans={setScans}
    //                     files={scans}
    //                     fileTypes={"pdf"}
    //                     acceptExt=".pdf"
    //                     tabValue={tabValue}
    //                 />
    //             </div>
    //         </Tab.Pane>
    //     ),
    // },
  ];
  return (
    <>
      {/* <button className="add__clinical__case" onClick={handleShow}>
        Add Clinical Case
      </button> */}

      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Clinical Case</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="form-group row">
              <div className="col-md-12">
                <select
                  className="form-control"
                  name="specialtyId"
                  placeholder="Speciality"
                  ref={register({ required: true })}
                >
                  <option value="">Speciality</option>
                  {Constants.specialityList.map((elm, i) => {
                    return (
                      <option key={"sp_" + i} value={elm}>
                        {elm}
                      </option>
                    );
                  })}
                </select>
                {errors.specialtyId && (
                  <div className="error-text" role="alert">
                    Speciality is required.
                  </div>
                )}
              </div>

              <div className="col-md-12" style={{ marginTop: "20px" }}>
                <h4>Patient's Details</h4>
              </div>
              <div className="col-md-4">
                <input
                  type="number"
                  className="form-control"
                  name="Age"
                  required
                  placeholder="Enter Age"
                  ref={register({ required: true, min: 1 })}
                />
                {errors.Age && (
                  <div className="error-text" role="alert">
                    Invalid age.
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <select
                  className="form-control"
                  name="Gender"
                  placeholder="Gender"
                  ref={register({ required: true })}
                >
                  <option value="">Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {errors.Gender && (
                  <div className="error-text" role="alert">
                    Gender is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-12">
                <div className="add__diagnosis">
                  <div className="add__d__head">Diagnosis</div>
                  <div className="add__d__field">
                    <textarea
                      className="form-control description-field"
                      name="diagnosis"
                      placeholder="Enter Text..."
                      ref={register({ required: true })}
                    ></textarea>
                  </div>
                </div>
                {errors.diagnosis && (
                  <div className="error-text" role="alert">
                    Diagnosis is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-12">
                <div className="add__diagnosis">
                  <div className="add__d__head">History</div>
                  <div className="add__d__field">
                    <textarea
                      ref={register({ required: false })}
                      className="form-control description-field"
                      name="history"
                      placeholder="Enter Text..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-12">
                <div className="add__diagnosis">
                  <div className="add__d__head">Clinical Question</div>
                  <div className="add__d__field">
                    <textarea
                      className="form-control description-field"
                      name="questions"
                      placeholder="Enter Questions... For Example: Q. Your Question Goes Here."
                      ref={register({ required: false })}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-12">
                <div className="add__diagnosis">
                  <Tab
                    className="file-upload-tabs"
                    menu={{ attached: false }}
                    panes={panes}
                    activeIndex={tabValue}
                    onTabChange={(ev, data) => {
                      setTabValue(data.activeIndex);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-12">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      ref={register({ required: true })}
                      name="clauses"
                      required
                      style={{ marginRight: "6px" }}
                    />
                    I hereby understand and provide the following Indemnity.
                    {/* <a
                                            href={indemnity}
                                            target="blank"
                                            style={{ color: "#007bff" }}
                                        >
                                            {" "}
                                            Click Here to Read{" "}
                                        </a> */}
                    <Link
                      to="/terms_and_conditions"
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tap here to read.
                    </Link>
                  </label>
                  {errors.clauses && (
                    <div className="error-text" role="alert">
                      Please check the checkbox.
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-12">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      ref={register({ required: true })}
                      name="patientConsent"
                      required
                      style={{ marginRight: "6px" }}
                    />
                    I hereby confirm that any patient details shared on this
                    platform are with respect to the patients consent.
                  </label>
                  {errors.patientConsent && (
                    <div className="error-text" role="alert">
                      Please check the checkbox.
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-12">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      ref={register({ required: true })}
                      name="platformDataCheckbox"
                      required
                      //   ref={register({ required: true })}
                      style={{ marginRight: "6px" }}
                    />
                    This data entered is relevant to the platform.
                  </label>
                  {errors.platformDataCheckbox && (
                    <div className="error-text" role="alert">
                      Please check the checkbox.
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className="col-md-12"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit(onSubmitClinicalCase)}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddClinicalCase;
