import React, { Fragment, useEffect, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
import blogTwoImage from '../assets/img/pngwave (1).png';
// import ReactPlayer from "react-player";
import { Button } from 'react-bootstrap';
import Config from '../configs/Config';


const Ongoing = (props) => {

    const videoSection = createRef();
    useEffect(() => {
        const id = Config.getSessionInfo('id');
        const isAllDetailsFilled = Config.getSessionInfo('is_all_details_filled');
        if (!id || isAllDetailsFilled === 0) {
            // if (!id) {
            props.history.push('/login');
        }
    }, []);
    return (
        <Fragment>
            <div className="main-wrapper" ref={videoSection}>
                <div className="banner-header text-center">
                    {/* <h1 style={{ color: '#2272a1', textTransform: 'unset', fontSize: ' 3.25rem', marginTop: '25px', marginBottom: '25px' }}>
                        Media
                </h1> */}
                </div>

                <section className="section section-blogs" ref={videoSection}>
                    <div className="container-fluid">
                        <div className="section-header text-center">
                            <h2 style={{ color: '#2272a1', textTransform: 'unset', fontSize: ' 3.25rem' }}> Academics </h2>


                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                            <li className="nav-item">
                                                <NavLink activeClassName="active" className="nav-link" to="/media">
                                                    Images
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/video">
                                                    Videos
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink className="nav-link" to="/event">
                                                    Events
                                                    </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink className="nav-link" to="/meeting">
                                                    Meetings
                                                        </NavLink>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <p className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                        {/* <li className="nav-item">
                                            <NavLink activeClassName="active" className="nav-link" to="/event">
                                                Past Event
                                                </NavLink>
                                        </li> */}
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/upcoming">
                                                Upcoming Event
                                                </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/ongoing">
                                                Ongoing Event
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <br></br>

                        <div className="row align-items-center justify-content-center">

                            <div className="col-md-4">

                                <div className="blog grid-blog">

                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <span><h3 className="blog-title"><a href="blog-details.html">Meeting Title : Lorem ipsum dolor sit amet</a></h3></span>
                                                    <h3 className="blog-title"><a href="blog-details.html">Zoom Links :</a></h3>
                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                                    eiusmod tempor.</p>
                                                    <br></br>
                                                    <Button onClick={props.onHide}>Join Meeting</Button>
                                                </div>
                                            </li>
                                            {/* <li><i className="far fa-clock"></i> 4 Dec 2019</li> */}
                                        </ul>
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Acute care Surgery</a></h3> */}
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Zoom Links</a></h3>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p> */}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">

                                <div className="blog grid-blog">

                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <span><h3 className="blog-title"><a href="blog-details.html">Meeting Title : sit amet</a></h3></span>
                                                    <h3 className="blog-title"><a href="blog-details.html">Zoom Links :</a></h3>
                                                    <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                                    eiusmod
									tempor.</p>
                                                    <br></br>
                                                    <Button onClick={props.onHide}>Join Meeting</Button>
                                                </div>
                                            </li>
                                            {/* <li><i className="far fa-clock"></i> 4 Dec 2019</li> */}
                                        </ul>
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Acute care Surgery</a></h3> */}
                                        {/* <h3 className="blog-title"><a href="blog-details.html">Zoom Links</a></h3>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view-all text-center">
                            <a href="blog-list.html" className="btn btn-primary">View All</a>
                        </div>
                    </div>

                </section>
            </div>
            <FooterContent></FooterContent>
        </Fragment >
    )
}

export default Ongoing;