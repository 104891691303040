import React, { useLayoutEffect } from "react";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import About from "./About";
import BeyondSurgery from "./BeyondSurgery";
import BeyondSurgeryFollowing from "./BeyondSurgeryFollowing";
import BeyondSurgeryFollowingFriendList from "./BeyondSurgeryFollowingFriendList";
import BeyondSurgeryMyCollection from "./BeyondSurgeryMyCollection";
import BeyondSurgeryMyPost from "./BeyondSurgeryMyPost";
import BeyondSurgeryUserPost from "./BeyondSurgeryUserPost";
import CaseDetail from "./CaseDetail";
import ChangePassword from "./ChangePassword";
import Chat from "./Chat";
import ChatSupport from "./ChatSupport";
import Collection from "./Collection";
// import ChatSupport from "./ChatSupport";
import Community from "./Community";
import CommunityCollection from "./CommunityCollection";
import CommunityFollowing from "./CommunityFollowing";
import CommunityFollowingFriendList from "./CommunityFollowingFriendList";
import CommunityNonRegister from "./CommunityNonRegister";
import CommunityNonRegisterView from "./CommunityNonRegisterView";
import ContactPage from "./ContactPage";
import CookieAccept from "./CookieAccept";
import Event from "./Event";
import ForgotPassword from "./ForgotPassword";
import HeaderContent from "./HeaderContent";
import Navbar from "../components/login/Navbar";
// import FooterContent from './FooterContent';
// import logoImg from '../assets/img/logo3.png';
import Home from "./Home";
import Live from "./Live";
import Login from "./login/Home";
// import Login from "./Login";
import Media from "./Media";
import Meeting from "./Meeting";
import MyCases from "./MyCases";
import myCasesCommunity from "./myCasesCommunity";
import News from "./News";
import Ongoing from "./Ongoing";
import Opportunity from "./Opportunity";
import OpportunityPosted from "./OpportunityPosted";
import OpportunityResponse from "./OpportunityResponse";
import Profile from "./Profile";
import RouteChangeTracker from "./RouteChangeTracker";
import Signup from "./Signup/index";
import BenefitBlog from "./BenefitBlog/Blog.jsx";
import Blogs from "./Blogs/Blogs.jsx";
import Blog from "./Blogs/Blog.jsx";
import MediRegistration from "./Signup/mediRegistration";
// import SurgicalTraining from './Signup/suergical';
import MembershipAssociation from "./Signup/membershipAssociation";
import PersonalInformation from "./Signup/personalInformation";
import Qualification from "./Signup/qualification";
import Upcoming from "./Upcoming";
import Verify from "./Verify";
import Video from "./Video";
import Welcome from "./Welcome";
import Explore from "./Explore";
import ExploreReels from "./ExploreReels";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import Config from "../configs/Config";
import { useDispatch, useSelector } from "react-redux";
import { handleAuthLogin } from "../reducers/auth/thunk";

const AnimatedSwitch = withRouter(({ location, showLoader }) => {
  return (
    <>
      {/* <ChatSupport phoneNumber={"8828103808"} /> */}
      <RouteChangeTracker />
      <Switch location={location} showLoader={showLoader}>
        <Route exact path="/home/:sectionName?" component={Home} />
        <Route exact path="/contactPage" component={ContactPage} />
        <Route exact path="/about" component={About} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/video" component={Video} />
        <Route exact path="/event" component={Event} />
        <Route exact path="/news" component={News} />
        <Route exact path="/meeting" component={Meeting} />
        <Route exact path="/upcoming" component={Upcoming} />
        <Route exact path="/ongoing" component={Ongoing} />
        <Route exact path="/live" component={Live} />
        <Route exact path="/personal" component={PersonalInformation} />
        <Route exact path="/qualification" component={Qualification} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/membershipAssociation"
          component={MembershipAssociation}
        />
        <Route exact path="/mediRegistration" component={MediRegistration} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/profile/:id" component={Profile} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/chat" component={Chat} />
        <Route exact path="/community" component={Community} />
        <Route
          exact
          path="/communityNonRegister"
          component={CommunityNonRegister}
        />
        <Route
          exact
          path="/communityNonRegisterView"
          component={CommunityNonRegisterView}
        />
        <Route
          exact
          path="/communityCollection"
          component={CommunityCollection}
        />
        <Route exact path="/collection" component={Collection} />
        <Route
          exact
          path="/communityFollowing"
          component={CommunityFollowing}
        />
        <Route
          exact
          path="/communityFollowingFriendList"
          component={CommunityFollowingFriendList}
        />
        <Route exact path="/caseDetails/:caseId" component={CaseDetail} />
        <Route exact path="/myCasesCommunity" component={myCasesCommunity} />
        <Route exact path="/myCases" component={MyCases} />

        <Route exact path="/opportunities" component={Opportunity} />
        <Route exact path="/opportunityPosted" component={OpportunityPosted} />
        <Route
          exact
          path="/opportunityResponse/:id"
          component={OpportunityResponse}
        />

        <Route exact path="/beyondSurgery" component={BeyondSurgery} />
        <Route
          exact
          path="/beyondSurgeryMyPost"
          component={BeyondSurgeryMyPost}
        />
        <Route
          exact
          path="/beyondSurgoryFollowing"
          component={BeyondSurgeryFollowing}
        />
        <Route
          exact
          path="/beyondSurgoryFollowingFriendList"
          component={BeyondSurgeryFollowingFriendList}
        />
        <Route
          exact
          path="/beyondSurgeryMyCollection"
          component={BeyondSurgeryMyCollection}
        />
        <Route
          exact
          path="/beyondSurgeryUserPost/:id"
          component={BeyondSurgeryUserPost}
        />

        <Route exact path="/explore" component={Explore} />
        <Route exact path="/explore/:id" component={ExploreReels} />

        <Route
          // exact
          path="/terms_and_conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/changePassword/:id" component={ChangePassword} />

        <Redirect to={"/home"} />
      </Switch>
    </>
  );
});

const AnimatedAuth = withRouter(({ location, showLoader }) => {
  return (
    <>
      {/* <ChatSupport phoneNumber={"8828103808"} /> */}
      <RouteChangeTracker />
      <Switch location={location} showLoader={showLoader}>
        <Route exact path="/about" component={About} />
        <Route exact path="/about/mission" component={About} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/changePassword/:id" component={ChangePassword} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/verify" component={Verify} />
        <Route exact path="/benefit-blog" component={BenefitBlog} />
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/blogs/:id" component={Blog} />
        <Route
          // exact
          path="/terms_and_conditions"
          component={TermsAndConditions}
        />

        <Redirect to={"/login"} />
      </Switch>
    </>
  );
});

const InnerContent = ({ location, showLoader }) => {
  const { isAuth } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");
    if (id && isAllDetailsFilled) {
      dispatch(handleAuthLogin(true));
    }
  }, []);

  return (
    <React.Fragment>
      <HashRouter>
        {isAuth ? <HeaderContent showLoader={showLoader} /> : <Navbar />}
        {isAuth ? <AnimatedSwitch showLoader={showLoader} /> : <AnimatedAuth />}
        <CookieAccept />
      </HashRouter>
    </React.Fragment>
  );
};

export default InnerContent;
