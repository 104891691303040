import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SurgeonImg from "../../assets/SurgeonImg.png";
import SurgeonImg1 from "../../assets/SurgeonImg2.png";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Quotes from "../../assets/Quotes.png";
import { useMediaQuery } from "@mui/material";
import member1 from "../../assets/img/testimonial/member1.png";
import member2 from "../../assets/img/testimonial/member2.png";
import member3 from "../../assets/img/testimonial/member3.png";
import member4 from "../../assets/img/testimonial/member4.png";
import { data } from "jquery";
const SurgeonsTestimonials = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0, // this is needed to tell the amount of px that should be visible.
    },
  };

  const textTimonialData = [
    {
      id: 1,
      img: member1,
      testimonial:
        "After sharing my surgery video on SFS, the feedback I received from surgeons across the globe was really motivating.",
    },
    {
      id: 2,
      img: member2,
      testimonial:
        "I got an opportunity to work with a mentor whom I admire for his expertise. Thanks to SFS, I could easily make the connection.",
    },
    {
      id: 3,
      img: member3,
      testimonial:
        "I was able to attend a great workshop by an expert surgeon during my short visit to London. Thanks SFS for the timely alert that helped me make the connection.",
    },
    {
      id: 4,
      img: member4,
      testimonial:
        "My mentor and seniors watch and comment on my videos. Thanks SFS. It feels great.",
    },
  ];

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box my={{sm:4}} mb={10}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Typography
          color={"#13375B"}
          fontSize={{ xs: "1.2rem", sm: "1.5rem", md: "2.3rem" }}
          width={{ xs: "100%", sm: "70%", md: "60%" }}
          textAlign={"center"}
          fontFamily={"Plus Jakarta Sans"}
          fontWeight={700}
        >
          What surgeons are saying about SurgeonsForSurgeons
        </Typography>
      </Box>
      <Box
        mt={4}
        sx={{
          bgcolor: "#13375B",
          py: 4,
          pb: 10,
        }}
      >
        <Box
          mt={4}
          sx={{
            // overflowX: "hidden",
            position: "relative",
          }}
        >
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            //   containerClass="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            //   deviceType={this.props.deviceType}
            dotListClass="custom-dot"
            itemClass="carousel-item-custom"
            centerMode={isSmallScreen ? false : true}
          >
            {textTimonialData.map((dt) => (
              <Box display={"flex"} justifyContent={"center"}>
                <Cards key={dt.id} image={dt.img} title={dt.testimonial} />
              </Box>
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default SurgeonsTestimonials;

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <Button
      onClick={() => onClick()}
      sx={{
        position: "absolute",
        right: {xs:'30%',sm:"40%"},
        color: "white",
        bottom: "-56px",
        zIndex: 1500,
      }}
    >
      <ArrowForwardIosRoundedIcon color="#ffffff" />
    </Button>
  );
};
const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType },
  } = rest;
  // onMove means if dragging or swiping in progress.
  return (
    <Button
      onClick={() => onClick()}
      sx={{
        position: "absolute",
        left: {xs:'30%',sm:"40%"},
        color: "white",
        bottom: "-56px",
        zIndex: 1500,
      }}
    >
      <ArrowBackIosNewRoundedIcon color="#ffffff" />
    </Button>
  );
};

const Cards = ({ image, title }) => {
  return (
    <Box mx={2} width={"100%"}>
      <Box
        width={"100%"}
        sx={{
          bgcolor: "white",
          borderRadius: "12px",
          p: 2,
          display: "flex",
          gap: 2,
          position: "relative",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Box height={250} width={{ lg: "40%", xs: "100%" }}>
          <img
            src={image}
            height={"100%"}
            width={"100%"}
            alt=""
            style={{
              objectFit:'contain'
            }}
          />
        </Box>
        <Box
          height={25}
          sx={{
            position: { lg: "absolute", xs: "static" },
            right: "2%",
            top: "4%",
          }}
        >
          <img src={Quotes} height={"100%"} alt="" />
        </Box>
        <Box pt={{sm:2}} width={{ lg: "56%", xs: "100%" }}>
          <Typography
            color={"#202020"}
            fontSize={"1rem"}
            // width={"50%"}
            fontWeight={400}
            fontFamily={"Plus Jakarta Sans"}
          >
            {title}
          </Typography>
          {/* <Typography
            mt={2}
            color={"#000000"}
            fontSize={"1rem"}
            // width={"50%"}
            fontWeight={600}
            fontFamily={"Plus Jakarta Sans"}
          >
            Kyle Merwin
          </Typography>
          <Typography
            color={"#808080"}
            fontSize={".9rem"}
            // width={"50%"}
            fontWeight={400}
            fontFamily={"Plus Jakarta Sans"}
          >
            CO-owner
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};
