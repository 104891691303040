import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import Footer from "../login/Footer";
import globalSurgeryPoster from "../../assets/Main article image.png";

const Blog = () => {
  return (
    <Box>
      <Box p={4}>
        <Box height={"500px"} width={"100%"} borderRadius={2}>
          <img
            src={globalSurgeryPoster}
            alt=""
            height={"100%"}
            width={"100%"}
            style={{
              objectFit:'cover'
            }}
          />
        </Box>
        <Box my={4}>
          <Box display={"flex"} gap={2} flexWrap={"wrap"} alignItems={"center"}>
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              // maxWidth={'65%'}
              sx={{
                fontSize: { xs: "1.25rem", md: "1.5rem" },
                color: "#2D3748",
                fontWeight: 700,
              }}
            >
              The Many Benefits of Joining Surgeon-Led Online Communities
            </Typography>
            {false && <Box
              sx={{
                backgroundColor: "#E2EFF5",
                px: 3,
                py: 1,
                width: "auto",
                display: "inline-block",
              }}
            >
              <Typography
                fontFamily={"Plus Jakarta Sans"}
                // maxWidth={'65%'}
                sx={{
                  fontSize: { xs: ".75rem", md: "1rem" },
                  color: "#13375B",
                  fontWeight: 700,
                }}
              >
                Oncology
              </Typography>
            </Box>}
          </Box>
          {false && <Box
            display={"flex"}
            gap={2}
            flexWrap={"wrap"}
            alignItems={"center"}
            mt={1}
          >
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              // maxWidth={'65%'}
              sx={{
                fontSize: { xs: ".75rem", md: "1rem" },
                color: "#2D3748",
                fontWeight: 700,
              }}
            >
              Written by John doe
            </Typography>
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              // maxWidth={'65%'}
              sx={{
                fontSize: { xs: ".75rem", md: "1rem" },
                color: "#718096",
                fontWeight: 400,
              }}
            >
              Monday May 20
            </Typography>
          </Box>}
          <Box mt={2}>
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              sx={{
                fontSize: { xs: ".75rem", md: "1rem" },
              }}
            >
              In today's digital age, the medical field is embracing online
              platforms as a means to connect, collaborate, and advance
              knowledge. Surgeons, in particular, are finding immense value in
              joining online communities tailored to their profession. These
              communities, often created by surgeons themselves, offer a wealth
              of benefits that enhance professional development, foster
              collaboration, and improve patient care.
            </Typography>
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              mt={2}
              sx={{
                fontSize: { xs: ".75rem", md: "1rem" },
              }}
            >
              Let's explore the many advantages of participating in these
              surgeon-led online communities.
            </Typography>
            {data.map((dt, id) => (
              <Typography
                fontFamily={"Plus Jakarta Sans"}
                mt={2}
                sx={{
                  fontSize: { xs: ".75rem", md: "1rem" },
                }}
              >
                <span style={{fontWeight:700}}>{id+1}. {dt.title}: </span>
                {dt.description}
              </Typography>
            ))}
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              mt={2}
              sx={{
                fontSize: { xs: ".75rem", md: "1rem" },
              }}
            >
              In conclusion, joining surgeon-created online communities offers a
              multitude of benefits for professionals in the field. From
              knowledge sharing and networking to professional development and
              advocacy, these communities play a vital role in advancing the
              practice of surgery and improving patient outcomes. Surgeons who
              actively participate in these communities not only enhance their
              own skills and knowledge but also contribute to the collective
              growth and advancement of the surgical profession as a whole.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Blog;

const data = [
  {
    title: "Knowledge Sharing",
    description:
      "Surgeon-led online communities serve as hubs for sharing knowledge and expertise. Through forums, discussion boards, and virtual conferences, members can engage in conversations about surgical techniques, best practices, and emerging technologies. This exchange of information allows surgeons to stay updated on the latest advancements in their field and continuously refine their skills.",
  },
  {
    title: "Networking Opportunities",
    description:
      "Connecting with peers is essential for professional growth, and online communities provide a convenient platform for networking. Surgeons can interact with colleagues from around the world, exchange ideas, and establish valuable connections. These networks can lead to collaborations on research projects, referrals for complex cases, and mentorship opportunities for younger surgeons.",
  },
  {
    title: "Peer Support and Collaboration",
    description:
      "Surgery can be a demanding and isolating profession, but online communities offer a sense of camaraderie and support. Surgeons can seek advice from their peers, share challenges, and celebrate successes together. This supportive environment fosters collaboration rather than competition, ultimately benefiting patient care.",
  },
  {
    title: "Continuing Medical Education (CME)",
    description:
      "Many surgeon-led online communities offer educational resources and CME opportunities. Members can access webinars, case studies, and interactive modules to earn credits and stay current with certification requirements. These educational offerings are often tailored to the specific needs and interests of surgeons, making them highly relevant and engaging.",
  },
  {
    title: "Access to Expertise",
    description:
      "One of the key advantages of online communities is the ability to tap into a diverse pool of expertise. Surgeons can consult with specialists in other fields, seek second opinions, and gather insights from thought leaders in surgery. This access to expertise enhances decision-making and improves patient outcomes by ensuring that surgeons have access to the best available information and advice.",
  },
  {
    title: "Research and Innovation",
    description:
      "Online communities serve as incubators for research and innovation in surgery. Members can collaborate on clinical studies, share groundbreaking research findings, and brainstorm new approaches to surgical challenges. Surgeons can drive advancements in surgical techniques, technology, and patient care by leveraging the collective intelligence of the community.",
  },
  {
    title: "Professional Development",
    description:
      "Surgeon-led online communities contribute to ongoing professional development. Surgeons can engage in discussions on topics such as leadership, practice management, and career advancement. Additionally, many communities may offer mentoring programs and career resources to help members navigate the complexities of the profession and achieve their professional goals.",
  },
  {
    title: "Global Perspective",
    description:
      "Online communities transcend geographical boundaries, allowing surgeons to connect with colleagues from diverse backgrounds and healthcare systems. This global perspective enriches discussions, exposes members to different approaches to patient care, and fosters cultural competence. Surgeons can broaden their horizons and become more effective practitioners by learning from each other s experiences.",
  },
  {
    title: "Quality Improvement and Patient Safety",
    description:
      "By sharing insights and experiences, surgeons can identify areas for quality improvement and enhance patient safety. Online communities provide a platform for discussing adverse events, near misses, and quality improvement initiatives. Through collaborative efforts, members can implement best practices, protocols, and quality improvement measures to ensure the highest standards of care for their patients.",
  },
  {
    title: "Empowerment and Advocacy",
    description:
      "Surgeon-led online communities empower members to advocate for their profession and drive positive change within the healthcare system. By amplifying their collective voice, surgeons can advocate for policies that support surgical practice, address systemic challenges, and promote patient-centred care. This advocacy strengthens the profession and ensures that surgeons have a seat at the table when decisions affecting their practice are made.",
  },
];
