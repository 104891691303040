// import letterImg from '../assets/img/letter.svg';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom";
import StickyBox from "react-sticky-box";
import "../assets/css/custome.css";
import "../assets/css/posts.css";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import LoaderEmitters from "../configs/LoaderEmitters";
import { setAddClinicalCaseModal } from "../reducers/clinicalCases/actions";
import { fetchSelectedClCase } from "../reducers/clinicalCases/thunk";
import AddClinicalCase from "./AddClinicalCase";
import ClinicalCaseDetail from "./ClinicalCaseDetail";
import FooterContent from "./FooterContent";

const CaseDetail = ({ clinicalCase = null, ...props }) => {
    let { caseId } = useParams();
    const {
        addModalVisible: clinicalShow,
        isClinicalImagesTab: clinicalImagesFlag,
        selectedClCase: clCase,
    } = useSelector((state) => state.clinicalCases);

    const dispatch = useDispatch();

    const setClinicalShow = (isVisible) => {
        dispatch(setAddClinicalCaseModal(isVisible));
    };

    const [show, setShow] = useState(false);

    const [userDetails, setUserDetails] = useState([]);

    const handleShow = () => dispatch(setAddClinicalCaseModal(true));

    useEffect(() => {
        const id = Config.getSessionInfo("id");
        const isAllDetailsFilled = Config.getSessionInfo(
            "is_all_details_filled"
        );

        if (!id || isAllDetailsFilled === 0) {
            props.history.push("/login");
        } else {
            setUserDetails({
                ...Config.getSessionInfo(),
            });
        }
    }, []);

    useEffect(() => {
        if (!!!caseId) return;
        LoaderEmitters.showLoader({
            showLoader: true,
        });
        dispatch(fetchSelectedClCase(caseId)).finally(() => {
            LoaderEmitters.showLoader({
                showLoader: false,
            });
        });

        return () => {};
    }, [caseId]);

    return (
        <React.Fragment>
            <div className="content">
                <div
                    className="container-fluid"
                    style={{ paddingLeft: "30px", paddingRight: "30px" }}
                >
                    <div className="row">
                        <div
                            className="col-md-5 col-lg-4 col-xl-3"
                            style={{ paddingBottom: 30 }}
                        >
                            <StickyBox offsetTop={118} offsetBottom={50}>
                                <div className="profile-sidebar">
                                    <div className="widget-profile pro-widget-content">
                                        <div className="profile-info-widget">
                                            <span className="booking-doc-img">
                                                <img
                                                    src={
                                                        Constants.server +
                                                        userDetails.profile_pic
                                                    }
                                                    alt="User Image"
                                                />
                                            </span>
                                            <div className="profile-det-info">
                                                <h3>
                                                    <NavLink
                                                        to={
                                                            "/profile/" +
                                                            userDetails.id
                                                        }
                                                        title="View Profile"
                                                    >
                                                        Dr.{" "}
                                                        {userDetails.full_name}
                                                    </NavLink>
                                                </h3>

                                                <div className="patient-details">
                                                    <h5 className="mb-0">
                                                        MBBS
                                                        {userDetails.specialty_type
                                                            ? `, MS - (${userDetails.specialty_type})`
                                                            : ""}
                                                    </h5>

                                                    <button
                                                        className="add__clinical__case"
                                                        onClick={handleShow}
                                                        style={{
                                                            backgroundColor:
                                                                "#096d9c",
                                                            color: "#fff",
                                                            border: "2px solid #096d9c",
                                                            margin: "10px 10px 0px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        Add a Clinical Case
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dashboard-widget">
                                        <nav className="dashboard-menu">
                                            <ul>
                                                <li>
                                                    <Link
                                                        to="#"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        {/* <span>Cases</span> */}
                                                        <NavLink
                                                            className="liked"
                                                            to={"/community"}
                                                        >
                                                            <i className="fas fa-columns"></i>
                                                            Clinical Case Feed
                                                        </NavLink>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="#"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        <NavLink
                                                            to={"/collection"}
                                                        >
                                                            <i className="fas fa-calendar-check"></i>
                                                            Clinical case
                                                            Collection
                                                        </NavLink>
                                                    </Link>
                                                </li>

                                                <li>
                                                    <Link
                                                        to="#"
                                                        onClick={(e) =>
                                                            e.preventDefault()
                                                        }
                                                    >
                                                        <NavLink
                                                            to={"/myCases"}
                                                        >
                                                            {" "}
                                                            <i className="fas fa-user-injured"></i>{" "}
                                                            My Clinical cases
                                                        </NavLink>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </StickyBox>
                        </div>
                        {!!clCase && (
                            <div className="col-md-7 col-lg-8 col-xl-9">
                                <ClinicalCaseDetail
                                    onEdit={() =>
                                        dispatch(fetchSelectedClCase(caseId))
                                    }
                                    clCase={clCase}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <AddClinicalCase
                show={clinicalShow}
                handleClose={setClinicalShow}
            />
            <FooterContent></FooterContent>
        </React.Fragment>
    );
};
export default CaseDetail;
