import React, { useState } from "react";
// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import { useForm } from "react-hook-form";
import moment from "moment";
import LoaderEmitters from "../configs/LoaderEmitters";
import EventEmitters from "../configs/EventEmitters";
import { Link } from "react-router-dom";
import Constants from "../configs/Constants";
import Config from "../configs/Config";
import { NavLink } from "react-router-dom";
import FooterContent from "./FooterContent";
import DummyProfile from "../assets/img/dummy-profile.png";
import BannerImage from "../assets/img/01_a_Banner.jpg";
import DocBanner from "../assets/img/Doccare_Banner.jpg";
import Banner3 from "../assets/img/banner3.jpeg";
import Banner2 from "../assets/img/banner2.jpeg";
import Banner1 from "../assets/img/banner1.jpeg";
import "../assets/css/posts.css";
import "../assets/css/custome.css";
import "../assets/css/opportunity.css";
import { Modal, Button } from "react-bootstrap";
import doctorThumb from "../assets/img/doctors/doctor-thumb-02.jpg";
import ReadMoreReact from "read-more-react";
import Alert from "react-bootstrap/Alert";
import indemnity from "../assets/docs/Indemnity Bond.pdf";
import ConfirmationActionModal from "./ConfirmationActionModal";
import apis from "../apis/apis";

const Opportunity = (props) => {
  const comment = function (id) {
    if (!id) return;
    var x = document.getElementById(id);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showAddOpportunity, setShowAddOpportunity] = useState(false);
  const handleCloseOpportunity = () => setShowAddOpportunity(false);
  const handleShowOpportunity = () => setShowAddOpportunity(true);

  const [showReadMore, setShowReadMore] = useState(false);
  const handleCloseReadMore = () => setShowReadMore(false);
  const handleShowReadMore = () => setShowReadMore(true);
  const { register, handleSubmit, errors, reset,getValues,setValue } = useForm(); // initialise the hook
  const [userDetails, setUserDetails] = useState([]);
  const [communityData, setCommunityData] = useState([]);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);

  const [curruntApplyPost, setCurruntApplyPost] = useState({});

  const [showApplyNow, setShowApplyNow] = useState(false);
  const handleCloseApplyNow = () => setShowApplyNow(false);
  const [otherSpecility, setOtherSpecility] = useState(false);
  const [countries, setCountries] = useState(false);
  const [cities, setCities] = useState(false)
  const [deleteId, setDeleteId] = useState(null);
  const [specialityList, setSpecialityList] = useState([])

  const handleShowApplyNow = (elm) => {
    setShowApplyNow(true);
    setCurruntApplyPost({
      ...elm,
    });
  };

  const [filters, setFilters] = useState({
    countyId: "all",
    specialtyId: "all",
    opportunityId: "all",
  });

  React.useEffect(() => {
    const id = Config.getSessionInfo("id");

    if (!id) {
      props.history.push("/opportunities");
    } else {
      // props.history.push('/community');
      // console.log("Config.getSessionInfo()>>>>>>>>", Config.getSessionInfo());
      setUserDetails({
        ...Config.getSessionInfo(),
      });
      loadDataOfOportunity();
      loadAllCountries();
      getAllSpecialities()
    }
  }, []);

  const getAllSpecialities = async () => {
    const { data } = await apis.getAllSpecialities();
    setSpecialityList(data.specialities);
  };

  const loadAllCountries = (filtersTemp) => {
    axios
      .post(Constants.getAllCountries, {})
      .then((response) => {
        setCountries([...response.data.result]);
      })
      .catch(console.error);
  };

  const loadAllCities = async (id) => {
    console.log(id);
    let { data } = await apis.getCityDetailsByCountryId(id);
    setCities(data.result);
  };

  const loadDataOfOportunity = (filtersTemp) => {
    filtersTemp = filtersTemp || filters;
    const id = Config.getSessionInfo("id");
    const data = {
      id: id,
      ...filtersTemp,
    };

    console.log("filters>>>>>>>>>>>>", filtersTemp);

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getOpportunitiesById, data)
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        setCommunityData([...response.data.result]);
      })
      .catch(console.error);
  };

  const onSubmit = (data) => {
    data.id = Config.getSessionInfo("id");
    console.log("onSubmit data>>>>>", data);

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    if (otherSpecility) {
      data.speciality = data.otherSpecialityInput;
      // data.otherSpecialityInput
    }

    axios
      .post(Constants.addOpportunities, data)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          loadDataOfOportunity();
          handleCloseOpportunity();
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        reset();
      })
      .catch(console.error);
  };

  const displayTime = (time) => {
    return moment(time).calendar();
  };

  const onSubmitApplyNow = (data) => {
    // data.id = Config.getSessionInfo('id');
    console.log("onSubmit data>>>>>", data);

    var xx = document.getElementById("cv");
    const formData = new FormData();
    formData.append("cv", xx.files[0]);

    // formData.append('name', data.name);
    // formData.append('email', data.email);
    // formData.append('contact', data.contact);
    // formData.append('address', data.address);
    // formData.append('medialCollege', data.medialCollege);
    // formData.append('degree', data.degree);

    console.log("formData>>>>>>>>>>>", formData);

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    const config = {
      headers: {
        name: data.name,
        emailid: data.emailId,
        contact: data.contact,
        address: data.address,
        medialcollege: data.medialCollege,
        degree: data.degree,
        opportunityid: curruntApplyPost.id,
        opportunitytitle: curruntApplyPost.title,
      },
    };
    handleCloseApplyNow();
    axios
      .post(Constants.addApplyNow, formData, config)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;

          // props.history.push('/qualification');
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        reset();
      })
      .catch(console.error);
  };

  const onChangeSpecility = (e) => {
    const filtersTemp = {
      ...filters,
      [e.target.name]: e.target.value,
    };

    setFilters({
      ...filtersTemp,
    });
    // const specility = e.target.value;
    loadDataOfOportunity(filtersTemp);
  };

  const onChangeSpeciality = (e) => {
    if (e && e.target && e.target.value === "Other") {
      setOtherSpecility(true);
    } else {
      setOtherSpecility(false);
    }
  };
  const handleFromDateChange = () => {
    // setValue('fromDateOfOpportunity',e.target.value)
    const { duration, durationYMD, fromDateOfOpportunity } = getValues();
    if (!duration || !durationYMD || !fromDateOfOpportunity) return;
    const yearPattern = /^\d{4}$/;
    const year = new Date(fromDateOfOpportunity).getFullYear().toString();
    if (!yearPattern.test(year)) return;

    const startMoment = moment(fromDateOfOpportunity, "YYYY-MM-DD");

    if (durationYMD === "Years") {
      startMoment.add(parseInt(duration), "years");
      console.log(startMoment);
    } else if (durationYMD === "Months") {
      startMoment.add(parseInt(duration), "months");
    } else if (durationYMD === "Days") {
      startMoment.add(parseInt(duration), "days");
    }
    console.log(startMoment);
    setValue("toDateOfOpportunity", startMoment.format("YYYY-MM-DD"));
  };

  const deletePostOpportunity = () => {
    // menuRightHideAll();

    axios
      .post(Constants.deleteOpportunityPosted, {
        opportunity_id: deleteId,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        // console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        loadDataOfOportunity();
      })
      .catch(console.error);
    setDeleteId(null);
    setDeleteConfirmationVisible(false);
  };

  const renderDescription = (elm) => {
    if (elm.description && elm.description.length >= 250) {
      return (
        <ReadMoreReact
          text={elm.description}
          min={10}
          ideal={200}
          max={250}
          readMoreText="Read more"
        />
      );
    } else {
      return elm.description;
    }
  };

  return (
    <React.Fragment>
      {/* <nav className="navbar navbar-expand-lg header-nav">
                <ul className="nav text-center sub-head">
                    <li className="nav-item">
                        <NavLink className="nav-link header-login" to={'/opportunities-non'} style={{ margin: '0 10px' }}>Non Register user</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link header-login" to={'/opportunities'} style={{ margin: '0 10px' }}> Registered user </NavLink>
                    </li>

                </ul>
            </nav> */}
      <div className="content">
        <div
          className="container-fluid"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="row">
            <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
              <div className="profile-sidebar">
                <div className="widget-profile pro-widget-content">
                  <div className="profile-info-widget">
                    <span className="booking-doc-img">
                      <img
                        src={Constants.server + userDetails.profile_pic}
                        alt="User Image"
                      />
                    </span>
                    <div className="profile-det-info">
                      <h3>Dr. {userDetails.full_name}</h3>

                      <div className="patient-details">
                        <h5 className="mb-0">
                          MBBS
                          {userDetails.specialty_type
                            ? `, MS - (${userDetails.specialty_type})`
                            : ""}
                        </h5>
                        {/* <li className="nav-item"> */}
                        <a
                          className="nav-link header-login pointer"
                          onClick={handleShowOpportunity}
                          style={{
                            backgroundColor: "#096d9c",
                            color: "#fff",
                            border: "2px solid #096d9c",
                            margin: "0 10px",
                          }}
                        >
                          {" "}
                          Add an Opportunity{" "}
                        </a>
                        {/* </li> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dashboard-widget">
                  <nav className="dashboard-menu">
                    <ul>
                      <li>
                        <NavLink to="/opportunities">
                          <i className="fas fa-columns"></i>
                          <span>All Opportunities </span>
                        </NavLink>
                      </li>
                      <li>
                        {/* <a href="opportunity_posted.php"> */}
                        <NavLink to="/opportunityPosted">
                          <i className="fas fa-columns"></i>
                          <span className="liked">Opportunities Posted</span>
                        </NavLink>
                        {/* </a> */}
                      </li>
                      {/* <li>
                                                <a href="response.php">
                                                    <i className="fas fa-calendar-check"></i>
                                                    <span>Application Received</span>
                                                </a>
                                            </li> */}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="dashboard-widget filters">
                <nav className="dashboard-menu">
                  <ul>
                    {/* <li>
                                            <div className="col-md-12" id="">
                                                <label for="Select country" className="col-form-label text-md-right">Select country</label>
                                                <select onchange="myFunction(this.value)" className="form-control">
                                                    <option value="Asia">Asia</option>
                                                    <option value="Africa">Africa</option>
                                                    <option value="USA">USA</option>
                                                    <option value="United Kingdom">United Kingdom</option>
                                                    <option value="Carribbean">Carribbean</option>
                                                    <option value="Middle East">Middle East</option>
                                                    <option value="Etc">Etc</option>
                                                </select>

                                            </div>
                                        </li> */}

                    <li>
                      <div className="col-md-12" id="nedmartop1">
                        <label
                          for="Select Opportunity"
                          className="col-form-label text-md-right"
                        >
                          {" "}
                          Select Country
                        </label>
                        <select
                          className="form-control"
                          name="countyId"
                          onChange={onChangeSpecility.bind(this)}
                        >
                          <option value="all"> All </option>
                          {countries &&
                            countries.length &&
                            countries.map((opt) => {
                              return (
                                <option value={opt.name}>{opt.name}</option>
                              );
                            })}
                        </select>
                      </div>
                    </li>

                    <li>
                      <div className="col-md-12" id="nedmartop1">
                        <label
                          for="Select specialty"
                          className="col-form-label text-md-right"
                        >
                          {" "}
                          Select specialty
                        </label>
                        <select
                          className="form-control"
                          name="specialtyId"
                          onChange={onChangeSpecility.bind(this)}
                        >
                          <option value="all">All</option>
                          {specialityList?.map((elm) => {
                            return <option value={elm}>{elm}</option>;
                          })}
                          {/* <option value="Neurology">Neurology</option>
                                                    <option value="Oncology">Oncology</option>
                                                    <option value="Gynecology">Gynecology</option> */}
                        </select>
                      </div>
                    </li>

                    <li>
                      <div className="col-md-12" id="nedmartop1">
                        <label
                          for="Select Opportunity"
                          className="col-form-label text-md-right"
                        >
                          {" "}
                          Select Opportunity
                        </label>
                        <select
                          className="form-control"
                          name="opportunityId"
                          onChange={onChangeSpecility.bind(this)}
                        >
                          <option value="all">All</option>
                          <option value="Visitor">Visitor</option>
                          <option value="Observer">Observer</option>
                          <option value="Paid training position">
                            Paid training position
                          </option>
                          <option value="Unpaid training position">
                            Unpaid training position
                          </option>
                          <option value="Paid Research position">
                            Paid Research position
                          </option>
                          <option value="Unpaid research position">
                            Unpaid research position
                          </option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="doc-review review-listing">
                {/* <p id="demo"></p> */}

                {communityData && communityData.length ? (
                  communityData.map((elm, idx) => {
                    return (
                      <div className="card" key={idx}>
                        <div className="card-body">
                          <div
                            className="doctor-widget"
                            id="opportunitiesIdForMobile"
                          >
                            <div className="doc-info-left col-md-12">
                              <div className="doc-info-cont col-md-7">
                                <h3 className="doc-name">{elm.title}</h3>
                                <div>
                                  {" "}
                                  <span
                                    className="comment-date"
                                    style={{ color: "#9E9E9E" }}
                                  >
                                    Posted {displayTime(elm.created_time_date)}
                                  </span>{" "}
                                </div>
                                <NavLink
                                  to={"/profile/" + elm.signup_id}
                                  title="View Profile"
                                >
                                  <span className="comment-author">
                                    Dr. {elm.full_name}
                                  </span>
                                  {/* Dr. {userDetails.full_name} */}
                                </NavLink>
                                {/* <p className="doc-speciality">Dr. {elm.full_name}</p> */}

                                <div className="clinic-details">
                                  <p className="doc-location">
                                    {/* {elm.description} */}

                                    {renderDescription(elm)}

                                    {/* <ReadMoreReact text={elm.description}
                                                                            min={10}
                                                                            ideal={200}
                                                                            max={250}
                                                                            readMoreText="Read more"
                                                                        /> */}
                                  </p>
                                  {/* <a className="read_more" data-toggle="modal" data-target="#about_opp">Read More</a> */}
                                  {/* <a className="read_more" data-toggle="modal" data-target="#about_opp">Read More</a> */}
                                </div>
                              </div>
                              <div className="doc-info-right col-md-5">
                                <div className="clini-infos">
                                  {/* onClick={deletePostCommunity.bind(this, elm)} */}

                                  <ul>
                                    <li title="Job Location">
                                      <i className="fas fa-map-marker-alt"></i>
                                      {elm.job_location_city}-
                                      {elm.job_location_country}
                                    </li>
                                    <li title="Duration">
                                      <i className="far fa-clock"></i>&nbsp;{" "}
                                      {elm.duration}{" "}
                                    </li>
                                    <li title="Posted Date">
                                      <i className="far fa-calendar"></i>
                                      <span>
                                        {Constants.getOppDate(elm.from_date)}-
                                        {Constants.getOppDate(elm.to_date)}
                                      </span>
                                    </li>
                                    <li title="Specility">
                                      <i className="fa fa-id-card"></i>
                                      &nbsp;{elm.speciality}
                                    </li>
                                    <li title="Specility">
                                      <i className="fa fa-podcast"></i>
                                      &nbsp;{elm.opportunity_type}
                                    </li>
                                    <li title="Qualifications">
                                      <i className="fa fa-graduation-cap"></i>
                                      &nbsp;{elm.qualifications}
                                    </li>
                                    <li title="Experience">
                                      <i className="fa fa-hourglass-start"></i>
                                      &nbsp;{elm.experience}
                                    </li>
                                  </ul>
                                </div>
                                <div className="clinic-booking">
                                  <NavLink
                                    to={"/opportunityResponse/" + elm.id}
                                    className="view_res"
                                  >
                                    {" "}
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;Response
                                  </NavLink>
                                  <Button
                                    variant="outline-danger"
                                    className="w-100 p-2 mt-2"
                                    onClick={() => {
                                      setDeleteId(elm.id);
                                      setDeleteConfirmationVisible(true);
                                    }}
                                  >
                                    Close Opportunity
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Alert variant={"primary"}>No Data Available</Alert>
                )}
              </div>
              {/* <div className="container">

                                <ul className="pagination">
                                    <li className="page-item"><a className="page-link" href="#">Previous</a></li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item active"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                                </ul>
                            </div> */}
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={showAddOpportunity}
        onHide={handleCloseOpportunity}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add an Opportunity Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="form-group row">
              <label htmlFor="title" className="col-md-4 col-form-label">
                Job Title
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  required
                  ref={register({
                    required: "Title is required",
                    minLength: {
                      value: 3,
                      message: "Title must be at least 3 characters long",
                    },
                  })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    {errors.title.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="name" className="col-md-4 col-form-label">
                Doctor Name
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  value={Config.getSessionInfo("full_name")}
                  name="drName"
                  readOnly={true}
                  required
                  ref={register({ required: true })}
                />
                {errors.drName && (
                  <div className="error-text" role="alert">
                    Doctor name is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="location" className="col-md-4 col-form-label">
                Job Location - Country
              </label>
              <div className="col-md-6">
                {/* <input type="text" className="form-control" name="jobLocation" required ref={register({ required: true })} /> */}
                <select
                  className="form-control"
                  name="jobLocationCountry"
                  onChange={(e) => {
                    const selectedOptionName =
                      e.target.options[e.target.selectedIndex].getAttribute(
                        "id"
                      );
                    loadAllCities(selectedOptionName);
                  }}
                  ref={register({ required: true })}
                >
                  <option value=""> Select Country </option>
                  {countries &&
                    countries.length &&
                    countries.map((opt) => {
                      return (
                        <option key={opt.id} id={opt.id} value={opt.name}>
                          {opt.name}
                        </option>
                      );
                    })}
                </select>

                {errors.jobLocationCountry && (
                  <div className="error-text" role="alert">
                    Country is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="location" className="col-md-4 col-form-label">
                Job Location - City
              </label>
              <div className="col-md-6">
                {/* <input type="text" className="form-control" name="jobLocation" required ref={register({ required: true })} /> */}
                <select
                  className="form-control"
                  name="jobLocationCity"
                  ref={register({ required: true })}
                >
                  {cities && cities.length ? (
                    <>
                      <option value=""> Select City </option>
                      {cities.map((opt) => (
                        <option key={opt.id} value={opt.name}>
                          {opt.name}
                        </option>
                      ))}
                    </>
                  ) : (
                    <option value="" disabled>
                      Select a country first
                    </option>
                  )}
                </select>

                {errors.jobLocationCity && (
                  <div className="error-text" role="alert">
                    City is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="duration" className="col-md-4 col-form-label">
                Duration of the Opportunity
              </label>
              <div className="col-md-3">
                <input
                  type="number"
                  className="form-control"
                  name="duration"
                  onChange={handleFromDateChange}
                  required
                  ref={register({ required: true })}
                />
              </div>

              <div className="col-md-3">
                <select
                  className="form-control"
                  name="durationYMD"
                  onChange={handleFromDateChange}
                  ref={register({ required: true })}
                >
                  <option value="Years"> Years </option>
                  <option value="Months"> Months </option>
                  <option value="Days"> Days </option>
                </select>
              </div>

              {errors.title && (
                <React.Fragment>
                  <label
                    htmlFor="duration"
                    className="col-md-4 col-form-label"
                  ></label>
                  <div className="error-text col-md-6" role="alert">
                    Duration of the Opportunity is required.
                  </div>
                </React.Fragment>
              )}
            </div>

            {/* <div className="form-group row">
                            <label htmlFor="date" className="col-md-4 col-form-label">Date</label>
                            <div className="col-md-6">
                                <input type="date" className="form-control datepicker-decades" name="dateOfOpportunity" required ref={register({ required: true })} />
                                {errors.dateOfOpportunity &&
                                    <div className="error-text" role="alert">
                                        Date is required.
                                                </div>}
                            </div>
                        </div> */}

            <div className="form-group row">
              <label htmlFor="date" className="col-md-4 col-form-label">
                From Date
              </label>
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control datepicker-decades"
                  name="fromDateOfOpportunity"
                  onChange={handleFromDateChange}
                  required
                  ref={register({ required: true })}
                />

                {errors.fromDateOfOpportunity && (
                  <div className="error-text" role="alert">
                    From Date is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="date" className="col-md-4 col-form-label">
                To Date
              </label>
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control datepicker-decades"
                  name="toDateOfOpportunity"
                  required
                  ref={register({ required: true })}
                />

                {errors.toDateOfOpportunity && (
                  <div className="error-text" role="alert">
                    To Date is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="description" className="col-md-4 col-form-label">
                Description
              </label>
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  name="description"
                  ref={register({ required: true })}
                ></textarea>
                {errors.description && (
                  <div className="error-text" role="alert">
                    Description is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="stipend" className="col-md-4 col-form-label">
                Speciality
              </label>
              <div className="col-md-6">
                {/* <input type="text" className="form-control" name="speciality" required ref={register({ required: true })} /> */}
                <select
                  className="form-control"
                  name="speciality"
                  ref={register({ required: true })}
                  onChange={onChangeSpeciality}
                >
                  <option value="">Select Speciality</option>
                  {specialityList?.map((elm, i) => {
                    return (
                      <option key={"sp_s_" + i} value={elm}>
                        {elm}
                      </option>
                    );
                  })}
                  <option value="Other">Other</option>
                  {/* <option value="Neurology">Neurology</option>
                                    <option value="Oncology">Oncology</option>
                                    <option value="Gynecology">Gynecology</option>
                                    <option value="Other">Other</option> */}
                </select>
                <br></br>
                {otherSpecility ? (
                  <input
                    type="text"
                    className="form-control"
                    name="otherSpecialityInput"
                    required
                    ref={register({ required: true })}
                    placeholder="Please feel the other speciality"
                  />
                ) : null}

                {errors.speciality && (
                  <div className="error-text" role="alert">
                    Speciality is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="stipend" className="col-md-4 col-form-label">
                Opportunity Type
              </label>
              <div className="col-md-6">
                {/* <input type="text" className="form-control" name="speciality" required ref={register({ required: true })} /> */}
                <select
                  className="form-control"
                  name="opportunityType"
                  ref={register({ required: true })}
                >
                  <option value="">Select Opportunity type</option>
                  <option value="Visitor">Visitor</option>
                  <option value="Observer">Observer</option>
                  <option value="Paid training position">
                    Paid training position
                  </option>
                  <option value="Unpaid training position">
                    Unpaid training position
                  </option>
                  <option value="Paid Research position">
                    Paid Research position
                  </option>
                  <option value="Unpaid research position">
                    Unpaid research position
                  </option>
                </select>
                {errors.opportunityType && (
                  <div className="error-text" role="alert">
                    Opportunity Type is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="location" className="col-md-4 col-form-label">
                Qualifications
              </label>
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  name="qualifications"
                  required
                  ref={register({ required: true })}
                ></textarea>
                {/* <input
                                    type="text"
                                    className="form-control"
                                    name="qualifications"
                                    required ref={register({ required: true })} /> */}

                {errors.qualifications && (
                  <div className="error-text" role="alert">
                    Qualifications is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="location" className="col-md-4 col-form-label">
                Experience
              </label>
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  name="experience"
                  required
                  ref={register({ required: true })}
                ></textarea>
                {/* <input
                                    type="text"
                                    className="form-control"
                                    name="experience"
                                    required ref={register({ required: true })} /> */}
                {errors.experience && (
                  <div className="error-text" role="alert">
                    Experience is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6 offset-md-4">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="checkbox"
                      required
                      ref={register({ required: true })}
                      style={{ marginRight: "6px" }}
                    />
                    This data entered is relevant to the platform.
                    {errors.checkbox && (
                      <div className="error-text" role="alert">
                        Please select check box
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6 offset-md-4">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="clauses"
                      required
                      ref={register({ required: true })}
                      style={{ marginRight: "6px" }}
                    />
                    I hereby understand and provide the following Indemnity.
                    {/* <a
                      href={indemnity}
                      target="_blank"
                      style={{ color: "#007bff" }}
                    >
                      {" "}
                      Click Here to Read{" "}
                    </a> */}
                      <Link
                      to="/terms_and_conditions"
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tap here to read.
                    </Link>
                    {errors.clauses && (
                      <div className="error-text" role="alert">
                        Please select check box
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 offset-md-4">
              <button
                type="submit"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                Add
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal size="lg" show={showApplyNow} onHide={handleCloseApplyNow}>
        <Modal.Header closeButton>
          <Modal.Title>Fill the following details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmitApplyNow)}
            noValidate
            autoComplete="off"
          >
            <div className="form-group row">
              <label for="name" className="col-md-4 col-form-label">
                Name
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  required
                  ref={register({ required: true })}
                />
                {errors.name && (
                  <div className="error-text" role="alert">
                    Name is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="emailId" className="col-md-4 col-form-label">
                Email
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="emailId"
                  required
                  ref={register({
                    required: "Email Id is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                />
                {errors.emailId && (
                  <div className="error-text" role="alert">
                    {errors.emailId.message}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="contact" className="col-md-4 col-form-label">
                Contact
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="contact"
                  required
                  ref={register({ required: true })}
                />
                {errors.contact && (
                  <div className="error-text" role="alert">
                    Contact is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="address" className="col-md-4 col-form-label">
                Address
              </label>
              <div className="col-md-6">
                <textarea
                  type="text"
                  className="form-control"
                  name="address"
                  required
                  ref={register({ required: true })}
                ></textarea>
                {errors.address && (
                  <div className="error-text" role="alert">
                    Address is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label for="medialCollege" className="col-md-4 col-form-label">
                Medical College
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control datepicker-decades"
                  name="medialCollege"
                  required
                  ref={register({ required: true })}
                />
                {errors.medialCollege && (
                  <div className="error-text" role="alert">
                    Medical College is required.
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label for="degree" className="col-md-4 col-form-label">
                Degree
              </label>
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  name="degree"
                  ref={register({ required: true })}
                ></textarea>
                {errors.degree && (
                  <div className="error-text" role="alert">
                    Degree is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row uploadfrm">
              <label for="cv" className="col-md-4 col-form-label ">
                Upload CV
              </label>
              <div className="col-md-6">
                {/* <span className="btn  btn-file form-control"> browse file*/}
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  ref={register({ required: true })}
                />
                  <div
                    className="alert-success px-2"
                    role="alert"
                    style={{ marginTop: "4px", width: "fit-content" }}
                  >
                    <span>Supported formats (.pdf, .docx)</span>
                   
                  </div>
                {/* </span> */}
                {errors.cv && (
                  <div className="error-text" role="alert">
                    CV is required.
                  </div>
                )}
              </div>
            </div>
            <div className="alert alert-info">
              <strong>Note: </strong>
              SFS Global, hereby declares that we as a Company or individually
              do not take any responsibility for the Job posted by the members
              and therefore we shall not be held responsible for any kind of
              current and future relationship between the Employer and the
              Applicant. We are only acting as a medium and not the Job
              provider.
            </div>
            <div className="col-md-6 offset-md-4">
              <button type="submit" className="btn btn-primary">
                Apply Now
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <ConfirmationActionModal
        modalTitle={"Delete Opportunity"}
        actionHandler={deletePostOpportunity}
        isVisible={deleteConfirmationVisible}
        setVisible={() => setDeleteConfirmationVisible((oldState) => !oldState)}
        actionBtnText={"Delete"}
      >
        <div style={{ fontSize: "1rem" }}>
          Are you sure you want to delete this Opportunity?
        </div>
      </ConfirmationActionModal>

      <FooterContent></FooterContent>
    </React.Fragment>
  );
};
export default Opportunity;
