import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-grid-carousel";
import "../assets/css/custome.css";
import Constants from "../configs/Constants";

function UploadedData(props) {
    const [show, setShow] = useState(false);
    const [selectedIndex, setselectedIndex] = useState(1);
    const { data, type } = props;

    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setselectedIndex(index);
        setShow(true);
    };
    return (
        <>
            <Carousel
                cols={3}
                rows={1}
                gap={11}
                responsiveLayout={[
                    {
                        breakpoint: 1200,
                        cols: 3,
                    },
                    {
                        breakpoint: 990,
                        cols: 2,
                    },
                ]}
                mobileBreakpoint={670}
                hideArrow={props.contents.length === 0}
            >
                {props.contents.map((item, index) => (
                    <Carousel.Item>
                        <div class="content2">
                            <div
                                onClick={() =>
                                    type === "image" && handleShow(index)
                                }
                            >
                                {item}
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <Modal
                show={show}
                onHide={handleClose}
                size={"lg"}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    {type === "image" && (
                        // eslint-disable-next-line jsx-a11y/alt-text
                        <img
                            class="content2-image"
                            src={
                                Constants.server +
                                data[selectedIndex]?.file_path
                            }
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default UploadedData;
