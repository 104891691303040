import React, { useState } from "react";
import { Box, FormGroup, IconButton, Typography } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const TextField = ({ label, ...props }) => {
  const [type, setType] = useState(props.type || "text");
  return (
    <FormGroup>
      <Typography
        // variant="body1"
        fontSize={{ xs: ".9rem", md: ".85rem" }}
        fontWeight={600}
        sx={{ color: "#000000", marginBottom: "4px", paddingLeft: "0px" }}
        fontFamily={"Plus Jakarta Sans"}
      >
        {label}
      </Typography>
      <Box
        // className="input-textfield"
        width={"100%"}
        pr={1}
        display={"flex"}
        alignItems={"center"}
        border={"1px solid #BDC4CD"}
        outline={"none"}
        height={"50px"}
        borderRadius={"8px"}
        sx={{ backgroundColor: "#FFFFFF" }}
      >
        <input
          className="input-textfield"
          value={props.value}
          {...props}
          step="0"
          type={type}
        />
        {props.type === "password" ? (
          type === "password" ? (
            <IconButton onClick={() => setType("text")}>
              <RemoveRedEyeOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => setType("password")}>
              <VisibilityOffOutlinedIcon />
            </IconButton>
          )
        ) : null}
        {props.type == "email" && props.value.trim().length ? (
          <IconButton
            onClick={() => {
              let e = { target: { value: "", name: props.name } };
              props.onChange(e);
            }}
          >
            <CancelOutlinedIcon />
          </IconButton>
        ) : null}
      </Box>
      {props.error && props.touched ? (
        <Typography
          fontFamily={"Plus Jakarta Sans"}
          sx={{ fontSize: ".8rem", color: "red", mt: ".2rem" }}
        >
          {props.error}
        </Typography>
      ) : null}
    </FormGroup>
  );
};

export default TextField;
