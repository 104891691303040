import { Box, Grid, Link, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <Box my={10} borderTop={"5px solid #EAEAEA"}>
      <Box
        display={"flex"}
        justifyContent={{ xs: "start", md: "space-around" }}
        gap={2}
        mt={5}
        px={{ xs: 2, sm: 5, md: 8 }}
        flexWrap={"wrap"}
      >
        <Box maxWidth={400}>
          <Link href={"/"} underline="none">
            <Box height={80}>
              <img src={logo} height={"100%"} alt="" />
            </Box>
          </Link>
          <Typography
            mt={2}
            color={"#2D2E2E"}
            fontSize={"1.1rem"}
            width={"80%"}
            // textAlign={"center"}
            fontWeight={500}
            fontFamily={"Plus Jakarta Sans"}
          >
            Surgeons for Surgeons is a network aimed at building a world-wide
            surgical community. We welcome surgeons from all continents to join
            us.
          </Typography>
        </Box>
        <Box visibility={'hidden'}>
          <Typography
            // mt={2}
            color={"#2D2E2E"}
            fontSize={"1rem"}
            // width={"90%"}
            // textAlign={"center"}
            fontWeight={600}
            lineHeight={"30px"}
            fontFamily={"Plus Jakarta Sans"}
          >
            Johannesburg, South Africa
          </Typography>
          <Typography
            mt={1}
            color={"#2D2E2E"}
            fontSize={"1rem"}
            // width={"90%"}
            // textAlign={"center"}
            fontWeight={600}
            lineHeight={"30px"}
            fontFamily={"Plus Jakarta Sans"}
          >
            998 Devonshire Ave.Camp Hill, PA 17011
          </Typography>
          <Typography
            mt={1}
            color={"#2D2E2E"}
            fontSize={"1rem"}
            // width={"90%"}
            // textAlign={"center"}
            fontWeight={600}
            lineHeight={"30px"}
            fontFamily={"Plus Jakarta Sans"}
          >
            (334) 202-4792
          </Typography>
        </Box>
        <Box>
          <Box
            display={"flex"}
            justifyContent={"end"}
            flexDirection={"column"}
            width={"100%"}
          >
            <Box display={"flex"} gap={3} justifyContent={"space-betwee"}>
              <Link
                href={
                  "https://www.linkedin.com/company/surgeons-for-surgeons-sfs/"
                }
                underline="none"
                target="_blank"
                sx={{ width: "50%" }}
              >
                <Typography
                  // mt={1}
                  color={"#2D2E2E"}
                  fontSize={"1rem"}
                  //   width={"90%"}
                  // textAlign={"center"}
                  fontWeight={600}
                  lineHeight={"30px"}
                  fontFamily={"Plus Jakarta Sans"}
                  width={"60%"}
                >
                  Linkedin
                </Typography>
              </Link>
              <Link
                sx={{ width: "50%" }}
                href={""}
                underline="none"
                target="_blank"
              >
                <Typography
                  // mt={1}
                  color={"#2D2E2E"}
                  fontSize={"1rem"}
                  //   width={"90%"}
                  // textAlign={"center"}
                  fontWeight={600}
                  lineHeight={"30px"}
                  fontFamily={"Plus Jakarta Sans"}
                  width={"60%"}
                >
                  Twitter
                </Typography>
              </Link>
              {/* <Typography
                mt={1}
                color={"#2D2E2E"}
                fontSize={"1rem"}
                //   width={"90%"}
                // textAlign={"center"}
                fontWeight={600}
                lineHeight={"30px"}
                fontFamily={"Plus Jakarta Sans"}
              >
                Investors
              </Typography> */}
            </Box>
            <Box display={"flex"} gap={3} justifyContent={"end"}>
              <Typography
                mt={1}
                color={"#2D2E2E"}
                fontSize={"1rem"}
                //   width={"90%"}
                // textAlign={"center"}
                fontWeight={600}
                lineHeight={"30px"}
                fontFamily={"Plus Jakarta Sans"}
              >
                Cookie policy
              </Typography>
              <Typography
                mt={1}
                // color={"#EAEAEA"}
                fontSize={"1rem"}
                //   width={"90%"}
                // textAlign={"center"}
                fontWeight={600}
                lineHeight={"30px"}
                fontFamily={"Plus Jakarta Sans"}
              >
                |
              </Typography>
              <Link href="/#/privacy-policy" target="_blank" underline="none">
                <Typography
                  mt={1}
                  color={"#2D2E2E"}
                  fontSize={"1rem"}
                  //   width={"90%"}
                  // textAlign={"center"}
                  fontWeight={600}
                  lineHeight={"30px"}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  Privacy policy
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
