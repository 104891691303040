import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import cuttingEdgeImg from "../../assets/Cutting-edge.png";
import blogUser1 from "../../assets/blogUser1.png";
import apis from "../../apis/apis";
import Blogs from "../Blogs/Blogs";
import { useHistory } from "react-router";

const DiveDeepSection = () => {
  const history = useHistory();
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    apis.getAllBlogs().then((res) => {
      console.log(res, "Blog response");
      setBlogData(res.data.data);
    });
  }, []);
  return (
    <Box my={4}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Typography
          color={"#13375B"}
          fontSize={{ xs: "1.2rem", sm: "1.5rem", md: "2.3rem" }}
          width={{ xs: "90%", sm: "70%", md: "60%" }}
          textAlign={"center"}
          fontWeight={700}
          fontFamily={"Plus Jakarta Sans"}
        >
          Dive deep. Check out what our fellow surgeons are thinking about
          today.
        </Typography>
      </Box>
      <Box
        // width={"90%"}
        display={"flex"}
        justifyContent={"center"}
        gap={2}
        flexWrap={"wrap"}
      >
        <Box
          width={"90%"}
          display={"flex"}
          justifyContent={"center"}
          gap={2}
          flexWrap={"wrap"}
          mt={4}
        >
          {blogData?.slice(0, 4).map((dt) => {
            return <Cards data={dt} />;
          })}
          {/* <Cards />
          <Cards />
          <Cards /> */}
        </Box>
      </Box>
      <Box
        // width={"90%"}
        display={"flex"}
        justifyContent={"center"}
        mt={2}
        flexWrap={"wrap"}
      >
        <Button
          onClick={() => history.push(`/blogs`)}
          variant="contained"
          sx={{
            // width: "100%",
            borderRadius: "5px",
            backgroundColor: "#1681B1",
            boxShadow: "none",
            py: 1,
            textTransform: "none",
            display: "flex",
            gap: 3,
            alignItems: "center",
            fontSize: "16px",
            color: "#fff",
            mt: 2,
            px: 10,
            ":hover": {
              backgroundColor: "#1681B1",
              boxShadow: "none",
            },
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          View all blogs
        </Button>
      </Box>
    </Box>
  );
};

export default DiveDeepSection;

const Cards = ({ data }) => {
  const history = useHistory();
  const convertedDate = (dateString) => {
    var date = new Date(dateString);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var formattedDate =
      months[date.getMonth()] +
      " " +
      date.getDate() +
      ", " +
      date.getFullYear();
    return formattedDate;
  };

  return (
    <Box display={"flex"} flex={1} maxWidth={300} minWidth={250}>
      <Box
       onClick={()=> history.push(`/blogs/${data.id}`)}
        sx={{
          border: "1px solid #E8E8EA",
          borderRadius: "12px",
          p: 1.5,
          cursor: "pointer"
        }}
      >
        <Box height={200} borderRadius={"6px"} overflow={"hidden"}>
          <img src={data?.imageUrl} height={"100%"} width="100%" alt="" style={{objectFit:'cover'}}/>
        </Box>
        <Typography
          mt={1}
          width={"90%"}
          color={"#181A2A"}
          fontSize={{ xs: ".8rem", sm: ".9rem", md: "1.2rem" }}
          fontWeight={500}
          fontFamily={"Plus Jakarta Sans"}
          // className={classes.ellipsis}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: {xs:2,md:3},
            WebkitBoxOrient: "vertical",
          }}
        >
          {data.title}
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"95%"}
          mt={2}
          pb={2}
          px={1}
        >
          <Box display={"flex"} gap={2} alignItems={"center"}>
            {/* <Box height={35}>
              <img src={blogUser1} alt="" height={"100%"} />
            </Box> */}
            <Typography
              mt={1}
              color={"#97989F"}
              fontSize={{ xs: ".8rem", sm: ".9rem", md: ".8rem" }}
              fontWeight={500}
              fontFamily={"Plus Jakarta Sans"}
            >
              {data?.authorName}
            </Typography>
          </Box>
          <Typography
            mt={1}
            color={"#97989F"}
            fontSize={{ xs: ".8rem", sm: ".9rem", md: ".8rem" }}
            fontWeight={500}
            fontFamily={"Plus Jakarta Sans"}
          >
            {convertedDate(data?.dateOfUpload)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
