import { Box, Typography } from "@mui/material";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import doctor1 from "../../assets/Doctors1.png";
import connect from "../../assets/Connect.png";
import upgrade from "../../assets/Upgrade1.png";
import share from "../../assets/share.png";
import collaborate from "../../assets/Collaborate.png";
import grow from "../../assets/Grow.png";
import { useMediaQuery } from '@mui/material';



const BenefitsCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 50, // this is needed to tell the amount of px that should be visible.
    },
  };
  const isSmallScreen = useMediaQuery('(max-width:900px)');


  return (
    <Box mb={{sm:20,xs:15}}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
        <Typography
          color={"#13375B"}
          fontSize={{ xs: "1.2rem", sm: "1.5rem", md: "2.3rem" }}
          width={{ xs: "95%", sm: "70%", md: "60%" }}
          textAlign={"center"}
          fontWeight={700}
          fontFamily={"Plus Jakarta Sans"}

        >
          Explore the exclusive benefits of joining the Surgeons For Surgeons
          community
        </Typography>
      </Box>
      <Box
        mt={{xs:4,sm:8}}
        sx={{
          // overflowX: "hidden",
          position: "relative",
        }}
      >
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          infinite={true}
          //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          //   containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          //   deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          centerMode={isSmallScreen?false:true}

          
        >
          {data.map((dt) => (
            <Box display={"flex"} justifyContent={"center"}>
              <Cards dt={dt}/>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default BenefitsCarousel;

const Cards = ({dt}) => {
  return (
    <Box mx={0} width={{sm:"90%",xs:'90%',md:'100%'}}>
      <Box
        width={"100%"}
        sx={{
          border: "1px solid #1681b180",
          borderRadius: "7px",
          px: {sm:4,xs:'.5rem'},
          py: 6,
          display: "flex",
          justifyContent: "center",
          gap: 4,
          flexWrap: "wrap",
          alignItems:'center',
          flexDirection:{xs:'column-reverse',sm:'row'}
        }}
      >
        <Box minWidth={'40%'} maxWidth={{sm:'60%'}}>
          <Typography
            color={"#13375B"}
            fontSize={{ xs: "1.2rem", sm: "1.25rem", md: "1.5rem" }}
            // width={"50%"}
            fontFamily={"Plus Jakarta Sans"}
            textAlign={{xs:'center',sm:"start"}}
            fontWeight={600}
          >
            {dt.heading}
          </Typography>
          <Typography
            mt={2}
            color={"#000000"}
            fontSize={"1.1rem"}
            fontFamily={"Plus Jakarta Sans"}
            fontWeight={400}
            textAlign={{xs:'center',sm:"start"}}
          >
            {dt.subheading}
          </Typography>
        </Box>
        <Box height={{sm:160,xs:140}} sx={{aspectRatio:1}} >
          <img src={dt.image} height={"100%"} width={'100%'}  alt="" />
        </Box>
      </Box>
    </Box>
  );
};


const data = [
  {
    image:connect,
    heading:"Connect with the best anytime, anywhere.",
    subheading:'Find a surgeon by name, specialization, city, or alma-mater. Connect instantly.', 
  },
  {
    image:collaborate,
    heading:"Improve outcomes by collaborating with peers",
    subheading:'Get complex cases solved. Ask for second opinions. Meet up when you travel... Anything is possible once you get connected.', 
  },
  {
    image:share,
    heading:"Share your cases  effortlessly. ",
    subheading:'Upload case photos and videos. Add a case description.  Click submit. And the community is enriched. ', 
  },
  {
    image:grow,
    heading:"Build your reputation beyond borders",
    subheading:'Share your expertise via videos, posts or articles. Become a speaker. Make a mark in the global surgical community.', 
  },
  {
    image:upgrade,
    heading:"Upgrade your knowledge at your convenience.",
    subheading:'Watch videos. Read posts. Take part in discussions. Attend events. A world of surgical knowledge is just a click away.', 
  },
]