import React, { useEffect } from "react";
import FooterContent from "./FooterContent";

const PrivacyPolicy = () => {

  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, []);

  return (
    <section
      id="privacy-policy"
      style={{
        color: "black",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="lead mb-4">
              PRIVACY POLICY, USER GUIDELINES and Allowable User Preferences
            </h1>
            <p className="text-justify">
              SFS Global LLC  (“SFS”, “us”, “we”, or “our”) operates the SFS
              website(s) and provides associated services (“Services”) and is
              the author and publisher of the internet resource
              ______________________ (“Website”) on the world wide web as well
              as the software and applications provided by SFS, including but
              not limited to the mobile applications.
            </p>
            <p className="text-justify">
              This privacy policy ("Privacy Policy") explains how we collect,
              use, share, disclose and protect Personal information about the
              Users of the Services, including the Terms of Use, which may be
              accessed via the following _______________________ (the “Terms of
              Use”)), the End-Users (as defined in the Terms of Use), and the
              visitors of Website (jointly and severally referred to as “you” or
              “Users” in this Privacy Policy). We created this Privacy Policy to
              demonstrate our commitment to the protection of your privacy and
              your personal information. Your use of and access to the Services
              is subject to this Privacy Policy and our Terms of Use. Any
              capitalized term used but not defined in this Privacy Policy shall
              have the meaning attributed to it in our Terms of Use.
            </p>
            <p className="text-justify">
              BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION,
              YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE
              PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE
              TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR
              COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS
              DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE,
              MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY
              POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE
              WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE
              SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE
              SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN
              ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE
              AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY
              POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT
              ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND
              DISCLOSURE OF SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS
              DESCRIBED IN THIS PRIVACY POLICY.
            </p>
            <p className="text-justify">
              Purpose of this Document. This document governs your visit
              to surgeonsforsurgeons.com and associated websites and mobile
              applications, and explains how we collect, safeguard and disclose
              information that results from your use of the Services. We use
              your data to provide and improve Services. By using Services, you
              agree to the (i) collection and use of information in accordance
              with this document, and (ii) usage and other terms of this
              document. You shouldn’t use the Services if you don’t agree with
              this document or any other agreement that governs your use of the
              Services. All the data that we store is in compliance with the
              U.S. Health Insurance Portability and Accountability Act of 1996
              (“HIPAA”), General Data Protection Regulation (“GDPR”), National
              Health Service Act, and other applicable Laws, as amended.
            </p>

            <ol>
              <li>
                <p className="text-justify">
                  What Data We Collect. We collect certain data from you
                  directly, like information you enter yourself, data about your
                  knowledge and interests vis-a-vis SFS that you access and/or
                  share in the form of but not limited to videos, comments,
                  articles and any other information voluntarily provided and
                  data from third-party and platforms you connect with via SFS.
                  We also collect some data automatically, like information
                  about your device and what parts of our Services you interact
                  with and the amount of time you spend time using the same.
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      Data You Provide to Us. We may collect data from or about
                      you depending on how you use the Services. Below are some
                      examples to help you better understand the data we
                      collect. When you create an account and/or use the
                      Services, including through a third-party platform, we
                      collect any data you provide directly, including:
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          Account Data. In order to use certain features (like
                          enrolling in a course), you need to create a user
                          account. When you create or update your account, we
                          collect and store the data you provide, like your
                          email address, password, gender, and date of birth;
                          and assign you a unique identifying number (“Account
                          Data”).
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Profile Data. You can also choose to provide profile
                          information like a photo, headline, website link,
                          social media profiles, or other data. Your Profile
                          Data will be publicly viewable by others.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Shared Content. Parts of the Services let you interact
                          with other users or share content publicly, including
                          by posting reviews, asking or answering questions,
                          sending messages to other members, or posting photos,
                          videos or other work you upload. Such shared content
                          may be publicly viewable by others depending on where
                          it is posted.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Payment Data. If you make purchases, we collect
                          certain data about your purchase (such as your name
                          and zip code) as necessary to process your order. You
                          must provide certain payment and billing data directly
                          to our payment processing partners, including your
                          name, credit card information, billing address, and
                          zip code. For security, SFS does not collect or store
                          sensitive cardholder data, such as full credit card
                          numbers or card authentication data.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Data About Your Accounts on Other Services. We may
                          obtain certain information through your social media
                          or other online accounts if they are connected to your
                          SFS account. For example, depending on the platform or
                          service, we may collect your name, profile picture,
                          account ID number, login email address, location,
                          physical location of your access devices, gender,
                          birthday, and list of friends or contacts. Those
                          platforms and services make information available to
                          us through their APIs. The information we receive
                          depends on what information you (via your privacy
                          settings) or the platform or service decide to give
                          us. If you access or use our Services through a
                          third-party platform or service, or click on any
                          third-party links, the collection, use, and sharing of
                          your data will also be subject to the privacy policies
                          and other agreements of that third party.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Communications and Support. The content,
                          communications and other information you provide when
                          you use the Services, including when you sign up for
                          an account, create or share content, and message or
                          communicate with others via our website(s). This can
                          include information in or about the content you
                          provide (such as metadata, the location of a photo or
                          the date a file was created). The content,
                          communications and information that other people
                          provide to or about you when they use the Services.
                          This can include information about you, such as when
                          they share or comment on a photo, send a message to
                          you, or upload, sync or import your contact
                          information. All communications with other users of
                          the Services. User communications, feedback,
                          suggestions and ideas sent to you or by you. If you
                          contact us for support or to report a problem or
                          concern (regardless of whether you have created an
                          account), we collect and store your contact
                          information, messages, and other data about you like
                          your name, email address, location, operating system,
                          IP address, and any other data you provide or that we
                          collect through automated means (which we cover
                          below). We use this data to respond to you and
                          research or respond to your question or concern, in
                          accordance with this Privacy Policy.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          The data listed above is stored by us and associated
                          with your account.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="text-justify">
                      Data we collect Through Automated Means. When you access
                      the Services (including by surfing through our website),
                      we collect certain data by automated means, including:
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          System Data. Technical data about your computer or
                          device, like your IP address, device type, operating
                          system type and version, unique device identifiers,
                          browser, browser language, domain and other systems
                          data, and platform types.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Usage Data. Usage statistics about your interactions
                          with the Services, including time spent on pages or
                          the Services, pages visited, features used, your
                          search queries, click data, date and time, and other
                          data regarding your use of the Services.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Approximate Geographic Data. An approximate geographic
                          location, including information like country, city,
                          and geographic coordinates, calculated based on your
                          IP address.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          The data listed above is collected through the use of
                          server log files and tracking technologies. It is
                          stored by us and associated with your account.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  How We Get Data About You. We use tools like cookies, web
                  beacons, analytics services, and advertising providers to
                  gather the data listed above. Some of these tools offer you
                  the ability to opt out of data collection.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  No Sale of Data. SFS does not sell your personal information
                  or the personal information of any of our users.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Ownership of Content. All images, text and other content you
                  provide to or via a SFS or affiliated website becomes the
                  property of SFS, and you shall be deemed to have assigned the
                  copyright therein to SFS.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Cookies and Data Collection Tools. As detailed in our Cookie
                  Policy below, SFS and service providers acting on our behalf
                  (like Google Analytics and third party advertisers) use server
                  log files and automated data collection tools like cookies,
                  tags, scripts, customized links, device or browser
                  fingerprints, and web beacons (together, “Data Collection
                  Tools”) when you access and use the Services. These Data
                  Collection Tools automatically track and collect certain
                  System Data and Usage Data (as detailed in Section 1) when you
                  use the Services. In some cases, we tie data gathered through
                  those Data Collection Tools to other data that we collect as
                  described in this Privacy Policy. We use cookies (small files
                  that websites send to your device to uniquely identify your
                  browser or device or to store data in your browser) for things
                  like analyzing your use of the Services, personalizing your
                  experience, making it easier to log into the Services, and
                  recognizing you when you return. We use web beacons (small
                  objects that allow us to measure the actions of visitors and
                  users using the Services) for things like identifying whether
                  a page was visited, identifying whether an email was opened,
                  and advertising more efficiently by excluding current users
                  from certain promotional messages or identifying the source of
                  a new mobile app download.
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      SFS uses the following types of cookies:
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          Preferences. cookies that remember data about your
                          browser and preferred settings that affect the
                          appearance and behavior of the Services (like your
                          preferred language).
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Security. Cookies used to enable you to log in and
                          access the Services; protect against fraudulent
                          logins; and help detect and prevent abuse or
                          unauthorized use of your account.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Functional. Cookies that store functional settings
                          (like the volume level you set for video/audio call
                          playback).
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Session State. Cookies that track your interactions
                          with the Services to help us improve the Services and
                          your browsing experience, remember your login details,
                          and enable processing of your course purchases. These
                          are strictly necessary for the Services to work
                          properly, so if you disable them then certain
                          functionalities will break or be unavailable. You can
                          set your web browser to alert you about attempts to
                          place cookies on your computer, limit the types of
                          cookies you allow, or refuse cookies altogether. If
                          you do, you may not be able to use some or all
                          features of the Services, and your experience may be
                          different or less functional.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Some of the third-party partners who provide certain
                          features on our site may also use Local Storage
                          Objects (also known as flash cookies or LSOs) to
                          collect and store data.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Analytics. We use third-party browser and mobile analytics
                  services. These services use Data Collection Tools to help us
                  analyse your use of the Services, including information like
                  the third-party website you arrive from, how often you visit,
                  events within the Services, usage and performance data, and
                  where the application was downloaded from. We use this data to
                  improve the Services, better understand how the Services
                  perform on different devices, and provide information that may
                  be of interest to you.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Online Advertising. We use third-party advertising services
                  like ad networks and ad servers to deliver advertising about
                  our Services on other websites and applications you use. The
                  ads may be based on things we know about you, like your Usage
                  Data, System Data and things that these ad service providers
                  know about you based on their tracking data. The ads can be
                  based on your recent activity or activity over time and across
                  other sites and services, and may be tailored to your
                  interests. Depending on the types of advertising services we
                  use, they may place cookies or other tracking technologies on
                  your computer, phone, or other device to collect data about
                  your use of our Services, and may access those tracking
                  technologies in order to deliver these tailored advertisements
                  to you. To help deliver tailored advertising, we may provide
                  these service providers with an anonymized version of your
                  email address (in a non-human-readable form) and content that
                  you share publicly on the Services.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  What We Use Your Data For. We use your data to do things like
                  provide our Services, communicate with you, troubleshoot
                  issues, secure your information against fraud and abuse,
                  improve and update our Services, analyze how people use our
                  Services, deliver personalized advertising, and as required by
                  law or necessary for safety and integrity. In particular, SFS
                  uses the data collected through your use of the Services to:
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      Provide and administer the Services, including to display
                      customized content and facilitate communication with other
                      users;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">Process your requests; </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Communicate with you about your account by:
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          Responding to your questions and concerns;
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Sending you administrative messages and information,
                          including messages from members, notifications about
                          changes to our Service, and updates to our agreements;
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Sending you information and in-app messages about your
                          in-app communications, rewards programs, new services,
                          new features, promotions, newsletters, and other
                          available data (which you can opt out of at any time);
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Sending push notifications to your wireless device to
                          provide updates and other relevant messages (which you
                          can manage from the “options” or “settings” page of
                          the mobile app);
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="text-justify">
                      Manage your account preferences;{" "}
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Facilitate the Services’ technical functioning, including
                      troubleshooting and resolving issues, securing the
                      Services, and preventing fraud and abuse;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Solicit feedback from users;{" "}
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Market and administer surveys and promotions administered
                      or sponsored by SFS;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Learn more about you by linking your data with additional
                      data through third-party data providers or analysing the
                      data with the help of analytics service providers;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Identify unique users across devices;{" "}
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Tailor advertisements across devices;{" "}
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Improve our Services and develop new products, services,
                      and features;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Analyze trends and traffic, track purchases, and track
                      usage data;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Advertise the Services on third-party websites and
                      applications;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Comply with requirements of law; and/or{" "}
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Engage in such activities as we, in our sole discretion,
                      otherwise determine to be necessary to ensure the safety
                      or integrity of our users, employees, third parties, the
                      public, or the Services.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Who We Share Your Data With. We share certain data about you
                  with companies performing services for us, our business
                  partners, analytics and data enrichment providers, your social
                  media providers, companies helping us run promotions and
                  surveys, and advertising companies who help us promote our
                  Services. We may also share your data as needed for security,
                  legal compliance, or as part of a corporate restructuring.
                  Lastly, we can share data in other ways if it is aggregated or
                  de-identified or if we get your consent. We may share your
                  data with third parties under the following circumstances or
                  as otherwise described in this Privacy Policy:
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      With Other Members: Depending on your settings, your
                      shared content and profile data may be publicly viewable,
                      including to other members. If you ask a question of
                      anyone in a forum you have joined, your information
                      (including your name) may also be publicly viewable by
                      other users depending on your settings.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      With Service Providers, Contractors, and Agents: We share
                      your data with third-party companies who perform services
                      on our behalf, like payment processing, data analysis,
                      marketing and advertising services (including retargeted
                      advertising), email and hosting services, and customer
                      services and support. These service providers may access
                      your personal data and are required to use it solely as we
                      direct (which will be in accordance with your privacy
                      settings), to provide our requested service.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      With Business Partners: We have agreements with other
                      websites and platforms to distribute our Services and
                      drive traffic to SFS . Depending on your location, we may
                      share your data with our partners.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      With Analytics and Data Enrichment Services: As part of
                      our use of third-party analytics tools like Google
                      Analytics and other data enrichment services, we share
                      certain contact information, Account Data, System Data,
                      Usage Data (as detailed in Section 1), or de-identified
                      data as needed. De-identified data means data where we’ve
                      removed things like your name and email address and
                      replaced it with a token ID. This allows these providers
                      to provide analytics services or match your data with
                      publicly-available database information (including contact
                      and social information from other sources). We do this to
                      communicate with you in a more effective and customized
                      manner.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      To Administer Promotions and Surveys: We may share your
                      data as necessary to administer, market, or sponsor
                      promotions and surveys you choose to participate in, as
                      required by applicable law (like to provide a winners’
                      list or make required filings), or in accordance with the
                      rules of the promotion or survey.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      For Advertising:  We may use and share certain System Data
                      and Usage Data with third-party advertisers and networks
                      regarding general demographic and preference information
                      of our users. We may also allow advertisers to collect
                      System Data through Data Collection Tools (as detailed in
                      Section 2.1), and to use this data to offer you targeted
                      ad delivery to personalize your user experience (through
                      behavioral advertising) and undertake web analytics.
                      Advertisers may also share with us the data they collect
                      about you. To learn more or opt out from participating ad
                      networks’ behavioral advertising, see Section 6.1 (Your
                      Choices About the Use of Your Data) below. Note that if
                      you opt out, you’ll continue to be served generic ads.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      For Security and Legal Compliance: We may disclose your
                      data to third parties if we (in our sole discretion) have
                      a good faith belief that the disclosure is:
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          Permitted or required by law;{" "}
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Requested as part of a judicial, governmental, or
                          legal inquiry, order, or proceeding;
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Reasonably necessary as part of a valid subpoena,
                          warrant, or other legally-valid request;
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Reasonably necessary to enforce our Terms of Use,
                          Privacy Policy, and other legal agreements;
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Required to detect, prevent, or address fraud, abuse,
                          misuse, potential violations of law (or rule or
                          regulation), or security or technical issues; or
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Reasonably necessary in our discretion to protect
                          against imminent harm to the rights, property, or
                          safety of SFS , our users, employees, members of the
                          public, or our Services.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          We may also disclose data about you to our auditors
                          and legal advisors in order to assess our disclosure
                          obligations and rights under this Privacy Policy.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="text-justify">
                      During a Change in Control: If SFS undergoes a business
                      transaction like a merger, acquisition, corporate
                      divestiture, or dissolution (including bankruptcy), or a
                      sale of all or some of its assets, we may share, disclose,
                      or transfer all of your data to the successor organization
                      during such transition or in contemplation of a transition
                      (including during due diligence).
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      After Aggregation/De-identification: We can disclose or
                      use aggregate or de-identified data for any purpose.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      With Your Permission: with your consent, we may share data
                      with third parties outside the scope of this Privacy
                      Policy.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Security. We use appropriate security based on the type and
                  sensitivity of data being stored. As with any internet-enabled
                  system, there is always a risk of unauthorized access, so it’s
                  important to protect your password, change it at least every
                  three months, and to promptly contact us if you suspect any
                  unauthorized access of your account.
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      SFS takes appropriate security measures to protect against
                      unauthorized access, alteration, disclosure, or
                      destruction of your personal data that we collect and
                      store. These measures vary based on the type and
                      sensitivity of the data. Unfortunately, however, no system
                      can be 100% secured, so we cannot guarantee that
                      communications between you and SFS , the Services, or any
                      information provided to us in connection with the data we
                      collect through the Services will be free from
                      unauthorized access by third parties. Your password is an
                      important part of our security system, and it is your
                      responsibility to protect it. You should not share your
                      password with any third party, and if you believe your
                      password or account has been compromised, you should
                      change it immediately and
                      contact support@surgeonsforsurgeons.com with any concerns.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Your Rights. You have certain rights regarding the use of your
                  data, including the ability to opt out of promotional emails,
                  cookies, and collection of your data by certain analytics
                  providers. You can update or terminate your account from
                  within the Services, and can also contact us with individual
                  rights requests concerning your personal data. Parents who
                  believe we’ve (unintentionally) collected personal data about
                  their underage child should contact us for help deleting that
                  information.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Your Choices About the Use of Your Data. You can choose:
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      Not to provide certain data to us, but if you do so you
                      may not be able to use certain features of the Services.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      To stop receiving promotional communications from us. You
                      can opt out by using the unsubscribe mechanism in the
                      promotional communication you receive or by changing
                      the email preferences in your account profile. Regardless
                      of your email preference settings, we will send you
                      transactional and relationship messages regarding the
                      Services, including administrative confirmations, order
                      confirmations, important updates about the Services, and
                      notices about our policies.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      The browser or device you use may allow you to control
                      cookies and other types of local data storage. Your
                      wireless device may also allow you to control whether
                      location or other data is collected and shared.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      If you have any questions about your data, our use of it,
                      or your rights, contact us
                      at support@surgeonsforsurgeons.com.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Terminating Your Account; Accessing, Updating, and Deleting
                  Your Personal Data. You can terminate your account, access,
                  update and delete your personal data that SFS collects and
                  maintains as follows:
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      To update data you provide directly, log into your account
                      and update your account profile at any time.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      To terminate your account: support@surgeonsforsurgeons.com{" "}
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          Please note: even after your account is terminated,
                          some or all of your data may still be visible to
                          others, such as (but not limited to) any data that has
                          been (a) copied, stored, or disseminated by other
                          users (including in-course comment); (b) shared or
                          disseminated by you or others (including in your
                          shared content); or (c) posted to a third-party
                          platform. After your account is terminated, we retain
                          your data for as long as we have a legitimate purpose
                          to do so (and in accordance with applicable law),
                          including to assist with legal obligations, resolve
                          disputes, and enforce our agreements. We may retain
                          and disclose such data pursuant to this Privacy Policy
                          after your account has been terminated.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="text-justify">
                      To request to access, correct, or delete your personal
                      data, email support@surgeonsforsurgeons.com or write to us
                      at SFS. Please allow up to 30 days for a response. For
                      your protection, we may require that the request be sent
                      through the email address associated with your account,
                      and we may need to verify your identity before
                      implementing your request. Please note that we retain
                      certain data where we have a lawful reason to do so,
                      including for mandatory record-keeping and to complete
                      transactions.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Our Policy Concerning Children. We recognize the privacy
                  interests of children and encourage parents and guardians to
                  take an active role in their children’s online activities and
                  interests. Children under 18 years of age should not be
                  permitted to use the Services. Parents who believe that SFS
                  may have collected personal data from a child under the age of
                  18 years can submit a request that corresponding information
                  be removed to support@surgeonsforsurgeons.com.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Users in California. California resident users have certain
                  rights under the California Consumer Privacy Act, (“CCPA”). If
                  you are an eligible California user, included in these rights
                  are:
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      Right to Know. You have the right to know more about the
                      categories and specific pieces of personal information
                      that we collect about you and access a copy of your
                      collected personal information.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Right to Deletion”. You have the right to request deletion
                      of personal information that we have collected about you.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Right to Non-Discrimination”. If you choose to exercise
                      any of your rights under CCPA, SFS will treat you like all
                      other users. In other words, there is no penalty for
                      exercising your rights under CCPA.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      To exercise any of these rights under CCPA, please email
                      support@surgeonsforsurgeons.com or write to us at
                      support@surgeonsforsurgeons.com. As part of the CCPA you
                      may designate an authorized agent to make these requests
                      on your behalf. For your protection, we may require that
                      the request be sent through the email address associated
                      with your account, and we may verify you and/or your
                      agent’s identity before fulfilling your request.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      For more information about the personal data we collect
                      and how we collect it, please see the sections above
                      entitled “What Data We Collect” and “How We Get Data About
                      You.”
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      To learn about the business and commercial purposes for
                      which your personal information is collected and the
                      categories of service providers who have access to your
                      personal information, please see the sections above
                      entitled “What We Use Your Data For” and “Who We Share
                      Your Data With.”
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      You also have the right under the CCPA to request certain
                      details about what personal information we share with
                      third parties for those third parties’ direct marketing
                      purposes. To submit your request, send an email
                      to support@surgeonsforsurgeons.com with the phrase
                      “California Shine the Light” and include your mailing
                      address, state of residence, and email address. Since the
                      internet industry is still working on Do Not Track
                      standards, solutions, and implementation, we do not
                      currently recognize or respond to browser-initiated Do Not
                      Track signals.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Updates and Contact Information. When we make a material
                  change to this policy, we notify users via email, in-product
                  notice, or another mechanism required by law. Changes become
                  effective the day they’re posted. Please contact us via email
                  (to support@surgeonsforsurgeons.com) or postal mail with any
                  questions, concerns, or disputes.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Modifications to This Document. From time to time we may
                  update this Document. If we make any material change to it, we
                  will notify you via email, through a notification posted on
                  the Services website(s), or as required by applicable law. We
                  will also include a summary of the key changes. Unless stated
                  otherwise, modifications will become effective on the day they
                  are posted.
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      As permitted by applicable law, if you continue to use the
                      Services after the effective date of any change, then your
                      access and/or use will be deemed an acceptance of (and
                      agreement to follow and be bound by) the revised Document.
                      The revised Document supersedes all previous versions.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Interpretation. Any capitalized terms not defined in this
                  policy are defined as specified in SFS ' Terms of Use. Any
                  version of this Privacy Policy in a language other than
                  English is provided for convenience. If there is any conflict
                  with a non-English version, the English language version will
                  control.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Questions. If you have any questions, concerns, or disputes
                  regarding this Document, please feel free to contact our staff
                  at support@surgeonsforsurgeons.com. You can also send postal
                  mail to us at SFS.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  USER CONTENT and CONDUCT GUIDELINES.{" "}
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      General Guidelines. These guidelines apply to any and all
                      use of the Services:
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          NO INDIVIDUALLY IDENTIFIABLE HEALTH INFORMATION SHALL
                          BE INCLUDED IN ANY COMMUNICATION WITH, POSTING ON, OR
                          VIA ANY SFS WEBSITE OR ANY AFFILIATED PORTAL, FORUM OR
                          SYSTEM.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          All content posted shall be in compliance with U.S.
                          HIPAA (Health Insurance Portability and Accountability
                          Act of 1996, as amended).
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Where we are alerted to possible guidelines
                          violations, we may investigate and take necessary
                          action, including restricting or terminating
                          permission to use the Services.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          SFS may remove accounts that are inactive for more
                          than six months.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          We may alter these guidelines, so kindly refer to them
                          regularly.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="text-justify">Unacceptable Use:</p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          Participation in unlawful activities.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Dissemination of malware.{" "}
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Accessing another user’s account without prior
                          consent.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Circulating another individual’s personaI information
                          or likeness without consent.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Phishing or otherwise attempting to obtain personal
                          information of another.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Sexist, racial, derogatory, cursing or otherwise
                          offensive speech.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Sending spam or otherwise undesirable mass
                          solicitation.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Creation of multiple accounts.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Publication of unauthorized links to third party
                          software or content.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Manipulation of ranking or relevance using strategy
                          like repetitive or deceptive keywords or metadata,
                          offering incentives to review a particular product or
                          service.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Posting of fake reviews.{" "}
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Encouraging others to post offensive comments.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Circulation of content that ill-treats children or
                          images, text or data that represents children in any
                          sexual manner.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Aggressive, bullying or otherwise intimidating
                          behavior.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Online gambling or promotion of gambling utilizing the
                          Services.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Misuse or sale of user names.{" "}
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          User Name Squatting: Some of the factors we take into
                          consideration when determining whether conduct is
                          username squatting include:
                        </p>
                        <ol>
                          <li>
                            <p className="text-justify">
                              the number of accounts created;{" "}
                            </p>
                          </li>
                          <li>
                            <p className="text-justify">
                              the creation of accounts for the purpose of
                              preventing others from using those account names;
                            </p>
                          </li>
                          <li>
                            <p className="text-justify">
                              the creation of accounts for the purpose of
                              selling those accounts; and
                            </p>
                          </li>
                          <li>
                            <p className="text-justify">
                              the use of third-party content feeds to update and
                              maintain accounts under the names of those third
                              parties.
                            </p>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Legal Rights May Vary With the Services. These guidelines are
                  applicable to the content you post on our Service. However,
                  some Services have their own policy, which could be located
                  within those Services and are applicable to your use of them.
                  All the Services are regulated by their applicable Terms of
                  Service.
                </p>
              </li>
              <li>
                <p className="text-justify">
                  Consequences of Failure to Adhere to Guidelines. Failure to
                  adhere to the guidelines may result in SFS taking one or more
                  of the following enforcement actions:
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      Requiring deletion of prohibited content before creation
                      of new posts and interaction with other SFS users;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Temporarily limiting the ability to create posts or
                      interact with other SFS users;
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Requiring verification of account ownership with a phone
                      number or email address; or
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Permanently suspending an account. Any attempt to evade
                      permanent suspension by creating new accounts will result
                      in suspension of the new accounts.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">DISCLAIMERS.</p>
                <ol>
                  <li>
                    <p className="text-justify">
                      Limitation of Liability. SFS SHALL NOT BE LIABLE TO ANY
                      USER (OR TO ANY PERSON CLAIMING RIGHTS DERIVED FROM A
                      USER) FOR DIRECT, INCIDENTAL, INDIRECT, CONSEQUENTIAL,
                      SPECIAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY KIND --
                      INCLUDING LOST REVENUES OR PROFITS, LOSS OF BUSINESS, LOSS
                      OF DATA AND DAMAGE TO REPUTATION -- IN ANY WAY RELATED TO
                      THE SERVICES, INCLUDING WITHOUT LIMITATION AS A RESULT OF
                      BREACH OF ANY TERM OF THIS POLICY, ANY STATEMENT ON THE
                      WEBSITE(S), OR AS A RESULT OF NEGLIGENCE OR BREACH OF
                      STATUTORY DUTY, REGARDLESS OF WHETHER SFS WAS ADVISED, HAD
                      OTHER REASON TO KNOW, OR IN FACT KNEW OF THE POSSIBILITY
                      THEREOF. IN NO EVENT SHALL THE LIABILITY OF SFS FOR A
                      CLAIM EXCEED THE TOTAL AMOUNT PAID TO OR RECEIVED BY SFS
                      FROM THE CLAIMANT DURING THE TWELVE MONTH PERIOD PRIOR TO
                      THE DATE THE CLAIM AROSE.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      No Warranties; No Implied Endorsements. THE SERVICES ARE
                      PROVIDED "AS IS" AND "AS AVAILABLE", WITHOUT WARRANTY BY
                      SFS OF ANY KIND; AND, TO THE MAXIMUM EXTENT ALLOWED BY
                      APPLICABLE LAW, SFS EXPRESSLY DISCLAIMS ALL WARRANTIES,
                      EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED
                      WARRANTIES OF MERCHANTABILITY, TITLE, AND FITNESS FOR A
                      PARTICULAR PURPOSE OR ANY WARRANTY OF NON-INFRINGEMENT OF
                      THIRD PARTY INTELLECTUAL PROPERTY RIGHTS OR RIGHTS ARISING
                      FROM A COURSE OF DEALING, USAGE, OR TRADE PRACTICE. THE
                      USER WILL NOT HOLD SFS RESPONSIBLE FOR ANY DAMAGES THAT
                      RESULT FROM THE USER’S USE OF THE SERVICES. NO ORAL OR
                      WRITTEN INFORMATION, STATEMENT OR ADVICE GIVEN BY ANY
                      PERSON SHALL CREATE A WARRANTY IN ANY WAY WHATSOEVER
                      RELATING TO SFS . DESCRIPTIONS OF, OR REFERENCES TO,
                      PRODUCTS OR PUBLICATIONS DO NOT IMPLY ENDORSEMENT OF THAT
                      PRODUCT OR PUBLICATION. THE WEBSITE(S) ARE UNDER
                      CONTINUOUS DEVELOPMENT AND CHANGES MAY BE MADE IN THEIR
                      VIDEO PRESENTATION, PROGRAMS, IMAGES, TEXT AND OTHER
                      CONTENT AT ANY TIME. SFS DOES NOT HAVE ANY DUTY TO UPDATE
                      THE INFORMATION CONTAINED THEREIN, TO CORRECT ERRORS, OR
                      OTHERWISE.
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          The information provided
                          by support@surgeonsforsurgeons.com, LLC on or via the
                          SFS .com website and affiliated or linked websites is
                          for general informational purposes only. All
                          information on the website(s) is provided in good
                          faith. SFS makes no representation or warranty of any
                          kind, express or implied, regarding the accuracy,
                          adequacy, validity, reliability, availability, or
                          completeness of any information on the website(s).
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          No endorsement of Opinions. The opinions or views
                          expressed on SFS or social media platforms, including,
                          but not limited to blogs, Facebook and Twitter pages,
                          represent the thoughts of individual users and online
                          communities, and not necessarily those of SFS , any of
                          its corporate partners, affiliates or any of their
                          respective directors, officers, employees, staff or
                          members of its respective board of managers of board
                          of directors. The opinions and views expressed on
                          these pages do not in any way reflect the views of the
                          site they are posted on, other sites affiliated with
                          the site, the SFS staff involved with maintaining the
                          site, or any users or members of the site. By
                          submitting content (defined as materials, advice,
                          representations, opinions and views) to any of SFS ’
                          sites or platforms, you understand and acknowledge
                          that this information is available to the public, and
                          that you are allowing SFS , and its member
                          organizations and clubs, to have a permanent right to
                          use your comments for SFS business purposes including
                          but not limited to internal and external promotions.
                          This may include using, republishing, reproducing,
                          distributing, changing or displaying the comments
                          across SFS brand products and other websites without
                          restriction from time of submission in perpetuity.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="text-justify">
                      External Links Disclaimer. The SFS website(s) may contain
                      (or may connect to) links to other websites or content
                      belonging to or originating from third parties or links to
                      websites and features. Such external links are not
                      investigated, monitored, or checked for accuracy,
                      adequacy, validity, reliability, availability or
                      completeness by us. We do not warrant, endorse, guarantee,
                      or assume responsibility for the accuracy or reliability
                      of any information offered by third-party websites linked
                      through the site or any website or feature linked in any
                      banner or other advertising. We will not be a party to or
                      in any way be responsible for monitoring any transaction
                      between you and third-party providers of products or
                      services. SFS :
                    </p>
                    <ol>
                      <li>
                        <p className="text-justify">
                          Does not control or guarantee the accuracy, relevance,
                          timeliness or completeness of information contained on
                          a linked website.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Does not endorse the organizations sponsoring linked
                          websites, the views they express or the products and
                          services they offer.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Does not authorize the use of copyrighted materials
                          contained in linked websites. Users must request such
                          authorization from the sponsor of the linked website,
                          and from every identifiable person who appears in
                          materials which are posted or to be posted.
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          Is not liable for any special, incidental, indirect or
                          consequential damages of any kind arising out of or in
                          connection with the use of articles or other material
                          derived from any linked website or user thereof,
                          whether or not advised of the possibility of damage,
                          or on any theory of liability.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p className="text-justify">
                      User Data Disclaimer. User data is provided "as is"
                      without warranty of any kind, either express or implied;
                      including, but not limited to the warranties of
                      merchantability, fitness for a particular purpose, and
                      non-infringement.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Affiliates Disclosure. SFS may receive an affiliate
                      commission for any purchases or actions made by users on
                      affiliate or linked websites.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Testimonials Disclaimer. SFS and linked website may
                      contain testimonials by users of products and/or services.
                      These testimonials reflect the real-life experiences and
                      opinions of such users. However, the experiences are
                      personal to those particular users, and may not
                      necessarily be representative of all users of the products
                      and/or services. SFS does not claim, and users should not
                      assume that all users will have the same experiences, as
                      individual results may vary. Some testimonials may have
                      been shortened or otherwise edited in a non-substantive
                      manner. The views and opinions contained in the
                      testimonials belong solely to the individual user and do
                      not reflect views or opinions of SFS or any affiliate.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      No Legal Advice. The SFS website(s) do not purport to give
                      or give any legal advice of any kind. No user should rely
                      on anything contained therein as constituting such advice.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      No Authorization Regarding Copyright Protected Materials.
                      Users are not authorized to download or copy any copyright
                      protected materials from any SFS website(s) or any website
                      linked to the same.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Errors and Omission Disclaimer. While reasonable efforts
                      have been made to ensure that the information contained in
                      the SFS website(s) has been obtained from reliable
                      sources, SFS is not responsible for any errors or
                      omissions or for the result of use of this information.
                      All information is provided "as is", with no guarantee of
                      completeness, accuracy, timeliness or of the results
                      obtained from the use of this information, and without
                      warranty of any kind, express or implied, including, but
                      not limited to warranties of performance, merchantability,
                      and fitness for a particular purpose. In no event will SFS
                      , its related entities, or the partners, agents, employees
                      or consultants thereof be liable for any decision made or
                      action taken in reliance on such information or for any
                      consequential, special or similar damages, even if advised
                      of the possibility of such damages.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Guest Contributors Disclaimer. The SFS website(s) include
                      content from guest contributors and any views or opinions
                      expressed in such content are personal to the contributor
                      and do not represent those of SFS or any of its staff or
                      affiliates unless explicitly so stated.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Cookie Policy. Cookies serve to remember information about
                  user visits to the SFS website(s), like preferred language,
                  and to make the site easier to use. SFS uses both session
                  cookies, which expire after a short time or when you close
                  your browser, and persistent cookies, which remain stored in
                  your browser for a set period of time. Session cookies are
                  used to identify each user during a single browsing session,
                  like when you log into SFS . Persistent cookies are used to
                  identify users over a longer period, like when you request
                  that we keep you signed in. Cookies and similar technologies
                  like web beacons, pixel tags, and local shared objects (“flash
                  cookies”) are used to deliver, measure, and improve Services;
                  and are used when accessing the website(s) through a browser
                  and through the SFS mobile app. Additional user data may be
                  gathered through other methods. Purposes for which cookies are
                  used include the following:
                </p>
                <ol>
                  <li>
                    <p className="text-justify">
                      Authentication and Security. To log users into SFS ; to
                      protect user security; to help detect and fight spam,
                      abuse, and other activities that violate SFS ’ policies or
                      agreements. For example, cookies help authenticate user
                      access to the SFS website(s) and prevent unauthorized
                      parties from accessing user accounts.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Preferences. To remember data about your browser and your
                      preferences; to remember user settings and other user
                      choices you’ve made. For example, cookies help SFS
                      remember your preferred language or the country you’re in,
                      so we can provide content in your preferred language
                      without asking each time you visit.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Analytics and Research. To help SFS improve and understand
                      how people use its website(s). For example, cookies help
                      test different versions of the website(s) to determine
                      which features or content users prefer; web beacons help
                      determine which email messages are opened, and cookies
                      help determine how users interact with the website(s),
                      like the links they most frequently click on. SFS also
                      works with a number of analytics partners, including
                      Google Analytics, who use cookies and similar technologies
                      to help SFS analyze how users use the Services, including
                      by noting the sites from which they arrive. Those service
                      providers may collect that data themselves or SFS may
                      disclose it to them.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Personalized Content. To customize the SFS website(s) with
                      more relevant content. For example, cookies enable display
                      of a personalized list of recommended courses or programs.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Targeted Advertising. To provide users with more relevant
                      advertising. Where advertising technology is integrated
                      into the Services, users may receive advertising on other
                      websites and applications, but it will not be tailored to
                      your interests.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Privacy Options. Most browsers automatically accept
                      cookies, but you can change your browser settings to
                      decline cookies by consulting your browser’s support
                      articles. If you decide to decline cookies, you may not be
                      able to sign in, customize, or use some interactive
                      features in the Services.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Updates. From time to time, SFS may update this Cookie
                      Policy. If so, users will be notified by posting the
                      policy on the website(s) with a new effective date. If SFS
                      makes any material changes, reasonable steps will be taken
                      to notify users in advance of the planned change.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                <p className="text-justify">
                  Contact SFS . Feedback, comments, requests for technical
                  support or other inquiries, should be sent by email to
                  support@surgeonsforsurgeons.com.
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <FooterContent/>
    </section>
  );
};

export default PrivacyPolicy;
