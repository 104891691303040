import React, { Fragment, useEffect, createRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import FooterContent from "./FooterContent";
import blogTwoImage from "../assets/img/Puestow pancreatic duct exposed Pic 1.png";
import blogThreeImage from "../assets/img/Puestow pic 2 Anastomosis in progress.png";
import blogFourImage from "../assets/img/Puestow Anastomosis complete Pic3.png";
import blogDefaultImage from "../assets/img/OQ6UTW0.jpg";
import doctorThumbTwoImage from "../assets/img/doctors/doctor-thumb-02.jpg";
import doctorThumbThreeImage from "../assets/img/doctors/doctor-thumb-03.jpg";
import Config from "../configs/Config";
import { Alert, Badge, FormControl, Dropdown } from "react-bootstrap";
import apis from "../apis/apis";
import Constants from "../configs/Constants";
import { format } from 'date-fns';

const Media = (props) => {
  // const mediaSection = createRef();

  const [modalShow, setModalShow] = React.useState(false);
  const [activeImage, setActiveImage] = React.useState(null);
  const [activeDescription, setActiveDescription] = React.useState(null);
  const [showAllImages, setShowAllImages] = React.useState(null);

  const setModalImage = (image, description) => {
    setActiveImage(image);
    setActiveDescription(description);
    setModalShow(true);
  };

  useEffect(() => {
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    // if (!id) {
    if (!id || isAllDetailsFilled === 0) {
      props.history.push("/login");
    } else {
      getImages();
    }
  }, []);

  const getImages = async () => {
    try {
      let data = await apis.getAllLearnImages();
      setShowAllImages(data.data.posts);
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = useState("");

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  return (
    <Fragment>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        activeImage={activeImage}
        activeDescription={activeDescription}
      />
      <div className="main-wrapper">
        <div className="banner-header text-center"></div>

        <section className="section section-blogs">
          <div className="container-fluid">
            <div className="section-header text-center">
              <h2
                style={{
                  color: "#2272a1",
                  textTransform: "unset",
                  fontSize: " 3.25rem",
                }}
              >
                {" "}
                Academics{" "}
              </h2>

              <div className="row align-items-center justify-content-center">
                <div className="col-md-6">
                  <div className="card-body">
                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                      <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          className="nav-link"
                          to="/media"
                        >
                          Images
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/video">
                          Videos
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <NavLink className="nav-link" to="/event">
                          Events
                        </NavLink>
                      </li> */}
                      {/* <li className="nav-item">
                                                <NavLink className="nav-link" to="/meeting">
                                                    Meetings
                                                </NavLink>
                                            </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              {/* <p className="sub-title">A 13-year-old female presented with a long history of recurrent pancreatitis.
                            Extensive workup found no known etiology for the pancreatitis. Imaging revealed a
                            dilated pancreatic duct with pancreatolithiasis. There were no pancreatic masses.
                            The patient underwent a Partington-Rochelle Modification of Puestow Procedure
and remains asymptomatic to this day.</p> */}
            </div>

            {/* <div className="row"> */}
            {/* <div className="col-10"> */}
            <form
              autoComplete="off"
              class="form-inline col-12"
              style={{
                justifyContent: "center",
                marginBottom: "12px",
                minHeight: "40px",
              }}
            >
              {/* <input class="form-control mr-sm-2 col-lg-4 col-md-4 col-sm-12" type="search" placeholder="Search" aria-label="Search" />
                            <button class="btn btn-outline-primary my-2 my-sm-0 col-lg-1 col-md-1 col-sm-12" type="submit">Search</button> */}

              {/* <Dropdown className="col-lg-2">
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" style={{ color: '#0ddbfb' }}>
                                    Select Alphabet
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu} style={{ overflowY: 'auto', height: '280px' }}>
                                    <Dropdown.Item eventKey="1">A</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">B</Dropdown.Item>
                                    <Dropdown.Item eventKey="3" active>
                                        C
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="1">D</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">E</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">F</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">G</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">H</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">I</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">J</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">K</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">L</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">M</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">N</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">O</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">P</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">Q</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">R</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">S</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">T</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">U</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">V</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">W</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">X</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">Y</Dropdown.Item>
                                    <Dropdown.Item eventKey="1">Z</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}

              {/* <Dropdown className="col-lg-2">
                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" style={{ color: '#0ddbfb' }}>
                                    Select Organ
                                        </Dropdown.Toggle>
                                <Dropdown.Menu as={CustomMenu} >
                                    <Dropdown.Item eventKey="1" active>Open</Dropdown.Item>
                                    <Dropdown.Item eventKey="2">Laparoscopic</Dropdown.Item>
                                    <Dropdown.Item eventKey="3" >
                                        Thoracoscopic
                                            </Dropdown.Item>
                                    <Dropdown.Item eventKey="4">
                                        MAS
                                            </Dropdown.Item>
                                    <Dropdown.Item eventKey="4">
                                        Robotic
                                            </Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown> */}
            </form>
            {/* </div> */}
            {/* </div> */}

            <div className="row blog-grid-row">
              {showAllImages?.map((item) => {
                // console.log(item)
                return (
                  <div
                    key={item?.post_id}
                    className="col-md-6 col-lg-4 col-sm-12"
                    onClick={setModalImage.bind(
                      this,
                      Constants.server+"lwsfs/"+(item?.attachment),
                      item.description
                    )}
                  >
                    <div className="blog grid-blog">
                      <div className="blog-image">
                        <a href="javascript:void(0)">
                          <img
                            className="img-fluid1"
                            src={Constants.server+"lwsfs/"+(item?.attachment)}
                            alt="Post Image"
                          />
                        </a>
                      </div>
                      <div className="blog-content">
                        <ul className="entry-meta meta-item">
                          <li>
                            <div className="post-author">
                              {/* <a href="javascript:void(0)"> */}
                              <img src={Constants.server+(item?.profile_pic)} alt="Post Author" />
                              <span>
                                <Link to="/profile/9">
                                  <Link to="/profile/9">Dr. {item?.full_name}</Link>
                                </Link>
                              </span>
                              {/* </a> */}
                              <div className="community-badge">
                                <Badge
                                  style={{
                                    color: "white",
                                    fontSize: "small",
                                    margin: "10px 0",
                                  }}
                                  pill
                                  variant="info"
                                >
                                  {item?.speciality}
                                </Badge>
                              </div>
                            </div>
                          </li>
                          <li>
                            <i className="far fa-clock"></i> {format(new Date(item?.createdAt),"d MMM yyyy")}
                          </li>
                        </ul>
                        <h3 className="blog-title">{item?.title}</h3>
                        <small>{item.description}</small>
                        {/* <h3 className="blog-title"><a href="blog-details.html">Benefits of consulting with an Online
										Doctor</a></h3> */}
                        {/* <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <div className="view-all text-center">
                            <a href="blog-list.html" className="btn btn-primary">View All</a>
                        </div> */}
          </div>
        </section>
      </div>
      <div className="alert alert-info text-center">
        Are you interested in sharing your surgical images, videos and events on
        the Academics section? Please send us an e-mail on{" "}
        <a href="mailto:support@surgeonsforsurgeons.com">
          support@surgeonsforsurgeons.com
        </a>
        .
      </div>
      <FooterContent></FooterContent>
    </Fragment>
  );
};

export default Media;

function MyVerticallyCenteredModal(props) {
  console.log("props<<<<<<<<<<<>>>>>>>>>>>>>>>>", props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <img className="img-fluid1 " style={{display:'block',margin:"0 auto"}} src={props.activeImage} alt="Post Image" />
        <p
          className="mb-0"
          style={{ fontSize: "15px", marginTop: "25px", marginBottom: "25px" }}
        >
          {props.activeDescription}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
