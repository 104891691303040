// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Image, ListGroup, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { Tab } from "semantic-ui-react";
import "../assets/css/custome.css";
import "../assets/css/posts.css";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import LoaderEmitters from "../configs/LoaderEmitters";
import CasePost from "./CasePost";
import ConfirmationActionModal from "./ConfirmationActionModal";
import ConfirmationModal from "./ConfirmationModal";
import EditCaseModal from "./EditCaseModal";
import apis from "../apis/apis";
import { Formik } from "formik";
import * as Yup from "yup";
import EditClinicalCase from "./EditClinicalCase";
import { Link } from "react-router-dom";
import indemnity from "../assets/docs/Indemnity Bond.pdf";

const panes = [
  {
    menuItem: "Tab 1",
    render: () => <Tab.Pane attached={false}>Tab 1 Content</Tab.Pane>,
  },
  {
    menuItem: "Tab 2",
    render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane>,
  },
  {
    menuItem: "Tab 3",
    render: () => <Tab.Pane attached={false}>Tab 3 Content</Tab.Pane>,
  },
];

const CommunityCases = (props) => {
  const comment = function (id, elm, flag) {
    if (!flag) {
      return;
    }

    if (!id) return;
    var x = document.getElementById(id);
    setCurruntComment("");
    if (x.style.display === "none") {
      x.style.display = "block";
      getCommentForCommunityId(elm);
    } else {
      x.style.display = "none";
    }
  };

  const location = useLocation();
  const history = useHistory();
  const forceSpecialityFilter = () =>
    location.pathname === "/community" && setSpecialityFilter("All");

  const { show, setShow } = props;
  const [showReportModal, setShowReportModal] = useState(false);
  const onEdit = () => {};
  const [communityData, setCommunityData] = useState([]);
  const [specialityFilter, setSpecialityFilter] = useState("All");
  const [communityCollection, setCommunityCollection] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [userLikesDetails, setUserLikesDetails] = useState([]);
  const [curruntComment, setCurruntComment] = useState([]);
  const [communityCommentData, setCommunityCommentData] = useState([]);
  const [curruntIdForReport, setCurruntIdForReport] = useState(null);
  // const [loginDetails, setLoginDetails] = useState({});
  const [communityFriendRequest, setCommunityFriendRequest] = useState([]);
  const [shouldRefresh, setshouldRefresh] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const refetch =() => {
  //   loadDataOfCommunity(Config.getSessionInfo("id"));
  // }
  const refetch = React.useCallback(() => {
    loadDataOfCommunity(Config.getSessionInfo("id"));
  }, []);
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const beyondFileRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentToDeleted, setCommentToDeleted] = useState({
    comment: null,
    elm: null,
  });

  const [modalListLikeData, setModalListLikeData] = useState(false);
  const [showLikeList, setShowLikeList] = useState(false);
  const handleCloseLikeModal = (elm) => {
    setShowLikeList(false);
  };
  const handleShowLikeModal = (elm) => {
    setShowLikeList(true);
  };

  const handleCloseReportModal = (elm) => {
    setShowReportModal(false);
    setCurruntIdForReport(null);
  };
  const handleShowReportModal = (elm, flag) => {
    if (!flag) {
      return;
    }
    setShowReportModal(true);
    setCurruntIdForReport(elm);
  };

  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileType, setSelectedFileType] = useState();
  const [preview, setPreview] = useState();
  const [filters, setFilters] = useState({
    countyId: "all",
    specialtyId: "all",
    opportunityId: "all",
  });
  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);
  const [caseToBeDeleted, setCaseToBeDeleted] = useState(null);
  const [isEditCaseModalVisible, setEditCaseModalVisible] = useState(false);
  const [caseToBeEdited, setcaseToBeEdited] = useState(null);
  const [caseEditId, setCaseEditId] = useState(null);
  const [isEditClicnicalCase, setIsEditClicnicalCase] = useState(false);

  useEffect(() => {
    if (shouldRefresh) {
      setCommunityData([]);
      loadDataOfCommunity(Config.getSessionInfo("id"));
      setshouldRefresh(false);
    }
    return () => {};
  }, [shouldRefresh]);

  useEffect(() => {
    // setLoginDetails(Config.getSessionInfo());
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    if (!id || isAllDetailsFilled === 0) {
      history.push("/login");
      // props.history.push('/communityNonRegister');
    } else {
      // props.history.push('/community');
      // console.log("Config.getSessionInfo()>>>>>>>>", Config.getSessionInfo());
      setUserDetails({
        ...Config.getSessionInfo(),
      });
      loadDataOfCommunity(Config.getSessionInfo("id"));
      // loadDataOfLikes()

      getCommentFriendRequestList({ id: Config.getSessionInfo("id") });
    }

    // if (Config.getSessionInfo('id')) {
    //     console.log("Config.getSessionInfo()>>>>>>>>", Config.getSessionInfo());
    //     setUserDetails({
    //         ...Config.getSessionInfo()
    //     });
    //     loadDataOfCommunity(Config.getSessionInfo('id'));
    // }
  }, []);

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const getCommentForCommunityId = (elm) => {
    // setCommunityCommentData();

    // LoaderEmitters.showLoader({
    //   showLoader: true,
    // });

    axios
      .post(Constants.getCommentForCommunityId, {
        community_id: elm.id,
      })
      .then((response) => {
        // reset();
        // LoaderEmitters.showLoader({
        //   showLoader: false,
        // });

        console.log("loadDataOfCommunity response>>>>>>", response);
        setCommunityCommentData([...response.data.result]);
      })
      .catch(console.error);
  };

  const mergeById = (arr) => {
    const merged = _.merge(
      _.keyBy(arr[0], "id"),
      _.keyBy(arr[1], "id"),
      _.keyBy(arr[2], "id"),
      _.keyBy(arr[3], "id")
    );
    let values = _.values(merged);
    values = _.orderBy(merged, ["id"], ["desc"]);
    return values;
  };

  const loadDataOfLikes = (id) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getCommunityPostLike, {
        id: Config.getSessionInfo("id"),
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        let likeArr = [];
        for (let val of response.data.result) {
          likeArr.push(val.community_id);
        }
        setUserLikesDetails(likeArr);
      })
      .catch(console.error);
  };

  const loadDataOfCommunity = (id) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getCommunityPost, {
        id: id,
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        getCommunityCollection(id);
        // loadDataOfLikes(id)
        //getBeyondSurgoryCollection (id);
        setCommunityData(response.data.result[0]);
        console.log(response.data.result);
        // setBeyondSurgoryData([...newMergedArr])
        setUserLikesDetails([...response.data.result[1]]);
      })
      .catch(console.error);
  };

  const getCommentFriendRequestList = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getCommentFriendRequestList, {
        signup_id: elm.id,
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        console.log("getCommentFriendRequestList response>>>>>>", [
          ...response.data.result,
        ]);
        setCommunityFriendRequest([...response.data.result]);
      })
      .catch(console.error);
  };

  const handleCaseCommentDelete = async (caseId, commentId, communityId) => {
    try {
      LoaderEmitters.showLoader({
        showLoader: true,
      });
      const response = await axios.post(Constants.deleteCommentForCommunityId, {
        comment_id: commentId,
        community_id: communityId,
      });
      if (response.data.code === 200) {
        setCommunityData((oldData) =>
          oldData.map((Case) => {
            if (Case.id === caseId && Case.comment_count) {
              Case.comment_count--;
            }
            return Case;
          })
        );
        setCommunityCommentData((oldComments) =>
          oldComments.filter((ele) => ele.id !== commentId)
        );
      }
      LoaderEmitters.showLoader({
        showLoader: false,
      });
      setIsModalVisible(false);
    } catch (error) {
      console.log(error);
      LoaderEmitters.showLoader({
        showLoader: false,
      });
      setIsModalVisible(false);
    }
  };

  const displayTime = (time) => {
    return moment(time).calendar();
  };

  const onSubmit = (data) => {
    // data.id = Config.getSessionInfo('id');

    var xx = document.getElementById("attachFile");
    const formData = new FormData();
    formData.append("cummunityfile", xx.files[0]);
    // formData.append('description', data.description);
    // formData.append('title', data.title);
    // formData.append('description', data.description);
    // formData.append('medicalRegistration', data.medicalRegistration);

    console.log("formData>>>>>>>>>>>", formData);

    LoaderEmitters.showLoader({
      showLoader: true,
    });
    handleClose();

    const config = {
      headers: {
        title: data.title,
        description: data.description,
        speciality: data.specialtyId,
        medicalregistration: "NA",
        // 'medicalregistration': data.medicalRegistration,
        id: Config.getSessionInfo("id"),
      },
    };

    axios
      .post(Constants.addCommunityPost, formData, config)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          // setCommunityData(oldData => oldData)
          setCommunityData([]);
          setSpecialityFilter("All");
          loadDataOfCommunity(Config.getSessionInfo("id"));
          handleClose();
          // props.history.push('/qualification');
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        reset();
      })
      .catch(console.error);
  };

  const onSubmitReportReason = (data) => {
    console.log("onSubmit data>>>>>", data);
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    // debugger;
    axios
      .post(Constants.addCommunityReportReason, {
        signup_id: Config.getSessionInfo("id"),
        community_id: curruntIdForReport.id,
        reason: data.reason,
        case: curruntIdForReport,
        reportingUser: userDetails,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          loadDataOfCommunity(Config.getSessionInfo("id"));
          handleClose();
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }

        reset();
        handleCloseReportModal();
      })
      .catch(console.error);
  };

  const addComment = (elm) => {
    if (curruntComment) {
      LoaderEmitters.showLoader({
        showLoader: true,
      });
      console.log("add Comment>>>>>>>>", curruntComment);
      axios
        .post(Constants.addCommunityPostAddComment, {
          signup_id: Config.getSessionInfo("id"),
          community_id: elm.id,
          curruntComment,
          elm,
          full_name: Config.getSessionInfo("full_name"),
          module: "community",
          route: "community/comment",
        })
        .then((response) => {
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          setCurruntComment("");
          getCommentForCommunityId(elm);
          // reset();
          let tost = {
            message: "",
            type: "",
          };
          console.log("response>>>>>>", response);
          if (response.data.code === 200) {
            tost.type = "success";
            tost.message = response.data.msg;
            loadDataOfCommunity(Config.getSessionInfo("id"));
            // loadDataOfCommunity(Config.getSessionInfo('id'));
            // handleClose();
            // props.history.push('/qualification');
          } else {
            tost.type = "error";
            tost.message = response.data.msg;
          }

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
        })
        .catch(console.error);
    }
  };

  const onClickLike = (elm, flag, isLiked) => {
    console.log(elm, flag, isLiked ? "yes" : "no");
    if (!flag) {
      return;
    }
    console.log(elm);
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.addCommunityPostLike, {
        signup_id: Config.getSessionInfo("id"),
        community_id: elm.id,
        isLike: 1,
        elm,
        full_name: Config.getSessionInfo("full_name"),
        module: "community",
        route: "community/like",
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          document
            .getElementById(`${elm.id}_liked`)
            .classList.toggle("hideshow");
          document
            .getElementById(`${elm.id}_unLiked`)
            .classList.toggle("hideshow");
          tost.type = "success";
          tost.message = response.data.msg;
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const commentBoxInput = (e) => {
    const { value } = e.target;
    setCurruntComment(value);
    console.log(value);
  };

  const addToCollection = (elm, flag) => {
    if (!flag) {
      return;
    }
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.addCommunityPostToCollection, {
        signup_id: Config.getSessionInfo("id"),
        community_id: elm.id,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        getCommunityCollection(Config.getSessionInfo("id"));

        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const getCommunityCollection = (id) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getCommunityCollection, {
        id: id,
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        let dataArr = [];
        for (let val of response.data.result[4]) {
          dataArr.push(val.community_id);
          //dataArr.push(val.beyond_id);
        }

        setCommunityCollection(dataArr);

        // console.log("getBeyondCollection?>>>>>>>>>>>>>>", [
        //   response.data.result[4],
        // ]);
      })
      .catch(console.error);
  };

  const sendFreindRequest = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.addFriendRequestCommunity, {
        signup_id: Config.getSessionInfo("id"),
        user_two_id: elm.signup_id,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const deletePostCommunity = () => {
    const elm = caseToBeDeleted;
    setDeleteConfirmationVisible(false);
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    axios
      .post(Constants.deleteCommunityPost, {
        community_id: elm.id,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = "Case deleted successfully";
        } else {
          tost.type = "error";
          tost.message = "Failed to delete the case";
        }
        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        setCommunityData([]);
        loadDataOfCommunity(Config.getSessionInfo("id"));
      })
      .catch((e) => {
        EventEmitters.showTost({
          type: "error",
          message: "Failed to delete the case",
        });
      });
  };

  const checkForFriendReqDom = (elm) => {
    const ids = _.find(communityFriendRequest, { user_one: elm.signup_id });
    const ids2 = _.find(communityFriendRequest, {
      user_two: elm.signup_id,
    });

    return ids == undefined && ids2 == undefined;
  };

  const seeLikeList = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    axios
      .post(Constants.getLikeListOfCommunity, {
        signup_id: Config.getSessionInfo("id"),
        community_id: elm.id,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          setShowLikeList(true);
          setModalListLikeData([...response.data.result]);
          // loadDataOfBeyondSurgory(Config.getSessionInfo('id'));
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        // if (tost.message) {
        //     EventEmitters.showTost(tost);
        // }
      })
      .catch(console.error);
  };

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const fileSize = Constants.getFileSizeInMB(e.target);
    // alert(fileSize);

    if (fileSize >= Constants.fileSizeLimitCommunity) {
      EventEmitters.showTost({
        type: "error",
        message:
          "File size should be less than " +
          Constants.fileSizeLimitCommunity +
          " MB.",
      });
      reset();
      return;
    }
    const fileName = e.target.files[0];
    // .jpg, .jpeg, .png, .gif, .webm, .ogg, .mp4,
    const fileNameSmallCap = fileName.name.toLocaleLowerCase();
    // .jpg, .jpeg, .png, .gif, .webm, .ogg, .mp4,
    if (
      fileNameSmallCap.search(".png") > -1 ||
      fileNameSmallCap.search(".jpeg") > -1 ||
      fileNameSmallCap.search(".jpg") > -1 ||
      fileNameSmallCap.search(".gif") > -1
    ) {
      setSelectedFileType({
        type: fileName.type,
        tagType: "image",
      });
    }
    // else if (fileName.name.search('.mp4') > -1 || fileName.name.search('.webm') > -1 || fileName.name.search('.ogg') > -1) {
    //     setSelectedFileType({
    //         type: fileName.type,
    //         tagType: 'video'
    //     });;
    // }
    else {
      EventEmitters.showTost({
        type: "error",
        message: "File type not supported.",
      });
      reset();
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(fileName);
  };

  // const onChangeSpecility = (e) => {
  //     const filtersTemp = {
  //         ...filters,
  //         [e.target.name]: e.target.value
  //     };

  //     setFilters({
  //         ...filtersTemp
  //     });
  //     // const specility = e.target.value;
  // }

  return (
    <React.Fragment>
      <CasePost
        setCommunityData={setCommunityData}
        setCommentToDeleted={setCommentToDeleted}
        setModalVisible={() => setIsModalVisible(!isModalVisible)}
        handleCaseCommentDelete={handleCaseCommentDelete}
        communityData={communityData}
        setSpecialityFilter={setSpecialityFilter}
        specialityFilter={specialityFilter}
        onClickLike={onClickLike}
        userDetails={userDetails}
        displayTime={displayTime}
        seeLikeList={seeLikeList}
        communityCommentData={communityCommentData}
        setCaseToBeDeleted={setCaseToBeDeleted}
        setDeleteConfirmationVisible={setDeleteConfirmationVisible}
        comment={comment}
        addToCollection={addToCollection}
        communityCollection={communityCollection}
        handleShowReportModal={handleShowReportModal}
        setEditCaseModalVisible={setEditCaseModalVisible}
        setcaseToBeEdited={setcaseToBeEdited}
        userLikesDetails={userLikesDetails}
        setUserLikesDetails={setUserLikesDetails}
        caseEditId={caseEditId}
        setCaseEditId={setCaseEditId}
        setIsEditClicnicalCase={setIsEditClicnicalCase}
      />
      <ConfirmationModal
        setModalVisible={() => setIsModalVisible(!isModalVisible)}
        isModalVisible={isModalVisible}
        CommentType="community"
        handleCommentDelete={handleCaseCommentDelete}
        commentToDeleted={commentToDeleted.comment}
        elm={commentToDeleted.elm}
      />
      <Modal show={showReportModal} onHide={handleCloseReportModal}>
        <Modal.Header closeButton>
          <Modal.Title> Please fill the reason. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmitReportReason)}
            noValidate
            autoComplete="off"
          >
            <div className="form-group row">
              <label
                for="email_address"
                className="col-md-4 col-form-label text-md-right"
              >
                Reason
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="reason"
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Reason is required.
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 offset-md-4">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showLikeList} onHide={handleCloseLikeModal}>
        <Modal.Header closeButton>
          <Modal.Title> Surgeons who liked. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {modalListLikeData} */}

          <ListGroup>
            {modalListLikeData &&
              modalListLikeData.map((elm) => {
                if (elm.is_like === 0) return null;
                return (
                  <ListGroup.Item>
                    <Image
                      className="like-modal-img"
                      src={Constants.server + elm.profile_pic}
                      roundedCircle
                    />
                    <h6
                      style={{
                        display: "inline",
                      }}
                    >
                      <NavLink to={"profile/" + elm.owner_id}>
                        {elm.full_name}
                      </NavLink>
                    </h6>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <ConfirmationActionModal
        modalTitle={"Delete Case"}
        actionHandler={deletePostCommunity}
        isVisible={isDeleteConfirmationVisible}
        setVisible={() => setDeleteConfirmationVisible((oldState) => !oldState)}
        actionBtnText={"Delete"}
      >
        <div style={{ fontSize: "1rem" }}>
          Are you sure you want to delete the case?
        </div>
      </ConfirmationActionModal>

      {caseToBeEdited && caseEditId === 1 && (
        <EditCaseModal
          isVisible={isEditCaseModalVisible}
          setVisible={() => setEditCaseModalVisible(!isEditCaseModalVisible)}
          Case={caseToBeEdited}
          setCaseToBeEdited={setcaseToBeEdited}
          setshouldRefresh={setshouldRefresh}
        />
      )}
      {caseToBeEdited && caseEditId === 2 && (
        <EditClinicalCase
          isVisible={isEditClicnicalCase}
          setVisible={() => setIsEditClicnicalCase(!isEditClicnicalCase)}
          Case={caseToBeEdited}
          setCaseToBeEdited={setcaseToBeEdited}
          setshouldRefresh={setshouldRefresh}
        />
      )}

      <CreateClinicalCaseModal
        show={show}
        handleClose={handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const CreateClinicalCaseModal = ({ show, handleClose, refetch }) => {
  const [postType, setPostType] = React.useState(1);
  const [specialityList, setSpecialityList] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      try {
        const response = await apis.getAllSpecialities();
        setSpecialityList(response.data?.specialities);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Case</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <h5 className="mr-4">What would you like to add?</h5>
            <Form.Group controlId="formBasicSelect">
              <Form.Control
                as="select"
                value={postType}
                onChange={(e) => {
                  setPostType(e.target.value);
                }}
              >
                <option value="1">Clinical Image</option>
                <option value="2">Clinical Case</option>
              </Form.Control>
            </Form.Group>
            {
              {
                1: (
                  <ClinicalImageModalForm
                    specialityList={specialityList}
                    handleClose={handleClose}
                    refetch={refetch}
                  />
                ),
                2: (
                  <ClinicalCaseModalForm
                    specialityList={specialityList}
                    handleClose={handleClose}
                    refetch={refetch}
                  />
                ),
              }[postType]
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const clinicalImageValidationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  description: Yup.string().trim().required("Description is required"),
  speciality: Yup.string().trim().required("Speciality is required"),
  file_attached: Yup.bool()
    .isTrue("File is required")
    .required("File is required"),
  t_n_c: Yup.bool()
    .isTrue(
      "Please accept if you understand and provide the following indemnity"
    )
    .required(
      "Please accept if you understand and provide the following indemnity"
    ),
  patient_consent: Yup.bool()
    .isTrue(
      " Please confirm that the patient details shared on this platform are with respect to patient's consent"
    )
    .required(
      " Please confirm that the patient details shared on this platform are with respect to patient's consent"
    ),
  data_relevant: Yup.bool()
    .isTrue("Please accept if the data entered is relevant to platform")
    .required("Please accept if the data entered is relevant to platform"),
});

const clinicalImageInitialValues = {
  title: "",
  description: "",
  speciality: "",
  file_attached: false,
  t_n_c: false,
  patient_consent: false,
  data_relevant: false,
};

const ClinicalImageModalForm = ({ specialityList, handleClose, refetch }) => {
  const [attachment, setAttachment] = React.useState(null);
  const kilo_size = 1024;
  const validFileSize_in_MB = 20;
  const validFileSize_in_KB = validFileSize_in_MB * kilo_size;
  const validFileTypes = ["image/jpg", "image/jpeg", "image/png"];

  const validateFile = (file) => {
    if (
      // check if file type is invalid
      !validFileTypes.includes(file.type)
    )
      return {
        valid: false,
        message: `File type is not valid`,
      };
    const size = file.size / kilo_size;
    // check if file size is invalid
    if (size > validFileSize_in_KB) {
      return {
        valid: false,
        message: `File size is greater than ${validFileSize_in_MB} megabytes`,
      };
    }
    return { valid: true, message: "File is valid" };
  };

  const createClinicalImage = async (values) => {
    const { title, description, speciality } = values;
    const postData = {
      title, // from formik values
      description, // from formik values
      speciality, // from formik values
      cummunityfile: attachment, // from React state
    };
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    try {
      const { id: userId } = JSON.parse(
        window.sessionStorage.getItem("session")
      );
      const response = await apis.createClinicalImage(postData, userId);
      let tost = {
        message: "",
        type: "",
      };
      if (response.data.code === 200) {
        tost.type = "success";
        tost.message = response.data.msg;
        refetch();
      } else {
        tost.type = "error";
        tost.message = response.data.msg;
      }

      if (tost.message) {
        EventEmitters.showTost(tost);
      }
    } catch (error) {
      console.error(error);
    }
    handleClose();
    LoaderEmitters.showLoader({
      showLoader: false,
    });
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={clinicalImageInitialValues}
        validationSchema={clinicalImageValidationSchema}
        onSubmit={(values, actions) => {
          const { title, description, speciality } = values;
          createClinicalImage({
            title: title.trim(),
            description: description.trim(),
            speciality: speciality.trim(),
          });
          actions.setSubmitting(false);
        }}
      >
        {(formik) => {
          return (
            <React.Fragment>
              <form className="container" onSubmit={formik.handleSubmit}>
                <div className="form-group row">
                  <label
                    for="title"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Title
                  </label>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      placeholder="Title"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    />
                    {formik.touched.title && formik.errors.title ? (
                      <div className="error-text" role="alert">
                        {formik.errors.title}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="title"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Speciality
                  </label>
                  <div className="col-md-6">
                    <Form.Control
                      required
                      as="select"
                      id="speciality"
                      name="speciality"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {specialityList?.length ? (
                        <>
                          <option value="">Select a speciality</option>
                          {specialityList.map((_speciality) => (
                            <option value={_speciality}>{_speciality}</option>
                          ))}
                        </>
                      ) : null}
                    </Form.Control>
                    {formik.touched.speciality && formik.errors.speciality ? (
                      <div className="error-text" role="alert">
                        {formik.errors.speciality}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="description"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Description
                  </label>
                  <div className="col-md-6">
                    <textarea
                      required
                      className="form-control"
                      id="description"
                      name="description"
                      cols="30"
                      rows="10"
                      placeholder="Description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched?.description &&
                    formik.errors?.description ? (
                      <div className="error-text" role="alert">
                        {formik.errors?.description}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="file_attached"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Attachment
                  </label>
                  <div className="col-md-6">
                    <input
                      required
                      type="file"
                      accept="image/*"
                      name="file_attached"
                      id="file_attached"
                      onChange={(e) => {
                        if (e.target.files?.length) {
                          const file = e.target.files[0];
                          const { valid, message } = validateFile(file);
                          if (valid) {
                            formik.setFieldValue("file_attached", valid, true);
                            setAttachment(file);
                          } else {
                            formik.setFieldError("file_attached", message);
                            setAttachment(null);
                          }
                        }
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.file_attached &&
                    formik.errors.file_attached ? (
                      <div className="error-text" role="alert">
                        {formik.errors.file_attached}
                      </div>
                    ) : null}
                  <div
                    className="alert-success px-2"
                    role="alert"
                    style={{ marginTop: "4px", width: "fit-content" }}
                  >
                    <span>Supported type (Images - .jpg, .jpeg, .png)</span>
                    <p>Max file size for upload is 200 MB</p>
                  </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-6 offset-md-4">
                    <div className="checkbox">
                      <label htmlFor="t_n_c">
                        <input
                          type="checkbox"
                          id="t_n_c"
                          name="t_n_c"
                          required
                          style={{ marginRight: "6px" }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        I hereby understand and provide the following indemnity.
                      </label>
                      <br />
                      <Link
                        to="/terms_and_conditions"
                        // target="_blank"
                        rel="noopener noreferrer"
                      >
                        Tap here to read.
                      </Link>
                      {/* <a
                        href={indemnity}
                        target="_blank"
                        style={{ color: "#007bff" }}
                      >
                        {" "}
                        Click Here to Read{" "}
                      </a> */}
                      {formik.touched.t_n_c && formik.errors.t_n_c ? (
                        <div className="error-text" role="alert">
                          {formik.errors.t_n_c}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-6 offset-md-4">
                    <div className="patient_consent">
                      <label>
                        <input
                          type="checkbox"
                          id="patient_consent"
                          name="patient_consent"
                          required
                          style={{ marginRight: "6px" }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        I hereby confirm that my patient details shared on this
                        platform are with respect to patient's consent
                      </label>
                      {formik.touched?.patient_consent &&
                      formik.errors?.patient_consent ? (
                        <div className="error-text" role="alert">
                          {formik.errors?.patient_consent}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-6 offset-md-4">
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          id="data_relevant"
                          name="data_relevant"
                          required
                          style={{ marginRight: "6px" }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        This data entered is relevant to platform
                      </label>
                      {formik.touched.data_relevant &&
                      formik.errors.data_relevant ? (
                        <div className="error-text" role="alert">
                          {formik.errors.data_relevant}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 offset-md-4">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal1"
                  >
                    Post
                  </button>
                </div>
              </form>
            </React.Fragment>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

const clinicalCaseValidationSchema = Yup.object().shape({
  speciality: Yup.string().trim().required("Speciality is required"),
  diagnosis: Yup.string().trim().required("Diagnosis is required"),
  age: Yup.number()
    .min(0, "Patient's age needs to be greater than 0")
    .max(100, "Patient's age needs to be lesser than 100")
    .required("Patient's age is required"),
  gender: Yup.string().trim().required("Patient's gender is required"),
  history: Yup.string().trim().required("History is required"),
  clinical_question: Yup.string()
    .trim()
    .required("Clinical question is required"),
  // file_attached: Yup.bool()
  //   .isTrue("File is required")
  //   .required("File is required"),
  file_attached: Yup.bool()
    .isTrue("File size cannot be greater than 200 MB")
    .required("File size cannot be greater that 200 MB"),

  t_n_c: Yup.bool()
    .isTrue("Please agree terms and conditions")
    .required("Please agree terms and conditions"),
  patient_consent: Yup.bool()
    .isTrue(
      "Please comfirm that the patient detsila shared on this platform are with respect to patient's consent"
    )
    .required(
      "Please comfirm that the patient detsila shared on this platform are with respect to patient's consent"
    ),
  data_relevant: Yup.bool()
    .isTrue("Please comfirm that data entered is relevant to platform")
    .required("Please comfirm that data entered is relevant to platform"),
});

const clinicalCaseInitialValues = {
  speciality: "",
  diagnosis: "",
  age: 0,
  gender: "",
  history: "",
  clinical_question: "",
  file_attached: false,
  t_n_c: false,
  patient_consent: false,
  data_relevant: false,
};

const ClinicalCaseModalForm = ({ specialityList, handleClose, refetch }) => {
  const [attachment, setAttachment] = React.useState(null);
  const kilo_size = 1024;
  const validFileSize_in_MB = 200;
  const validFileSize_in_KB = validFileSize_in_MB * kilo_size;
  const validFileTypes = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "video/mp4",
    "video/mkv",
  ];

  const validateFile = (file) => {
    if (
      // check if file type is invalid
      !validFileTypes.includes(file.type)
    )
      return {
        valid: false,
        message: `File type is not valid`,
      };
    const size = file.size / kilo_size;
    // check if file size is invalid
    if (size > validFileSize_in_KB) {
      return {
        valid: false,
        message: `File size cannot be greater that ${validFileSize_in_MB} MB`,
      };
    }
    return { valid: true, message: "File is valid" };
  };

  const createClinicalCase = async (values) => {
    const { age, gender, speciality, diagnosis, history, clinical_question } =
      values;
    const postData = {
      age, // from formik values
      gender, // from formik values
      speciality, // from formik values
      diagnosis, // from formik values
      history, // from formik values
      clinical_question, // from formik values
      cummunityfile: attachment, // from React state
    };
    try {
      LoaderEmitters.showLoader({
        showLoader: true,
      });
      const { id: userId } = JSON.parse(
        window.sessionStorage.getItem("session")
      );
      const response = await apis.createClinicalCase(postData, userId);
      let tost = {
        message: "",
        type: "",
      };
      if (response.data.code === 200) {
        tost.type = "success";
        tost.message = response.data.msg;
        refetch();
      } else {
        tost.type = "error";
        tost.message = response.data.msg;
      }

      if (tost.message) {
        EventEmitters.showTost(tost);
      }
      console.log(response);
    } catch (error) {
      console.error(error);
      LoaderEmitters.showLoader({
        showLoader: false,
      });
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={clinicalCaseInitialValues}
        validationSchema={clinicalCaseValidationSchema}
        onSubmit={(values, actions) => {
          const {
            age,
            gender,
            speciality,
            diagnosis,
            history,
            clinical_question,
          } = values;
          // alert(
          //   JSON.stringify(
          //     {
          //       age: +age,
          //       gender: gender.trim(),
          //       speciality: speciality.trim(),
          //       diagnosis: diagnosis.trim(),
          //       history: history.trim(),
          //       clinical_question: clinical_question.trim(),
          //     },
          //     null,
          //     2
          //   )
          // );
          createClinicalCase({
            age: +age,
            gender: gender.trim(),
            speciality: speciality.trim(),
            diagnosis: diagnosis.trim(),
            history: history.trim(),
            clinical_question: clinical_question.trim(),
          });
          actions.setSubmitting(false);
        }}
      >
        {(formik) => (
          <React.Fragment>
            <form className="container" onSubmit={formik.handleSubmit}>
              <div className="form-group row">
                <label
                  for="speciality"
                  className="col-md-4 col-form-label text-md-right"
                >
                  Speciality
                </label>
                <div className="col-md-6">
                  <Form.Control
                    required
                    as="select"
                    id="speciality"
                    name="speciality"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {specialityList?.length ? (
                      <>
                        <option value="">Select a speciality</option>
                        {specialityList.map((_speciality) => (
                          <option value={_speciality}>{_speciality}</option>
                        ))}
                      </>
                    ) : null}
                  </Form.Control>
                  {formik.touched.speciality && formik.errors.speciality ? (
                    <div className="error-text" role="alert">
                      {formik.errors.speciality}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="diagnosis"
                  className="col-md-4 col-form-label text-md-right"
                >
                  Diagnosis
                </label>
                <div className="col-md-6">
                  <textarea
                    required
                    className="form-control"
                    id="diagnosis"
                    name="diagnosis"
                    cols="30"
                    rows="10"
                    placeholder="Diagnosis"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.diagnosis && formik.errors.diagnosis ? (
                    <div className="error-text" role="alert">
                      {formik.errors.diagnosis}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="age"
                  className="col-md-4 col-form-label text-md-right"
                >
                  Age
                </label>
                <div className="col-md-6">
                  <input
                    required
                    type="number"
                    step="0"
                    max="100"
                    min="0"
                    className="form-control"
                    id="age"
                    name="age"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.age && formik.errors.age ? (
                    <div className="error-text" role="alert">
                      {formik.errors.age}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="gender"
                  className="col-md-4 col-form-label text-md-right"
                >
                  Gender
                </label>
                <div className="col-md-6">
                  <Form.Control
                    required
                    as="select"
                    id="gender"
                    name="gender"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select patient's gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Form.Control>
                  {formik.touched?.gender && formik.errors?.gender ? (
                    <div className="error-text" role="alert">
                      {formik.errors?.gender}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="history"
                  className="col-md-4 col-form-label text-md-right"
                >
                  History
                </label>
                <div className="col-md-6">
                  <textarea
                    required
                    className="form-control"
                    id="history"
                    name="history"
                    cols="30"
                    rows="10"
                    placeholder="History"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched?.history && formik.errors.history ? (
                    <div className="error-text" role="alert">
                      {formik.errors.history}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="file_attached"
                  className="col-md-4 col-form-label text-md-right"
                >
                  Attachments
                </label>
                <div className="col-md-6">
                  <input
                    required
                    type="file"
                    accept="video/*, image/*"
                    name="file_attached"
                    id="file_attached"
                    onChange={(e) => {
                      if (e.target.files?.length) {
                        const file = e.target.files[0];
                        const { valid, message } = validateFile(file);
                        if (valid) {
                          formik.setFieldValue("file_attached", valid, true);
                          setAttachment(file);
                        } else {
                          formik.setFieldError("file_attached", message);
                          setAttachment(null);
                        }
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.file_attached &&
                  formik.touched.file_attached ? (
                    <div className="error-text" role="alert">
                      {formik.errors.file_attached}
                    </div>
                  ) : null}
                     <div
                    className="alert-success px-2"
                    role="alert"
                    style={{ marginTop: "4px", width: "fit-content" }}
                  >
                    <span>Supported type (.jpg, .jpeg, .png, .mp4)</span>
                    <p>Max file size for upload is 200 MB</p>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label
                  for="clinical_question"
                  className="col-md-4 col-form-label text-md-right"
                >
                  Clinical question
                </label>
                <div className="col-md-6">
                  <input
                    required
                    type="text"
                    className="form-control"
                    name="clinical_question"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.clinical_question &&
                  formik.errors.clinical_question ? (
                    <div className="error-text" role="alert">
                      {formik.errors.clinical_question}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-6 offset-md-4">
                  <div className="checkbox">
                    <label htmlFor="t_n_c">
                      <input
                        type="checkbox"
                        id="t_n_c"
                        name="t_n_c"
                        required
                        style={{ marginRight: "6px" }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      I hereby understand and provide the following indemnity.
                    </label>
                    <br />
                    <Link
                      to="/terms_and_conditions"
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tap here to read.
                    </Link>
                    {/* <a
                      href={indemnity}
                      target="_blank"
                      style={{ color: "#007bff" }}
                    >
                      {" "}
                      Click Here to Read{" "}
                    </a> */}
                    {formik.touched.t_n_c && formik.errors.t_n_c ? (
                      <div className="error-text" role="alert">
                        {formik.errors.t_n_c}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-6 offset-md-4">
                  <div className="patient_consent">
                    <label>
                      <input
                        type="checkbox"
                        id="patient_consent"
                        name="patient_consent"
                        required
                        style={{ marginRight: "6px" }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      I hereby confirm that my patient details shared on this
                      platform are with respect to patient's consent
                    </label>
                    {formik.touched?.patient_consent &&
                    formik.errors?.patient_consent ? (
                      <div className="error-text" role="alert">
                        {formik.errors?.patient_consent}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-6 offset-md-4">
                  <div className="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        id="data_relevant"
                        name="data_relevant"
                        required
                        style={{ marginRight: "6px" }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      This data entered is relevant to platform
                    </label>
                    {formik.touched.data_relevant &&
                    formik.errors.data_relevant ? (
                      <div className="error-text" role="alert">
                        {formik.errors.data_relevant}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-6 offset-md-4">
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#exampleModal1"
                >
                  Post
                </button>
              </div>
            </form>
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default CommunityCases;
