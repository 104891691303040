import React, { useState } from "react";
import { Alert, Badge, Dropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import ReadMoreReact from "read-more-react";
import Constants from "../configs/Constants";
import CaseFilterBar from "./CaseFilterBar";
import Comment from "./Comment";

const CasePost = ({
  setCommunityData,
  setCommentToDeleted,
  setModalVisible,
  communityData,
  setSpecialityFilter,
  specialityFilter,
  onClickLike,
  userDetails,
  displayTime,
  seeLikeList,
  handleCaseCommentDelete,
  communityCommentData,
  setCaseToBeDeleted,
  setDeleteConfirmationVisible,
  comment,
  addToCollection,
  communityCollection,
  handleShowReportModal,
  setEditCaseModalVisible,
  setcaseToBeEdited,
  userLikesDetails,
  setUserLikesDetails,
  caseEditId,
  setIsEditClicnicalCase,
  setCaseEditId,
}) => {
  const location = useLocation();
  console.log(userLikesDetails);

  const [isReadMore, setIsReadMore] = useState(null);

  const checkPostLike = (id) => {
    for (let i = 0; i < userLikesDetails.length; i++) {
      if (userLikesDetails[i].id === id && userLikesDetails[i].is_like === 1) {
        console.log("yes liked", userLikesDetails[i]);
        return true;
      }
    }
    return false;
  };

  return (
    <ul className="comments-list">
      {communityData && communityData.length > 0 && (
        <CaseFilterBar
          communityData={communityData}
          setSpecialityFilter={setSpecialityFilter}
          SpecialityFilter={specialityFilter}
        />
      )}
      {communityData && communityData.length ? (
        communityData.map((elm, key) => {
          // const [isLiked, setIsLiked] = useState(checkPostLike(elm.id));
          let isLiked = checkPostLike(elm.id);
          if (specialityFilter !== "All" && elm.speciality !== specialityFilter)
            return null;
          const differenceBtnDates = Constants.getDifferenceBtnDates(
            elm.created_time_date,
            7
          );

          return (
            <li key={`${elm.signup_id}_${elm.id}`}>
              <div className="comment">
                <div className="comment-body full-width-100">
                  <div className="meta-data d-flex">
                <img
                  className="avatar rounded-circle mr-4"
                  alt="User Image"
                  src={
                    Constants.server +
                    elm.profile_pic +
                    `?${new Date().getTime()}`
                  }
                />
                <div className="flex-grow-1">

             
                    <span className="comment-author d-flex justify-content-between align-items-center">
                      {/* Dr. {elm.full_name} */}
                      <NavLink to={"/profile/" + elm.signup_id}>
                        Dr. {elm.full_name}
                      </NavLink>
                      {userDetails.id === elm.signup_id && (
                        <Dropdown>
                          <Dropdown.Toggle
                            as={"span"}
                            className="case-dropdown-toggle"
                          >
                            <i class="fas fa-ellipsis-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              style={{ color: "#757575" }}
                              onClick={() => {
                                setCaseToBeDeleted(elm);
                                setDeleteConfirmationVisible(true);
                              }}
                            >
                              <i class="far fa-trash-alt"></i> Delete
                            </Dropdown.Item>

                            {/* ============ Edit button ===============  */}

                            <Dropdown.Item
                              style={{ color: "#757575" }}
                              onClick={() => {
                                setCaseEditId(parseInt(elm.type));
                                setcaseToBeEdited(elm);
                                console.log("this is type", elm.type);
                                if (elm.type === 1)
                                  setEditCaseModalVisible(true);
                                else setIsEditClicnicalCase(true);
                              }}
                            >
                              <i class="far fa-edit"></i> Edit
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </span>
                    {/* Dr. {userDetails.full_name} */}

                    <span className="comment-date">
                      Posted On: {displayTime(elm.created_time_date)}
                    </span>
                    {elm.speciality ? (
                      <div className="community-badge">
                        <Badge
                          style={{
                            color: "white",
                            fontSize: "small",
                            margin: "10px 0",
                          }}
                          pill
                          variant="info"
                        >
                          {elm.speciality}
                        </Badge>
                      </div>
                    ) : null}

                    <div className="review-count rating">
                      {
                        // (userDetails && (userDetails.id != elm.signup_id) && (checkForFriendReqDom(elm))) ?
                        //     (<a href="javascript:void(0)" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="follow" onClick={sendFreindRequest.bind(this, elm)}>
                        //         <i className="fas fa-plus"></i> Connect
                        //     </a>) : ""
                      }
                    </div>
                    </div>
                  </div>
                  {elm.file ? (
                    `${elm.file}`.includes(".mp4") ? (
                      <div className="d-flex">
                        <video
                          playsInline
                          controls
                          className="img-responsive mx-auto"
                          style={{
                            maxWidth: "100%",
                          }}
                          src={Constants.server + "community/" + elm.file}
                          controlsList="nodownload"
                        />
                      </div>
                    ) : (
                      <div className="d-flex">
                        <img
                          className="img-responsive mx-auto"
                          style={{
                            maxWidth: "100%",
                          }}
                          src={Constants.server + "community/" + elm.file}
                        />
                      </div>
                    )
                  ) : null}
                  <p className="comment-content">
                    {elm?.title ? (
                      <h6
                        // className="text-dark"
                        style={{
                          fontSize: "1rem",
                          // fontWeight: 500,
                          // marginBottom: 0,
                          textTransform: "capitalize",
                        }}
                      >
                        {decodeURIComponent(elm.title)}
                      </h6>
                    ) : null}
                    {elm.description ? (
                      <ReadMoreReact
                        text={decodeURIComponent(elm.description)}
                        // min={40}
                        // ideal={150}
                        // max={500}
                        readMoreText="Read More"
                      />
                    ) : null}
                    {/* {elm.speciality ? (
                      <ReadMoreReact
                        text={decodeURIComponent(elm.speciality)}
                        min={10}
                        ideal={200}
                        max={250}
                        readMoreText="Read more"
                      />
                    ) : null} */}
                    {elm.diagnosis && isReadMore !== elm.id && (
                      <div className="mb-1 diagnosis">
                        <h6 className="mb-0 ">Diagnosis</h6>

                        <ReadMoreReact
                          text={decodeURIComponent(elm.diagnosis)}
                          // min={10}
                          // ideal={100}
                          // max={250}
                          readMoreText=""
                        />

                        <span
                          style={{
                            color: "#09dca4",
                            cursor: "pointer",
                            marginLeft: "3px",
                          }}
                          onClick={() => setIsReadMore(elm.id)}
                        >
                          Read More
                        </span>
                      </div>
                    )}

                    <div
                      style={{
                        height: isReadMore === elm.id ? "auto" : "0px",
                        overflow: "hidden",
                      }}
                    >
                     {elm.diagnosis && <div className="mb-1">
                          <h6 className="mb-0">Diagnosis</h6>
                          {/* <ReadMoreReact
                            text={decodeURIComponent(elm.history)}
                            min={10}
                            ideal={200}
                            max={250}
                            readMoreText="Read more"
                          /> */}
                          <p style={{ margin: "0" }}>
                            {decodeURIComponent(elm.diagnosis)}
                          </p>
                        </div>}
                      {elm.age || elm.gender ? (
                        <div className="mb-1">
                          <h6 className="mb-0">Patient Details</h6>
                          <div className="d-flex" style={{ gap: "1rem" }}>
                            {/* <ReadMoreReact
                              text={decodeURIComponent("Age : " + elm.age)}
                              // min={10}
                              // ideal={200}
                              // max={250}
                              readMoreText="Read more"
                            /> */}
                            <p style={{ margin: "0" }}>
                              {decodeURIComponent("Age : " + elm.age)}
                            </p>

                            {/* <ReadMoreReact
                              text={decodeURIComponent(
                                "Gender : " + elm.gender
                              )}
                              // min={10}
                              // ideal={200}
                              // max={250}
                              readMoreText="Read more"
                            /> */}
                            <p style={{ margin: "0" }}>
                              {decodeURIComponent("Gender : " + elm.gender)}
                            </p>
                          </div>
                        </div>
                      ) : null}
                      {elm.history ? (
                        <div className="mb-1">
                          <h6 className="mb-0">History</h6>
                          {/* <ReadMoreReact
                            text={decodeURIComponent(elm.history)}
                            min={10}
                            ideal={200}
                            max={250}
                            readMoreText="Read more"
                          /> */}
                          <p style={{ margin: "0" }}>
                            {decodeURIComponent(elm.history)}
                          </p>
                        </div>
                      ) : null}
                      {elm.clinical_question ? (
                        <div className="mb-1 ">
                          <h6 className="mb-0">Clinical Question</h6>
                          {/* <ReadMoreReact
                            text={decodeURIComponent(elm.clinical_question)}
                            min={10}
                            ideal={200}
                            max={250}
                            readMoreText="Read more"
                          /> */}
                          <p style={{ margin: "0",marginBottom:'7px' }}>{elm.clinical_question}</p>
                          {isReadMore === elm.id && (
                            <span
                              style={{ color: "#09dca4", cursor: "pointer",fontWeight:'500',fontSize:'14px' }}
                              onClick={() => setIsReadMore(false)}
                            >
                              Read Less
                            </span>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </p>
                  {/* {differenceBtnDates ? null : (
                    <p className="alert alert-danger">Case has been closed</p>
                  )} */}
                  <div className="all-media">
                    {/* {isLiked ? ( */}
                    <span
                      id={`${elm.id}_liked`}
                      href="#"
                      style={{ fontSize: "20px" }}
                      className={`like-btn liked pointer ${
                        !isLiked ? "hideshow" : ""
                      }`}
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i
                        title="Like"
                        className="far fa-heart"
                        style={{ lineHeight: "none" }}
                        onClick={onClickLike.bind(
                          this,
                          elm,
                          differenceBtnDates
                        )}
                      ></i>
                      {/* <small
                          title="Click here to view like members"
                          onClick={seeLikeList.bind(this, elm)}
                          className="likes-count"
                        >
                          {elm.like_count} {elm.like_count ? "-like" : null}
                        </small> */}
                      <small
                        title="Click here to view like members"
                        onClick={seeLikeList.bind(this, elm)}
                        style={{ display: isLiked ? true : false }}
                        className="likes-count"
                      >
                        {elm.like_count}
                        {elm.like_count ? "-like" : null}
                      </small>
                    </span>
                    {/* ) : ( */}
                    <span
                      id={`${elm.id}_unLiked`}
                      href="#"
                      className={`like-btn pointer ${
                        isLiked ? "hideshow" : ""
                      }`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Like"
                      style={{ fontSize: "20px" }}
                    >
                      <i
                        className="far fa-heart"
                        onClick={onClickLike.bind(
                          this,
                          elm,
                          differenceBtnDates,
                          isLiked
                        )}
                      ></i>
                      <small
                        title="Click here to view like members"
                        onClick={seeLikeList.bind(this, elm)}
                        style={{ display: isLiked ? true : false }}
                        className="likes-count"
                      >
                        {elm.like_count}
                        {elm.like_count ? "-like" : null}
                      </small>
                    </span>
                    {/* )} */}

                    <a
                      className="dislike-btn pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Comment"
                      onClick={comment.bind(
                        this,
                        "comment-d" + elm.id,
                        elm,
                        true
                      )}
                    >
                      <span
                        style={{ fontSize: "20px" }}
                        className="far fa-comment"
                      ></span>
                      <span tyle={{ fontFamily: "inherit" }} className="like">
                        {" "}
                        {elm.comment_count}{" "}
                      </span>
                      {/* <i className="fa fa-comment"></i> {elm.comment_count} */}
                    </a>

                    {/* {differenceBtnDates && ( */}
                    <>
                      {communityCollection && (
                        <a
                          className="collection-btn float-right pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          onClick={addToCollection.bind(this, elm, true)}
                          title="Add To Collection"
                        >
                          <i
                            className={
                              communityCollection.includes(elm.id)
                                ? "fa fa-folder liked"
                                : "fa fa-folder"
                            }
                          ></i>{" "}
                          {communityCollection.includes(elm.id)
                            ? "Remove from"
                            : "Add to"}{" "}
                          Collection
                        </a>
                      )}

                      {userDetails.id !== elm.signup_id && (
                        <a
                          className="float-right error"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Report"
                          onClick={handleShowReportModal.bind(this, elm, true)}
                        >
                          <i className="fa fa-flag"></i> Report{" "}
                        </a>
                      )}
                    </>
                    {/* )} */}
                  </div>
                </div>
              </div>
              <Comment
                setPostData={setCommunityData}
                setModalVisible={setModalVisible}
                setCommentToDeleted={setCommentToDeleted}
                CommentData={communityCommentData}
                CommentType={"community"}
                elm={elm}
                userID={userDetails.id}
                handleCommentDelete={handleCaseCommentDelete}
                isInputVisible={differenceBtnDates}
              />
            </li>
          );
        })
      ) : (
        <section>
          <div className="insta fade-scroll" style={{ position: "relative" }}>
            <Alert className="alert-no-post" key={1} variant={"primary"}>
              {location.pathname.includes("communityCollection")
                ? "No cases added to the collection"
                : location.pathname.includes("myCasesCommunity")
                ? "No cases posted"
                : "No cases posted"}
            </Alert>
          </div>
        </section>
      )}
    </ul>
  );
};

export default CasePost;
