import React from "react";
import letterImg from "../assets/img/letter.svg";
// import HeaderContent from './HeaderContent';
import FooterContent from "./FooterContent";
import { useForm } from "react-hook-form";
import Constants from "../configs/Constants";
import axios from "axios";
import LoaderEmitters from "../configs/LoaderEmitters";
import EventEmitters from "../configs/EventEmitters";

const ContactPage = () => {
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const onSubmit = (data) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.server + "contactPage", data)
      .then((response) => {
        reset();
        console.log("response", response);
        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 200) {
          tost.message = response.data.msg;
          tost.type = "success";
        }

        if (tost.message) {
          tost.autoClose = 5000;
          EventEmitters.showTost(tost);
        }

        LoaderEmitters.showLoader({
          showLoader: false,
        });
      })
      .catch(console.error);
  };

  return (
    <React.Fragment>
      {/* <HeaderContent></HeaderContent> */}
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="account-content">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-7 col-lg-6 login-left">
                    <img
                      src={letterImg}
                      className="img-fluid1"
                      alt="Login Banner"
                    />
                  </div>

                  <div className="col-md-12 col-lg-6 login-right">
                    <div className="login-header">
                      <h2>Contact Us </h2>
                    </div>

                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      autoComplete="off"
                    >
                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          name="fName"
                          ref={register({
                            required: "Full Name is required.",
                            minLength: {
                              value: 3,
                              message:
                                "Full Name must be at least 3 characters long.",
                            },
                          })}
                        />
                        <label className="focus-label">Full Name*</label>
                      </div>
                      {errors.fName && (
                        <div className="error-text" role="alert">
                          {errors.fName.message}
                        </div>
                      )}

                      <div className="form-group form-focus">
                        <input
                          type="email"
                          className="form-control floating"
                          name="email"
                          ref={register({
                            required: "Email is required.",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Invalid email address.",
                            },
                          })}
                        />
                        <label className="focus-label">Email*</label>
                      </div>
                      {errors.email && (
                        <div className="error-text" role="alert">
                          {errors.email.message}
                        </div>
                      )}

                      <div className="form-group form-focus">
                        <input
                          type="text"
                          className="form-control floating"
                          name="mobile"
                          ref={register({
                            required: "Mobile number is required.",
                            pattern: {
                              value: /^[0-9]{7,14}$/,
                              message:
                                "Invalid mobile number. It must be 10 digits.",
                            },
                          })}
                        />
                        <label className="focus-label">Mobile*</label>
                      </div>
                      {errors.mobile && (
                        <div className="error-text" role="alert">
                          {errors.mobile.message}
                        </div>
                      )}

                      <div className="form-group form-focus">
                        <textarea
                          className="form-control form-control-message floating"
                          name="message"
                          ref={register({ required: "Message is required." ,minLength:{
                            value:15,message:'Very short message'
                          }})}
                        ></textarea>
                        <label
                          className="focus-label"
                          style={{ overflow: "hidden" }}
                        >
                          Message*
                        </label>
                      </div>
                      <br />
                      {errors.message && (
                        <div className="error-text" role="alert">
                          {errors.message.message}
                        </div>
                      )}
                      <br />
                      <button
                        className="btn btn-primary btn-block btn-lg login-btn mt-2"
                        type="submit"
                      >
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <FooterContent></FooterContent>
    </React.Fragment>
  );
};

export default ContactPage;
