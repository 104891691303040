import Config from "../../configs/Config";

let INIT_STATE = {
  isAuth: Config.getSessionInfo("id"),
};

let authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_IS_AUTH":
      return { ...state, isAuth: action.payload };
    default:
      return state;
  }
};

export default authReducer;
