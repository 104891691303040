import React from "react";
import { useForm } from "react-hook-form";
import Constants from "../../configs/Constants";
import axios from "axios";
import Config from "../../configs/Config";
import EventEmitters from "../../configs/EventEmitters";
import { NavLink } from "react-router-dom";
import FooterContent from "../FooterContent";
import termsOfUse from "../../assets/docs/Terms of Use.pdf";
import companyPolicy from "../../assets/docs/Company Policy.pdf";

const MediRegistration = (props) => {
  React.useEffect(() => {
    const id = Config.getSessionInfo("id");
    if (!id) {
      props.history.push("/");
    }
    loadAllCountries();
  }, []);

  const countryMap = {
    96: "India",
    230: "USA",
    12: "Australia",
    71: "Great Britain (UK)",
  };

  // errors, reset
  const { register, handleSubmit, reset,errors } = useForm(); // initialise the hook

  const [checkbox, setCheckbox] = React.useState(false);
  const [checkboxTwo, setCheckboxTwo] = React.useState(false);

  const [country, setCountry] = React.useState(0);
  const [region, setRegion] = React.useState(0);
  const [mediRegistration, setMediRegistration] = React.useState(null);

  const [regionsList, setRegionsList] = React.useState([]);
  const [countries, setCountries] = React.useState(false);
  const [addMoreList, setAddMoreList] = React.useState({
    country: [],
    region: [],
    mediRegistration: [],
  });

  const onChange = (name, e) => {
    const { value } = e.target;
    if (name === "country") {

      setCountry(
        // ...country,
        value
      );
      axios
        .post(Constants.searchRegionByCountry, {
          countryId: value,
        })
        .then((response) => {
          const { numOfResults, result } = response.data;
          if (numOfResults) {
            if(result[0].name === "London"){
              setRegionsList([{name:"United Kingdom"}])
            }else{
              setRegionsList(result)
            }
          } else {
            setRegionsList([]);
          }
        });
    } else if (name === "region") {
      
      setRegion(
        // ...region,
        value
      );
    } else if (name === "medicalRegistrationNumber") {
      setMediRegistration(
        // ...region,
        value
      );
    }
  };

  const updateAddMoreList = (list) => {
    return list && list.country && list.country.length
      ? list.country.map((elm) => {
          return getCountryNameByList(elm);
        })
      : [];
  };

  const onSubmit = (data) => {
    data.curruentTab = 4;
    data.id = Config.getSessionInfo("id");


    if (
      addMoreList &&
      addMoreList.country &&
      addMoreList.country.length &&
      addMoreList.mediRegistration.length
    ) {
    } else {
      EventEmitters.showTost({
        message: "Click on Add to record the entry.",
        type: "error",
      });
      return;
    }

    if (!checkbox) {
      EventEmitters.showTost({
        message: "Please check Terms & Conditions and Privacy Policy.",
        type: "error",
      });
      return;
    }

    if (!checkboxTwo) {
      EventEmitters.showTost({
        message: "Please check the declaration.",
        type: "error",
      });
      return;
    }
    data.list = { ...addMoreList };
    data.list.countryNames = updateAddMoreList({ ...addMoreList });
    axios
      .post(Constants.mediRegistration, data)
      .then((response) => {
        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 404) {
          tost.message = response.data.msg;
          tost.type = "error";
        } else if (response.data.code === 202) {
          tost.message = response.data.msg;
          tost.type = "success";
          props.history.push("/signup");
        } else if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;

          const loginDetails = JSON.parse(sessionStorage.getItem("session"));
          loginDetails.is_all_details_filled = 1;
          loginDetails.till_where_filled = 1;

          sessionStorage.setItem("session", JSON.stringify(loginDetails));
          props.history.push("/welcome");
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const addMedicalReg = () => {
    // debugger;
    if (country && region && mediRegistration) {
      setAddMoreList({
        country: [...addMoreList.country, country],
        region: [...addMoreList.region, region],
        mediRegistration: [...addMoreList.mediRegistration, mediRegistration],
      });
      // setMediRegistration("");
      setCountry(null);
      setRegionsList([]);
      // reset();
      document.getElementsByName("medicalRegistrationNumber")[0].value = "";
      document.getElementsByName("country")[0].value = 0;
    }
  };

  const removeRecordFromList = (elm, idx) => {
    console.log("addMoreList>>>>>>>>", addMoreList);

    const listTemp = { ...addMoreList };
    listTemp.country.splice(idx, 1);
    listTemp.region.splice(idx, 1);
    listTemp.mediRegistration.splice(idx, 1);
    setAddMoreList({
      ...listTemp,
    });
  };

  const onChangeCheckbox = (e) => {
    const { checked } = e.target;
    setCheckbox(checked);
  };

  const onChangeCheckboxTwo = (e) => {
    const { checked } = e.target;
    setCheckboxTwo(checked);
  };

  const loadAllCountries = () => {
    axios
      .post(Constants.getAllCountries, {})
      .then((response) => {
        setCountries([...response.data.result]);
      })
      .catch(console.error);
  };

  const getCountryNameByList = (elm) => {
    const countriesTemp = [...countries];
    var filterName = countriesTemp.filter((e) => {
      return e.id == elm;
    });
    console.log("filterName.name?>>>>>>>>>>>>>>>>>>", filterName);
    return filterName && filterName[0].name;
  };

  return (
    <React.Fragment>
      <div className="content">
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span className="white">Step 4</span>
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Medical Registration</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-lg-9 col-xl-9">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="row form-row">
                      <div className="col-12 col-md-3 col-lg-3">
                        <div className="form-group">
                          <label>Country *</label>
                          <select
                            name="country"
                            ref={register({ required: true })}
                            className="select form-control"
                            onChange={onChange.bind(this, "country")}
                          >
                            <option value="0" selected="selected">
                              Select Country
                            </option>
                            {countries &&
                              countries.length &&
                              countries.map((opt) => {
                                return (
                                  <option value={opt.id}>{opt.name}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-lg-3">
                        <div className="form-group">
                          <label>State *</label>
                          <select
                            name="region"
                            ref={register({ required: true })}
                            className="select form-control"
                            onChange={onChange.bind(this, "region")}
                          >
                            <option selected="selected" value={0}>
                              Select State*
                            </option>
                            {regionsList &&
                              regionsList.length &&
                              regionsList.map((elm, idx) => {
                                return (
                                  <option key={idx} value={elm.name}>
                                    {elm.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-lg-3">
                        <div className="form-group">
                          <label>Medical Registration Number *</label>
                          <input
                            onChange={onChange.bind(
                              this,
                              "medicalRegistrationNumber"
                            )}
                            type="text"
                            className="form-control"
                            name="medicalRegistrationNumber"
                            placeholder="Medical Registration Number"
                            ref={register({ required: false })}
                          />
                        </div>
                        
                      </div>
                      <div className="col-12 col-md-2 col-lg-2">
                        <div className="form-group">
                          <div>
                            <br />
                          </div>
                          <button
                            style={{ marginTop: "10px", marginLeft: "20px" }}
                            type="button"
                            onClick={addMedicalReg}
                            className="btn btn-primary submit-btn"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <ul>
                        {addMoreList &&
                        addMoreList.country &&
                        addMoreList.country.length
                          ? addMoreList.country.map((elm, idx) => {
                              return (
                                // <li key={idx}> {countries[elm]}  - {addMoreList.region[idx]}</li>
                                <li key={idx}>
                                  {getCountryNameByList(elm)} -
                                  {addMoreList.region[idx]} -
                                  {addMoreList.mediRegistration[idx]}
                                  <sup
                                    onClick={removeRecordFromList.bind(
                                      this,
                                      elm,
                                      idx
                                    )}
                                    title="Click here to remove the record."
                                    style={{
                                      marginLeft: "12px",
                                      marginBottom: "12px",
                                      color: "#F44336",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Delete
                                  </sup>
                                  {/* <span
                                                                        onClick={removeRecordFromList.bind(this, elm, idx)}
                                                                        title="Click here to remove the record."
                                                                        className="fa fa-times"
                                                                        style={{
                                                                            marginLeft: '12px',
                                                                            marginBottom: '12px',
                                                                            color: '#F44336',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                    >

                                                                    </span> */}
                                </li>
                              );
                            })
                          : null}
                      </ul>
                    </div>
                    <div className="form-group">
                      <div className="row form-row">
                        <div className="submit-section col-12">
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={checkbox}
                            className=""
                            onChange={onChangeCheckbox}
                          />
                          <span> I agree to Surgeons for Surgeons</span>
                          <a
                            className="btn btn-link"
                            href={termsOfUse}
                            target="_blank"
                          >
                            Terms of Use
                          </a>
                          <span>and</span>
                          <a
                            className="btn btn-link"
                            href={companyPolicy}
                            target="_blank"
                          >
                            {" "}
                            Company Policies{" "}
                          </a>
                        </div>
                        <div className="submit-section col-12">
                          <input
                            type="checkbox"
                            name="checkboxTwo"
                            checked={checkboxTwo}
                            className=""
                            onChange={onChangeCheckboxTwo}
                          />
                          <span>
                            {" "}
                            The data entered above is true and correct as per my
                            knowledge and belief and I take complete
                            responsibility for the accuracy of the data entered
                            and shared by me.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row form-row">
                        <div className="submit-section col-6">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent></FooterContent>
    </React.Fragment>
  );
};

export default MediRegistration;
