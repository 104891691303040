// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import Select, { components } from "react-select";
import Constants from "../configs/Constants";

const customStyles = {
  singleValue: (provided) => ({
    ...provided,
    fontSize: ".9rem",
    color: "#272b41",
  }),
  input: (provided) => ({
    ...provided,
    fontSize: ".9rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: ".8rem",
  }),
};

const Control = ({ children, ...props }) => {
  const { icon } = props.selectProps;
  const style = {
    cursor: "pointer",
    paddingLeft: "1rem",
    fontSize: ".9rem",
    color: "#272b41",
  };

  return (
    <components.Control {...props}>
      <i className={`fa ${icon}`} aria-hidden="true" style={style}></i>
      {children}
    </components.Control>
  );
};

const SpecialityOption = (props) => {
  // console.log("speciality props", props);
  return (
    <components.Option {...props}>
      <div className="row">
        <span className="col-9">{props.children}</span>
        <span
          className="col-3"
          style={{ color: props.isSelected ? "white" : "grey" }}
        >
          <i class="fas fa-stethoscope mr-1"></i>
          {props.data.count}
        </span>
      </div>
    </components.Option>
  );
};

let axiosSearchCancelToken;
let axiosSearchCanToken;

const UserSearchBar = (props) => {
  const [suggestions, setSuggestions] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [city, setCity] = useState("NULL");
  const [country, setCountry] = useState("NULL");
  const [countryList, setCountryList] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [specialitylist, setSpecialitylist] = useState([]);
  const [speciality, setSpeciality] = useState("NULL");
  const [userResultList, setUserResultList] = React.useState([]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    // Do something
  }, []);

  const loadInitSpecialityData = async () =>
    axios.get(Constants.loadInitUserSearchData).then((response) => {
      let specialityCount = response.data.specialityCount.map((spec) => {
        if (!!spec.specialty_type)
          spec.specialty_type = spec.specialty_type.trim();
        return spec;
      });
      // console.log("sp count", specialityCount);
      let specialities = Constants.specialityList.map((spec) => ({
        value: spec.trim(),
        label: spec.trim(),
        count: 0,
      }));

      let totalDocsInSpec = 0;
      for (let spec of specialityCount) {
        if (!!spec.specialty_type) {
          specialities = specialities.map((speciality) => {
            if (speciality.value === spec.specialty_type) {
              speciality.count = spec.num;
              // console.log("spec", spec);
            }
            return speciality;
          });
          totalDocsInSpec += spec.num;
        }
        // console.log("adter spec", spec, spec.specialty_type);
      }
      setSpecialitylist(
        [...specialities].sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
    });

  useEffect(() => {
    axios.post(Constants.getAllCountries).then((response) => {
      console.log(response);
      setCountryList(
        [
          ...response.data.result.map((country) => ({
            value: country.id,
            label: country.name,
          })),
        ].sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
    });

    axios.get(Constants.loadInitUserSearchData).then((response) => {
      console.log(country);

      setCitylist(
        [
          ...response.data.cities.map((city) => ({
            countryId: city.country_id,
            value: city.name,
            label: city.name,
          })),
        ].sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );

      let specialityCount = response.data.specialityCount;
      // console.log("sp count", specialityCount);
      let specialities = Constants.specialityList.map((spec) => ({
        value: spec.trim(),
        label: spec.trim(),
        count: 0,
      }));

      let totalDocsInSpec = 0;
      for (let spec of specialityCount) {
        if (!!spec.specialty_type) {
          specialities = specialities.map((speciality) => {
            if (speciality.value === spec.specialty_type) {
              speciality.count = spec.num;
              // console.log("spec", spec);
            }
            return speciality;
          });
          totalDocsInSpec += spec.num;
        }
        // console.log("adter spec", spec, spec.specialty_type);
      }
      setSpecialitylist(
        [...specialities].sort(function (a, b) {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
    });
    return () => {};
  }, []);

  const manipulateState = (states) => {
    return {
      updateSpecCount: states.includes("SELECTED") || !!states[3],
      clearResults: !states.includes("SELECTED") && !!!states[3],
      setInitialCount: !states.includes("SELECTED") && !!!states[3],
    };
  };

  useEffect(() => {
    const actions = manipulateState([
      country === "NULL" ? "NULL" : "SELECTED",
      city === "NULL" ? "NULL" : "SELECTED",
      speciality === "NULL" ? "NULL" : "SELECTED",
      value,
    ]);

    console.log(country, city, value, speciality, actions);

    if (actions.clearResults) {
      setSuggestions([]);
      loadInitSpecialityData();
    } else {
      fetchWithCityAndSpeciality({
        value: value,
        city: city,
        speciality: speciality,
        country:
          country !== "NULL"
            ? countryList.find((el) => el.value === country).value
            : country,
        shouldUpdateCount: actions.updateSpecCount,
        loadInitialSpecialityData: actions.setInitialCount,
      });
    }

    return () => {};
  }, [country, city, value, speciality]);

  const inputRef = useRef(null);

  const fetchWithCityAndSpeciality = async ({
    value: Value,
    city: icity,
    speciality: ispeciality,
    country: icountry,
    shouldUpdateCount,
    loadInitialSpecialityData = false,
  }) => {
    console.log("axios search cancel token", typeof axiosSearchCancelToken);
    if (typeof axiosSearchCancelToken != typeof undefined) {
      axiosSearchCancelToken.cancel("Operation canceled due to new request.");
      console.log("cancelling fetch with");
    }
    if (typeof axiosSearchCanToken != typeof undefined) {
      console.log("cancelling getsuggestions");
      axiosSearchCanToken.cancel("Operation canceled due to new request.");
    }
    axiosSearchCancelToken = axios.CancelToken.source();
    axios
      .post(
        Constants.searchUserDetailsWithFilter,
        {
          searchTerm: Value,
          city: icity,
          speciality: ispeciality,
          country: icountry,
        },
        { cancelToken: axiosSearchCancelToken.token }
      )
      .then((response) => {
        loadInitialSpecialityData && loadInitSpecialityData();
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          if (shouldUpdateCount) {
            let specialitiesFromRes = result
              .map((res) =>
                !!res.specialty_type ? res.specialty_type.trim() : ""
              )
              .filter((res) => !!res);
            // console.log("specialitiesFromRes", specialitiesFromRes);
            let uspecs = Array.from(new Set(specialitiesFromRes)).map(
              (spec) => ({
                specialty_type: spec.trim(),
                num: 0,
              })
            );
            uspecs = uspecs.map((element) => {
              let count = 0;
              specialitiesFromRes.forEach((specialityfromres) => {
                if (specialityfromres === element.specialty_type) count++;
              });
              element.num = count;
              return element;
            });
            console.log("uspecs", uspecs);
            let specialityCount = uspecs;
            // console.log("sp count", specialityCount);
            let specialities = Constants.specialityList.map((spec) => ({
              value: spec,
              label: spec,
              count: 0,
            }));
            let totalDocsInSpec = 0;
            for (let spec of specialityCount) {
              if (!!spec.specialty_type) {
                specialities = specialities.map((speciality) => {
                  if (speciality.value === spec.specialty_type) {
                    speciality.count = spec.num;
                    // console.log("spec", spec);
                  }
                  return speciality;
                });
                totalDocsInSpec += spec.num;
              }
              // console.log("adter spec", spec, spec.specialty_type);
            }
            setSpecialitylist(
              [...specialities].sort(function (a, b) {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              })
            );
          }

          console.log("current value", value);

          setSuggestions(result);
          // }
          return;
        } else {
          const otherArr = [];
          otherArr.push({
            id: -321,
            full_name: "No result found",
          });
          setSuggestions(otherArr);
          shouldUpdateCount &&
            setSpecialitylist((old) =>
              old
                .map((spec) => {
                  spec.count = 0;
                  return spec;
                })
                .sort(function (a, b) {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                })
            );
        }
      });
    // }
  };

  const getSuggestionValue = (suggestion) => {
    // console.log("suggestion>>>>>>>", suggestion);
    if (suggestion.id === -321) {
      return suggestion.name || "";
    } else {
      if (props.sendToPath) {
        // setTimeout(()=>{
        props.history.push("/" + props.sendToPath + "/" + suggestion.id);
        props.handleClose && props.handleClose();
        // }, 5000);
      } else {
        props.history.push("/beyondSurgeryUserPost/" + suggestion.id);
      }
      // <NavLink to={"beyondSurgeryUserPost/" + elm.signup_id}>Dr. {elm.full_name}</NavLink>
    }
  };

  const renderSuggestion = (suggestion) => (
    <div
      className="top-insta h-100"
      style={{ borderBottom: "1px solid #eaeaea", cursor: "pointer" }}
      onClick={() => getSuggestionValue(suggestion)}
    >
      {suggestion.profile_pic ? (
        <span href="#" target="_blank">
          <img
            src={Constants.server + suggestion.profile_pic}
            style={{ width: 40, height: 40 }}
          />
        </span>
      ) : null}
      <div>
        <div className="user" style={{ fontSize: ".9rem" }}>
          {suggestion.full_name}
          {/* <NavLink to={"beyondSurgeryUserPost/" + suggestion.id}>Dr. {suggestion.name}</NavLink> */}
        </div>
        <div style={{ fontSize: ".8rem", marginLeft: "10px" }}>
          {[suggestion.current_location, suggestion.specialty_type]
            .filter((val) => !!val)
            .join(", ")}
        </div>
      </div>
    </div>
    // <div>
    //     {suggestion.name}
    // </div>
  );

  //country change count

  const handleCountryChange = (data) => {
    console.log(data);
    if (data) {
      console.log(data.value);
      setCountry(data.value);
    } else {
      setCountry("NULL");
    }
    setCity("NULL");
  };

  // update count
  const handleCityChange = (data) => {
    if (data) {
      setCity(data.value);
    } else {
      setCity("NULL");
    }
  };

  const handleSpecialityChange = (data) => {
    if (data) {
      setSpeciality(data.value);
    } else {
      setSpeciality("NULL");
    }
  };

  return (
    <div className="">
      <div className="row ">
        <div className="col-12 col-md-4 mb-3">
          {" "}
          <Select
            icon="fas fa-globe-asia"
            components={{ Control }}
            options={countryList}
            onChange={handleCountryChange}
            styles={customStyles}
            defaultValue={null}
            placeholder={"Select Country"}
            isClearable={true}
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          {" "}
          <Select
            icon="fa-map-marker-alt"
            components={{ Control }}
            options={
              country === "NULL"
                ? []
                : citylist.filter((city) => city.countryId === country)
            }
            onChange={handleCityChange}
            styles={customStyles}
            value={city !== "NULL" ? {value : city, label : city} : null}
            defaultValue={null}
            placeholder={"Select City"}
            isClearable={true}
          />
        </div>
        <div className="col-12 col-md-4 mb-3">
          {" "}
          <Select
            icon="fas fa-stethoscope"
            components={{ Control, Option: SpecialityOption }}
            options={specialitylist}
            onChange={handleSpecialityChange}
            styles={customStyles}
            placeholder={"Select Speciality"}
            isClearable={true}
          />
        </div>
      </div>

      <div className="position-relative">
        <input
          type="text"
          class="form-control position-relative"
          id="inputPassword"
          placeholder="Enter the name of surgeon"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          ref={inputRef}
        ></input>
        {suggestions.length > 0 && suggestions[0].id !== -321 && (
          <div className="mt-3">
            <strong
              className="ml-2"
              style={{ color: "#096d9c", fontSize: "1rem", marginTop: "2rem" }}
            >
              {suggestions.length}{" "}
              {suggestions.length > 1 ? "results" : "result"}
            </strong>
          </div>
        )}
        <div
          style={{
            maxHeight: "50vh",
            overflowY: "auto",
            paddingTop: "10px",
          }}
        >
          {suggestions.map(renderSuggestion)}
        </div>
        <i
          className="fa fa-search"
          aria-hidden="true"
          style={{
            position: "absolute",
            right: "22px",
            top: "16px",
          }}
        ></i>
      </div>
    </div>
  );
};

export default withRouter(UserSearchBar);
