import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import FounderImg from "../../assets/FounderImg.png";
import Quotes1 from "../../assets/Quotes1.svg";
import Quotes2 from "../../assets/Quotes2.svg";
import Founderbg from "../../assets/FounderBg.png";
import Founder1 from "../../assets/img/founders/Parul Shukla _MD (1).png";
import Founder2 from "../../assets/img/founders/Harsh Kanhere.png";
import Founder3 from "../../assets/img/founders/Shailesh Shrikhande 2.png";
import Founder4 from "../../assets/img/founders/Dhananjay Kulkarni.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Founder = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      // partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      // partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
  };

  const foundersData = [
    {
      name: "Dr. Parul Shukla, Founder",
      img: Founder1,
      message:
        "In the world of surgery, connections lead to breakthroughs. Together, we can push boundaries and redefine healthcare.",
      designation:
        "Regional Chief of Colon & Rectal Surgery at Northwell Health. Surgery Professor. Founder of Surgeons for Surgeons.",
    },
    {
      name: "Dr. Harsh Kanhere",
      img: Founder2,
      message:
        "The best way to stay ahead in surgery is through collaboration.",
      designation:
        "Head of upper GI surgery at the royal adelaide hospital and senior consultant surgeon HPB surgery at the queen Elizabeth hospital",
    },
    {
      name: "Prof. Shailesh V. Shrikhande",
      img: Founder3,
      message: "Amplify your impact and connect with leaders in your field.",
      designation:
        "Deputy director of tata memorial hospital head of cancer surgery. Chief of GI & HPB Surgical Service.",
    },
    {
      name: "Dr. Dhananjay Kulkarni",
      img: Founder4,
      message:
        "SFS makes it easy to share our skills and knowlegde, making surgery better for everyone.",
      designation:
        "Consultant Oncoplastic Breast Surgeon, Breast Unit, Western General Hospital Edinburgh",
    },
  ];

  return (
    <Box my={{ xs: 8,sm:6, lg: 14 }} mb={{ xs: 0, lg: 10 }}>
      <Carousel
        // swipeable={true}
        // draggable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={4000}
        keyBoardControl={true}
        // customTransition="all 1"
        // transitionDuration={100}
        containerClass="founder-container"
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        //   deviceType={this.props.deviceType}
      >
        {foundersData.map((dt) => (
          <Box
            minHeight={{ xs: "85vh",sm:'65vh', md: "340px" }}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={{xs:'center',sm:'end',md:"center"}}
            position={"relative"}
          >
            <Box
              px={{ xs: 1, sm: 3, md: 4 }}
              display={"flex"}
              flexDirection={"column"}
              height={{ xs: "380px", sm: "300px" }}
              width={"100%"}
              bgcolor={"#13375B"}
            >
              <Grid container display={"flex"} height={"100%"} className="">
                <Grid
                  item
                  md={5}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    position={"absolute"}
                    top={{ md: "50%" }}
                    left={{ xs: "50%", md: "10%" }}
                    height={{ xs: 200, sm: 200, md: 350 }}
                    sx={{
                      transform: {
                        xs: "translate(-50%,-80%)",
                        md: "translateY(-50%)",
                      },
                      backgroundImage: `url(${Founderbg})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      padding: { xs: 2, md: 3 },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      height={{ xs: 175, md: 295 }}
                      sx={{ borderRadius: "50%", overflow: "hidden" }}
                      width={{ xs: 175, md: 295 }}
                    >
                      <img src={dt.img} alt="" height={"100%"} width={"100%"} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={7} width={"100%"}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={{ xs: "end", sm: "center" }}
                    height={"100%"}
                    pb={{ xs: 2, sm: 0 }}
                  >
                    <Box
                      width={{ xs: "100%", sm: "85%", md: "75%" }}
                      position={"relative"}
                    >
                      <Box position={"relative"}>
                        <Box
                          height={{ xs: 20, md: 30 }}
                          display={{ xs: "none", sm: "block" }}
                          position={"absolute"}
                          left={{ xs: "-15%", sm: "-12%", md: "-10%" }}
                        >
                          <img src={Quotes1} alt="" height={"100%"} />
                        </Box>
                        <Typography
                          variant="p"
                          mt={2}
                          color={"#ffffff"}
                          fontSize={{
                            xs: "1.15rem",
                            sm: "1.25rem",
                            md: "1.6rem",
                          }}
                          fontStyle={"italic"}
                          fontWeight={500}
                        >
                          {dt.message}
                        </Typography>
                        <Box
                          height={{ xs: 20, md: 30 }}
                          position={"absolute"}
                          right={"-10%"}
                          bottom={"-5%"}
                          display={{ xs: "none", sm: "block" }}
                        >
                          <img src={Quotes2} alt="" height={"100%"} />
                        </Box>
                      </Box>
                      <Typography
                        mt={1}
                        color={"#ffffff"}
                        fontSize={{ xs: ".8rem", sm: ".9rem", md: "1rem" }}
                      >
                        {dt.name}
                      </Typography>
                      <Typography
                        mt={1}
                        color={"#ffffff"}
                        fontSize={{ xs: ".8rem", sm: ".9rem", md: "1rem" }}
                      >
                        {dt.designation}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default Founder;
