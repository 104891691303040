import React, { useState } from "react";
// import { NavLink } from 'react-router-dom';
import Autosuggest from "react-autosuggest";
import { useForm } from "react-hook-form";
import Constants from "../../configs/Constants";
import axios from "axios";
// import YearPicker from "react-year-picker";
import EventEmitters from "../../configs/EventEmitters";
import Config from "../../configs/Config";
// import Loader from 'react-loader-spinner';
import FooterContent from "../FooterContent";
import Constant from "../../configs/Constants";
import termsOfUse from "../../assets/docs/Terms of Use.pdf";
import companyPolicy from "../../assets/docs/Company Policy.pdf";
import { InputGroup } from "react-bootstrap";
import Select from "react-select";

const Qualification = (props) => {
  const loadYears = () => {
    const tempYears = [];
    for (var i = new Date().getFullYear(); i >= 1960; i--) {
      console.log(i);
      tempYears.push(i);
    }
    setYearList(tempYears);
  };

  React.useEffect(() => {
    const id = Config.getSessionInfo("id");
    if (!id) {
      props.history.push("/");
    }
    loadYears();
    loadAllCountries();
  }, []);

  const [superSpecilityShowHide, setSuperSpecilityShowHide] = useState(false);
  const [generalSurgeryShowHide, setGeneralSurgeryShowHide] = useState(false);
  const [otherGroupShowHide, setOtherGroupShowHide] = useState(false);
  const [otherGroup, setOtherGroup] = useState();

  const [mbbsDisabledSetting, setMbbsDisabledSetting] = React.useState({
    city: true,
    medicalSchool: true,
    year: true,
  });

  const [surgicalDisabledSetting, setSurgicalDisabledSetting] = React.useState({
    city: true,
    center: true,
  });

  const [superSpecilityCountrySetting, setSuperSpecilityCountrySetting] =
    React.useState({
      city: true,
      center: true,
    });

  const [mbbsValues, setMbbsValues] = React.useState({
    mbbsCountry: "",
    mbbsCity: "",
    mbbsMedicalSchool: "",
    mbbsYear: "",
    generalSurgeryCountry: "",
    superSpecilityCountry: "",
  });

  const [yearList, setYearList] = React.useState([]);
  console.log("other groups>>>>", otherGroup);
  // errors, reset
  const { register, handleSubmit, errors, getValues } = useForm(); // initialise the hook
  const [suggestions, setSuggestions] = React.useState([]);
  const [speciality, setSpeciality] = React.useState();
  const [value, setValue] = React.useState("");
  const [countries, setCountries] = React.useState(false);
  // const [valueCityId, setValueCityId] = React.useState('');
  const [checkbox, setCheckbox] = React.useState(false);
  const [checkboxTwo, setCheckboxTwo] = React.useState(false);
  const options_group = Constant.specialityList
    .map((res) => {
      return { value: res, label: res };
    })
    .filter((res) => {
      return res.value != speciality;
    });
  const [medicalSchoolList, setMedicalSchoolList] = React.useState([
    {
      id: "Other",
      name: "Other",
      city_id: "Other",
      // country_id: 'Other',
      country_name: "Other",
      city_name: "Other",
    },
  ]);

  const [medicalSchoolListSurgical, setMedicalSchoolListSurgical] =
    React.useState([
      {
        id: "Other",
        name: "Other",
        city_id: "Other",
        // country_id: 'Other',
        country_name: "Other",
        city_name: "Other",
      },
    ]);
  const [suggestionsSurgical, setSuggestionsSurgical] = React.useState([]);
  // const [valueMbbs, setValueMbbs] = React.useState('');
  const [valueSurgical, setValueSurgical] = React.useState("");

  const [
    medicalSchoolListSuperSpeciality,
    setMedicalSchoolListSuperSpeciality,
  ] = React.useState([
    {
      id: "Other",
      name: "Other",
      city_id: "Other",
      // country_id: 'Other',
      country_name: "Other",
      city_name: "Other",
    },
  ]);
  const [suggestionsSuperSpeciality, setSuggestionsSuperSpeciality] =
    React.useState([]);
  const [valueSuperSpeciality, setValueSuperSpeciality] = React.useState("");

  const [finalIds, setFinalIds] = React.useState([]);

  // Other city flag
  const [otherMbbsCityFlag, setOtherMbbsCityFlag] = React.useState(false);
  const [otherSurgicalCityFlag, setOtherSurgicalCityFlag] =
    React.useState(false);
  const [otherSuperSpecialityCityFlag, setOtherSuperSpecialityCityFlag] =
    React.useState(false);
  // Other CenterFlag
  const [medicalSchoolListOtherFlag, setMedicalSchoolListOtherFlag] =
    React.useState(false);
  const [
    medicalSchoolListSurgicalOtherFlag,
    setMedicalSchoolListSurgicalOtherFlag,
  ] = React.useState(false);
  const [
    medicalSchoolListSuperSpecialityOtherFlag,
    setMedicalSchoolListSuperSpecialityOtherFlag,
  ] = React.useState(false);

  // for cities list on change countryId
  const [mbbsCitiesList, setMbbsCitiesList] = React.useState([]);
  const [gsCitiesList, setGsCitiesList] = React.useState([]);
  const [superSpecialityCitiesList, setSuperSpecialityCitiesList] =
    React.useState([]);

  // const [addMoreList, setAddMoreList] = React.useState({
  //     countrySST: [],
  //     city: [],
  //     mediRegistration: []
  // });

  const getCitiesListByCountryId = (from, value) => {
    axios
      .post(Constants.getCityDetailsByCountryId, {
        countryId: value,
      })
      .then((response) => {
        console.log("response getCitiesListByCountryId>>>>>>", response);
        const { result } = response.data;
        result.push({
          id: "Other",
          region_id: "Other",
          // country_id: 'Other',
          latitude: "Other",
          longitude: "Other",
          name: "Other",
        });
        if (from === "mbbsCountry") {
          setMbbsCitiesList([...result]);
        } else if (from === "generalSurgeryCountry") {
          setGsCitiesList([...result]);
        } else if (from === "superSpecilityCountry") {
          setSuperSpecialityCitiesList([...result]);
        }
      });
  };

  const onChange = (name, e) => {
    // console.log(e.target.value, "<<<<<<<<<<<<<<<<e");
    const { value } = e.target;
    if (name) {
      setMbbsValues({
        ...mbbsValues,
        [name]: value,
      });
    }
    if ("mbbsCountry" === name) {
      setMbbsDisabledSetting({
        ...mbbsDisabledSetting,
        city: false,
      });
      getCitiesListByCountryId(name, value);
    } else if ("mbbsMedicalSchool" === name) {
      if (value === "Other#Other") {
        setMedicalSchoolListOtherFlag(true);
        // document.getElementsByName('mbbsMedicalSchool')[0].value = "Other#Other";
      } else {
        setMedicalSchoolListOtherFlag(false);
        // document.getElementsByName('mbbsMedicalSchool')[0].value = "-1";
      }
      setMbbsDisabledSetting({
        ...mbbsDisabledSetting,
        year: false,
      });
    } else if ("generalSurgeryCenter" === name) {
      if (value === "Other#Other") {
        setMedicalSchoolListSurgicalOtherFlag(true);
      } else {
        setMedicalSchoolListSurgicalOtherFlag(false);
      }
      setMbbsDisabledSetting({
        ...mbbsDisabledSetting,
        year: false,
      });
    } else if ("superSpecialityCenter" === name) {
      if (value === "Other#Other") {
        setMedicalSchoolListSuperSpecialityOtherFlag(true);
      } else {
        setMedicalSchoolListSuperSpecialityOtherFlag(false);
      }
      setMbbsDisabledSetting({
        ...mbbsDisabledSetting,
        year: false,
      });
    } else if ("generalSurgeryCountry" === name) {
      setSurgicalDisabledSetting({
        ...surgicalDisabledSetting,
        city: false,
      });
      getCitiesListByCountryId(name, value);
    } else if ("superSpecilityCountry" === name) {
      setSuperSpecilityCountrySetting({
        ...superSpecilityCountrySetting,
        city: false,
      });
      getCitiesListByCountryId(name, value);
    }
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    return axios.post(Constants.searchCityDetails, {
      searchKey: value,
      countryId: mbbsValues.mbbsCountry,
    });
  };

  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
  // Other Step 1
  const getSuggestionValue = (event) => {
    // console.log("suggestion>>>>>>>", suggestion);
    const suggestionArr = event.target.value.split("##");
    const suggestion = {
      name: suggestionArr[0],
      id: suggestionArr[1],
    };
    if (suggestion && suggestion.name === "Other") {
      setOtherMbbsCityFlag(true);
      document.getElementsByName("mbbsMedicalSchool")[0].value = "Other#Other";
      // return;
    } else {
      setOtherMbbsCityFlag(false);
      document.getElementsByName("mbbsMedicalSchool")[0].value = "-1";
    }
    // if ("mbbsCity" === name) {
    setMbbsDisabledSetting({
      ...mbbsDisabledSetting,
      medicalSchool: false,
    });
    // }
    axios
      .post(Constants.searchMedicalSchools, {
        // countryId: suggestion.country_id,
        cityId: suggestion.id,
      })
      .then((response) => {
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          result.push({
            id: "Other",
            name: "Other",
            city_id: "Other",
            city_name: "Other",
            // country_id: 'Other',
            // country_name: "Other"
          });
          setMedicalSchoolList(result);
          setMedicalSchoolListOtherFlag(false);
        } else {
          setMedicalSchoolListOtherFlag(true);
          setMedicalSchoolList([
            {
              id: "Other",
              name: "Other",
              city_id: "Other",
              city_name: "Other",
              // country_id: 'Other',
              // country_name: "Other"
            },
          ]);
          document.getElementsByName("mbbsMedicalSchool")[0].value =
            "Other#Other";
        }
      });
    // setValueCityId(suggestion.id);
    setFinalIds({
      ...finalIds,
      mbbs: {
        ...suggestion,
      },
    });
    return suggestion.name;
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    if (value && value.length > 2) {
      getSuggestions(value).then((response) => {
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          result.push({
            id: "Other",
            region_id: "Other",
            // country_id: 'Other',
            latitude: "Other",
            longitude: "Other",
            name: "Other",
          });
          setSuggestions(result);
        } else {
          const otherArr = [];
          otherArr.push({
            id: "Other",
            region_id: "Other",
            // country_id: 'Other',
            latitude: "Other",
            longitude: "Other",
            name: "Other",
          });
          setSuggestions(otherArr);
        }
      });
    }
  };

  const onChangeAutocomplete = (event) => {
    console.log(event.target.value, "<<<<<<<<<<<<<<<<<<<<<event.target.value");
    setValue(event.target.value);
  };

  const inputProps = {
    tabIndex: "2",
    placeholder: "Type min 3 characters to search",
    value,
    onChange: onChangeAutocomplete,
    className: "form-control",
    disabled: mbbsDisabledSetting.city,
    ref: register({ required: true }),
    name: "mbbsCity",
    autoComplete: "off",
  };

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestionsSurgical = (value) => {
    return axios.post(Constants.searchCityDetails, {
      searchKey: value,
      countryId: mbbsValues.generalSurgeryCountry,
    });
  };

  const renderSuggestionSurgical = (suggestion) => <div>{suggestion.name}</div>;

  const getSuggestionValueSurgical = (event) => {
    // console.log("suggestion>>>>>>>", suggestion);
    const suggestionArr = event.target.value.split("##");
    const suggestion = {
      name: suggestionArr[0],
      id: suggestionArr[1],
    };
    if (suggestion && suggestion.name === "Other") {
      setOtherSurgicalCityFlag(true);
      document.getElementsByName("generalSurgeryCenter")[0].value =
        "Other#Other";
    } else {
      setOtherSurgicalCityFlag(false);
      document.getElementsByName("generalSurgeryCenter")[0].value = "-1";
    }

    setSurgicalDisabledSetting({
      ...surgicalDisabledSetting,
      center: false,
    });

    axios
      .post(Constants.searchMedicalSchools, {
        countryId: suggestion.country_id,
        cityId: suggestion.id,
      })
      .then((response) => {
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          result.push({
            id: "Other",
            name: "Other",
            city_id: "Other",
            // country_id: 'Other',
            country_name: "Other",
            city_name: "Other",
          });

          setMedicalSchoolListSurgical(result);
          setMedicalSchoolListSurgicalOtherFlag(false);
        } else {
          setMedicalSchoolListSurgicalOtherFlag(true);
          setMedicalSchoolListSurgical([
            {
              id: "Other",
              name: "Other",
              city_id: "Other",
              // country_id: 'Other',
              // country_name: "Other",
              city_name: "Other",
            },
          ]);
          document.getElementsByName("generalSurgeryCenter")[0].value =
            "Other#Other";
        }
      });

    setValueSurgical(suggestion.id);
    setFinalIds({
      ...finalIds,
      surgical: {
        ...suggestion,
      },
    });
    return suggestion.name;
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequestedSurgical = () => {
    setSuggestionsSurgical([]);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequestedSurgical = ({ value }) => {
    if (value && value.length > 2) {
      getSuggestionsSurgical(value).then((response) => {
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          result.push({
            id: "Other",
            region_id: "Other",
            // country_id: 'Other',
            latitude: "Other",
            longitude: "Other",
            name: "Other",
          });
          setSuggestionsSurgical(result);
        } else {
          const otherArr = [];
          otherArr.push({
            id: "Other",
            region_id: "Other",
            // country_id: 'Other',
            latitude: "Other",
            longitude: "Other",
            name: "Other",
          });
          setSuggestionsSurgical(otherArr);
        }
      });
    }
  };

  const onChangeAutocompleteSurgical = (event, val) => {
    console.log(val, "<<<<<<<<<<<<<<<<<<<<<val");
    setValueSurgical(val.newValue);
  };

  const inputPropsSurgical = {
    tabIndex: 6,
    placeholder: "Type min 3 characters to search",
    value: valueSurgical,
    onChange: onChangeAutocompleteSurgical,
    className: "form-control",
    disabled: surgicalDisabledSetting.city,
    ref: register({ required: true }),
    name: "surgicalCity",
    autoComplete: "off",
  };

  // const handleYearChange = (date) => {
  //     console.log(date);
  //     setMbbsValues({
  //         ...mbbsValues,
  //         mbbsYear: date
  //     });
  // }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestionsSuperSpeciality = (value) => {
    return axios.post(Constants.searchCityDetails, {
      searchKey: value,
      countryId: mbbsValues.superSpecilityCountry,
    });
  };

  const renderSuggestionSuperSpeciality = (suggestion) => (
    <div>{suggestion.name}</div>
  );

  const getSuggestionValueSuperSpeciality = (event) => {
    // console.log("suggestion>>>>>>>", suggestion);
    const suggestionArr = event.target.value.split("##");
    const suggestion = {
      name: suggestionArr[0],
      id: suggestionArr[1],
    };
    if (suggestion && suggestion.name === "Other") {
      setOtherSuperSpecialityCityFlag(true);
      document.getElementsByName("superSpecialityCenter")[0].value =
        "Other#Other";
    } else {
      setOtherSuperSpecialityCityFlag(false);
      document.getElementsByName("superSpecialityCenter")[0].value = "-1";
    }

    setSuperSpecilityCountrySetting({
      ...superSpecilityCountrySetting,
      center: false,
    });

    axios
      .post(Constants.searchMedicalSchools, {
        countryId: suggestion.country_id,
        cityId: suggestion.id,
      })
      .then((response) => {
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          result.push({
            id: "Other",
            name: "Other",
            city_id: "Other",
            // country_id: 'Other',
            country_name: "Other",
            city_name: "Other",
          });
          setMedicalSchoolListSuperSpeciality(result);
          setMedicalSchoolListSuperSpecialityOtherFlag(false);
        } else {
          setMedicalSchoolListSuperSpecialityOtherFlag(true);
          setMedicalSchoolListSuperSpeciality([
            {
              id: "Other",
              name: "Other",
              city_id: "Other",
              // country_id: 'Other',
              // country_name: "Other",
              city_name: "Other",
            },
          ]);
          document.getElementsByName("superSpecialityCenter")[0].value =
            "Other#Other";
        }
      });

    setValueSuperSpeciality(suggestion.id);
    setFinalIds({
      ...finalIds,
      superSpecility: {
        ...suggestion,
      },
    });
    return suggestion.name;
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequestedSuperSpeciality = () => {
    setSuggestionsSuperSpeciality([]);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequestedSuperSpeciality = ({ value }) => {
    if (value && value.length > 2) {
      getSuggestionsSuperSpeciality(value).then((response) => {
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          result.push({
            id: "Other",
            region_id: "Other",
            // country_id: 'Other',
            latitude: "Other",
            longitude: "Other",
            name: "Other",
          });
          setSuggestionsSuperSpeciality(result);
        } else {
          const otherArr = [];
          otherArr.push({
            id: "Other",
            region_id: "Other",
            // country_id: 'Other',
            latitude: "Other",
            longitude: "Other",
            name: "Other",
          });
          setSuggestionsSuperSpeciality(otherArr);
        }
      });
    }
  };

  const onChangeAutocompleteSuperSpeciality = (event, val) => {
    console.log(val, "<<<<<<<<<<<<<<<<<<<<<val");
    setValueSuperSpeciality(val.newValue);
  };

  const inputPropsSuperSpeciality = {
    tabIndex: "11",
    placeholder: "Type min 3 characters to search",
    value: valueSuperSpeciality,
    onChange: onChangeAutocompleteSuperSpeciality,
    className: "form-control",
    disabled: superSpecilityCountrySetting.city,
    ref: register({ required: true }),
    name: "superSpecialityCity",
    autoComplete: "off",
  };

  const onSubmit = (data) => {
    // data.mbbsYear = mbbsValues.mbbsYear;
    data.finalIds = finalIds;
    data.curruentTab = 4;
    data.id = Config.getSessionInfo("id");

    if (otherMbbsCityFlag) {
      data.finalIds.mbbs = {
        id: data.otherMbbsCityName,
        region_id: data.otherMbbsCityName,
        country_id: data.otherMbbsCityName,
        latitude: data.otherMbbsCityName,
        longitude: data.otherMbbsCityName,
        name: data.otherMbbsCityName,
      };
    }

    if (otherSurgicalCityFlag) {
      // data.mbbsMedicalSchool = data.mbbsMedicalSchoolOther;

      data.finalIds.surgical = {
        id: data.otherSurgicalCityName,
        region_id: data.otherSurgicalCityName,
        country_id: data.otherSurgicalCityName,
        latitude: data.otherSurgicalCityName,
        longitude: data.otherSurgicalCityName,
        name: data.otherSurgicalCityName,
      };
    }

    if (otherSuperSpecialityCityFlag) {
      data.finalIds.superSpecility = {
        id: data.otherSuperSpecialityCityName,
        region_id: data.otherSuperSpecialityCityName,
        country_id: data.otherSuperSpecialityCityName,
        latitude: data.otherSuperSpecialityCityName,
        longitude: data.otherSuperSpecialityCityName,
        name: data.otherSuperSpecialityCityName,
      };
    }

    if (medicalSchoolListOtherFlag) {
      data.mbbsMedicalSchool =
        data.mbbsMedicalSchoolOther + "#" + data.mbbsMedicalSchoolOther;
    }

    if (medicalSchoolListSurgicalOtherFlag) {
      data.generalSurgeryCenter =
        data.generalSurgeryCenterOther + "#" + data.generalSurgeryCenterOther;
    }

    if (medicalSchoolListSuperSpecialityOtherFlag) {
      data.superSpecialityCenter =
        data.superSpecialityCenterOther + "#" + data.superSpecialityCenterOther;
    }
    if (otherGroup) {
      data.otherGroup = otherGroup.map((res) => {
        return res.value+"#";
      });
    }

    console.log("data.otherGroup,>>>",data.otherGroup)

    if (!checkbox) {
      EventEmitters.showTost({
        message: "Please check Terms & Conditions and Privacy Policy.",
        type: "error",
      });
      return;
    }

    if (!checkboxTwo) {
      EventEmitters.showTost({
        message: "Please check the declaration.",
        type: "error",
      });
      return;
    }
    //   console.log("other groups>>>>",data.otherGroup)
    axios
      .post(Constants.qualification, data)
      .then((response) => {
        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 404) {
          tost.message = response.data.msg;
          tost.type = "error";
        } else if (response.data.code === 202) {
          tost.message = response.data.msg;
          tost.type = "success";
          props.history.push("/signup");
        } else if (response.data.code === 200) {
          var existingData = JSON.parse(
            window.sessionStorage.getItem("session")
          );
          var newData = {
            ...existingData,
            specialty_type: data.superSpecialitySpeciality,
          };
          sessionStorage.setItem("session", JSON.stringify(newData));

          tost.type = "success";
          tost.message = response.data.msg;
          const loginDetails = JSON.parse(sessionStorage.getItem("session"));
          loginDetails.is_all_details_filled = 1;
          loginDetails.till_where_filled = 1;

          sessionStorage.setItem("session", JSON.stringify(loginDetails));
          props.history.push("/welcome");
          // props.history.push('/membershipAssociation');
        }

        if (medicalSchoolListSuperSpecialityOtherFlag) {
            data.superSpecialityCenter = data.superSpecialityCenterOther + "#" + data.superSpecialityCenterOther;
        }
        if(otherGroup)
        {
            data.otherGroup = otherGroup.map((res)=>{
                return res.value+"#";
            })
        }
      })
      .catch(console.error);
  };
  const onChangeCheckbox = (e) => {
    const { checked } = e.target;
    setCheckbox(checked);
  };

  const onChangeCheckboxTwo = (e) => {
    const { checked } = e.target;
    setCheckboxTwo(checked);
  };
  const onChangeUploadFile = (e) => {
    const file = e.target && e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("cv", file);
      const config = {
        headers: {
          id: Config.getSessionInfo("id"),
        },
      };
      axios
        .post(Constants.uploadCv, formData, config)
        .then((response) => {
          console.log("response>>>>>>>>>>", response);
          // setProfilePic(Constants.server + response.data.fileInfo);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const loadAllCountries = () => {
    axios
      .post(Constants.getAllCountries, {})
      .then((response) => {
        setCountries([...response.data.result]);
      })
      .catch(console.error);
  };

  return (
    <React.Fragment>
      <div className="content">
        <div className="breadcrumb-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-12 col-12">
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <span className="white">Step 2</span>
                    </li>
                  </ol>
                </nav>
                <h2 className="breadcrumb-title">Qualifications </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 col-lg-9 col-xl-9">
              <div className="card">
                <div className="card-body">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <div className="card-body">
                      <h3 className="card-title" style={{ fontSize: "22px" }}>
                        1.Medical School
                      </h3>
                      <div className="education-info">
                        <div className="row form-row education-cont">
                          <div className="col-12 col-md-10 col-lg-11">
                            <div className="row form-row">
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                  <label>Country *</label>
                                  <select
                                    tabIndex="1"
                                    autoFocus
                                    name="mbbsCountry"
                                    ref={register({ required: true })}
                                    className="select form-control"
                                    onChange={onChange.bind(
                                      this,
                                      "mbbsCountry"
                                    )}
                                  >
                                    <option value="" selected="selected">
                                      Select Country*
                                    </option>
                                    {countries &&
                                      countries.length &&
                                      countries.map((opt) => {
                                        return (
                                          <option value={opt.id}>
                                            {opt.name}
                                          </option>
                                        );
                                      })}
                                  </select>

                                  {errors.mbbsCountry && (
                                    <div className="error-text" role="alert">
                                      Please select Country.
                                    </div>
                                  )}

                                  {/* <select name="mbbsCountry"
                                                                        ref={register({ required: true })}
                                                                        className="select form-control"
                                                                        onChange={onChange.bind(this, "mbbsCountry")}>
                                                                        <option selected="selected">Select Country</option>
                                                                        <option value="96">India</option>
                                                                        <option value="230">USA</option>
                                                                        <option value="71">Great Britain (UK)</option>
                                                                        <option value="12">Australia</option>
                                                                    </select> */}
                                </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                <label>City *</label>
                                  <select
                                    disabled={mbbsDisabledSetting.city}
                                    tabIndex="2"
                                    name="mbbsCity"
                                    ref={register({ required: true })}
                                    className="select form-control"
                                    onChange={getSuggestionValue}
                                  >
                                    <option selected="selected" value="">
                                      Select City*
                                    </option>
                                    {mbbsCitiesList &&
                                      mbbsCitiesList.length &&
                                      mbbsCitiesList.map((elm, idx) => {
                                        return (
                                          <option
                                            key={idx}
                                            value={elm.name + "##" + elm.id}
                                          >
                                            {elm.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  {errors.mbbsCity && (
                                    <div className="error-text" role="alert">
                                      Please select city.
                                    </div>
                                  )}
                                  {/* {
                                                                        tabIndex: "2",
                                                                        placeholder: 'Type min 3 characters to search',
                                                                        value,
                                                                        onChange: onChangeAutocomplete,
                                                                        className: "form-control",
                                                                        disabled: mbbsDisabledSetting.city,
                                                                        ref: register({required: true }),
                                                                        name: "mbbsCity",
                                                                        autoComplete: "off"
                                                                    }; */}
                                  {/* <Autosuggest
                                                                        suggestions={suggestions}
                                                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                                        getSuggestionValue={getSuggestionValue}
                                                                        renderSuggestion={renderSuggestion}
                                                                        inputProps={inputProps}
                                                                    /> */}
                                  {otherMbbsCityFlag && (
                                    <input
                                      type="text"
                                      name="otherMbbsCityName"
                                      className="form-control"
                                      ref={register({ required: true })}
                                      placeholder="Please enter your city name"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                <label>Medical School *</label>
                                  <select
                                    name="mbbsMedicalSchool"
                                    tabIndex="3"
                                    className="select form-control"
                                    disabled={mbbsDisabledSetting.medicalSchool}
                                    onChange={onChange.bind(
                                      this,
                                      "mbbsMedicalSchool"
                                    )}
                                    ref={register({
                                      required: true,
                                      pattern: /^(?!.*(-1)).*$/,
                                    })}
                                  >
                                    <option value={-1} selected="selected">
                                      Select Medical School*
                                    </option>
                                    {medicalSchoolList &&
                                      medicalSchoolList.length &&
                                      medicalSchoolList.map((elm, idx) => {
                                        return (
                                          <option
                                            value={elm.id + "#" + elm.name}
                                          >
                                            {elm.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                  {medicalSchoolListOtherFlag && (
                                    <input
                                      tabIndex="3"
                                      type="text"
                                      name="mbbsMedicalSchoolOther"
                                      className="form-control"
                                      ref={register({ required: true })}
                                      placeholder="Please enter the college name as per the registered name."
                                    />
                                  )}

                                  {errors.mbbsMedicalSchool && (
                                    <div className="error-text" role="alert">
                                      Please select Medical School.
                                    </div>
                                  )}
                                </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                  <label>Completion year *</label>
                                  <select
                                    tabIndex="4"
                                    name="mbbsYear"
                                    className="select form-control"
                                    disabled={mbbsDisabledSetting.mbbsYear}
                                    onChange={onChange.bind(this, "mbbsYear")}
                                    ref={register({ required: true })}
                                  >
                                    <option value={""} selected="selected">
                                      Select Year*
                                    </option>
                                    {yearList &&
                                      yearList.length &&
                                      yearList.map((elm, idx) => {
                                        return (
                                          <option value={elm}>{elm}</option>
                                        );
                                      })}
                                  </select>
                                  {errors.mbbsYear && (
                                    <div className="error-text" role="alert">
                                      Please select Completion year.
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <h3
                        className="card-title"
                        style={{ display: "inline-block", fontSize: "22px" }}
                      >
                        2.Surgical Training
                      </h3>
                      {
                        <React.Fragment>
                          <span
                            style={{
                              display: "inline-block",
                              marginLeft: "12px",
                              marginRight: "12px",
                            }}
                          >
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  onClick={() => {
                                    setGeneralSurgeryShowHide(
                                      !generalSurgeryShowHide
                                    );
                                    // if (superSpecilityShowHide) {
                                    //     setSuperSpecilityShowHide(false)
                                    // } else {
                                    //     setSuperSpecilityShowHide(false)
                                    // }
                                  }}
                                  aria-label="Checkbox for following text input"
                                />
                              </InputGroup.Prepend>
                            </InputGroup>
                          </span>
                          <small>
                            (Please select if you want to enter Surgical
                            training details)
                          </small>
                        </React.Fragment>
                      }
                      <div className="education-info">
                        {generalSurgeryShowHide ? (
                          <div className="row form-row education-cont">
                            <div className="col-12 col-md-10 col-lg-11">
                              <div className="row form-row">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                    <label>Country</label>
                                    <select
                                      tabIndex="5"
                                      name="generalSurgeryCountry"
                                      ref={register({ required: true })}
                                      className="select form-control"
                                      onChange={onChange.bind(
                                        this,
                                        "generalSurgeryCountry"
                                      )}
                                    >
                                      <option value="" selected="selected">
                                        Select Country
                                      </option>
                                      {countries &&
                                        countries.length &&
                                        countries.map((opt) => {
                                          return (
                                            <option value={opt.id}>
                                              {opt.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {errors.generalSurgeryCountry && (
                                      <div className="error-text" role="alert">
                                        Please select Country.
                                      </div>
                                    )}
                                    {/* <select name="generalSurgeryCountry"
                                                                        ref={register({ required: true })}
                                                                        className="select form-control"
                                                                        onChange={onChange.bind(this, "generalSurgeryCountry")}>
                                                                        <option selected="selected">Select Country</option>
                                                                        <option value="96">India</option>
                                                                        <option value="230">USA</option>
                                                                        <option value="71">Great Britain (UK)</option>
                                                                        <option value="12">Australia</option>
                                                                    </select> */}
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                    <label>City</label>
                                    <select
                                      disabled={surgicalDisabledSetting.city}
                                      tabIndex="6"
                                      name="surgicalCity"
                                      ref={register({ required: true })}
                                      className="form-control"
                                      onChange={getSuggestionValueSurgical}
                                    >
                                      <option selected="selected" value="">
                                        Select City
                                      </option>
                                      {gsCitiesList &&
                                        gsCitiesList.length &&
                                        gsCitiesList.map((elm, idx) => {
                                          return (
                                            <option
                                              key={idx}
                                              value={elm.name + "##" + elm.id}
                                            >
                                              {elm.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {/* tabIndex: 6,
                                                                        placeholder: 'Type min 3 characters to search',
                                                                        value: valueSurgical,
                                                                        onChange: onChangeAutocompleteSurgical,
                                                                        className: "form-control",
                                                                        disabled: surgicalDisabledSetting.city,
                                                                        ref: register({required: true }),
                                                                        name: "surgicalCity",
                                                                        autoComplete: "off" */}

                                    {/* <Autosuggest
                                                                        suggestions={suggestionsSurgical}
                                                                        onSuggestionsFetchRequested={onSuggestionsFetchRequestedSurgical}
                                                                        onSuggestionsClearRequested={onSuggestionsClearRequestedSurgical}
                                                                        getSuggestionValue={getSuggestionValueSurgical}
                                                                        renderSuggestion={renderSuggestionSurgical}
                                                                        inputProps={inputPropsSurgical}
                                                                    /> */}

                                    {otherSurgicalCityFlag && (
                                      <input
                                        tabIndex="6"
                                        type="text"
                                        name="otherSurgicalCityName"
                                        className="form-control"
                                        ref={register({ required: true })}
                                        placeholder="Please enter your city name"
                                      />
                                    )}

                                    {errors.surgicalCity && (
                                      <div className="error-text" role="alert">
                                        Please select city.
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                    <label>Center/College</label>
                                    <select
                                      tabIndex="7"
                                      className="select form-control"
                                      ref={register({
                                        required: true,
                                      })}
                                      // pattern: /^(?!.*(-1)).*$/
                                      name="generalSurgeryCenter"
                                      onChange={onChange.bind(
                                        this,
                                        "generalSurgeryCenter"
                                      )}
                                      disabled={surgicalDisabledSetting.center}
                                    >
                                      <option value={""} selected="selected">
                                        Center/College
                                      </option>
                                      {medicalSchoolListSurgical &&
                                        medicalSchoolListSurgical.length &&
                                        medicalSchoolListSurgical.map(
                                          (elm, idx) => {
                                            return (
                                              <option
                                                value={elm.id + "#" + elm.name}
                                              >
                                                {elm.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    {medicalSchoolListSurgicalOtherFlag && (
                                      <input
                                        tabIndex="7"
                                        type="text"
                                        name="generalSurgeryCenterOther"
                                        className="form-control"
                                        ref={register({ required: true })}
                                        placeholder="Please enter the college name as per the registered name."
                                      />
                                    )}
                                    {errors.generalSurgeryCenter && (
                                      <div className="error-text" role="alert">
                                        Please select city.
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                    <label>Completion year</label>
                                    <select
                                      tabIndex="8"
                                      name="generalSurgeryYear"
                                      className="select form-control"
                                      disabled={
                                        mbbsDisabledSetting.generalSurgeryYear
                                      }
                                      onChange={onChange.bind(
                                        this,
                                        "generalSurgeryYear"
                                      )}
                                      ref={register({ required: true })}
                                    >
                                      <option value={""} selected="selected">
                                        Select Year
                                      </option>
                                      {yearList &&
                                        yearList.length &&
                                        yearList.map((elm, idx) => {
                                          return (
                                            <option value={elm}>{elm}</option>
                                          );
                                        })}
                                    </select>
                                    {errors.generalSurgeryYear && (
                                      <div className="error-text" role="alert">
                                        Please select Year.
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="card-body">
                      <h3
                        className="card-title"
                        style={{ display: "inline-block", fontSize: "22px" }}
                      >
                        3.Speciality Training
                      </h3>
                      {
                        <React.Fragment>
                          <span
                            style={{
                              display: "inline-block",
                              marginLeft: "12px",
                              marginRight: "12px",
                            }}
                          >
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Checkbox
                                  onClick={() => {
                                    setSuperSpecilityShowHide(
                                      !superSpecilityShowHide
                                    );
                                    // if (superSpecilityShowHide) {
                                    //     setSuperSpecilityShowHide(false)
                                    // } else {
                                    //     setSuperSpecilityShowHide(false)
                                    // }
                                  }}
                                  aria-label="Checkbox for following text input"
                                />
                              </InputGroup.Prepend>
                            </InputGroup>
                          </span>
                          <small>
                            (Please select if you want to enter Speciality
                            training details)
                          </small>
                        </React.Fragment>
                      }
                      {superSpecilityShowHide ? (
                        <div className="education-info">
                          <div className="row form-row education-cont">
                            <div className="col-12 col-md-10 col-lg-11">
                              <div className="row form-row">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                  <label>Speciality </label>
                                    <select
                                      className="select form-control"
                                      tabIndex="13"
                                      ref={register({ required: true })}
                                      name="superSpecialitySpeciality"
                                      onChange={(e) => {
                                        setSpeciality(e.target.value);
                                      }}
                                    >
                                      <option value={""} selected="selected">
                                        Speciality
                                      </option>
                                      {Constant.specialityList.map((val) => {
                                        return (
                                          <option value={val}>{val}</option>
                                        );
                                      })}
                                    </select>
                                    {errors.superSpecialitySpeciality && (
                                      <div className="error-text" role="alert">
                                        Please select Speciality.
                                        {/* {errors.superSpecilityCountry.message} */}
                                      </div>
                                    )}
                                  </div>
                                      </div>
                                  <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                  <label>Country </label>
                                    <select
                                      tabIndex="9"
                                      name="superSpecilityCountry"
                                      ref={register({ required: true })}
                                      className="select form-control"
                                      onChange={onChange.bind(
                                        this,
                                        "superSpecilityCountry"
                                      )}
                                    >
                                      <option value="" selected="selected">
                                        Select Country
                                      </option>
                                      {countries &&
                                        countries.length &&
                                        countries.map((opt) => {
                                          return (
                                            <option value={opt.id}>
                                              {opt.name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    {errors.superSpecilityCountry && (
                                      <div className="error-text" role="alert">
                                        Please select Country.
                                        {/* {errors.superSpecilityCountry.message} */}
                                      </div>
                                    )}
                                  </div>

                              
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                <div className="form-group">
                                  <label>City </label>
                                    <select
                                      disabled={
                                        superSpecilityCountrySetting.city
                                      }
                                      tabIndex="11"
                                      name="superSpecialityCity"
                                      ref={register({ required: true })}
                                      className="form-control"
                                      onChange={
                                        getSuggestionValueSuperSpeciality
                                      }
                                    >
                                      <option selected="selected" value={""}>
                                        Select City
                                      </option>
                                      {superSpecialityCitiesList &&
                                        superSpecialityCitiesList.length &&
                                        superSpecialityCitiesList.map(
                                          (elm, idx) => {
                                            return (
                                              <option
                                                key={idx}
                                                value={elm.name + "##" + elm.id}
                                              >
                                                {elm.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>

                                    {otherSuperSpecialityCityFlag && (
                                      <input
                                        tabIndex="11"
                                        type="text"
                                        name="otherSuperSpecialityCityName"
                                        className="form-control"
                                        ref={register({ required: true })}
                                        placeholder="Please enter your city name"
                                      />
                                    )}

                                    {errors.superSpecialityCity && (
                                      <div className="error-text" role="alert">
                                        Please select city.
                                        {/* {errors.superSpecilityCountry.message} */}
                                      </div>
                                    )}
                                  </div>    
                                      </div>
                                      <div className="col-12 col-md-6 col-lg-6">
                              
                                  <div className="form-group">
                                  <label>Center/College </label>
                                    <select
                                      className="select form-control"
                                      tabIndex="10"
                                      ref={register({ required: true })}
                                      onChange={onChange.bind(
                                        this,
                                        "superSpecialityCenter"
                                      )}
                                      name="superSpecialityCenter"
                                      disabled={
                                        superSpecilityCountrySetting.center
                                      }
                                    >
                                      <option value={""} selected="selected">
                                        Center/College
                                      </option>
                                      {medicalSchoolListSuperSpeciality &&
                                        medicalSchoolListSuperSpeciality.length &&
                                        medicalSchoolListSuperSpeciality.map(
                                          (elm, idx) => {
                                            return (
                                              <option
                                                value={elm.id + "#" + elm.name}
                                              >
                                                {elm.name}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                    {medicalSchoolListSuperSpecialityOtherFlag && (
                                      <input
                                        tabIndex="10"
                                        type="text"
                                        name="superSpecialityCenterOther"
                                        className="form-control"
                                        ref={register({ required: true })}
                                        placeholder="Please enter the college name as per the registered name."
                                      />
                                    )}

                                    {errors.superSpecialityCenter && (
                                      <div className="error-text" role="alert">
                                        Please select Center/College.
                                        {/* {errors.superSpecilityCountry.message} */}
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                                  <label>Completion year </label>
                                    <select
                                      tabIndex="12"
                                      name="superSpecialityYear"
                                      className="select form-control"
                                      disabled={
                                        mbbsDisabledSetting.superSpecialityYear
                                      }
                                      onChange={onChange.bind(
                                        this,
                                        "superSpecialityYear"
                                      )}
                                      ref={register({ required: true })}
                                    >
                                      <option value={""} selected="selected">
                                        Select Year
                                      </option>
                                      {yearList &&
                                        yearList.length &&
                                        yearList.map((elm, idx) => {
                                          return (
                                            <option value={elm}>{elm}</option>
                                          );
                                        })}
                                    </select>
                                    {errors.superSpecialityYear && (
                                      <div className="error-text" role="alert">
                                        Please select Completion year.
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {/* <div className="col-12 col-md-6 col-lg-6">
                                                                <br />
                                                                <div className="form-group" style={{ marginTop: '12px' }}>
                                                                    <label> <br /> </label>
                                                                    <button type="submit" className="btn btn-primary submit-btn"> Add More </button>
                                                                </div>
                                                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="card-body">
                      <h3
                        className="card-title"
                        style={{ display: "inline-block", fontSize: "22px" }}
                      >
                        Do you want to join other speciality groups?
                      </h3>
                      {
                        <React.Fragment>
                          <span
                            style={{
                              display: "inline-block",
                              marginLeft: "12px",
                              marginRight: "12px",
                            }}
                          >
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Radio
                                    checked={otherGroupShowHide}
                                  onClick={() => {
                                    setOtherGroupShowHide(true);
                                    
                                    // if (superSpecilityShowHide) {
                                    //     setSuperSpecilityShowHide(false)
                                    // } else {
                                    //     setSuperSpecilityShowHide(false)
                                    // }
                                  }}
                                  aria-label="Radio for following text input"
                                />
                              </InputGroup.Prepend>
                              <span><small>&nbsp;Yes&nbsp;</small></span>
                              <InputGroup.Prepend>
                                <InputGroup.Radio
                                    checked={!otherGroupShowHide}
                                    value="false"
                                  onClick={() => {
                                    setOtherGroupShowHide(false);
                                    
                                    // if (superSpecilityShowHide) {
                                    //     setSuperSpecilityShowHide(false)
                                    // } else {
                                    //     setSuperSpecilityShowHide(false)
                                    // }
                                  }}
                                  aria-label="Radio for following text input"
                                />
                              </InputGroup.Prepend>
                              <span><small>&nbsp;No</small></span>
                            </InputGroup>
                          </span>
                          
                          <small>
                          (Please select "Yes" if you want to join any other Speciality group)
                          </small>
                        </React.Fragment>
                      }
                      {otherGroupShowHide ? (
                        <div className="education-info">
                          <div className="row form-row education-cont">
                            <div className="col-12 col-md-10 col-lg-11">
                              <div className="row form-row">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="form-group">
                               
                                    <Select
                                      name="otherGroup"
                                      isMulti
                                      ref={register({ required: false })}
                                      name="colors"
                                      options={options_group} // options will object of label and value
                                      className="basic-multi-select"
                                      onChange={(e) => {
                                        setOtherGroup(e);
                                      }}
                                    ></Select>

                                    {errors.superSpecilityCountry && (
                                      <div className="error-text" role="alert">
                                        Please select Country.
                                        {/* {errors.superSpecilityCountry.message} */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>                                     
                    <div className="card-body">
                      <h3 className="card-title" style={{ fontSize: "22px" }}>
                        {" "}
                        4.Additional Qualifications{" "}
                      </h3>
                      <div className="education-info">
                        <div className="row form-row education-cont">
                          <div className="col-12 col-md-10 col-lg-11">
                            <div className="row form-row">
                              <textarea
                                tabIndex="14"
                                rows="3"
                                className="form-control"
                                ref={register({ required: false })}
                                name="additionalQualifications"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card-body">
                      <h3 className="card-title" style={{ fontSize: "22px" }}>
                        {" "}
                        5.Upload CV{" "}
                      </h3>
                      <div className="education-info">
                        <div className="row form-row education-cont">
                          <div className="col-12 col-md-10 col-lg-11">
                            <div className="row form-row">
                              <input
                                tabIndex="15"
                                type="file"
                                className="upload"
                                accept="application/pdf"
                                onChange={onChangeUploadFile}
                              />
                            </div>
                          </div>
                          <small
                            style={{
                              padding: "6px",
                              marginTop: "12px",
                            }}
                            className="alert alert-success"
                          >
                            Supported file type .pdf
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row form-row">
                        <div className="submit-section col-12">
                          <input
                            type="checkbox"
                            name="checkbox"
                            checked={checkbox}
                            className=""
                            onChange={onChangeCheckbox}
                            ref={register({ required: true })}
                          />
                          <span> I agree to Surgeons for Surgeons</span>
                          <a
                            className="btn btn-link"
                            href={termsOfUse}
                            target="_blank"
                          >
                            Terms of Use
                          </a>
                          <span>and</span>
                          <a
                            className="btn btn-link"
                            href={companyPolicy}
                            target="_blank"
                          >
                            {" "}
                            Company Policies{" "}
                          </a>
                          {errors.checkbox && (
                            <div className="error-text" role="alert">
                              Please select check box for Terms of use and
                              Privacy Policy
                            </div>
                          )}
                        </div>
                        <div className="submit-section col-12">
                          <input
                            type="checkbox"
                            name="checkboxTwo"
                            checked={checkboxTwo}
                            className=""
                            ref={register({ required: true })}
                            onChange={onChangeCheckboxTwo}
                          />
                          <span>
                            {" "}
                            The data entered above is true and correct as per my
                            knowledge and belief and I take complete
                            responsibility for the accuracy of the data entered
                            and shared by me.
                          </span>
                          {errors.checkboxTwo && (
                            <div className="error-text" role="alert">
                              Please select check box for Terms of use and
                              Privacy Policy
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="row form-row">
                        <div className="submit-section">
                          <button
                            type="submit"
                            className="btn btn-primary submit-btn"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent></FooterContent>
    </React.Fragment>
  );
};

export default Qualification;
