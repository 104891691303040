const EventEmitters = () => {
    const evt = new Event("showTost");
    const loginLogutEvt = new Event("loginLogut");
    const loaderEvt = new Event("loaderEvt");

    return {
        showTost: (obj) => {
            evt.tostConfig = obj;
            document.dispatchEvent(evt);
        },
        loginLogutService: (obj) => {
            document.dispatchEvent(loginLogutEvt);
        },
        showLoader: (obj) => {
            loaderEvt.loaderConfig = obj;
            document.dispatchEvent(loaderEvt);
        }

    }
}

export default EventEmitters();