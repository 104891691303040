import {
    SET_ADD_CLINICAL_CASE_MODAL,
    SET_CLINICAL_CASES,
    SET_CLINICAL_CASES_COLLECTION,
    SET_CLINICAL_IMAGES_TAB,
    SET_SELF_CLINICAL_CASES,
    SET_SELECTED_CL_CASE
} from "./actions";

let INIT_STATE = {
    addModalVisible: false,
    clinicalCases: [],
    clinicalCaseCollection: [],
    selfClinicalCases: [],
    isClinicalImagesTab: true,
    selectedClCase:null
};

let clinicalCases = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_ADD_CLINICAL_CASE_MODAL:
            return { ...state, addModalVisible: action.payload };
        case SET_CLINICAL_CASES:
            return { ...state, clinicalCases: action.payload };
        case SET_CLINICAL_CASES_COLLECTION:
            return { ...state, clinicalCaseCollection: action.payload };
        case SET_SELF_CLINICAL_CASES:
            return { ...state, selfClinicalCases: action.payload };
        case SET_CLINICAL_IMAGES_TAB:
            return { ...state, isClinicalImagesTab: action.payload };
        case SET_SELECTED_CL_CASE:
            return { ...state, selectedClCase: action.payload };    
        default:
            return state;
    }
};

export default clinicalCases;
