import React, { useState, useEffect } from 'react';
// import letterImg from '../assets/img/letter.svg';
import axios from 'axios';
import Constants from '../configs/Constants';
import Config from '../configs/Config';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
import DummyProfile from '../assets/img/dummy-profile.png';
import BannerImage from '../assets/img/01_a_Banner.jpg';
import DocBanner from '../assets/img/Doccare_Banner.jpg';
import Banner3 from '../assets/img/banner3.jpeg';
import Banner2 from '../assets/img/banner2.jpeg';
import Banner1 from '../assets/img/banner1.jpeg';
import '../assets/css/posts.css';
import '../assets/css/custome.css';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import EventEmitters from '../configs/EventEmitters';
import moment from 'moment';
import LoaderEmitters from '../configs/LoaderEmitters';
import _ from 'lodash';

const CommunityFollowingFriendList = (props) => {

    const [friendList, setFriendList] = React.useState([]);

    useEffect(() => {
        const id = Config.getSessionInfo('id');
        const isAllDetailsFilled = Config.getSessionInfo('is_all_details_filled');

        if (!id || isAllDetailsFilled === 0) {
            props.history.push('/home');
        } else {
            getFriendListCommunity(id);
        }

    }, []);

    const getFriendListCommunity = (id) => {

        // LoaderEmitters.showLoader({
        //     showLoader: true
        // });

        axios.post(Constants.getFriendListCommunity, {
            signup_id: id
        })
            .then((response) => {
                // reset();
                LoaderEmitters.showLoader({
                    showLoader: false
                });
                console.log("loadDataOfCommunity response>>>>>>", response);
                setFriendList([...response.data.result]);
            })
            .catch(console.error);
    }

    const chatNowUser = (elm) => {

        axios.post(Constants.chatRoomSolo, {
            senderId: Config.getSessionInfo('id'),
            reciverId: elm.id
        }).then((response) => {
            props.history.push('/chat');
        });
    }


    return (
        <React.Fragment>
            <div className="row align-items-center justify-content-center">
                <div className="col-md-4">
                    <div className="card-body">
                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" to="/communityFollowing">
                                    Request
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/communityFollowingFriendList">
                                    Friend List
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <br />
            <div className="content">
                <div className="container-fluid" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="row row-grid">
                                {
                                    friendList && friendList.map((elm) => {
                                        return (
                                            <div className="col-md-6 col-lg-4 col-xl-3">
                                                <div className="profile-widget">
                                                    <div className="doc-img">
                                                        <a href="surgen-dp.phpl">
                                                            <img style={{ width: '96px' }} className="img-fluid" alt="User Image" src={Constants.server + elm.profile_pic} />
                                                        </a>
                                                        <a href="javascript:void(0)" className="fav-btn">
                                                            <i className="far fa-bookmark"></i>
                                                        </a>
                                                    </div>
                                                    <div className="pro-content">
                                                        <h3 className="title">
                                                            <span>Dr. {elm.full_name}</span>
                                                        </h3>
                                                        <p className="speciality">MDS - Periodontology and Oral Implantology, BDS</p>

                                                        <div className="row row-sm">
                                                            <div className="col-6">
                                                                {/* <a href="view-profile.php" className="btn view-btn">View Profile</a> */}
                                                                <NavLink className="btn view-btn" to={"profile/" + elm.id}>View Profile</NavLink>
                                                            </div>
                                                            <div className="col-6">
                                                                <NavLink onClick={chatNowUser.bind(this, elm)} className="btn view-btn" to={"chat"}>Chat</NavLink>
                                                                {/* <a href="chat.html" className="btn book-btn">Chat</a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent></FooterContent>
        </React.Fragment>
    );
}
export default CommunityFollowingFriendList;