import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Footer from "../login/Footer";
import apis from "../../apis/apis";
import { useParams } from "react-router-dom";

const Blog = () => {
  const [blogdata, setBlogdata] = useState(null);
  const params = useParams();

  console.log(params);

  useEffect(() => {
    apis.getBlogById(params.id).then(res=>{
        setBlogdata(res.data.data);
    })
  }, []);

  const convertedDate = (dateString) => {
    var date = new Date(dateString);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var formattedDate = months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
    return formattedDate;
  };


  console.log(blogdata);

  if(!blogdata) return (
    <Box height={'80vh'} width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <CircularProgress/>
    </Box>
  )

  return (
    <Box>
      <Box p={4}>
        <Box height={"500px"} width={"100%"} borderRadius={2}>
        <img
          src={blogdata?.imageUrl}
          alt=""
          height={"100%"}
          width={"100%"}
          style={{objectFit:'cover'}}
        />
      </Box>
        <Box my={4}>
          <Box display={"flex"} gap={2} flexWrap={"wrap"} alignItems={"center"}>
            <Typography
              fontFamily={"Plus Jakarta Sans"}
              // maxWidth={'65%'}
              sx={{
                fontSize: { xs: "1.25rem", md: "1.5rem" },
                color: "#2D3748",
                fontWeight: 700,
              }}
            >
              {blogdata?.title}
            </Typography>
              <Box
                sx={{
                  backgroundColor: "#E2EFF5",
                  px: 3,
                  py: 1,
                  width: "auto",
                  display: "inline-block",
                  borderRadius:4,
                }}
              >
                <Typography
                  fontFamily={"Plus Jakarta Sans"}
                  // maxWidth={'65%'}
                  sx={{
                    fontSize: { xs: ".75rem", md: "1rem" },
                    color: "#13375B",
                    fontWeight: 700,
                  }}
                >
                  {blogdata?.category}
                </Typography>
              </Box>
          </Box>
            <Box
              display={"flex"}
              gap={2}
              flexWrap={"wrap"}
              alignItems={"center"}
              mt={1}
            >
              <Typography
                fontFamily={"Plus Jakarta Sans"}
                // maxWidth={'65%'}
                sx={{
                  fontSize: { xs: ".75rem", md: "1rem" },
                  color: "#2D3748",
                  fontWeight: 700,
                }}
              >
                Written by {blogdata?.authorName}
              </Typography>
              <Typography
                fontFamily={"Plus Jakarta Sans"}
                // maxWidth={'65%'}
                sx={{
                  fontSize: { xs: ".75rem", md: "1rem" },
                  color: "#718096",
                  fontWeight: 400,
                }}
              >
                {convertedDate(blogdata?.dateOfUpload)}
              </Typography>
            </Box>
          <Box mt={2}>
            <div className="blog_dynamic_data" dangerouslySetInnerHTML={{ __html: blogdata?.description }}/>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Blog;
