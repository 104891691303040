import React, { useEffect } from "react";
import FooterContent from "./FooterContent";

const TermsAndConditions = () => {

  
  useEffect(() => {
    document.body.scrollTo(0,0)
  }, [])

  return (
    <React.Fragment>
      <div className="container">
        <div className="">
          <div className="mx-auto my-5 section-header">
            <h2 className="text-center" style={{color: 'rgb(34, 114, 161)',textTransform: "unset",fontSize: '3.25rem'}} >Terms And Conditions</h2>
          </div>
          <div>
            <p>
              IMPORTANT - PLEASE READ THESE TERMS OF
              USE ("AGREEMENT") CAREFULLY BEFORE USING THE SERVICES OFFERED BY
              SFS GLOBAL LLC ("SFS" OR "WE" OR "US"). THIS IS A BINDING
              AGREEMENT BETWEEN YOU AND SFS. BY ACCESSING OR USING THE SERVICES
              IN ANY MANNER, YOU AGREE THAT YOU HAVE READ AND AGREE TO BE BOUND
              BY AND A PARTY TO THE TERMS AND CONDITIONS OF THIS AGREEMENT TO
              THE EXCLUSION OF ALL OTHER TERMS. IF THE TERMS OF THIS AGREEMENT
              ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH
              TERMS. IF YOU DO NOT UNCONDITIONALLY AGREE TO ALL THE TERMS AND
              CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE THE SERVICES.
            </p>
            <p>
              This Agreement, among other things, provides the terms and
              conditions for use, is primarily a web based practice management
              hosted and managed remotely through the website and through native
              mobile applications.
            </p>
            <p>
              {" "}
              This Agreement defines the terms and conditions under which you
              are allowed to use the Website and describes the manner in which
              we shall treat your account while you are registered as a member
              with us. If you have any questions about any part of the
              Agreement, feel free to contact us at____________________ .
            </p>
            <p>
              By downloading the mobile application or accessing the Website to
              use the Services, you irrevocably accept all the conditions
              stipulated in this Agreement, the Privacy Policy, as available on
              the Website, and agree to abide by them. This Agreement supersedes
              all previous oral and written terms and conditions (if any)
              communicated to you relating to your use of the Website to avail
              the Services. By availing any Service, you signify your acceptance
              of the terms of this Agreement.{" "}
            </p>
            <p>
              Before using SFS, you need to be at least 18 years old. Welcome to
              SFS. By accessing or using the SFS website(s), by browsing and
              viewing the Video Publication(s) from the same, being a part of
              any group, accessing the chat rooms, etc. you (You or Your) agree
              to be bound by these terms of use and all policies and guidelines
              incorporated by reference herein. If you do not agree to all of
              these terms of use, do not use the Site and do not access any of
              the Video Publication(s). Please continue reading to learn about
              the rules and restrictions that govern your use of the SFS'
              website(s) and all other products, services and applications made
              available by SFS from time to time (collectively. the "Services").{" "}
            </p>
            <p>
              The Privacy Policy, User Guidelines and Allowable User Preferences
              on the website(s) are incorporated into this Agreement by
              reference, in their entirety. Please review the same carefully.
            </p>
            <p>
              {" "}
              Will these terms ever change? We constantly try to improve our
              Services, so we may update this Agreement as we offer new and
              improved Services. We may modify this Agreement at any time, but
              we will do our best to bring this to your attention by posting a
              notice on the website(s). If you don't agree with the new terms,
              you should stop using the Services. Your use of the Services in
              any way following notification of changes by SFS constitutes your
              acceptance of the terms and conditions as modified. We may suspend
              or discontinue any part of the Services, or we may introduce new
              features, impose limits on certain features or restrict access to
              part or all of the Services at any time. We'll do our best to
              provide you notice when we make a material change to the Services
              that might adversely affect you. Your use of the Website and
              mobile application following any such modification constitutes
              your agreement to follow and be bound by the Agreement so
              modified.
            </p>
            <p></p>
            <p> You represent and warrant to SFS that:</p>
            <p></p>
            <p> 1. you are at least 18 years old;</p>
            <p>
              2. all registration information you submit is accurate and
              truthful;
            </p>
            <p>
              3. you will maintain the accuracy of such information and promptly
              advise us of any changes;
            </p>
            <p>
              {" "}
              4. if you're agreeing to this Agreement on behalf of an
              organization or entity, you represent and warrant that you are
              authorized to agree to this Agreement on the organization or
              entity's behalf and bind it to this Agreement (in which case, the
              references to "you" and "your" in these Terms will refer to that
              organization or entity). You also certify that you are legally
              permitted to use and access the Services and take full
              responsibility for the selection, use of, and access to the
              Services, including ensuring that you have permission to
              communicate with anyone you choose to interact with through the
              Services.
            </p>
            <p>
              5. You hereby declare that all the content that you post/share
              shall be in compliance with HIPAA (Health Insurance Portability
              and Accountability Act of 1996, as amended) and you shall not
              disclose any individually identifiable patient related
              information.
            </p>
            <p>
              This Agreement is void where prohibited by law, and the right to
              access the Services is deemed revoked in such jurisdictions.{" "}
            </p>
            <h1>Fees and payment</h1>
            <p>Responsibility for usage charges</p>
            <p>
              {" "}
              SFS may offer at its discretion, a free trial of its Subscription
              Services for a specified time period. Users of the Software during
              the trial period are bound by the terms of this Agreement and any
              applicable law, regulation and generally accepted practices or
              guidelines in the relevant jurisdictions. Any data User enters
              into the Software, and any customizations made to the Software by
              or for User, during User's free trial will be permanently lost at
              the expiry of the specified time period unless the User upgrades
              his/her/its subscription to one of the User Plans. SFS does not
              provide any warranty during the trial period.
            </p>
            <p>
              {" "}
              Through SFS, you may be able to access and use products or
              services provided by individuals or companies who are not employed
              or controlled by SFS (such as, a payment platform to request
              and/or make payments). If you use any of these products or
              services, you must review and agree to the corresponding terms and
              conditions and privacy policy, as well as pay any applicable fees
              required to use those products. Any information you agree to
              provide will be governed by that business' privacy policy. Note
              that, by using the Services, you may receive e-mail or text
              messages on your phone or mobile device, which may cause you to
              incur usage charges or other fees or costs in accordance with your
              wireless or data service plan. Any and all such charges, fees, or
              costs are your sole responsibility. You should consult with your
              wireless carrier to determine what rates, charges, fees, or costs
              may apply to your use of the Services.
            </p>
            <h1>Registration and security</h1>
            <p>
              As a condition to using Services, you may be required to register
              with SFS by selecting a password and providing your name and a
              valid email address. You will provide SFS with accurate, complete,
              and updated registration information. You may not:
            </p>
            <p>
              {" "}
              a. Select or use the name or email address of another person with
              intent to impersonate that person; or{" "}
            </p>
            <p>
              b. Use a name subject to any rights of any person other than you
              without appropriate authorization.
            </p>
            <p>
              {" "}
              SFS reserves the right to refuse registration of or to cancel a
              SFS account in its sole discretion. You will be responsible for
              maintaining the confidentiality of your account and password.
            </p>
            <p>
              {" "}
              You may not transfer your account to anyone without prior written
              consent of SFS. Violation of any of the foregoing may result in
              immediate termination of your account, revocation of your ability
              to use the Services, and subject you to state and federal
              penalties and other legal consequences. SFS reserves the right,
              but will have no obligation, to investigate your use of the
              Services in order to determine whether a violation of this
              Agreement has occurred or to comply with any applicable law,
              regulation, legal process or governmental request.
            </p>
            <p>
              {" "}
              The security of your account is very important to us. Please keep
              your password safe, use your real name and create your own account
              before using SFS.
            </p>
            <h1> Restrictions and responsibilities </h1>
            <p>
              You warrant, represent and agree that you will not contribute any
              Content or otherwise use (or encourage anyone else to use) the
              Services in a manner that:
            </p>
            <ul>
              <li>
                infringes or violates the intellectual property rights or
                proprietary rights, rights of publicity or privacy, or other
                rights of any third party:
              </li>
              <li>violates any law, statute, ordinance or regulation;</li>
              <li>
                is harmful, fraudulent, threatening, abusive, harassing,
                tortuous, defamatory, vulgar, obscene, libellous, or otherwise
                objectionable as reasonably determined by SFS,
              </li>
              <li>
                jeopardizes the security of your account in any way, such as
                allowing someone else access to your account or password;
              </li>
              <li>
                attempts, in any manner, to obtain the password, account, or
                other security information of any other user;
              </li>
              <li>
                violates the security of any computer network. or cracks any
                passwords or security encryption codes;
              </li>
              <li>
                runs Maillots, Listserv, any form of auto-responder or "spam" on
                the Services, or any processes that run or are activated while
                you are not logged into the Services, or that otherwise
                interferes with the proper working of the Services (including by
                placing an unreasonable load on the Services' infrastructure);
              </li>
              <li>
                "crawls," "scrapes," or "spiders" any page, data, or portion of
                or relating to the Services or Content (through use of manual or
                automated means);
              </li>
              <li>copies or stores any significant portion of the Content;</li>
              <li>
                decompiles, reverse engineers, or otherwise attempts to obtain
                the source code or underlying ideas or information of or
                relating to the Services (or the products of SFS' third party
                service providers). SFS reserves the right to terminate access
                of any third-party application or service to our published or
                unpublished APIs.SFS reserves the right (but does not have the
                obligation) to remove any Content from the Services at any time,
                for any reason (including, but not limited to, if we receive
                claims or allegations from individuals, companies or authorities
                relating to that Content or if SFS is concerned that you may
                have breached the foregoing restrictions), or for no reason at
                all.
              </li>
            </ul>
            <p>
              SFS reserves the right to moderate the suggestions made by the
              users through feedback and the right to remove any abusive or
              inappropriate or promotional content added on the Website.
              However, SFS shall not be liable if any inactive, inaccurate,
              fraudulent, or non- existent profiles of Surgeons are added to the
              Website or mobile application. SFS ensures easy access to the
              users by providing a tool to update your profile information. SFS
              reserves the right of ownership of all the User's profile and
              photographs and to moderate the changes or updates requested by
              User. However, SFS takes the independent decision whether to
              publish or reject the requests submitted for the respective
              changes or updates. You hereby represent and warrant that you are
              fully entitled under law to upload all content uploaded by you as
              part of your profile or otherwise while using SFS's services, and
              that no such content breaches any third-party rights, including
              intellectual property rights. Upon becoming aware of a breach of
              the foregoing representation, SFS may modify or delete parts of
              your profile information at its sole discretion with or without
              notice to you.
            </p>
            <p>
              SFS shall have no obligation to pre-screen, review, flag, filter,
              modify, refuse or remove any or all Critical Content from any
              Service, except as required by applicable law.{" "}
            </p>
            <p>
              You understand that by using the Services you may be exposed to
              Critical Content or other content that you may find offensive or
              objectionable. SFS shall not be liable for any effect on user's
              business due to Critical Content of a negative nature. In these
              respects, you may use the Service at your own risk. SFS however,
              as an 'intermediary, takes steps as required to comply with
              applicable law as regards the publication of Critical Content.
            </p>
            <p>
              {" "}
              The User is and shall be duly registered, licensed and qualified
              to practice medicine/ provide health care, wellness services, as
              per applicable laws/regulations/guidelines set out by competent
              authorities and the Practitioner shall not be part of any
              arrangement which will prohibit him/her from practicing medicine
              within the territory of Origin or any other place. The User shall
              at all times ensure that all the applicable laws that govern the
              User shall be followed and utmost care shall be taken in terms of
              the consultation/ services being rendered.
            </p>
            <p>
              {" "}
              These Terms of Use will also be applicable to Users who access
              Software features using native mobile applications published by
              SFS including but not limited to its applications for devices
              running on platforms such as iOS, Android, Windows, Blackberry,
              Tizen and any derivatives or any other platforms. Additional terms
              of use may be applicable to Users while accessing Software using
              such mobile applications. You agree that any registration
              information you give to SFS will always be true. accurate,
              correct, complete and up to date, to the best of our knowledge.
              Any phone number used to register with the Subscription Services
              be registered in your name and you might be asked to provide
              supporting documents to prove the same. You agree that you will
              not use the Services provided by SFS for any unauthorized and
              unlawful purpose. You will not impersonate another person,
              including, without limitation, a Surgeon, a Practice or User. You
              agree to use the Subscription Services only for purposes that are
              permitted by (a) the Terms of Use and (b) any applicable law,
              regulation and generally accepted practices or guidelines in the
              relevant jurisdictions (including any laws regarding the export of
              data or software to and from U.S.A or other relevant countries).
            </p>
            <p>
              {" "}
              SFS provides Software through its website, as a Software as a
              Service (SaaS) model. SFS is not responsible for and does not deal
              with any of patient managed by User through the website or native
              mobile applications and only provides Software to User through the
              website and native mobile applications. To the extent User uses
              such software or downloads such software from the website, the
              software, will be deemed to be licensed to User by SFS, for
              providing Subscription Services to User and enabling User to use
              those Software only.{" "}
            </p>
            <p>
              SFS does not transfer either the title or the intellectual
              property rights to the Software and other its Subscription
              Services, and SFS (or its licensors) retain full and complete
              title to the Software as well as all intellectual property rights
              therein. User agrees to use the Subscription Services and the
              materials provided therein only for purposes that are permitted
              by: (a) this Agreement; and (b) any applicable law, regulation or
              generally accepted practices or guidelines in the relevant
              jurisdictions. Information provided by a User to SFS may be used,
              stored or re-published by SFS or its affiliates even after the
              termination of these terms of Service.
            </p>
            <p>
              Notwithstanding anything to the contrary contained herein, SFS
              does not warrant that its Services will always function without
              disruptions, delay or errors. A number of factors may impact the
              use of the Services (depending on the Subscription Services used)
              and native mobile applications and may result in the failure of
              your communications including but not limited to: your local
              network, firewall, your internet service provider, the public
              internet, your power supply and telephony services. SFS takes no
              responsibility for any disruption, interruption or delay caused by
              any failure of or inadequacy in any of these items or any other
              items over which we have no control.
            </p>
            <p>
              {" "}
              You, not SFS, remain solely responsible for all Content that you
              upload, post, email, transmit, or otherwise provide using, or in
              connection with, the Services, and you warrant that you possess
              all rights necessary to provide such Content to SFS and to grant
              SFS the rights to use such information as provided herein. You
              will be responsible for obtaining and maintaining any equipment or
              ancillary services needed to connect to, access, or otherwise use
              the Services, including, without limitation, modems, hardware,
              software, and long distance or local telephone service. You will
              be responsible for ensuring that such equipment or ancillary
              services are compatible with the Services. You will be responsible
              for withholding, filing, and reporting all taxes, duties and other
              governmental assessments associated with your activity in
              connection with the Services. Multiple Users are not permitted to
              share the same/single log-in,
            </p>
            <h1>Data download and security</h1>
            <p>
              1. The person using SFS website may view, reproduce or store
              copies of articles, provided that the articles are used only for
              that person's personal, non-commercial use. Uses beyond that
              allowed by the 'Fair Use' limitations (Sections 107 and 108 of
              Title 17 of the U.S. Copyright law) require permission of the
              publisher. Request permission here by sending an email to SFS at
              xxx. If you believe your work has been reproduced at the Site in a
              way that constitutes copyright infringement, please notify us by
              sending an e-mail to xxx.
            </p>
            <p>
              2. Any content provided to or via the website(s) in whole or in
              part must include the customary bibliographic citation, including
              author attribution, date, article title, the URL, and MUST include
              a copy of the copyright notice.
            </p>
            <p>
              3. The SFS name and logo are trademarks of xxx. Any use of said
              trademarks in connection with the sale, distribution, or
              advertising of any goods or services is strictly prohibited.
            </p>
            <p>
              4. We reserve the right to suspend, terminate or prevent your
              registration at our sole discretion if we consider you to have
              breached or likely to breach these Terms and Conditions of Use, or
              if someone has reported abuse.
            </p>
            <p>
              {" "}
              5. We will not share registration information or usage statistics
              of our users with any third party without express permission. We
              reserve the right to display the names of subscribed institutions.
            </p>
            <p>
              6. We will make reasonable efforts to keep the website(s)
              available 24 hours a day, 7 days a week. However, as a result of
              technical failures, routine maintenance, or other unforeseen
              circumstances, availability may be limited and we will not be
              responsible for the non-availability of the website(s). Users
              should be aware that SFS reserves the right to change,
              discontinue, suspend or restrict access to all or any part of the
              website(s) at any time. Reliance on their continued availability
              is at the user's risk.
            </p>
            <p>
              {" "}
              7. Links and references to, as well as descriptions of other
              products, publications or services does not imply endorsement of
              any kind unless expressly stated by SFS.
            </p>
            <p>Copyright dispute policy; Indemnification </p>
            <p>
              Under the U.S. Digital Millennium Copyright Act (the "DMCA"),
              online service providers such as SFS have the right but not the
              obligation, to remove material that allegedly violates someone's
              copyright. We respect others' intellectual property rights, and
              reserve the right to delete or disable Content alleged to be
              infringing, and to terminate the accounts of repeat alleged
              infringers. As a user, you agree to indemnify SFS and hold it
              harmless as to any claims or lawsuits arising out of such
              copyright infringement regarding content you have provided. SFS is
              not responsible for any content posted by the users.
            </p>
            <h1>Third party service providers</h1>
            <p>
              The website(s) and Services may contain links to third party
              websites, products or services that are not owned or controlled by
              SFS ("Third Party Services"). When you access Third Party
              Services, you do so at your own risk. SFS encourages you to be
              aware when you leave its website(s) and to read the terms,
              conditions and privacy policy of each Third-Party Website or
              Service that you visit or use. SFS has no control over, and
              assumes no responsibility for, the content, accuracy, privacy
              policies, or practices of or opinions expressed in any Third-Party
              Services. In addition, SFS will not and cannot monitor, verify,
              censor or edit the content of any Third-Party Services. By using
              the Services, you expressly relieve and hold harmless SFS from any
              and all liability arising from your use of any Third-Party
              Services. Your interactions with organizations and/or individuals
              found on or through the Services, including payment and delivery
              of services, and any other terms, with such dealings, are solely
              between you and such organizations and/or individuals. You should
              make whatever investigation you feel necessary or appropriate
              before proceeding with any online or offline transaction with any
              third parties. You agree that SFS will not be responsible or
              liable for any loss or damage of any sort incurred as the result
              of any such dealings. If there is a dispute between SFS users, or
              between SFS users and any third party, you understand and agree
              that SFS is under not obligation to become involved. In the event
              that you have a dispute with one or more other users, you hereby
              release SFS, its officers, employees, agents, consultants and
              successors in rights from claims, demands, and damages (actual and
              consequential) of every kind or nature, known or unknown,
              suspected or unsuspected, disclosed or undisclosed, arising out of
              or in any way related to such disputes and/or the Services. If you
              are a California resident, you will and hereby do waive California
              Civil Code Section 1542, which says: "A general release does not
              extend to claims which the creditor does not know or suspect to
              exist in his favor at the time of executing the release, which, if
              known by him must have materially affected his settlement with the
              debtor." While using SFS, you may be able to access or use third
              party services that aren't part of our product (such as YouTube,
              Flickr, etc.). SFS shall not be responsible for the content of
              services or any issues that arise.
            </p>
            <p>
              <h1>Warranty and disclaimer</h1>
            </p>
            <p>
              TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, IN NO EVENT WILL
              SFS OR ITS SUPPLIERS OR SERVICE PROVIDERS, OR THEIR RESPECTIVE
              OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE WITH RESPECT TO
              THE SERVICES OR THE SUBJECT MATTER OF THIS AGREEMENT UNDER ANY
              CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY, DEFAMATION, OR OTHER
              LEGAL OR EQUITABLE THEORY: 1. FOR ANY AMOUNT IN THE AGGREGATE IN
              EXCESS OF THE GREATER OF $100 OR THE FEES PAID BY YOU TO SFS
              HEREUNDER DURING THE 12-MONTH PERIOD PRECEDING THE APPLICABLE
              CLAIM; 2 FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL
              DAMAGES OF ANY KIND WHATSOEVER; 3. FOR DATA LOSS OR COST OF
              PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; OR 4. FOR ANY MATTER
              BEYOND SFS REASONABLE CONTROL SOME STATES DO NOT ALLOW THE
              EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
              THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
            </p>
            <p>
              SFS is a communication tool. While we are here to support you, we
              are not liable for anything that happens because of our Services.
            </p>
            <h1>Termination</h1>
            <p>
              This Agreement will remain in full force and effect while you use
              the Services. You may terminate your use of the Services or your
              account at any time by deleting your account in your account
              settings tab or by contacting us at xxx. If you become a user
              through an Organization, and you request that SFS delete your
              account, SFS may notify the Organization (and the School
              Administrators of any classes or groups you are in) of your
              deletion request. SFS may disable or terminate your access to the
              Services or your account at any time, for any reason (for your
              violation of any term of this Agreement or without cause), and
              without warning or notice, which may result in the forfeiture of
              all information associated with your account. Upon termination of
              your account, your right to use the Services and any Content will
              immediately cease; however, SFS may retain messaging data or other
              data and information pursuant to this Agreement, as necessary for
              auditing purposes, and as required by law. All provisions of this
              Agreement which, by their nature, should survive termination, will
              survive termination, including, without limitation, ownership
              provisions, warranty disclaimers, and limitations of liability.
              You can delete your SFS account online at any time.
            </p>
            <p></p>
            <h1>Choice of law and arbitration</h1>
            <p>
              This Agreement will be governed by and construed in accordance
              with the laws of the State of New Jersey, U.S.A. without regard to
              the conflict of laws provisions thereof. Any dispute arising from
              or relating to the subject matter of this Agreement will be
              resolved by arbitration in the State of New York, County of New
              York, in accordance with the Commercial Arbitration Rules of the
              American Arbitration Association.
            </p>
            <h1>Miscellaneous</h1>
            <p>
              The failure of either party to exercise, in any respect, any right
              provided for herein will not be deemed a waiver of any further
              rights hereunder. SFS will not be liable for any failure to
              perform its obligations hereunder where such failure results from
              any cause beyond its reasonable control, including, without
              limitation, mechanical, electronic or communications failure or
              degradation. If any provision of this Agreement is found to be
              unenforceable or invalid, that provision will be limited or
              eliminated to the minimum extent necessary so that this Agreement
              will otherwise remain in full force and effect. This Agreement is
              not assignable, transferable or sublicensable by you except with
              SFS' prior written consent. SFS may transfer, assign or delegate
              this Agreement and its rights and obligations without your
              consent. Both parties agree that this Agreement is the complete
              and exclusive statement of the mutual understanding of the parties
              and supersedes and cancels all previous written and oral
              agreements, communications and other understandings relating to
              the subject matter of this Agreement, and that all modifications
              must be in a writing signed by both parties, except as otherwise
              provided herein. This Agreement and any subsequent versions of
              this Agreement posted to the SFS website(s) will be deemed
              accepted upon use thereafter by you. No agency, partnership, joint
              venture, or employment relationship is created as a result of this
              Agreement. You do not have any authority of any kind to bind SFS
              in any way whatsoever.
            </p>
            <h1>Severability</h1>
            <p>
              If any provision of the Agreement is held by a court of competent
              jurisdiction or arbitral tribunal to be unenforceable under
              applicable law, then such provision shall be excluded from this
              Agreement and the remainder of the Agreement shall be interpreted
              as if such provision were so excluded and shall be enforceable in
              accordance with its terms; provided however that, in such event,
              the Agreement shall be interpreted so as to give effect, to the
              greatest extent consistent with and permitted by applicable law,
              to the meaning and intention of the excluded provision as
              determined by such court of competent jurisdiction or arbitral
              tribunal.
            </p>
            <h1>Subscription services</h1>
            <p>
              You agree to notify SFS immediately, in writing or by mail to ______________________ or
              by calling SFS customer care on ______________________ if your content is stolen or if
              you become aware at any time that your account with any Service is
              being misused or being used fraudulently. When you call or write,
              you must provide your account details and a detailed description
              of the circumstances of the theft or fraudulent use of the
              Services. Failure to do so promptly or within a reasonably prompt
              time period after discovery of the improper use may result in the
              termination of your Services and additional charges to you. You
              will be liable for all use of the Services if your account is
              misused and also for any and all stolen Services or fraudulent use
              of the Service. Notwithstanding anything herein to the contrary,
              SFS shall not be liable to extend the subscription period or
              waive-off any fees on account of such theft or fraudulent use.
              This includes, but is not limited to, modem hijacking, wireless
              hijacking, or other fraud arising out of a failure of your
              internal or corporate security procedures. SFS will not issue
              refunds for fraudulent use resulting from your negligent or wilful
              acts or those of an authorized user of your Services.
            </p>
          </div>
        </div>
      </div>
      <FooterContent></FooterContent>
    </React.Fragment>
  );
};

export default TermsAndConditions;
