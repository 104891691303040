import React, { useState, useEffect, useRef } from "react";
// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import Constants from "../configs/Constants";
import Config from "../configs/Config";
import { NavLink } from "react-bootstrap";
import FooterContent from "./FooterContent";
import EventEmitters from "../configs/EventEmitters";
import moment from "moment";
import { Modal, ListGroup, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import Compress from "compress.js";

import edit_icon from "../assets/img/edit_pencil.png";
import PersonalEdit from "./PersonalEdit";
const Profile = (props) => {
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const [profileData, setProfileData] = React.useState({});
  const [loginDetails, setLoginDetails] = React.useState({});
  const [isSameUser, setIsSameUser] = React.useState(true);
  const [cvData, setCvData] = React.useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [perosonalEditPopup, setperosonalEditPopup] = React.useState(false);
  const [specialityGroupPopup, setSpecialityGroupPopup] = React.useState(false);
  const [membershipAssociationPopup, setmembershipAssociationPopup] =
    React.useState(false);
  const [medicalRegistrationPopup, setmedicalRegistrationPopup] =
    React.useState(false);
  const [additionalQualificationsPopup, setadditionalQualificationsPopup] =
    React.useState(false);
  const [avatar, setAvatar] = React.useState(null);

  // const Compress = require("Compress.js");
  // const Compress = new Compress();

  const handleShowModalHide = (elm) => {
    setShowModalPopup(false);
  };
  const handleShowModalShow = (elm) => {
    setShowModalPopup(true);
  };
  const handleShowHide = (elm) => {
    setShowPopup(false);
  };
  const handleShowShow = (elm) => {
    setShowPopup(true);
  };

  React.useEffect(() => {
    // console.log("props>>>>>>>>>>>>", props);
    const { params } = props.match;
    const id = Config.getSessionInfo("id");
    const loginData = Config.getSessionInfo();
    if (loginData && loginData.is_all_details_filled == 0) {
      // toast({
      //     type: "success",
      //     message: "Please complete the registration first."
      // });
      toast.warn("Please complete the registration first!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      props.history.push(Constants[loginData.till_where_filled + 1]);
    } else {
      // setIsSameUser(true);
    }
    setIsSameUser(params.id == id);
    setLoginDetails(loginData);
    getProfileDetails(params.id);
  }, [props]);

  const getProfileDetails = (id) => {
    axios.post(Constants.getProfileDetails, { id }).then((response) => {
      if (response.data && response.data.result) {
        setProfileData(response.data.result);

        setCvData(
          response.data.result &&
            response.data.result.profile &&
            response.data.result.profile.cv
        );
      } else {
        setProfileData([]);
        setCvData(null);
      }
    });
  };

  const chatNowUser = (elm) => {
    const { params } = props.match;
    axios
      .post(Constants.chatRoomSolo, {
        senderId: loginDetails.id,
        reciverId: params.id,
      })
      .then((response) => {
        props.history.push("/chat");
      });
  };

  const logoutUser = () => {
    window.sessionStorage.clear();
    window.location.href = "//" + window.location.host;
  };
  // document.querySelectorAll('img').addEventListener('contextmenu',(e)=>{
  //   e.preventDefault();
  // })
  const deleteAccount = () => {
    handleShowModalShow();
    // if (window.confirm("Are you sure you want to Delete the SFS Account?")) {
    //     const { params } = props.match;
    //     axios.post(Constants.deleteAccount, {
    //         id: loginDetails.id
    //     }).then((response) => {
    //         logoutUser();
    //         // props.history.push('/home');
    //     });
    // }
  };

  const onSubmitDeleteAccountAPI = (data) => {
    if (window.confirm("Are you sure you want to Delete the SFS Account?")) {
      const { params } = props.match;
      axios
        .post(Constants.deleteAccount, {
          id: loginDetails.id,
          reason: data.reason,
        })
        .then((response) => {
          handleShowModalHide();
          let tost = {
            message: "Your account has been deleted successfully",
            type: "success",
          };
          EventEmitters.showTost(tost);
          logoutUser();
          // props.history.push('/home');
        });
    }
  };

  function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  const deleteCv = () => {
    // alert("hello")
    if (window.confirm("Are you sure you want to Delete CV ?")) {
      const config = {
        headers: {
          id: Config.getSessionInfo("id"),
        },
      };

      axios
        .post(Constants.deleteCv, config)
        .then((response) => {
          // console.log(response)
          setCvData(null);
          // const { params } = props.match
          // getProfileDetails(params.id);
          // console.log("here it ends")
          if (response.data.code == 200) {
            console.log("true");
          }

          // console.log("session information>>>>>>>>>",Config.getSessionInfo());
        })
        .catch((e) => {
          console.error(e);
        });
      // }
    }
  };

  const updateProfile = () => {
    try {
      let file = avatar;
      if (file == null) {
        EventEmitters.showTost({
          type: "error",
          message: "Please select an image ",
        });
        return false;
      }
      // // console.log(file[0])
      // // event.target && event.target.files[0];
      var t = file[0].type.split("/").pop().toLowerCase();
      if (t != "jpeg" && t != "jpg" && t != "png" && t != "bmp" && t != "gif") {
        EventEmitters.showTost({
          type: "error",
          message: "Please Select Valid Image type",
        });
        // document.getElementById("img").value = '';
        return false;
      }

      const files = [...file];

      const formData=new FormData()
      formData.append("profile_pic",file[0])

      const config = {
        headers: {
          id: Config.getSessionInfo("id"),
        },
      };

      axios
          // .post('http://localhost:3001/uploadProfilePic', formData, config)
          .post(Constants.uploadProfilePic, formData, config)
          .then((response) => {
            console.log("response of profile_pic>>", response);
            console.log("here it starts");
            const { params } = props.match;
            getProfileDetails(params.id);
            console.log("here it ends");
            if (response.data.code == 200) {
              console.log("in the loop");
            }

            console.log(
              "session information>>>>>>>>>",
              Config.getSessionInfo()
            );
          })
          .catch((e) => {
            console.error(e);
          });
    } catch (error) {
      console.log(error)
    }
  };

  const onChangeUploadFile = (e) => {
    const file = e.target && e.target.files[0];
    e.target.value = null;
    if (file) {
      const formData = new FormData();
      formData.append("cv", file);
      const config = {
        headers: {
          id: Config.getSessionInfo("id"),
        },
      };
      axios
        .post(Constants.uploadCv, formData, config)
        .then((response) => {
          console.log("response>>>>>>>>>>", response);
          setCvData(null);
          setCvData(response.data.fileInfo);

          // setProfilePic(Constants.server + response.data.fileInfo);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
  const getName = (profileData) => {
    if (profileData && profileData.profile) {
      if (profileData.profile.first_name != null) {
        return (
          profileData.profile.first_name + " " + profileData.profile.last_name
        );
      } else {
        return profileData.profile.full_name;
      }
    }
  };
  // console.log("****** Profile Data *******");
  // console.log(profileData);

  const showNewModal = () => {
    setShowModal(true);
  };

  const hideNewModal = () => {
    setShowModal(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="content">
        {profileData && profileData.profile ? (
          <div className="container" style={{ float: "none" }}>
            <div className="card">
              <div className="card-body">
                <div className="doctor-widget">
                  <div className="doc-info-left">
                    <div className="doctor-img">
                      <img ///profileData.profile.display_pic
                        src={
                          Constants.server +
                          profileData.profile.display_pic +
                          `?${new Date().getTime()}`
                        }
                        className="img-fluid doctor-img-profile"
                        alt="User Image"
                        key={profileData.randomnumber}
                      />
                      {console.log(
                        "profileData.>>>>>>",
                        profileData.randomnumber
                      )}

                      {/* <a onClick={handleShowShow}> */}
                      {isSameUser && (
                        <span className="edit-text">
                          Edit &nbsp;
                          <img
                            src={edit_icon}
                            onClick={handleShowShow}
                            alt="Profile_edit"
                            className="profile-edit"
                            style={{ height: "15px", cursor: "pointer" }}
                          />
                        </span>
                      )}

                      {/* </a> */}
                    </div>
                    <div className="doc-info-cont">
                      <h4 className="doc-name">Dr.{getName(profileData)} </h4>
                      <p className="doc-speciality">
                        {profileData.qualification &&
                          profileData.qualification.specialty_type}
                      </p>

                      <div className="clinic-details">
                        <p className="doc-location">
                          <i className="fas fa-map-marker-alt"></i>{" "}
                          {profileData.profile.current_location}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="doc-info-right">
                    <div className="clini-infos"></div>
                    <div className="doctor-action">
                      <NavLink
                        disabled={isSameUser || !loginDetails}
                        onClick={chatNowUser}
                        className={"btn btn-white msg-btn"}
                      >
                        <i className="far fa-comment"></i> Connect Now
                      </NavLink>
                      {/* <a href="chat.html" className="btn btn-white msg-btn">
                                            <i className="fas fa-at"></i>
                                        </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body pt-0">
                <nav className="user-tabs mb-4">
                  <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        role="button"
                        data-toggle="tab"
                      >
                        Overview
                      </a>
                    </li>
                  </ul>
                </nav>

                <div className="tab-content pt-0">
                  <div
                    role="tabpanel"
                    id="doc_overview"
                    className="tab-pane fade show active"
                  >
                    <div className="row">
                      <div className="col-md-12 col-lg-9">
                        <div className="widget education-widget">
                          <h4 className="widget-title">
                            Personal Details&nbsp;
                            {isSameUser && (
                              <img
                                src={edit_icon}
                                alt="Profile_edit"
                                className="profile-edit"
                                style={{ height: "15px", cursor: "pointer" }}
                                onClick={() => {
                                  showNewModal();
                                  setperosonalEditPopup(true);
                                  setmembershipAssociationPopup(false);
                                  setmedicalRegistrationPopup(false);
                                  setSpecialityGroupPopup(false);
                                  setadditionalQualificationsPopup(false);
                                }}
                              />
                            )}
                          </h4>

                          <div className="details-box">
                            {isSameUser ? (
                              <React.Fragment>
                                {profileData.profile.mobile && (
                                  <div className="row m-0">
                                    <i className="fa fa-phone-alt mr-3 mt-1" />
                                    <p>+{profileData.profile.mobile}</p>
                                  </div>
                                )}
                                {profileData.profile.email_id && (
                                  <div className="row m-0">
                                    <i className="fa fa-envelope mr-3 mt-1" />
                                    <p>{profileData.profile.email_id}</p>
                                  </div>
                                )}
                                {profileData.profile.dob
                                  ? profileData.profile.dob !==
                                      "0000-00-00" && (
                                      <div className="row m-0">
                                        <i className="fa fa-calendar mr-3 mt-1" />
                                        <p>
                                          {moment(
                                            profileData.profile.dob
                                          ).format("Do MMM YYYY")}
                                        </p>
                                      </div>
                                    )
                                  : ""}
                              </React.Fragment>
                            ) : (
                              <span></span>
                            )}
                            <React.Fragment>
                              {profileData.profile.current_location && (
                                <div className="row m-0">
                                  <i className="fa fa-map-pin mr-3 mt-1" />
                                  <p>{profileData.profile.current_location}</p>
                                </div>
                              )}
                            </React.Fragment>
                          </div>
                          <h4 className="widget-title">Education</h4>
                          <div className="experience-box">
                            <ul className="experience-list">
                              <li>
                                <div className="experience-user">
                                  <div className="before-circle"></div>
                                </div>
                                <div className="experience-content">
                                  <div className="timeline-content">
                                    <a
                                      role="button"
                                      className="name"
                                      style={{ color: "#000" }}
                                    >
                                      {" "}
                                      {"MBBS - " +
                                        profileData.qualification?.mbbs_city}
                                    </a>
                                    <div>
                                      {profileData.qualification &&
                                        profileData.qualification.mbbs_center}
                                    </div>
                                    <span className="time">
                                      {profileData.qualification &&
                                        profileData.qualification.mbbs_year}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              {profileData.qualification &&
                                profileData.qualification?.gs_center && (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle"></div>
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a
                                          role="button"
                                          className="name"
                                          style={{ color: "#000" }}
                                        >
                                          {profileData.qualification &&
                                            profileData.qualification
                                              ?.gs_center}
                                        </a>
                                        <div>
                                          {"General Surgery - " +
                                            profileData.qualification &&
                                            profileData.qualification?.gs_city}
                                        </div>
                                        <span className="time">
                                          {profileData.qualification &&
                                            profileData.qualification.gs_year}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              {profileData.qualification &&
                                profileData.qualification.specialty_type && (
                                  <li>
                                    <div className="experience-user">
                                      <div className="before-circle"></div>
                                    </div>
                                    <div className="experience-content">
                                      <div className="timeline-content">
                                        <a
                                          role="button"
                                          className="name"
                                          style={{ color: "#000" }}
                                        >
                                          {profileData.qualification &&
                                            profileData.qualification
                                              .specialty_center}
                                        </a>
                                        <div>
                                          {(profileData.qualification &&
                                            profileData.qualification
                                              .specialty_type) +
                                            "-" +
                                            (profileData.qualification &&
                                              profileData.qualification
                                                .specialty_city)}
                                        </div>
                                        <span className="time">
                                          {profileData.qualification &&
                                            profileData.qualification
                                              .specialty_year}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                )}
                            </ul>
                          </div>
                        </div>
                        <div className="service-list">
                          {isSameUser && (
                            <>
                              <h4>
                                Additional Speciality Group&nbsp;
                                <img
                                  src="https://img.icons8.com/glyph-neue/64/62b3fb/add.png"
                                  height="50px"
                                  alt="Profile_edit"
                                  className="profile-edit"
                                  style={{ height: "15px", cursor: "pointer" }}
                                  onClick={() => {
                                    setperosonalEditPopup(false);
                                    setmembershipAssociationPopup(false);
                                    setmedicalRegistrationPopup(false);
                                    setadditionalQualificationsPopup(false);
                                    setSpecialityGroupPopup(true);
                                    showNewModal();
                                  }}
                                />
                              </h4>

                              <ul className="clearfix">
                                {/* {console.log(!profileData.addGroup.filter((res)=>res.chat_room_center_name.includes("#")==true))}
                                                      {(profileData.addGroup.filter((res)=>res.chat_room_center_name.includes("#")==true).length > 0)?"" :<li>Be a part of more than one speciality group.</li> }
                          
                                                      {!profileData &&
                                                        !profileData.addGroup &&
                                                        <li>No data added</li>}
                                                      {typeof profileData.addGroup ===
                                                      "undefined" ? (
                                                        <li>No Data Added</li>
                                                      ) : profileData.addGroup
                                                           === "" ? (
                                                        <li>No Data Added</li>
                                                      ) : (
                                                        ""
                                                      )} */}
                                {profileData.specialtyGroups.length !== 0 ? (
                                  profileData?.specialtyGroups?.map((item) => (
                                    <li key={item.id}>
                                      {item.chat_room_center_name.slice(
                                        0,
                                        item.chat_room_center_name.length - 1
                                      )}
                                    </li>
                                  ))
                                ) : (
                                  <li>
                                    Be a part of more than one speciality group.
                                  </li>
                                )}
                                {/* {profileData &&
                                                        profileData.addGroup &&
                                                        profileData.addGroup.filter((res)=>res.chat_room_center_name.includes("#")==true).map((elm, idx) => {
                                                            return <li key={idx}>{elm.chat_room_center_name.slice(0,elm.chat_room_center_name.length-1)}</li>;
                                                          })} */}
                              </ul>
                            </>
                          )}
                        </div>
                        <div className="service-list">
                          <h4>
                            Membership of Association&nbsp;
                            {isSameUser && (
                              <img
                                src={edit_icon}
                                alt="Profile_edit"
                                className="profile-edit"
                                style={{ height: "15px", cursor: "pointer" }}
                                onClick={() => {
                                  setperosonalEditPopup(false);
                                  setmembershipAssociationPopup(true);
                                  setmedicalRegistrationPopup(false);
                                  setadditionalQualificationsPopup(false);
                                  setSpecialityGroupPopup(false);
                                  showNewModal();
                                }}
                              />
                            )}
                          </h4>

                          <ul className="clearfix">
                            {!profileData &&
                              !profileData.membershipAssociation &&
                              !profileData.membershipAssociation
                                .association && <li>No data added</li>}
                            {typeof profileData.membershipAssociation ===
                            "undefined" ? (
                              <li>No Data Added</li>
                            ) : profileData.membershipAssociation
                                .association === "" ? (
                              <li>No Data Added</li>
                            ) : (
                              ""
                            )}
                            {profileData &&
                              profileData.membershipAssociation &&
                              profileData.membershipAssociation.association
                                .split(",")
                                .map((elm, idx) => {
                                  return <li key={idx}>{elm}</li>;
                                })}
                          </ul>
                        </div>

                        {isSameUser && (
                          <div className="service-list">
                            <h4>
                              Medical Registration&nbsp;
                              {isSameUser && (
                                <img
                                  src={edit_icon}
                                  alt="Profile_edit"
                                  className="profile-edit"
                                  style={{ height: "15px" }}
                                  onClick={() => {
                                    setperosonalEditPopup(false);
                                    setmembershipAssociationPopup(false);
                                    setadditionalQualificationsPopup(false);
                                    setmedicalRegistrationPopup(true);
                                    setSpecialityGroupPopup(false);
                                    showNewModal();
                                  }}
                                />
                              )}
                            </h4>
                            {console.log(
                              "this is hsi",
                              profileData.mediRegistration
                            )}
                            <ul className="clearfix">
                              {typeof profileData.mediRegistration
                                .mediRegistrationCountry === "undefined" ? (
                                <li>No data added</li>
                              ) : profileData.mediRegistration
                                  .mediRegistrationCountry == "" ? (
                                <li>No Data added</li>
                              ) : (
                                profileData.mediRegistration &&
                                profileData.mediRegistration.mediRegistrationCountry.map(
                                  (elm, idx) => {
                                    return (
                                      <li key={idx}>
                                        {elm +
                                          " - " +
                                          profileData.mediRegistration
                                            .mediRegistrationState[idx] +
                                          "-" +
                                          profileData.mediRegistration
                                            .mediRegistrationNumber[idx]}
                                      </li>
                                    );
                                  }
                                )
                              )}
                            </ul>
                          </div>
                        )}

                        <div className="service-list">
                          <h4>
                            Additional Qualifications&nbsp;
                            {isSameUser && (
                              <img
                                src={edit_icon}
                                alt="Profile_edit"
                                className="profile-edit"
                                style={{ height: "15px", cursor: "pointer" }}
                                onClick={() => {
                                  setadditionalQualificationsPopup(true);
                                  setmedicalRegistrationPopup(false);
                                  setmembershipAssociationPopup(false);
                                  setperosonalEditPopup(false);
                                  setSpecialityGroupPopup(false);
                                  setShowModal(true);
                                }}
                              />
                            )}
                          </h4>

                          <ul className="clearfix">
                            <li>
                              {profileData.qualification
                                ?.additionalQualifications || "NA"}
                            </li>
                          </ul>
                        </div>

                        <div className="service-list">
                          <h4>CV</h4>
                          {isSameUser ? (
                            <React.Fragment>
                              <ul className="clearfix">
                                <div>
                                  <label>
                                    Update CV{" "}
                                    <small>(Format type : .pdf)</small>
                                  </label>
                                </div>
                                <input
                                  type="file"
                                  className="upload"
                                  accept="application/pdf"
                                  onChange={onChangeUploadFile}
                                />
                                {!cvData ? null : (
                                  <button
                                    type="submit"
                                    class="btn btn-link"
                                    style={{ color: "#316d9c" }}
                                    onClick={deleteCv.bind(this)}
                                  >
                                    Remove CV
                                  </button>
                                )}
                              </ul>
                              <hr />
                            </React.Fragment>
                          ) : null}

                          {cvData ? (
                            <embed
                              src={Constants.server + cvData}
                              width="100%"
                              height="600"
                              type="application/pdf"
                            ></embed>
                          ) : (
                            <div>No CV uploaded</div>
                          )}
                        </div>
                        {isSameUser ? (
                          <div
                            className="service-list"
                            style={{ display: "flex" }}
                          >
                            <button
                              className="btn btn-link"
                              style={{ color: "#316d9c" }}
                              onClick={deleteAccount.bind(this)}
                            >
                              {" "}
                              Delete Account{" "}
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PersonalEdit
              show={showModal}
              perosonalEditPopup={perosonalEditPopup}
              hide={hideNewModal}
              membershipAssociationPopup={membershipAssociationPopup}
              medicalRegistrationPopup={medicalRegistrationPopup}
              additionalQualificationsPopup={additionalQualificationsPopup}
              profile={profileData}
              getProfileDetailsFun={getProfileDetails}
              match={props.match}
              specialityGroupPopup={specialityGroupPopup}
            />
          </div>
        ) : null}
      </div>

      <Modal show={showPopup} onHide={handleShowHide}>
        <Modal.Header closeButton>
          <Modal.Title> Update Profile Picture </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <form onSubmit={handleSubmit(onUpdateProfile)}> */}

          <div class="form-group row">
            <div class="col-md-8">
              <input
                type="file"
                id="profilepic"
                class="form-control"
                ref={register({ required: false })}
                name="reason"
                required
                accept="image/*"
                onChange={(ev) => {
                  setAvatar(ev.target.files);
                }}
              />
              <p style={{ color: "#1681b1" }}>
                Files supported : .jpg, .png and .jpeg
              </p>
            </div>
          </div>

          <div class="col-md-4 offset-md-4">
            <center>
              <button
                type="submit"
                class="btn btn-primary"
                onClick={() => {
                  handleShowHide();
                  updateProfile();
                  const { params } = props.match;
                  getProfileDetails(params.id);
                  // window.location.reload( );
                }}
              >
                Save
              </button>
            </center>
          </div>
          {/* </form> */}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showModalPopup} onHide={handleShowModalHide}>
        <Modal.Header closeButton>
          <Modal.Title> Delete account. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item>
              Are you sure? This will permanently delete your SFS account. Once
              the deletion process begins, you won't be able to reactivate your
              account or retrieve any data or information.
            </ListGroup.Item>
          </ListGroup>
          <hr />
          <form
            onSubmit={handleSubmit(onSubmitDeleteAccountAPI)}
            noValidate
            autoComplete="off"
          >
            <div class="form-group row">
              <label
                for="email_address"
                class="col-md-2 col-form-label text-md-right"
              >
                Reason
              </label>
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  name="reason"
                  required
                  ref={register({ required: true })}
                />
                {errors.reason && (
                  <div className="error-text" role="alert">
                    Reason is required.
                  </div>
                )}
              </div>
            </div>

            <div class="col-md-6 offset-md-4">
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {profileData && profileData.profile ? (
        <FooterContent></FooterContent>
      ) : null}
    </React.Fragment>
  );
};

export default Profile;
