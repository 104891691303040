import React, { Fragment, useEffect, createRef } from "react";
import firstImage from "../assets/img/Final_Doccure_banner.jpg";
import secondImage from "../assets/img/DC_Images01.png";
import thiredImage from "../assets/img/DC_Images02.png";
import fourthImage from "../assets/img/DC_Images03.png";
import specialitiesImage from "../assets/img/1.png";
import specialitiesTwoImage from "../assets/img/3.png";
import specialitiesThreeImage from "../assets/img/2.png";
import specialitiesFourthImage from "../assets/img/4.png";
import blogOneImage from "../assets/img/blog/blog-01.jpg";
import blogTwoImage from "../assets/img/blog/blog-02.jpg";
import blogThreeImage from "../assets/img/blog/blog-03.jpg";
import blogFourthImage from "../assets/img/blog/blog-04.jpg";
import doctorThumbImage from "../assets/img/doctors/doctor-thumb-01.jpg";
import doctorThumbTwoImage from "../assets/img/doctors/doctor-thumb-02.jpg";
import doctorThumbThreeImage from "../assets/img/doctors/doctor-thumb-03.jpg";
import doctorThumbFourthImage from "../assets/img/doctors/doctor-thumb-04.jpg";
import { NavLink, useLocation } from "react-router-dom";
// import HeaderContent from './HeaderContent';
import FooterContent from "./FooterContent";
import { Carousel } from "react-bootstrap";
// import { useLocation } from 'react-router';

const Home = (props) => {
  const featureSection = createRef();
  const howWeWorkSection = createRef();
  const blogsSection = createRef();
  const location = useLocation();

  useEffect(() => {
    const { sectionName } = props.match.params;
    const { state } = props.location;
    let behavior = true;
    console.log("sectionName>>>>>>>>>>>>>.", sectionName);
    if (state && state.indexOf("/home") > -1) {
      behavior = { block: "start", behavior: "smooth" };
    }
    switch (sectionName) {
      case "features":
        featureSection.current &&
          featureSection.current.scrollIntoView(behavior);
        break;
      case "howWework":
        howWeWorkSection.current &&
          howWeWorkSection.current.scrollIntoView(behavior);
        break;
      case "blogs":
        blogsSection.current && blogsSection.current.scrollIntoView(behavior);
        break;
      default:
        break;
    }
    // if (sectionName === "features") {
    //     console.log(">>>>>>>>>>>>>.", featureSection);
    //     // setTimeout(function (featureSection) {
    //     // debugger;
    //     featureSection.current && featureSection.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    //     // }.bind(this, featureSection), 500);
    // }
  }, [location.pathname]);

  

  return (
    <Fragment>
      {/* <HeaderContent></HeaderContent> */}
      <div>
        <section className="section section-search">
          <div className="container-fluid">
            <div className="banner-wrapper">
              <div className="banner-header text-center">
                {/* <h1 style={{ color: '#fff', textTransform: 'unset' }}>Surgeons For India</h1> */}
              </div>
            </div>
          </div>
        </section>

        <section className="section section-features">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5 features-img">
                <img
                  src={firstImage}
                  style={{ maxWidth: "100%" }}
                  className="img-fluid"
                  alt="Feature"
                />
              </div>
              <div className="col-md-7">
                <div className="section-header">
                  <h6 style={{ color: "#0d91d0" }}> INTRODUCTION </h6>
                  <h2 className="mt-2">
                    A Network of Surgeons, by Surgeons, for Surgeons
                  </h2>
                  <div>
                    <p>
                      Surgeons for Surgeons (SFS) is a network aimed at building
                      a world-wide surgical community.
                    </p>
                    <p>
                      SFS will give you access to the best surgical brains in
                      the world through an information-sharing chat platform.
                    </p>
                    <p>
                      You will be able discuss many issues with the entire
                      surgical community at your fingertips.
                    </p>
                    <p>
                      You will have an opportunity to network with surgeons from
                      your alma mater, training institutions, specialties, as
                      well as your own or different geographical locations.
                    </p>
                    <p>
                      Some of our unique features include chat groups, video
                      portals, how-I-do-its, seminars, live events, and more.
                    </p>
                    <p>We welcome surgeons from all countries to join us.</p>
                    {/* <p>
                                            <strong>
                                                "Surgeons for Surgeons" has been created by a group of world-renowned surgeons from various countries. The founding members have colleagues, peers, mentors and mentees all over the world. They cherish these connections and feel very grateful and privileged to have them. They conceived "Surgeons for Surgeons" to help surgeons all over the world create and maintain connections.
                                            </strong>
                                        </p> */}
                    {/* <p>
                                            We aim to create a platform, which would be user friendly, and provide some unique features like:
                                        </p>
                                        <p>
                                            World- wide connections
                                        </p> */}
                  </div>
                </div>
                {/* <div className="view-all">
                                    <NavLink className="btn btn-primary" to="/about">
                                        Know More
                                </NavLink>
                                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section-specialities"
          id="features"
          ref={featureSection}
        >
          <div className="container-fluid">
            <div className="section-header text-center">
              <h2 className="mt-2">Features</h2>
              <p className="sub-title">
                Surgeons for Surgeons offers a host of unique and relevant
                features.
              </p>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 col-lg-12 col-sm-12">
                <center>
                  <div className="specialities ">
                    <div className="col-lg-3 strong features-img">
                      <div className="speicality-img">
                        <img
                          src={specialitiesImage}
                          className="img-fluid"
                          alt="Speciality"
                        />
                        <span>
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </span>
                      </div>
                      <p className="para">
                        Inclusive community of licensed surgeons across the USA,
                        UK, Europe, Africa, Asia, Caribbean, Middle East and
                        Australasia
                      </p>
                    </div>

                    <div className="col-lg-3 strong features-img">
                      <div className="speicality-img">
                        <img
                          src={specialitiesTwoImage}
                          className="img-fluid"
                          alt="Speciality"
                        />
                        <span>
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </span>
                      </div>
                      <p className="para">
                        Groups based on Alma Mater and Specialization{" "}
                      </p>
                    </div>

                    <div className="col-lg-3 strong features-img">
                      <div className="speicality-img">
                        <img
                          src={specialitiesThreeImage}
                          className="img-fluid"
                          alt="Speciality"
                        />
                        <span>
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </span>
                      </div>
                      <p className="para">
                        Peer to Peer (One to One) communication{" "}
                      </p>
                    </div>
                    <div className="col-lg-3 strong features-img">
                      <div className="speicality-img">
                        <img
                          src={specialitiesFourthImage}
                          className="img-fluid"
                          alt="Speciality"
                        />
                        <span>
                          <i className="fa fa-circle" aria-hidden="true"></i>
                        </span>
                      </div>
                      <p className="para">
                        Texts and document sharing facility
                      </p>
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </section>

        <section
          id="howWework"
          className="section section-features"
          style={{ backgroundColor: "#f8f9fa" }}
          ref={howWeWorkSection}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 features-img">
                <div className="section-header">
                  <h6 style={{ color: "#0d91d0" }}>HOW WE WORK? - STEP 1:</h6>
                  <h2 className="mt-2">Sign up/Complete Profile.</h2>
                  <ul>
                    <li>
                      Welcome aboard! Please complete the basic formalities to
                      enroll yourself as a member of Surgeons for Surgeons.
                    </li>
                    <li>
                      Kindly help us with the relevant information that includes
                      your alma mater, specialty and other information as
                      requested in the sign-up form.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 features-img">
                <img
                  src={secondImage}
                  style={{ maxWidth: "100%" }}
                  className="img-fluid"
                  alt="Feature"
                />
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section-features"
          style={{ backgroundColor: "#f8f9fa" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 features-img">
                <img
                  src={thiredImage}
                  style={{ maxWidth: "100%" }}
                  className="img-fluid"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 features-img">
                <div className="section-header">
                  <h6 style={{ color: "#0d91d0" }}>STEP 2:</h6>

                  <h2 className="mt-2">Groups </h2>

                  <ul>
                    <li>
                      Once your profile has been completed and verified by our
                      team, you will be automatically added into groups created
                      based on your alma mater, specialization/super
                      specialization.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="section section-features"
          style={{ backgroundColor: "#f8f9fa" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 features-img">
                <div className="section-header">
                  <h6 style={{ color: "#0d91d0" }}>STEP 3:</h6>
                  <h2 className="mt-2">Chat Groups</h2>
                  <ul>
                    <li>
                      As a member of the community, you will be able to
                      participate in group chats as well as have one-to-one
                      chats with other members.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 features-img">
                <img
                  src={fourthImage}
                  style={{ maxWidth: "100%" }}
                  className="img-fluid"
                  alt="Feature"
                />
              </div>
            </div>
          </div>
        </section>

        {/* <section id="blogs" className="section section-blogs" ref={blogsSection}>
                    <div className="container-fluid">
                        <div className="section-header text-center">
                            <h2>Blogs and News</h2>
                            <p className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div className="row blog-grid-row">
                            <div className="col-md-6 col-lg-3 col-sm-12">

                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        <a href="blog-details.html">
                                            <img className="img-fluid" src={blogOneImage}
                                                alt="Post Image" />
                                        </a>
                                    </div>
                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <a href="doctor-profile.html">
                                                        <img
                                                            src={doctorThumbImage} alt="Post Author" />
                                                        <span>Dr. Ruby Perrin</span></a>
                                                </div>
                                            </li>
                                            <li><i className="far fa-clock"></i> 4 Dec 2019</li>
                                        </ul>
                                        <h3 className="blog-title"><a href="blog-details.html">Doccure – Making your clinic painless
										visit?</a></h3>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6 col-lg-3 col-sm-12">

                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        <a href="blog-details.html">
                                            <img className="img-fluid" src={blogTwoImage}
                                                alt="Post Image" /></a>
                                    </div>
                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <a href="doctor-profile.html">
                                                        <img
                                                            src={doctorThumbTwoImage} alt="Post Author" />
                                                        <span>Dr. Darren Elder</span></a>
                                                </div>
                                            </li>
                                            <li><i className="far fa-clock"></i> 3 Dec 2019</li>
                                        </ul>
                                        <h3 className="blog-title"><a href="blog-details.html">What are the benefits of Online
                                        Doctor
										Booking?</a></h3>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6 col-lg-3 col-sm-12">

                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        <a href="blog-details.html"><img className="img-fluid" src={blogThreeImage}
                                            alt="Post Image" /></a>
                                    </div>
                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <a href="doctor-profile.html"><img
                                                        src={doctorThumbThreeImage} alt="Post Author" />
                                                        <span>Dr. Deborah Angel</span></a>
                                                </div>
                                            </li>
                                            <li><i className="far fa-clock"></i> 3 Dec 2019</li>
                                        </ul>
                                        <h3 className="blog-title"><a href="blog-details.html">Benefits of consulting with an Online
										Doctor</a></h3>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6 col-lg-3 col-sm-12">

                                <div className="blog grid-blog">
                                    <div className="blog-image">
                                        <a href="blog-details.html"><img className="img-fluid" src={blogFourthImage}
                                            alt="Post Image" /></a>
                                    </div>
                                    <div className="blog-content">
                                        <ul className="entry-meta meta-item">
                                            <li>
                                                <div className="post-author">
                                                    <a href="doctor-profile.html"><img
                                                        src={doctorThumbFourthImage} alt="Post Author" />
                                                        <span>Dr. Sofia Brient</span></a>
                                                </div>
                                            </li>
                                            <li><i className="far fa-clock"></i> 2 Dec 2019</li>
                                        </ul>
                                        <h3 className="blog-title"><a href="blog-details.html">5 Great reasons to use an Online
										Doctor</a></h3>
                                        <p className="mb-0">Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                                        eiusmod
									tempor.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="view-all text-center">
                            <a href="blog-list.html" className="btn btn-primary">View All</a>
                        </div>
                    </div>
                </section> */}
      </div>
      <FooterContent></FooterContent>
    </Fragment>
  );
};
export default Home;
