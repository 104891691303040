import moment from "moment";

const new_AWS_DEV = "https://aws-api.surgeonsforsurgeons.com/";
const new_AWS_PROD_WITH_SSL = 'https://aws-prod-api.surgeonsforsurgeons.com/'


const Constants = () => {
  // const server = new_AWS_DEV;
  const server = new_AWS_PROD_WITH_SSL;
  return {
    server,
    login: server + "login",
    signup: server + "signup",
    verify: server + "verify",
    personal: server + "personal",
    qualification: server + "qualification",
    updateAdditionalQualification: server + "updateAdditionalQualification",
    mediRegistration: server + "mediRegistration",
    updateMediRegistration: server + "updateMediRegistration",
    searchCityDetails: server + "searchCityDetails",
    getCityDetailsByCountryId: server + "getCityDetailsByCountryId",
    getNotificationData: server + "getNotificationData",
    searchMedicalSchools: server + "searchMedicalSchools",
    membershipAssociation: server + "membershipAssociation",
    updatemembershipAssociation: server + "updatemembershipAssociation",
    searchRegionByCountry: server + "searchRegionByCountry",
    uploadPic: server + "uploadPic",
    uploadProfilePic: server + "uploadProfilePic",
    chatRooms: server + "chatRooms",
    updateChatOtherRooms: server + "updateChatOtherRooms",
    sendChatMessage: server + "sendChatMessage",
    getChatMessages: server + "getChatMessages",
    uploadChatFile: server + "uploadChatFile",
    getChatRoomDetails: server + "getChatRoomDetails",
    chatRoomSolo: server + "chatRoomSolo",
    chatFilePath: server + "chat/",
    getProfileDetails: server + "getProfileDetails",
    unReadCount: server + "unReadCount",
    deleteMsg: server + "deleteMsg",
    deleteCv: server + "deleteCv",
    subscribe: server + "subscribe",
    leaveGroup: server + "leaveGroup",
    deleteAccount: server + "deleteAccount",
    uploadCv: server + "uploadCv",
    sendOtp: server + "sendOtp",
    verifyOtp: server + "verifyOtp",
    addCommunityPost: server + "addCommunityPost",
    getCommunityPost: server + "getCommunityPost",
    editCommunityPost: server + "editCommunityPost",
    editBeyondPost: server + "editBeyondPost",
    addCommunityPostLike: server + "addCommunityPostLike",
    getCommunityPostLike: server + "getCommunityPostLike",
    addCommunityPostAddComment: server + "addCommunityPostAddComment",
    getCommentForCommunityId: server + "getCommentForCommunityId",
    deleteCommentForCommunityId: server + "deleteCommentForCommunityId",
    deleteCommentForBeyondSurgeryId: server + "deleteCommentForBeyondSurgeryId",
    addCommunityPostToCollection: server + "addCommunityPostToCollection",
    addCommunityReportReason: server + "addCommunityReportReason",
    addCommunityNonRegister: server + "addCommunityNonRegister",
    addFriendRequestCommunity: server + "addFriendRequestCommunity",
    getCommentFriendRequestList: server + "getCommentFriendRequestList",
    getCommunityCollection: server + "getCommunityCollection",
    deleteCommunityPost: server + "deleteCommunityPost",
    getFriendRequestCommunity: server + "getFriendRequestCommunity",
    getFriendListCommunity: server + "getFriendListCommunity",
    getCommunityFriendRequestList: server + "getCommunityFriendRequestList",
    updateFriendList: server + "updateFriendList",
    cancleFriendList: server + "cancleFriendList",
    addOpportunities: server + "addOpportunities",
    getOpportunities: server + "getOpportunities",
    updatePersonalDetailFirst: server + "updatePersonalDetailFirst",
    addApplyNow: server + "addApplyNow",
    getOpportunitiesById: server + "getOpportunitiesById",
    getOpportunitiesResponseById: server + "getOpportunitiesResponseById",
    handleApplicationResponse: server + "handleApplicationResponse",
    addBeyondSurgoryPost: server + "addBeyondSurgoryPost",
    getBeyondSurgoryPost: server + "getBeyondSurgoryPost",
    getCommentForBeyondSurgoryId: server + "getCommentForBeyondSurgoryId",
    addBeyondSurgoryPostAddComment: server + "addBeyondSurgoryPostAddComment",
    addBeyondSurgoryPostLike: server + "addBeyondSurgoryPostLike",
    addBeyondSurgoryPostToCollection:
      server + "addBeyondSurgoryPostToCollection",
    getFriendListBeyondSurgory: server + "getFriendListBeyondSurgory",
    addBeyondSurgoryReportReason: server + "addBeyondSurgoryReportReason",
    deleteCommunityPostBeyondSurgory:
      server + "deleteCommunityPostBeyondSurgory",
    addFriendRequestBeyondSurgory: server + "addFriendRequestBeyondSurgory",
    updateFriendListBeyondSurgory: server + "updateFriendListBeyondSurgory",
    getCommentFriendRequestListBeyondSurgory:
      server + "getCommentFriendRequestListBeyondSurgory",
    getBeyondSurgoryFriendRequestList:
      server + "getBeyondSurgoryFriendRequestList",
    cancleFriendListBeyondSurgory: server + "cancleFriendListBeyondSurgory",
    unFollowFriendRequestBeyondSurgory:
      server + "unFollowFriendRequestBeyondSurgory",
    getBeyondSurgoryCollection: server + "getBeyondSurgoryCollection",
    getLikeListOfBeyondSurgory: server + "getLikeListOfBeyondSurgory",
    searchUserDetails: server + "searchUserDetails",
    searchUserDetailsWithFilter: server + "searchUserDetailsWithFilter",
    loadInitUserSearchData: server + "loadInitUserSearchData",
    loadUserDataById: server + "loadUserDataById",
    getLikeListOfCommunity: server + "getLikeListOfCommunity",
    getAllCountries: server + "getAllCountries",
    getAllCities: server + "getAllCities",
    changePassword: server + "changePassword",
    deleteOpportunityPosted: server + "deleteOpportunityPosted",
    deleteChatRommsSolo: server + "deleteChatRommsSolo",
    updateUnReadCount: server + "updateUnReadCount",
    // clinical case endpoints
    getAllClinicalCases: server + "getAllClinicalCases",
    createClinicalCase: server + "createClinicalCase",
    likeClinicalCase: server + "likeClinicalCase",
    dislikeClinicalCase: server + "dislikeClinicalCase",
    deleteClinicalCaseById: server + "deleteClinicalCaseById",
    deleteClinicalComment: server + "deleteClinicalComment",
    addClinicalCaseCollection: server + "addClinicalCaseCollection",
    deleteClinicalCaseCollection: server + "deleteClinicalCaseCollection",
    getClinicalCaseCollection: server + "getClinicalCaseCollection",
    getClinicalCasesBySignupId: server + "getClinicalCasesBySignupId",
    getClinicalCaseById: server + "getClinicalCaseById",
    getClinicalCaseLikes: server + "getClinicalCaseLikes",
    getClinicalCaseLikesList: server + "getClinicalCaseLikesList",
    getCommentsOnClinicalCase: server + "getCommentsOnClinicalCase",
    commentOnClinicalCase: server + "commentOnClinicalCase",
    addClinicalCaseReport: server + "addClinicalCaseReport",
    editClinicalCaseById: server + "editClinicalCaseById",
    // **********
    countryMap: {
      96: "India",
      230: "United State",
      12: "Australia",
      71: "Great Britain",
    },
    notAllowed: [
      "/personal",
      "/qualification",
      "/surgicalTraining",
      "/membershipAssociation",
      "/mediRegistration",
    ],
    1: "/personal",
    2: "/qualification",
    3: "/membershipAssociation",
    4: "/mediRegistration",
    5: "/chat",
    fileSizeLimitBeyondSurgery: 200,
    fileSizeLimitCommunity: 200,
    // fileSizeLimitCommunity: 2,
    getFileSizeInMB: (target) => {
      if (!target) return;

      var sizeInMB = (target.files[0].size / (1024 * 1024)).toFixed(2);
      return sizeInMB;
      // alert(sizeInMB + 'MB');

      // var _size = target.files[0].size;
      // var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
      //     i = 0; while (_size > 900) { _size /= 1024; i++; }
      // var exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
      // // console.log('FILE SIZE = ', exactSize);
      // return exactSize;
    },
    getDifferenceBtnDates: (fromDate, numDates) => {
      fromDate = moment(fromDate);
      const toDate = moment();
      const diff = toDate.diff(fromDate, "days"); // 1
      return diff < numDates;
    },
    getOppDate: (date) => {
      return moment(date).format("ll");
    },
    specialityList: [
      // // "Neurology",
      // // "Oncology",
      // // "Gynecology",
      // "Oncosurgery(Cancer Surgery)",
      // "GI Surgery",
      // "Urology",
      // "ENT surgery",
      // "Head & Neck Surgery",
      // "Neurosurgery",
      // // "Cardio-Thoracic Surgery",
      // "Thoracic Surgery",
      // "Pediatric Surgery",
      // "Plastic Surgery",
      // "GYN Surgery",
      // "Eye Surgery",
      // "Breast Surgery",
      // // "Minimal Access Surgery",
      // "Orthopedic Surgery",
      // "Transplantation Surgery",
      // "Vascular surgery",
      // "Cardiac Surgery"

      "Oncosurgery (Cancer Surgery)",
      "GI Surgery",
      "Upper GI Surgery",
      "HPB Surgery",
      "Colon & Rectal Surgery",
      "Head & Neck Surgery",
      "Breast Surgery",
      "ENT Surgery",
      "Urology",
      "Plastic & Reconstructive Surgery",
      "Cardiac Surgery",
      "Thoracic Surgery",
      "GYN Surgery",
      "Orthopedic Surgery",
      "Transplantation Surgery",
      "Vascular Surgery",
      "Endocrine Surgery",
      "Trauma, Burns, Critical and Acute Care Surgery",
      "Minimal Access Surgery",
      "Pediatric Surgery",
      "Neurosurgery",
      "General Surgery",
      "Endoscopic Surgery",
      "Eye Surgery",
      "Bariatric Surgery",
    ],
  };
};

export default Constants();
