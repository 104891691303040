import React, { useState } from 'react';
// import letterImg from '../assets/img/letter.svg';
import axios from 'axios';
import Constants from '../configs/Constants';
import Config from '../configs/Config';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
import DummyProfile from '../assets/img/dummy-profile.png';
import BannerImage from '../assets/img/01_a_Banner.jpg';
import DocBanner from '../assets/img/Doccare_Banner.jpg';
import Banner3 from '../assets/img/banner3.jpeg';
import Banner2 from '../assets/img/banner2.jpeg';
import Banner1 from '../assets/img/banner1.jpeg';
import '../assets/css/custome.css';
import { Modal, Button } from 'react-bootstrap';
import LoaderEmitters from '../configs/LoaderEmitters';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import EventEmitters from '../configs/EventEmitters';
import ReadMoreReact from 'read-more-react';

const CommunityNonRegister = (props) => {

    const mergeById = (arr) => {
        const merged = _.merge(
            _.keyBy(arr[0], 'id'),
            _.keyBy(arr[1], 'id'),
            _.keyBy(arr[2], 'id'),
            _.keyBy(arr[3], 'id'));
        let values = _.values(merged);
        values = _.orderBy(merged, ['id'], ['desc']);
        return values;
    }

    const [show, setShow] = useState(false);
    const [loginDetails, setLoginDetails] = useState({});
    const [communityData, setCommunityData] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook

    React.useEffect(() => {
        setLoginDetails(Config.getSessionInfo());
        const id = Config.getSessionInfo('id');
        const isAllDetailsFilled = Config.getSessionInfo('is_all_details_filled');

        if (!id || isAllDetailsFilled === 0) {
            const idReister = Config.getSessionInfoRegister('name');
            if (idReister) {
                props.history.push('/communityNonRegisterView');
            } else {
                loadDataOfCommunity(-111);
            }
        } else {
            props.history.push('/community');
        }
    }, []);

    const loadDataOfCommunity = (id) => {
        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.getCommunityPost, {
            id: id
        })
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                const newMergedArr = mergeById(response.data.result);
                setCommunityData([...newMergedArr]);
                console.log("newMergedArr?>>>>>>>>>>>>>>", newMergedArr);
            })
            .catch(console.error);
    }

    const displayTime = (time) => {
        return moment(time).calendar();
    }

    const onSubmit = data => {

        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.addCommunityNonRegister, data)
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });
                // reset();
                let tost = {
                    message: "",
                    type: ""
                };
                if (response.data.code === 200) {
                    tost.type = "success";
                    tost.message = response.data.msg;
                } else {
                    tost.type = "error";
                    tost.message = response.data.msg;
                }

                if (tost.message) {
                    EventEmitters.showTost(tost);
                }
                reset();
                sessionStorage.setItem('sessionRegister', JSON.stringify(data));
                props.history.push('/communityNonRegisterView');

            })
            .catch(console.error);

    };

    return (
        <React.Fragment>

            <div className="col-md-8 col-lg-8 col-xl-8 offset-md-2">
                <div className="doc-review review-listing m-10">
                    <ul className="comments-list">
                        {
                            communityData && communityData.map((elm, id) => {
                                return (
                                    <li>
                                        <div className="comment">
                                            <img className="avatar rounded-circle" alt="User Image" src={Constants.server + elm.profile_pic} />
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <span className="comment-author">
                                                        {/* Dr. {elm.full_name} */}
                                                        <NavLink to={"/profile/" + elm.owner_id}>Dr. {elm.full_name}</NavLink>
                                                    </span>
                                                    <span className="comment-date">Posted {displayTime(elm.created_time_date)}</span>
                                                </div>
                                                <p className="comment-content">
                                                    {/* {elm.description} */}
                                                    {
                                                        // elm.description ?
                                                        //     <ReadMoreReact text={elm.description}
                                                        //         min={10}
                                                        //         ideal={200}
                                                        //         max={250}
                                                        //         readMoreText="Read more"
                                                        //     /> : ""

                                                        elm.description ?
                                                            (
                                                                <div onClick={handleShow}>
                                                                    {elm.description.substring(0, 200)}
                                                                    <div className="read-more"> &nbsp;</div>
                                                                </div>
                                                            ) :
                                                            null
                                                    }
                                                </p>
                                                <button className="btn-primary" onClick={handleShow}>View More</button>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>

            <div className="modal custom-modal fade" id="add_new_event">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Fill The Following Details</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form autoComplete="off">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input className="form-control form-white" placeholder="Enter name" type="text" name="category-name" />
                                </div>
                                <div className="form-group">
                                    <label>Mobile Number</label>
                                    <input className="form-control form-white" placeholder="Enter mobile number" type="text" name="category-number" />
                                </div>
                                <div className="form-group">
                                    <label>Profession</label>
                                    <select className="form-control form-white" data-placeholder="Choose a color..." name="category-color">
                                        <option value="success">Student</option>
                                        <option value="danger">Physician</option>
                                        <option value="info">Non Medical Person</option>
                                        <option value="primary">Etc</option>

                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Email ID</label>
                                    <input className="form-control form-white" placeholder="Enter email id" type="email" name="category-email" />
                                </div>
                                <div className="submit-section text-center">
                                    <span className="btn btn-primary">Save</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>To read the entire case please fill sign up form.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="form-group">
                            <label>Name *</label>
                            <input className="form-control form-white" placeholder="Enter name" type="text" name="name" ref={register({ required: true })} />

                            {errors.name &&
                                <div className="error-text" role="alert">
                                    Name is required.
                                                </div>}
                        </div>
                        <div className="form-group">
                            <label>Email ID</label>
                            <input className="form-control form-white" placeholder="Enter email id" type="email" ref={register({ required: true })} name="email_id" />
                            {errors.email_id &&
                                <div className="error-text" role="alert">
                                    Email id is required.
                                                </div>}
                        </div>

                        <div className="form-group">
                            <label>Profession</label>
                            <select ref={register({ required: true })} className="form-control form-white" data-placeholder="Choose a profession..." name="profession">
                                <option value="Student">Student</option>
                                <option value="Physician">Physician</option>
                                <option value="Non Medical Person">Non Medical Person</option>
                            </select>
                            {errors.profession &&
                                <div className="error-text" role="alert">
                                    Profession is required.
                                                </div>}
                        </div>

                        <div className="form-group">
                            <label>Mobile Number</label>
                            <input className="form-control form-white" placeholder="Enter mobile number" type="text" ref={register({ required: false })} name="mobile_number" />
                        </div>

                        <div className="submit-section text-center">
                            <button type="submit" className="btn btn-primary" >
                                Submit
                            </button>
                        </div>
                    </form>

                    <hr></hr>
                    <div className="text-center">
                        <Modal.Title>To access full features of Community you can signup from below link:</Modal.Title>
                        <div>
                            <button className="btn btn-warning">
                                <NavLink to="/signup">Register</NavLink>
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


            <FooterContent>
            </FooterContent>
        </React.Fragment >
    );
}
export default CommunityNonRegister;