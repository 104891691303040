import React, { useRef, useState, useEffect } from "react";
// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { Alert, Badge, Image, ListGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import StickyBox from "react-sticky-box";
import "../assets/css/list-surgen.css";
import "../assets/css/posts.css";
import "../assets/css/surgery-post-view.css";
import indemnityBondSurgery from "../assets/docs/Indemnity_Bond_Beyond_Surgery.pdf";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import LoaderEmitters from "../configs/LoaderEmitters";
import Comment from "./Comment";
import ConfirmationModal from "./ConfirmationModal";
import EditBeyondModal from "./EditBeyondModal";
import SearchUserInput from "./SearchUserInput";
import FooterContent from "./FooterContent";
import { Link } from "react-router-dom";

const BeyondSurgeryMyCollection = (props) => {
  const comment = function (id, elm) {
    if (!id) return;
    var x = document.getElementById(id);
    setCurruntComment("");
    if (x.style.display === "none") {
      x.style.display = "block";
      getCommentForBeyondSurgoryId(elm);
    } else {
      x.style.display = "none";
    }
  };

  const handleMenu = function (id, elm) {
    if (!id) return;
    var x = document.getElementById(id);
    // setMenuRight('');
    if (x.style.display === "none") {
      x.style.display = "block";
      // getCommentForBeyondSurgoryId(elm);
    } else {
      x.style.display = "none";
    }
  };

  const mergeById = (arr) => {
    if (arr[4] && arr[4].length) {
      const merged = _.merge(
        _.keyBy(arr[0], "id"),
        _.keyBy(arr[1], "id"),
        _.keyBy(arr[2], "id"),
        _.keyBy(arr[3], "id"),
        _.keyBy(arr[4], "id")
      );

      let values = _.values(merged);

      const sortedArr = arr[4].map((e) => {
        return e.beyond_surgery_id;
      });

      let newLatest = _.map(values, (o) => {
        if (sortedArr.indexOf(o.id) > -1) return o;
      });
      newLatest = _.without(newLatest, undefined);
      values = _.orderBy(newLatest, ["id"], ["desc"]);
      return values;
    } else {
      return [];
    }
  };

  const [caseToBeEdited, setcaseToBeEdited] = useState(null);
  const [isEditCaseModalVisible, setEditCaseModalVisible] = useState(false);
  const [shouldRefresh, setshouldRefresh] = useState(false);
  const [beyondCollection, setBeyondCollection] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [curruntComment, setCurruntComment] = useState([]);
  // const [menuRight, setMenuRight] = useState([]);
  const [showAddOpportunity, setShowAddOpportunity] = useState(false);
  const handleCloseOpportunity = () => setShowAddOpportunity(false);
  const handleShowOpportunity = () => setShowAddOpportunity(true);

  const [showReadMore, setShowReadMore] = useState(false);
  const handleCloseReadMore = () => setShowReadMore(false);
  const handleShowReadMore = () => setShowReadMore(true);
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const beyondFileRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [commentToDeleted, setCommentToDeleted] = useState({
    comment: null,
    elm: null,
  });

  const [userDetails, setUserDetails] = useState([]);
  const [beyondSurgoryData, setBeyondSurgoryData] = useState([]);
  const [beyoundSurgoryCommentData, setBeyoundSurgoryCommentData] = useState(
    []
  );
  const [communityFriendRequest, setCommunityFriendRequest] = useState([]);

  const [showReportModal, setShowReportModal] = useState(false);
  const [curruntIdForReport, setCurruntIdForReport] = useState(null);

  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const [selectedFileType, setSelectedFileType] = useState();

  const [showLikeList, setShowLikeList] = useState(false);
  const [modalListLikeData, setModalListLikeData] = useState(false);

  const handleCloseLikeModal = (elm) => {
    setShowLikeList(false);
  };
  const handleShowLikeModal = (elm) => {
    setShowLikeList(true);
  };

  useEffect(() => {
    if (shouldRefresh) {
      setBeyondSurgoryData([]);
      loadDataOfBeyondSurgory();
      setshouldRefresh(false);
    }
    return () => {};
  }, [shouldRefresh]);

  const getBeyondSurgoryCollection = (id) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getBeyondSurgoryCollection, {
        id: id,
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // const newMergedArr = mergeById(response.data.result);
        let dataArr = [];
        for (let val of response.data.result[4]) {
          // dataArr.push(val.community_id);
          dataArr.push(val.beyond_id);
        }
        setBeyondCollection(dataArr);
        // setUserLikesDetails([...response.data.result[1]]);
        console.log("getCommunityCollection?>>>>>>>>>>>>>>", [
          response.data.result[4],
        ]);
        // console.log("getBeyondCollection?>>>>>>>>>>>>>>", [
        //   response.data.result[4],
        // ]);
      })
      .catch(console.error);
  };

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  React.useEffect(() => {
    const id = Config.getSessionInfo("id");
    // const { params } = props.match;
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    if (id && isAllDetailsFilled === 1) {
      setUserDetails({
        ...Config.getSessionInfo(),
      });
      loadDataOfBeyondSurgory(id);
      getCommunityFriendRequestList(Config.getSessionInfo("id"));
    } else {
      props.history.push("/login");
      // console.log("Config.getSessionInfo()>>>>>>>>", Config.getSessionInfo());
    }
  }, []);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    const fileSize = Constants.getFileSizeInMB(e.target);
    // alert(fileSize);
    if (fileSize >= Constants.fileSizeLimitBeyondSurgery) {
      EventEmitters.showTost({
        type: "error",
        message:
          "File size should be less than " +
          Constants.fileSizeLimitBeyondSurgery +
          " MB.",
      });
      reset();
      return;
    }
    const fileName = e.target.files[0];
    // .jpg, .jpeg, .png, .gif, .webm, .ogg, .mp4,
    if (
      fileName.name.search(".png") > -1 ||
      fileName.name.search(".jpeg") > -1 ||
      fileName.name.search(".jpg") > -1 ||
      fileName.name.search(".gif") > -1
    ) {
      setSelectedFileType({
        type: fileName.type,
        tagType: "image",
      });
    } else if (
      fileName.name.search(".mp4") > -1 ||
      fileName.name.search(".webm") > -1 ||
      fileName.name.search(".ogg") > -1
    ) {
      setSelectedFileType({
        type: fileName.type,
        tagType: "video",
      });
    } else {
      EventEmitters.showTost({
        type: "error",
        message: "File type not supported.",
      });
      reset();
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(fileName);
  };

  const addToCollection = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.addBeyondSurgoryPostToCollection, {
        signup_id: Config.getSessionInfo("id"),
        beyond_surgery_id: elm.id,
      })
      .then((response) => {
        getBeyondSurgoryCollection(Config.getSessionInfo("id"));
        const idOfLogin = Config.getSessionInfo("id");
        loadDataOfBeyondSurgory(idOfLogin);
        // getCommentFriendRequestList({ id: idOfLogin });
        // getCommunityFriendRequestList(idOfLogin);

        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const loadDataOfBeyondSurgory = (opportunityId) => {
    const id = Config.getSessionInfo("id");
    const data = {
      id: id,
    };

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getBeyondSurgoryCollection, data)
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        if (
          response.data.result &&
          response.data.result[0] &&
          response.data.result[0].length
        ) {
          const newMergedArr = mergeById(response.data.result);
          setBeyondSurgoryData([...newMergedArr]);
        } else {
          setBeyondSurgoryData([]);
        }
        // const newMergedArr = mergeById(response.data.result);
        // if (response.data.result && response.data.result[0] && response.data.result[0].length) {
        //     setBeyondSurgoryData([...newMergedArr]);
        // } else {
        //     setBeyondSurgoryData([]);
        // }
      })
      .catch(console.error);
  };

  const onSubmit = (data) => {
    // data.id = Config.getSessionInfo('id');
    console.log("onSubmit data>>>>>", data);

    var xx = document.getElementById("attachFile");
    const formData = new FormData();
    formData.append("beyondFile", xx.files[0]);

    console.log("formData>>>>>>>>>>>", formData);

    LoaderEmitters.showLoader({
      showLoader: true,
    });

    const config = {
      headers: {
        title: data.title,
        id: Config.getSessionInfo("id"),
        filetagtype: selectedFileType && selectedFileType.tagType,
        type: selectedFileType && selectedFileType.type,
      },
    };
    handleCloseOpportunity();

    axios
      .post(Constants.addBeyondSurgoryPost, formData, config)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          setBeyondSurgoryData([]);
          loadDataOfBeyondSurgory();
          handleCloseOpportunity();
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
        reset();
      })
      .catch(console.error);
  };

  const getCommentForBeyondSurgoryId = (elm) => {
    // setBeyoundSurgoryCommentData();

    // LoaderEmitters.showLoader({
    //   showLoader: true,
    // });

    axios
      .post(Constants.getCommentForBeyondSurgoryId, {
        beyond_surgery_id: elm.id,
      })
      .then((response) => {
        // reset();
        // LoaderEmitters.showLoader({
        //   showLoader: false,
        // });

        console.log("loadDataOfCommunity response>>>>>>", response);
        setBeyoundSurgoryCommentData([...response.data.result]);
      })
      .catch(console.error);
  };

  const displayTime = (time) => {
    return moment(time).calendar();
  };

  const commentBoxInput = (e) => {
    const { value } = e.target;
    setCurruntComment(value);
    console.log(value);
  };

  const handleBeyondCommentDelete = async (
    caseId,
    commentId,
    beyondSurgeryId
  ) => {
    try {
      LoaderEmitters.showLoader({
        showLoader: true,
      });
      const response = await axios.post(
        Constants.deleteCommentForBeyondSurgeryId,
        {
          comment_id: commentId,
          beyond_surgery_id: beyondSurgeryId,
        }
      );
      if (response.data.code === 200) {
        setBeyondSurgoryData((oldData) =>
          oldData.map((Case) => {
            if (Case.id === caseId && Case.comment_count) {
              Case.comment_count--;
            }
            return Case;
          })
        );
        setBeyoundSurgoryCommentData((oldComments) =>
          oldComments.filter((ele) => ele.id !== commentId)
        );
      }
      LoaderEmitters.showLoader({
        showLoader: false,
      });
      setIsModalVisible(false);
    } catch (error) {
      console.log(error);
      LoaderEmitters.showLoader({
        showLoader: false,
      });
      setIsModalVisible(false);
    }
  };

  const addComment = (elm) => {
    if (curruntComment) {
      LoaderEmitters.showLoader({
        showLoader: true,
      });
      console.log("add Comment>>>>>>>>", curruntComment);
      axios
        .post(Constants.addBeyondSurgoryPostAddComment, {
          signup_id: Config.getSessionInfo("id"),
          beyond_surgery_id: elm.id,
          curruntComment,
        })
        .then((response) => {
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          setCurruntComment("");
          getCommentForBeyondSurgoryId(elm);
          // reset();
          let tost = {
            message: "",
            type: "",
          };
          console.log("response>>>>>>", response);
          if (response.data.code === 200) {
            tost.type = "success";
            tost.message = response.data.msg;
            loadDataOfBeyondSurgory(Config.getSessionInfo("id"));
            // loadDataOfCommunity(Config.getSessionInfo('id'));
            // handleClose();
            // props.history.push('/qualification');
          } else {
            tost.type = "error";
            tost.message = response.data.msg;
          }

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
        })
        .catch(console.error);
    }
  };

  const onClickLike = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.addBeyondSurgoryPostLike, {
        signup_id: Config.getSessionInfo("id"),
        beyond_surgery_id: elm.id,
        isLike: 1,
        elm,
        full_name: Config.getSessionInfo("full_name"),
        module: "beyondSurgery",
        route: "beyondSurgery/like",
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        // reset();
        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          loadDataOfBeyondSurgory(Config.getSessionInfo("id"));
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const handleShowReportModal = (elm) => {
    setShowReportModal(true);
    setCurruntIdForReport(elm);
    menuRightHideAll();
  };

  const handleCloseReportModal = (elm) => {
    setShowReportModal(false);
    setCurruntIdForReport(null);
  };

  const menuRightHideAll = () => {
    const menuRight = document.getElementsByClassName("menu-right-for-all");
    if (menuRight && menuRight[0]) {
      menuRight[0].style.display = "none";
    }
  };

  const onSubmitReportReason = (data) => {
    console.log("onSubmit data>>>>>", data);
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    // debugger;
    axios
      .post(Constants.addBeyondSurgoryReportReason, {
        signup_id: Config.getSessionInfo("id"),
        beyond_surgery_id: curruntIdForReport.id,
        reason: data.reason,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          loadDataOfBeyondSurgory(Config.getSessionInfo("id"));
          handleClose();
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }

        reset();
        handleCloseReportModal();
      })
      .catch(console.error);
  };

  const deletePostCommunity = (elm) => {
    menuRightHideAll();
    if (window.confirm("Are you sure you want to delete this post?")) {
      LoaderEmitters.showLoader({
        showLoader: true,
      });

      axios
        .post(Constants.deleteCommunityPostBeyondSurgory, {
          beyond_surgery_id: elm.id,
        })
        .then((response) => {
          LoaderEmitters.showLoader({
            showLoader: false,
          });

          let tost = {
            message: "",
            type: "",
          };
          console.log("response>>>>>>", response);
          if (response.data.code === 200) {
            tost.type = "success";
            tost.message = response.data.msg;
          } else {
            tost.type = "error";
            tost.message = response.data.msg;
          }

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
          loadDataOfBeyondSurgory(Config.getSessionInfo("id"));
        })
        .catch(console.error);
    }
  };

  const seeLikeList = (elm) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.getLikeListOfBeyondSurgory, {
        signup_id: Config.getSessionInfo("id"),
        beyond_surgery_id: elm.id,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          setShowLikeList(true);
          setModalListLikeData([...response.data.result]);
          // loadDataOfBeyondSurgory(Config.getSessionInfo('id'));
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        // if (tost.message) {
        //     EventEmitters.showTost(tost);
        // }
      })
      .catch(console.error);
  };

  const checkForFriendReqDom = (elm) => {
    let activeId;
    const ids = _.find(communityFriendRequest, { user_one: elm.signup_id });
    const ids2 = _.find(communityFriendRequest, { user_two: elm.signup_id });
    console.log(">>>checkForFriendReqDom>>>>>>>>>>>", ids);
    console.log(">>>checkForFriendReqDom>>>>>>>>>>>", ids2);
    // if (ids && ids.id) {
    //     activeId = ids.id;
    //     // setFriendListId(ids.id);
    // }
    // if (ids2 && ids2.id) {
    //     activeId = ids2.id;
    // }
    // window.sessionStorage.setItem('friendListId', activeId);
    // if (activeId) {
    //     setFriendListId(activeId);
    // }
    return ids == undefined && ids2 == undefined;
  };

  const sendFreindRequest = (elm) => {
    menuRightHideAll();
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.addFriendRequestBeyondSurgory, {
        signup_id: Config.getSessionInfo("id"),
        user_two_id: elm.signup_id,
      })
      .then((response) => {
        getCommunityFriendRequestList(Config.getSessionInfo("id"));
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        // console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const removeFreindRequest = (elm) => {
    if (!window.confirm("Are you sure you want to unfollow?")) {
      return;
    }

    menuRightHideAll();
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .post(Constants.unFollowFriendRequestBeyondSurgory, {
        signup_id: Config.getSessionInfo("id"),
        user_two_id: elm.signup_id,
      })
      .then((response) => {
        loadDataOfBeyondSurgory();
        getCommunityFriendRequestList(Config.getSessionInfo("id"));
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        // console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const [numberOfRequest, setNumberOfRequest] = useState(0);
  const getCommunityFriendRequestList = (id) => {
    axios
      .post(Constants.getBeyondSurgoryFriendRequestList, {
        signup_id: id,
      })
      .then((response) => {
        // reset();
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        console.log("loadDataOfCommunity response>>>>>>", response);
        // setFriendList([...response.data.result]);
        setNumberOfRequest(response.data.result.length);
      })
      .catch(console.error);
  };

  return (
    <React.Fragment>
      <div className="content" id="beyond-surgery">
        <div
          className="container-fluid"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <div className="row flex-row-reverse">
            <div
              className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar order-sm-1"
              style={{ paddingBottom: 30 }}
            >
              <StickyBox offsetTop={20} offsetBottom={50}>
                <div className="profile-sidebar">
                  <div className="widget-profile pro-widget-content">
                    <div className="profile-info-widget">
                      <span className="booking-doc-img">
                        <img
                          src={Constants.server + userDetails.profile_pic}
                          alt="User Image"
                        />
                      </span>
                      <div className="profile-det-info">
                        <h3>Dr. {userDetails.full_name}</h3>
                        <div className="patient-details">
                          <h5 className="mb-0">
                            MBBS
                            {userDetails.specialty_type
                              ? `, MS - (${userDetails.specialty_type})`
                              : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-widget">
                    <nav className="dashboard-menu">
                      <ul>
                        <li
                          className="nav-item"
                          id="nomorehover"
                          style={{ padding: "10px" }}
                        >
                          <a
                            className="nav-link header-login"
                            onClick={handleShowOpportunity}
                            style={{
                              backgroundColor: "#096d9c",
                              color: "#fff",
                              border: "2px solid #096d9c",
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            Add Post{" "}
                          </a>
                        </li>
                        <li>
                          {/* <a href="dr-post1.php"> */}
                          <NavLink to="/beyondSurgery">
                            <i className="fas fa-columns"></i>
                            <span> Feed </span>
                          </NavLink>
                          {/* </a> */}
                        </li>
                        <li>
                          <NavLink to="/beyondSurgeryMyPost">
                            <i className="fas fa-columns"></i>
                            <span> My Posts</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/beyondSurgeryMyCollection">
                            <i className="fas fa-bookmark"></i>
                            <span className="liked">Saved</span>
                          </NavLink>
                        </li>

                        {/* <li>
                          <NavLink to="/beyondSurgoryFollowing">
                            <i className="fas fa-calendar-check"></i>
                            <span>Request</span>
                            <sup>
                              <Badge
                                pill
                                variant="primary"
                                style={{
                                  fontSize: "11px",
                                  marginLeft: "6px",
                                }}
                              >
                                {numberOfRequest}
                              </Badge>
                            </sup>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/beyondSurgoryFollowingFriendList">
                            <i className="fas fa-user-injured"></i>
                            <span>Connections</span>
                          </NavLink>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </div>
              </StickyBox>
            </div>

            <div className="col-md-7 col-lg-8 col-xl-9 order-sm-2">
              {/* <div className='lil-bar'>
                                <div className='bar-cont'>
                                    <a href='#' onclick='return false;' className='list-order' role="button"><i className="fa fa-user-plus"></i></a>
                                </div>
                            </div>

                            <div className="list">
                                <ul>
                                    <li>
                                        <a href='our-profile.php' target='_blank'>
                                            <img src="assets/img/1_Oq92ZIGTAVHPVtqq_LpGjw.jpeg" className="avatar avatar-50" />uncle_oreo
                                        </a>
                                    </li>
                                    <li className="makeapointer">
                                        <a onclick="myFunction()">Search User</a>
                                        <div id="myDropdown" className="dropdown-content">
                                            <input type="text" placeholder="Search user.." id="myInput" onkeyup="filterFunction()" />
                                            <ul id="makeascroll">
                                                <li><a href='view-profile.php' target='_blank'>
                                                    <img src="assets/img/1_Oq92ZIGTAVHPVtqq_LpGjw.jpeg" className="avatar avatar-50" />
                                                    uncle_oreo
                                                    <button className="needcss float-right"><i className="fas fa-plus"></i>Connect</button></a>
                                                </li>

                                                <li>
                                                    <a href='view-profile.php' target='_blank'>
                                                        <img src="assets/img/1_Oq92ZIGTAVHPVtqq_LpGjw.jpeg" className="avatar avatar-50" />
                                                        uncle_oreo
                                                        <button className="needcss float-right">
                                                            <i className="fas fa-plus"></i>
                                                            Connect
                                                        </button>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href='view-profile.php' target='_blank'>
                                                        <img src="assets/img/1_Oq92ZIGTAVHPVtqq_LpGjw.jpeg" className="avatar avatar-50" />
                                                        uncle_oreo
                                                        <button className="needcss float-right">
                                                            <i className="fas fa-plus"></i>Connect
                                                        </button></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><a href='#'>Log out</a></li>
                                    <li className='collapse'><a href='#'>Collapse the bar</a></li>
                                </ul>
                            </div> */}
              <section>
                <SearchUserInput />
              </section>

              <hr style={{ width: "78%" }} />
              {beyondSurgoryData && beyondSurgoryData.length ? (
                beyondSurgoryData.map((elm) => {
                  return elm.title ? (
                    <section>
                      <div
                        className="insta fade-scroll"
                        style={{ position: "relative" }}
                      >
                        <div className="top-insta">
                          <NavLink
                            to={"beyondSurgeryUserPost/" + elm.signup_id}
                          >
                            <img src={Constants.server + elm.profile_pic} />
                          </NavLink>
                          <span className="user">
                            <NavLink
                              to={"beyondSurgeryUserPost/" + elm.signup_id}
                            >
                              Dr. {elm.full_name}
                            </NavLink>
                          </span>
                          {/* <input id="MsgToggle" type="button" onclick="MsToggle()" className="Show dot" /> */}
                          <i
                            onClick={handleMenu.bind(
                              this,
                              "menu-right" + elm.id,
                              elm
                            )}
                            class="fa fa-ellipsis-v Show dot"
                            aria-hidden="true"
                          ></i>

                          {/* <DropdownButton id="dropdown-basic-button" title="Dropdown button">
                                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                </DropdownButton> */}
                        </div>

                        <div
                          class="menu-right-for-all"
                          id={"menu-right" + elm.id}
                          style={{
                            display: "none",
                            position: "absolute",
                            right: 0,
                            top: 0,
                          }}
                        >
                          <div className="col-md-4 offset-8" id={"divOutside"}>
                            <div id="Text" className="divInside">
                              <ul id="nomorebullets" className="oneline">
                                {userDetails &&
                                userDetails.id != elm.signup_id &&
                                checkForFriendReqDom(elm) ? (
                                  <React.Fragment>
                                    <li
                                      onClick={handleShowReportModal.bind(
                                        this,
                                        elm
                                      )}
                                      className="dot05"
                                    >
                                      <i
                                        id="fapaddind"
                                        className="fas fa-flag"
                                      ></i>{" "}
                                      Report
                                    </li>
                                    <hr />
                                    <li
                                      className="dot05"
                                      onClick={sendFreindRequest.bind(
                                        this,
                                        elm
                                      )}
                                    >
                                      <i
                                        id="fapaddind"
                                        className="fas fa-user-plus"
                                      ></i>
                                      Connect
                                    </li>
                                  </React.Fragment>
                                ) : userDetails &&
                                  userDetails.id != elm.signup_id ? (
                                  <React.Fragment>
                                    <hr />
                                    <li
                                      className="dot05"
                                      onClick={removeFreindRequest.bind(
                                        this,
                                        elm
                                      )}
                                    >
                                      <i
                                        id="fapaddind"
                                        className="fas fa-user-plus"
                                      ></i>
                                      Unfollow
                                    </li>
                                  </React.Fragment>
                                ) : null}

                                {userDetails &&
                                userDetails.id == elm.signup_id ? (
                                  <React.Fragment>
                                    <hr />
                                    <li
                                      className="dot05"
                                      onClick={() => {
                                        setcaseToBeEdited(elm);
                                        setEditCaseModalVisible(true);
                                      }}
                                    >
                                      <i id="fapaddind" class="fas fa-edit"></i>
                                      Edit Post
                                    </li>
                                    <hr />
                                    <li
                                      className="dot05"
                                      onClick={deletePostCommunity.bind(
                                        this,
                                        elm
                                      )}
                                    >
                                      <i
                                        id="fapaddind"
                                        className="fas fa-trash"
                                      ></i>
                                      Delete Post
                                    </li>
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}

                                {/* <li onClick={handleShowReportModal.bind(this, elm)} className="dot05"> <i id="fapaddind" className="fas fa-flag"></i> Report</li>

                                                                <hr />
                                                                <li className="dot05" onClick={deletePostCommunity.bind(this, elm)} ><i id="fapaddind" className="fas fa-trash"></i>Delete Post </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>

                        {elm.file && elm.file !== "nofile" ? (
                          `${elm.file}`.includes(".mp4") ? (
                            <div className="post">
                              <div className="overlay">
                                <span></span>
                              </div>
                              <div className="d-flex">
                                <video
                                  playsInline
                                  controls
                                  className="img-responsive mx-auto"
                                  style={{
                                    maxWidth: "100%",
                                  }}
                                  src={
                                    Constants.server +
                                    "beyond_surgory/" +
                                    elm.file
                                  }
                                  controlsList="nodownload"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="post">
                              <div className="overlay">
                                <span></span>
                              </div>
                              <div className="d-flex">
                                <img
                                  className="img-responsive mx-auto"
                                  style={{
                                    maxWidth: "100%",
                                  }}
                                  src={
                                    Constants.server +
                                    "beyond_surgory/" +
                                    elm.file
                                  }
                                />
                              </div>
                            </div>
                          )
                        ) : null}

                        <div className="footer review-listing" id="removebg1">
                          {elm.title ? (
                            <div className="caption">
                              <span
                                style={{
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {decodeURIComponent(elm.title)}
                              </span>
                            </div>
                          ) : null}
                          <span>
                            <small
                              title="Click here to view like members"
                              onClick={seeLikeList.bind(this, elm)}
                            >
                              {elm.like_count ?? 0}{" "}
                              {elm.like_count == 1 ? "like" : "likes"}
                            </small>
                            &nbsp; &#8226;&nbsp;
                            <small
                              title="Click here to view comments"
                              onClick={comment.bind(
                                this,
                                "comment-d" + elm.id,
                                elm
                              )}
                            >
                              {elm.comment_count ?? 0}{" "}
                              {elm.comment_count == 1 ? "comment" : "comments"}
                            </small>
                          </span>
                          <div className="react">
                            {/* <span className='fa fa-heart'></span> */}
                            {elm.is_like ? (
                              <span
                                href="#"
                                className="like-btn liked pointer"
                                data-toggle="tooltip"
                                data-placement="top"
                              >
                                <i
                                  title="Unlike"
                                  className="far fa-heart"
                                  onClick={onClickLike.bind(this, elm)}
                                ></i>
                              </span>
                            ) : (
                              <span
                                href="#"
                                className="like-btn pointer"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Like"
                              >
                                <i
                                  className="far fa-heart"
                                  onClick={onClickLike.bind(this, elm)}
                                ></i>
                              </span>
                            )}
                            <span
                              onClick={comment.bind(
                                this,
                                "comment-d" + elm.id,
                                elm
                              )}
                            >
                              <i className="far fa-comment" title="Comment"></i>
                            </span>

                            <span>
                              <i
                                title="Remove from collection"
                                onClick={addToCollection.bind(this, elm)}
                                className="far fa-bookmark liked"
                              ></i>
                            </span>

                            <small style={{ float: "right" }}>
                              {" "}
                              Posted On:{" "}
                              <strong>
                                {" "}
                                {displayTime(elm.created_date_time)}{" "}
                              </strong>{" "}
                            </small>
                          </div>

                          {/* <Comment
                            CommentData={beyoundSurgoryCommentData}
                            CommentType="beyoundSurgory"
                            elm={elm}
                          /> */}
                          <Comment
                            setPostData={setBeyondSurgoryData}
                            CommentData={beyoundSurgoryCommentData}
                            CommentType="beyoundSurgory"
                            elm={elm}
                            userID={userDetails.id}
                            handleCommentDelete={handleBeyondCommentDelete}
                            setCommentToDeleted={setCommentToDeleted}
                            setModalVisible={() =>
                              setIsModalVisible(!isModalVisible)
                            }
                          />
                          {
                            //     <ul className="comments-reply" id={"comment-d" + elm.id} style={{ display: 'none' }}>
                            //     <li>
                            //         <div className="row" style={{ marginBottom: '15px' }}>
                            //             <div className="col-md-8">
                            //                 <input type="text" className="form-control" id="add-cmt" placeholder="Add a Comment" value={curruntComment} onChange={commentBoxInput.bind(this)} />
                            //             </div>
                            //             <div className="col-md-4">
                            //                 <button id="add-cmt" className="btn-facebook" onClick={addComment.bind(this, elm)}>Add Comment</button>
                            //             </div>
                            //         </div>
                            //         {
                            //             beyoundSurgoryCommentData && beyoundSurgoryCommentData.map((element) => {
                            //                 return (
                            //                     <div className="comment">
                            //                         <img className="avatar rounded-circle" alt="User Image" src={Constants.server + element.profile_pic} />
                            //                         <div className="comment-body">
                            //                             <div className="meta-data">
                            //                                 <span className="comment-author">
                            //                                     {/* Dr. {element.full_name} */}
                            //                                     <NavLink to={"/profile/" + element.id}>Dr. {element.full_name}</NavLink>
                            //                                 </span>
                            //                                 <span className="comment-date"> <small>Reviewed {displayTime(element.created_time_date)}  ago</small> </span>
                            //                             </div>
                            //                             <p className="comment-content">
                            //                                 {element.comment}
                            //                             </p>
                            //                             {/* <div className="comment-reply">
                            //                                 <a className="comment-btn" href="#">
                            //                                     <i className="fas fa-reply"></i> Reply
                            //                                 </a>
                            //                             </div> */}
                            //                         </div>
                            //                     </div>
                            //                 )
                            //             })
                            //         }
                            //     </li>
                            // </ul>
                          }
                        </div>
                      </div>
                      <div className="a1 fade-scroll"></div>
                      <div className="a1 fade-scroll"></div>
                      <div className="a1 fade-scroll"></div>
                      <div className="a1 fade-scroll"></div>
                      <div className="a1 fade-scroll"></div>
                    </section>
                  ) : null;
                })
              ) : (
                <section>
                  <div
                    className="insta fade-scroll"
                    style={{ position: "relative" }}
                  >
                    <Alert
                      className="alert-no-post"
                      key={1}
                      variant={"primary"}
                    >
                      No post saved
                    </Alert>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>

      {caseToBeEdited != null && (
        <EditBeyondModal
          isVisible={isEditCaseModalVisible}
          setVisible={() => setEditCaseModalVisible(!isEditCaseModalVisible)}
          Case={caseToBeEdited}
          setCaseToBeEdited={setcaseToBeEdited}
          setshouldRefresh={setshouldRefresh}
        />
      )}

      <ConfirmationModal
        setModalVisible={() => setIsModalVisible(!isModalVisible)}
        isModalVisible={isModalVisible}
        CommentType="beyoundSurgory"
        handleCommentDelete={handleBeyondCommentDelete}
        commentToDeleted={commentToDeleted.comment}
        elm={commentToDeleted.elm}
      />

      <Modal
        size="lg"
        show={showAddOpportunity}
        onHide={handleCloseOpportunity}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="form-group row">
              <label
                for="title"
                className="col-md-4 col-form-label text-md-right"
              >
                Title
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Title is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row uploadfrm">
              <label
                for="password"
                className="col-md-4 col-form-label text-md-right"
              >
                Attach File
              </label>
              <div className="col-md-6">
                <input
                  type="file"
                  id="attachFile"
                  name="beyondFile"
                  accept=".jpg, .jpeg, .png, .mp4, .gif, .webm, .ogg"
                  onChange={onSelectFile}
                  required
                  ref={beyondFileRef}
                  // ref={register({ required: false })}
                />
                <div
                  className="alert-success"
                  role="alert"
                  style={{ marginTop: "4px" }}
                >
                  Supported type (.jpg, .jpeg, .png, .mp4, .gif, .webm, .ogg) &
                  max {Constants.fileSizeLimitBeyondSurgery} MB in Size
                </div>
                {errors.beyondFile && (
                  <div className="error-text" role="alert">
                    File is required.
                  </div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6 offset-md-4">
                {selectedFileType &&
                  (selectedFileType.tagType === "image" ? (
                    <div className="d-flex flex-row justify-content-center ">
                      <img src={preview} style={{ width: "100%" }} />

                      {!!preview && (
                        <i
                          style={{ color: "red" }}
                          id="fapaddind"
                          className="far fa-times-circle"
                          onClick={() => {
                            setPreview(undefined);
                            setSelectedFile(undefined);
                            beyondFileRef.current.value = "";
                          }}
                        ></i>
                      )}
                    </div>
                  ) : (
                    <div className="d-flex flex-row justify-content-center ">
                      {!!preview && (
                        <video playsinline width="100%" controls>
                          <source src={preview} type={selectedFileType.type} />
                        </video>
                      )}

                      {!!preview && (
                        <i
                          style={{ color: "red" }}
                          id="fapaddind"
                          className="far fa-times-circle"
                          onClick={() => {
                            setPreview(undefined);
                            setSelectedFile(undefined);
                            beyondFileRef.current.value = "";
                          }}
                        ></i>
                      )}
                    </div>
                  ))}
                {/* // {selectedFile &&  <img src={preview} style={{ width: '100%' }} />} */}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-6 offset-md-4">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      name="clauses"
                      required
                      ref={register({ required: true })}
                      style={{ marginRight: "6px" }}
                    />
                    I hereby understand and provide the following Indemnity.
                    {/* <a
                      href={indemnityBondSurgery}
                      target="_blank"
                      style={{ color: "#007bff" }}
                    >
                      {" "}
                      Click Here to Read{" "}
                    </a> */}
                      <Link
                      to="/terms_and_conditions"
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tap here to read.
                    </Link>
                    {errors.clauses && (
                      <div className="error-text" role="alert">
                        Please select the checkbox
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="col-md-6 offset-md-4">
              <button
                type="submit"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal1"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showReportModal} onHide={handleCloseReportModal}>
        <Modal.Header closeButton>
          <Modal.Title> Please fill the reason. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmitReportReason)}
            noValidate
            autoComplete="off"
          >
            <div class="form-group row">
              <label
                for="email_address"
                class="col-md-4 col-form-label text-md-right"
              >
                Reason
              </label>
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  name="reason"
                  required
                  ref={register({ required: true })}
                />
                {errors.reason && (
                  <div className="error-text" role="alert">
                    Reason is required.
                  </div>
                )}
              </div>
            </div>

            <div class="col-md-6 offset-md-4">
              <button type="submit" class="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showLikeList} onHide={handleCloseLikeModal}>
        <Modal.Header closeButton>
          <Modal.Title> Surgeons who liked. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {modalListLikeData} */}

          <ListGroup>
            {modalListLikeData &&
              modalListLikeData.map((elm) => {
                return (
                  <ListGroup.Item>
                    <Image
                      className="like-modal-img"
                      src={Constants.server + elm.profile_pic}
                      roundedCircle
                    />
                    <h6
                      style={{
                        display: "inline",
                      }}
                    >
                      <NavLink to={"profile/" + elm.owner_id}>
                        {elm.full_name}
                      </NavLink>
                    </h6>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <FooterContent></FooterContent>
    </React.Fragment>
  );
};
export default BeyondSurgeryMyCollection;
