const Config = function () {
    return {
        getSessionInfo: function (key) {
            let sessionInfo = window.sessionStorage.getItem('session');
            if (sessionInfo) {
                sessionInfo = JSON.parse(sessionInfo);
                if (key) {
                    return sessionInfo && sessionInfo[key];
                } else {
                    const fullNameArr = sessionInfo.full_name.split(" ");
                    sessionInfo.firstName = fullNameArr[0];
                    sessionInfo.lastName = fullNameArr[1];
                    return sessionInfo;
                }
            } else {
                return null;
            }
        },
        getSessionInfoRegister: function (key) {
            let sessionInfo = window.sessionStorage.getItem('sessionRegister');
            if (sessionInfo) {
                sessionInfo = JSON.parse(sessionInfo);
                if (key) {
                    return sessionInfo && sessionInfo[key];
                } else {
                    return sessionInfo;
                }
            } else {
                return null;
            }
        }
    }
}

export default Config();