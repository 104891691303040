
import Constants from './Constants';
import Config from './Config';

const publicVapidKey = "BOJCOWOBhlqcqGE68x3KhX2ufMkQAAW_CmqhAZgo5xHiwvnGzXCdTPd5e0kXjo-s26f94B_cxn_wIMFreRr4UXA";


// Regsiter SW, Register Push, Send Push
async function send() {
    // Register Service Worker
    console.log('Registering service worker...');
    const register = await navigator.serviceWorker.register('/worker.js', {
        scope: './'
    });
    console.log('Service Worker Registerd...');

    // Register Push
    console.log('Register push....');
    const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
    });

    console.log('Push registerd...');

    // send Push Notification
    console.log('Sending Push...');

    await fetch(Constants.subscribe, {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
            'content-type': 'application/json',
            token: Config.getSessionInfo('token')
        }
    });

    console.log('Push Sent...');
}

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const NotificationClient = function () {
    return {
        send: send,
        register: () => {
            // Check for service worker
            if ('serviceWorker' in navigator) {
                send().catch(err => console.error(err));
            }

        }
    }
}

export default NotificationClient();