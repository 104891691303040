// import letterImg from '../assets/img/letter.svg';
import React, { useRef } from "react";
import "../assets/css/custome.css";
import "../assets/css/posts.css";
import EventEmitters from "../configs/EventEmitters";
import FileDisplay from "./FileDisplay";

const FileUpload = ({
    handleFileChanges: setFiles,
    acceptExt = "*",
    files,
    defaultFiles,
    handleUploadedFilesDelete,
    fileSizeLimit = null,
    tabValue,
    fileTypes,
}) => {
    const fileInputRef = useRef();

    const handleFileSelectionChanges = (e) => {
        console.log(e.target.files);
        if (e.target.files) {
            let filteredFiles = [];
            let receivedFiles = e.target.files;

            Array.prototype.every.call(receivedFiles, (file) => {
                if (acceptExt !== "*") {
                    let extensions = acceptExt
                        .split(",")
                        .map((ext) => ext.trim().substring(1));
                    let fileNameSplit = file.name.split(".");

                    if (
                        !extensions.includes(
                            fileNameSplit[fileNameSplit.length - 1]
                        )
                    ) {
                        fileInputRef.current.value = "";

                        EventEmitters.showTost({
                            type: "error",
                            message: "Unsupported file type found",
                        });

                        return false;
                        // display:  error
                    }
                }

                if (!!fileSizeLimit) {
                    if (file.size > fileSizeLimit) {
                        EventEmitters.showTost({
                            type: "error",
                            message: "File exceeding size limit found",
                        });

                        return false;
                    }
                }

                filteredFiles.push(file);
                return true;
            });

            if (receivedFiles.length === filteredFiles.length) {
                filteredFiles = filteredFiles.map((file) => ({
                    previewUrl: URL.createObjectURL(file),
                    fileData: file,
                }));
                setFiles((oldState) => [...oldState, ...filteredFiles]);
            }
            fileInputRef.current.value = "";
        }
    };

    const handleFileDelete = (index) => () => {
        let newState = [...files];
        URL.revokeObjectURL(newState[index].previewUrl);
        newState.splice(index, 1);
        setFiles(newState);
    };

    return (
        <div>
            <button
                className={"btn btn-outline-secondary btn-sm"}
                onClick={() => fileInputRef.current.click()}
            >
                Add Files
            </button>
            <input
                type="file"
                multiple
                style={{ visibility: "hidden" }}
                ref={fileInputRef}
                onChange={handleFileSelectionChanges}
                accept={acceptExt}
            />
            <div className="row mt-3 mx-auto">
                {files.map((file, index) => (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <FileDisplay
                        file={file}
                        handleDelete={handleFileDelete(index)}
                        key={index.toString()}
                        type={fileTypes}
                    />
                ))}
                {defaultFiles &&
                    defaultFiles.map((file, index) => (
                        <FileDisplay
                            key={index.toString()}
                            file={file}
                            handleDelete={handleUploadedFilesDelete(file)}
                            mode="edit"
                            type={fileTypes}
                        />
                    ))}
            </div>
        </div>
    );
};
export default FileUpload;
