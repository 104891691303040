import React from "react";
import logoImg from "../assets/img/logo3.png";
import { NavLink } from "react-router-dom";
import termsOfUse from "../assets/docs/Terms of Use.pdf";
import companyPolicy from "../assets/docs/Company Policy.pdf";
import { Box, Grid, Link, Typography } from "@mui/material";
import tmWhite from "../assets/img/TM-white.png";

const FooterContent = () => {

  

  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-about">
              <Link href={"/"} underline="none">
                <div className="footer-logo mt-0">
                  {/* <div className="footer-logo "> */}
                  <img src={tmWhite} alt="logo" style={{ width: "30%" }} />
                </div></Link>
                <div className="footer-about-content">
                  <p>
                    Surgeons for Surgeons is a network aimed at building a
                    world-wide surgical community. We welcome surgeons from all
                    continents to join us.{" "}
                  </p>
                  {/* <div className="social-icon">
                                        <ul>
                                            <li>
                                                <a href="#" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank"><i className="fab fa-twitter"></i> </a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank"><i className="fab fa-instagram"></i></a>
                                            </li>
                                            <li>
                                                <a href="#" target="_blank"><i className="fab fa-dribbble"></i> </a>
                                            </li>
                                        </ul>
                                    </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">For Surgeons</h2>
                <ul>
                  {/* <li>
                                        <NavLink to="/login"><i className="fas fa-angle-double-right"></i> Login</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/signup"><i className="fas fa-angle-double-right"></i> Register</NavLink>
                                    </li> */}
                  <li>
                    <NavLink to="/chat">
                      <i className="fas fa-angle-double-right"></i> Connect Now
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/opportunities">
                      <i className="fas fa-angle-double-right"></i>{" "}
                      Opportunities
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/beyondSurgery">
                      <i className="fas fa-angle-double-right"></i> Beyond
                      Surgery
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/community">
                      <i className="fas fa-angle-double-right"></i> Cases
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/video">
                      <i className="fas fa-angle-double-right"></i> Academics
                    </NavLink>
                  </li>
                  {/* <li>
                                        <NavLink to="/news">
                                            <i className="fas fa-angle-double-right"></i> News
                                        </NavLink>
                                    </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-1"></div>
            {/* <div className="col-lg-3 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">For Doctors</h2>
                                <ul>
                                    <li><a href="appointments.html"><i className="fas fa-angle-double-right"></i>
                                        Appointments</a></li>
                                    <li><a href="chat.html"><i className="fas fa-angle-double-right"></i> Chat</a></li>
                                    <li><a href="login.html"><i className="fas fa-angle-double-right"></i> Login</a></li>
                                    <li><a href="doctor-register.html"><i className="fas fa-angle-double-right"></i>
                                        Register</a></li>
                                    <li><a href="doctor-dashboard.html"><i className="fas fa-angle-double-right"></i> Doctor
										Dashboard</a></li>
                                </ul>
                            </div>

                        </div> */}

            <div className="col-lg-3 col-md-6">
              <div className="footer-widget footer-contact">
                <h2 className="footer-title">Contact Us</h2>
                <div className="footer-contact-info">
                  {/* <div className="footer-address">
                                        <span><i className="fas fa-map-marker-alt"></i></span>
                                        <p> 3556 Beech Street, San Francisco,<br /> California, CA 94108 </p>
                                    </div>
                                    <p>
                                        <i className="fas fa-phone-alt"></i>
                                        +91-9888888888
								    </p> */}
                  <p className="mb-0 mail">
                    <i className="fas fa-envelope"></i>
                    <a href="mailto:support@surgeonsforsurgeons.com">
                      support@surgeonsforsurgeons.com
                    </a>
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6">

                            <div className="footer-widget footer-menu">
                                <h2 className="footer-title">Social Media</h2>

                                <ul>
                                    <li>
                                        <a href="#" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank"><i className="fab fa-twitter"></i> </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank"><i className="fab fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank"><i className="fab fa-dribbble"></i> </a>
                                    </li>
                                </ul>

                                <div className="social-icon">
                                    <ul>
                                        <li>
                                            <a href="https://facebook.com" target="_blank"><i className="fab fa-facebook-f"></i> </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com" target="_blank"><i className="fab fa-twitter"></i> </a>
                                        </li>
                                        <li>
                                            <a href="https://linkedin.com" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com" target="_blank"><i className="fab fa-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://dribbble.com" target="_blank"><i className="fab fa-dribbble"></i> </a>
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div> */}
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container-fluid">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="copyright-text">
                  <p className="mb-0">
                    &copy; 2023{" "}
                    <a href="https://surgeonsforsurgeons.com/" target="_blank">
                      {" "}
                      Surgeons for Surgeons
                    </a>
                    . All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="copyright-menu">
                  <ul className="policy-menu text-md-center text-left">
                    <li>
                      {/* <a href={termsOfUse} target="_blank"> */}
                      <NavLink to={`terms_and_conditions`}>
                        Terms of Use
                        {/* </a> */}
                      </NavLink>
                    </li>
                    <li>
                      {/* <a href={companyPolicy} target="_blank"> */}
                      <NavLink to={`privacy-policy`}>
                        {" "}
                        Company Policies{" "}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterContent;
