import React from "react";
import CookieConsent from "react-cookie-consent";

const message =
  "We use cookies to improve your experience on our site. By using our site you consent cookies.";

const CookieAccept = () => {
  return (
    <CookieConsent
      style={{
        background: "white",
        color: "inherit",
        width: "200px",
        left: "15px",
        marginBottom: "15px",
        borderRadius: 5,
        paddingTop: "5px",
        paddingBottom: "25px",
        boxShadow: "rgb(0 0 0 / 12%) 3px 3px 17px 5px",
      }}
      contentStyle={{
        flex: "1 0 150px",
        textAlign: "center",
      }}
      buttonStyle={{
        marginTop: 0,
        fontStyle: ".8rem",
        backgroundColor: "#167faf",
        color: "white",
      }}
      buttonText={"Accept"}
    >
      <div
        className="d-flex justify-content-end"
        style={{ paddingBottom: "20px", cursor: "pointer" }}
      >
        <i
          className="fas fa-times"
          style={{ color: "#626b6e" }}
          onClick={() =>
            (document.querySelector(".CookieConsent").style.display = "none")
          }
        ></i>
      </div>
      {message}
      <br></br>
      <b>
        <a
          href="https://policies.google.com/technologies/partner-sites?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </b>
    </CookieConsent>
  );
};

export default CookieAccept;
