import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  OverlayTrigger,
  Tooltip,
  Carousel,
} from "react-bootstrap";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { NavLink, useLocation, withRouter } from "react-router-dom";
import docThumb from "../assets/img/doctors/doctor-thumb-01.jpg";
import logoImg from "../assets/img/logo3.png";
import Constants from "../configs/Constants";
import SearchUserInput from "./SearchUserInput";
import UserSearchBar from "./UserSearchBar";

// import { useLocation } from 'react-router';

const MainContent = (props) => {
  const [loginInfo, setLoginInfo] = useState(null);
  const [chatDisabled, setChatDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [notifcationData, setNotifcationData] = useState([]);
  const [isActiveNoti, setActiveNoti] = useState(false);
  const [isActiveNotiMobile, setActiveNotiMobile] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loginType,setLoginType] = useState(null);

  const location = useLocation();

  const toggleClass = (event) => {
    event && event.preventDefault();
    setActiveNoti(!isActiveNoti);
  };

  const toggleClassMobile = (event) => {
    event && event.preventDefault();
    setActiveNotiMobile(!isActiveNotiMobile);
  };

  document.addEventListener("loginLogut", (event) => {
    if (event.type !== "loginLogut") return;
    const loginDetails = JSON.parse(sessionStorage.getItem("session"));
    setLoginInfo(loginDetails);
    loginDetails && setChatDisabled(!loginDetails.is_all_details_filled);
  });

  React.useEffect(() => {
    setLoginDetails();
    const type = sessionStorage.getItem("type");
    setLoginType(type);
    document.addEventListener(
      "setLoginDetailsEvent",
      (event) => {
        // if ('setLoginDetailsEvent' !== event.type) return;
        setLoginDetails();
      },
      false
    );

    document.addEventListener(
      "loaderEvt",
      (event) => {
        if ("loaderEvt" !== event.type) return;
        const { showLoader } = event.detail;
        setShowLoader(showLoader);
      },
      false
    );
  }, []);

  const setLoginDetails = () => {
    const loginDetails = JSON.parse(sessionStorage.getItem("session"));
    // console.log("loginDetails>>>>>>>>>>>>>>", loginDetails);
    setLoginInfo(loginDetails);
    loginDetails && setChatDisabled(!loginDetails.is_all_details_filled);
    if (loginDetails) {
      callForNotification(loginDetails);
    }
  };

  const callForNotification = (loginDetails) => {
    const id = loginInfo ? loginInfo.id : loginDetails.id;
    if (id) {
      axios
        .post(Constants.server + "getNotificationData", {
          sent_to: id,
        })
        .then((response) => {
          console.log("response123>>>>>>>>>>>", response.data.result);
          if (response.data.result.length) {
            // response.data.result.push({
            //     msg: 'Clear All',
            //     created_date_time: 'Clear All',
            //     sent_to: loginDetails.id
            // })
            setNotifcationData(
              [...response.data.result].sort(
                (a, b) =>
                  new Date(b.created_date_time).getTime() -
                  new Date(a.created_date_time).getTime()
              )
            );
          } else {
            setNotifcationData([]);
          }
          window.setInterval(() => {
            axios
              .post(Constants.server + "getNotificationData", {
                sent_to: id,
              })
              .then((response) => {
                console.log("response123>>>>>>>>>>>", response.data.result);
                if (response.data.result.length) {
                  // response.data.result.push({
                  //     msg: 'Clear All',
                  //     created_date_time: 'Clear All',
                  //     sent_to: loginDetails.id
                  // })
                  setNotifcationData([...response.data.result].reverse());
                } else {
                  setNotifcationData([]);
                }
              });
          }, 30000);
        });
    }
  };

  const logoutUser = () => {
    window.sessionStorage.clear();
    window.location.href = "//" + window.location.host;
  };

  const handleClick = (e) => {
    const loginDetails = JSON.parse(sessionStorage.getItem("session"));
    setLoginInfo(loginDetails);
    // console.log("loginDetails>>>>>>>>>>>", loginDetails);
    // console.log("loginDetails.is_all_details_filled>>>>>>>>>>>", !loginDetails.is_all_details_filled);
    loginDetails && setChatDisabled(!loginDetails.is_all_details_filled);
    if (loginDetails && !loginDetails.is_all_details_filled) e.preventDefault();
  };

  const toggleHamberger = (flag) => {
    if (flag) {
      document.getElementsByTagName("html")[0].classList = ["menu-opened"];
      if (
        !document
          .getElementsByClassName("sidebar-overlay")[0]
          .classList.contains("opened")
      ) {
        document
          .getElementsByClassName("sidebar-overlay")[0]
          .classList.add("opened");
      }
    } else {
      document.getElementsByTagName("html")[0].classList = [""];
      if (
        document
          .getElementsByClassName("sidebar-overlay")[0]
          .classList.contains("opened")
      ) {
        document
          .getElementsByClassName("sidebar-overlay")[0]
          .classList.remove("opened");
      }
    }
  };

  const onClickReadNotifaction = (elm) => {
    // console.log(props);
    // debugger;
    if (elm.msg !== "Clear All") {
      props.history.push("/" + elm.module);
    }
    axios.post(Constants.server + "readNotification", {
      ...elm,
    });

    if (!isNaN(elm.id))
      setNotifcationData((oldData) =>
        oldData.filter((data) => data.id !== elm.id)
      );
    // .then((response) => {
    //   callForNotification();
    // });
  };

  const displayTime = (time) => {
    if (time !== "Clear All") {
      return moment(time).calendar();
    }
  };
  return (
    <React.Fragment>
      {/* <Modal show={show} onHide={handleClose}> */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Search Surgeons </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserSearchBar
            sendToPath="profile"
            style={{ minHeight: "40px" }}
            handleClose={handleClose}
          />
          {/* <SearchUserInput
            sendToPath="profile"
            style={{ minHeight: "40px" }}
            handleClose={handleClose}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
        </Modal.Footer>
      </Modal>

      <div
        style={{
          top: 0,
          zIndex: "2000",
          height: "100vh",
          width: "100vw",
          position: "absolute",
          display: showLoader ? "block" : "none",
          backgroundColor: "#b4b4b430",
        }}
      >
        <Loader
          className="loader-app"
          type="Oval"
          color="#096d9c"
          height={70}
          width={70}
          // timeout={50000} //3 secs
          visible={showLoader}
        />
      </div>

      <div
        className="main-wrapper"
        style={{ position: "sticky", top: 0, zIndex: 999 }}
      >
        <header className="header">
          <nav className="navbar navbar-expand-lg header-nav">
            {/* style={{width: '60px', marginRight: '48px'}} */}
            <div className="navbar-header header-navbar-mobile" >
              <a id="mobile_btn" onClick={toggleHamberger.bind(this, true)}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </a>
              <NavLink to="/home" className="navbar-brand logo temp-class"  >
                <img src={logoImg} className="img-fluid" alt="Logo" />
              </NavLink>

              <NavLink
                to="/chat"
                onClick={toggleHamberger.bind(this, false)}
                className="msg-btn nav-link chat-mobile-btn"
                role="button"
                tabIndex="-1"
                aria-disabled="true"
                style={{
                  fontSize: "30px",
                  color: "#0ddbfb",
                  marginLeft: loginInfo != null ? "0px" : "120px",
                }}
              >
                <i className="far fa-comment chat-mobile-btn"></i>
              </NavLink>

              {loginInfo !== null ? (
                <React.Fragment>
                  <a
                    className="chat-mobile-btn"
                    style={{
                      fontSize: "30px",
                      color: "#0ddbfb",
                    }}
                  >
                    <button
                      className="btn btn-link"
                      onClick={handleShow}
                      title="Search Surgeons"
                      style={{ color: "#096d9c" }}
                    >
                      <i
                        className="fa fa-search"
                        style={{ marginLeft: "4px" }}
                      ></i>
                    </button>
                  </a>

                  {/* <a
                    className={
                      isActiveNotiMobile
                        ? "nav-item dropdown noti-dropdown show chat-mobile-btn"
                        : "nav-item dropdown noti-dropdown chat-mobile-btn"
                    }
                    onClick={toggleClassMobile}
                  >
                    <a
                      href="javascript:void(0);"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      onClick={(event) => event.preventDefault()}
                    >
                      <i className="fa fa-bell" style={{ marginRight: 0 }}></i>{" "}
                      <span
                        className="badge badge-pill"
                        style={{
                          paddingLeft: 0,
                          marginLeft: -18,
                          paddingRight: 0,
                        }}
                      >
                        {notifcationData && notifcationData.length ? (
                          <div
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              // padding: 10,
                              borderRadius: "50%",
                              textAlign: "center",
                              width: 16,
                              height: 16,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>{notifcationData.length}</div>
                          </div>
                        ) : null}
                      </span>
                    </a>
                    <div
                      className="dropdown-menu notifications show"
                      style={{
                        maxWidth: "325px",
                        left: "-30%",
                      }}
                    >
                      <div className="topnav-dropdown-header">
                        <span className="notification-title">
                          Notifications
                        </span>
                        <a
                          href="javascript:void(0);"
                          onClick={(event) => {
                            event.preventDefault();
                            onClickReadNotifaction({
                              msg: "Clear All",
                              created_date_time: "Clear All",
                              sent_to: loginInfo.id,
                            });
                          }}
                          className="clear-noti"
                          style={{
                            color: "#096d9c",
                          }}
                        >
                          Clear All
                        </a>
                      </div>
                      <div className="noti-content">
                        <ul className="notification-list">
                          {notifcationData && notifcationData.length ? (
                            notifcationData.map((elm, idx) => {
                              return (
                                <li
                                  className="notification-message"
                                  key={idx}
                                  onClick={onClickReadNotifaction.bind(
                                    this,
                                    elm
                                  )}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    onClick={(event) => event.preventDefault()}
                                  >
                                    <div className="media">
                                      <span className="avatar avatar-sm">
                                        <img
                                          className="avatar-img rounded-circle"
                                          alt="User Image"
                                          src={docThumb}
                                        />
                                      </span>
                                      <div className="media-body">
                                        <p className="noti-title">{elm.msg}</p>
                                        <p className="noti-time">
                                          <span className="notification-time">
                                            {displayTime(elm.created_date_time)}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <li className="notification-message col-md-12">
                              <a
                                href="#"
                                onClick={(event) => event.preventDefault()}
                              >
                                <div className="media center">
                                  <div className="media-body">
                                    <p className="noti-details">
                                      <span className="noti-title">
                                        No new notification
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="topnav-dropdown-footer">
                        <a
                          href="#"
                          onClick={toggleClassMobile}
                          style={{
                            background: "#096d9c",
                            color: "white",
                            fontWeight: "600",
                            textAlign: "center",
                            width: "100%",
                            paddingLeft: "20px",
                          }}
                        >
                          {" "}
                          Close
                        </a>
                      </div>
                    </div>
                  </a> */}
                </React.Fragment>
              ) : null}
              {/* <SearchUserInput sendToPath="profile" placeholder="Search Surgeons" style={{minHeight: '40px'}} /> */}
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                {/* <NavLink to="/home" className="menu-logo">
                                <img src={logoImg} className="img-fluid" alt="Logo" />
                            </NavLink> */}
                {/* <a href="index.html" className="menu-logo">
                            </a> */}
                <a
                  id="menu_close"
                  className="menu-close"
                  onClick={toggleHamberger.bind(this, false)}
                >
                  <i className="fas fa-times"></i>
                </a>
              </div>
              <ul className="main-nav">
                <li>
                  <NavLink
                    className={
                      props.location.pathname == "/home" ? "liked" : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/home",
                      state: location.pathname,
                    }}
                  >
                    Home
                  </NavLink>

                  {/* <a href="index.html">Home</a> */}
                </li>

                <li>
                  <NavLink
                    className={
                      props.location.pathname == "/home/features"
                        ? "liked"
                        : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/home/features",
                      state: location.pathname,
                    }}
                  >
                    Features
                  </NavLink>
                  {/* <a href="/home/features" >Features</a> */}
                </li>

                {/* <li>
                                <NavLink onClick={toggleHamberger.bind(this, false)} to={{ pathname: "/home/howWework", state: location.pathname }} >How We Work?</NavLink>
                            </li> */}

                <li>
                  <NavLink
                    className={
                      ["/media", "/video"].includes(props.location.pathname)
                        ? "liked"
                        : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/video",
                      state: location.pathname,
                    }}
                  >
                    Learn with SFS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      ["/explore"].includes(props.location.pathname)
                        ? "liked"
                        : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/explore",
                      state: location.pathname,
                    }}
                  >
                    Explore
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className={
                      ["/opportunities", "/opportunityPosted"].includes(
                        props.location.pathname
                      )
                        ? "liked"
                        : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/opportunities",
                      state: location.pathname,
                    }}
                  >
                    {" "}
                    Opportunities{" "}
                  </NavLink>
                  {/* <a href="#" target="_blank">Media</a> */}
                </li>

                {/* <li>
                                <NavLink onClick={toggleHamberger.bind(this, false)} to={{ pathname: "/cases", state: location.pathname }} > Cases </NavLink>
                            </li> */}

                {/* <li>
                                <NavLink onClick={toggleHamberger.bind(this, false)} to={{ pathname: "/news", state: location.pathname }} > News </NavLink>
                            </li> */}

                <li>
                  <NavLink
                    className={
                      [
                        "/beyondSurgery",
                        "/beyondSurgeryMyPost",
                        "/beyondSurgeryMyCollection",
                        "/beyondSurgoryFollowing",
                        "/beyondSurgoryFollowingFriendList",
                      ].includes(props.location.pathname)
                        ? "liked"
                        : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/beyondSurgery",
                      state: location.pathname,
                    }}
                  >
                    {" "}
                    Beyond Surgery{" "}
                  </NavLink>
                </li>

                {/* <li>
                                <NavLink onClick={toggleHamberger.bind(this, false)} to={{ pathname: "/home/blogs", state: location.pathname }}  >Blogs</NavLink>
                            </li> */}

                {/* <li>
                                <NavLink onClick={toggleHamberger.bind(this, false)} to={{ pathname: "/about", state: location.pathname }}  >About</NavLink>
                            </li> */}

                <li>
                  <NavLink
                    className={
                      [
                        "/community",
                        "/communityCollection",
                        "/myCasesCommunity",
                      ].includes(props.location.pathname)
                        ? "liked"
                        : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/community",
                      state: location.pathname,
                    }}
                  >
                    {" "}
                    Cases{" "}
                  </NavLink>
                  {/* <a href="#" target="_blank">Media</a> */}
                </li>
                <li>
                  <NavLink
                    className={
                      ["/event", "/upcoming"].includes(
                        props.location.pathname
                      )
                        ? "liked"
                        : null
                    }                    
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/event",
                      state: location.pathname,
                    }}
                  >
                    Events
                  </NavLink>
                  {/* <a href="admin/index.html" target="_blank">Contact</a> */}
                </li>

                <li>
                  <NavLink
                    className={
                      props.location.pathname == "/contactPage" ? "liked" : null
                    }
                    onClick={toggleHamberger.bind(this, false)}
                    to={{
                      pathname: "/contactPage",
                      state: location.pathname,
                    }}
                  >
                    Contact
                  </NavLink>
                  {/* <a href="admin/index.html" target="_blank">Contact</a> */}
                </li>

                {chatDisabled ? (
                  <React.Fragment>
                    <li className="login-link">
                      <NavLink
                        onClick={toggleHamberger.bind(this, false)}
                        className="nav-link header-login"
                        to={{
                          pathname: "/login",
                          state: location.pathname,
                        }}
                      >
                        Login / Signup
                      </NavLink>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="login-link">
                      <NavLink
                        onClick={toggleHamberger.bind(this, false)}
                        to={"/profile/" + loginInfo.id}
                        title="View Profile"
                      >
                        My Profile - {loginInfo.full_name}
                      </NavLink>
                    </li>
                    {loginType !== 'third_party' && <li className="login-link">
                      <NavLink
                        onClick={toggleHamberger.bind(this, false)}
                        to={"/changePassword/" + loginInfo.id}
                        title="Change Password"
                      >
                        Change Password
                      </NavLink>
                    </li>}
                    <li className="login-link" onClick={logoutUser}>
                      <a onClick={logoutUser} style={{color:'#fff'}}> Logout </a>
                    </li>
                  </React.Fragment>
                )}
              </ul>
            </div>

            <ul className="nav  header-navbar-rht">
              {loginInfo !== null ? (
                // <li style={{ marginTop: "10px" }}>
                <li>
                  <button
                    className="btn btn-link"
                    onClick={handleShow}
                    title="Search Surgeons"
                    style={{ color: "#096d9c" }}
                  >
                    Search{" "}
                    <i
                      className="fa fa-search"
                      style={{ marginLeft: "4px" }}
                    ></i>
                  </button>
                  {/* <SearchUserInput sendToPath="profile" placeholder="Search Surgeons" style={{minHeight: '40px'}} /> */}
                </li>
              ) : null}
              <li className="nav-item" title="">
                {chatDisabled ? (
                  <OverlayTrigger
                    key={"bottom"}
                    placement={"left"}
                    overlay={
                      <Tooltip id={`tooltip-bottom`}>
                        Connect Now button will be active once you{" "}
                        <strong> succesfully login </strong>
                      </Tooltip>
                    }
                  >
                    <NavLink
                      className="nav-link header-login chat-now"
                      to={{
                        pathname: "/login",
                        state: location.pathname,
                      }}
                    >
                      Connect Now
                    </NavLink>
                  </OverlayTrigger>
                ) : (
                  <NavLink
                    className={
                      "nav-link header-login chat-now " +
                      (chatDisabled ? "disabled" : "chat-now-btn")
                    }
                    onClick={handleClick}
                    to={{
                      pathname: "/chat",
                      state: location.pathname,
                    }}
                  >
                    Connect now 
                  </NavLink>
                )}
              </li>
              {loginInfo === null ? (
                <li className="nav-item">
                  <NavLink className="nav-link header-login" to="/login">
                    Login / Signup
                  </NavLink>
                </li>
              ) : (
                <React.Fragment>
                  {/* <li
                    className={
                      isActiveNoti
                        ? "nav-item dropdown noti-dropdown show"
                        : "nav-item dropdown noti-dropdown"
                    }
                    onClick={toggleClass}
                  >
                    <a
                      href="javascript:void(0);"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      onClick={(event) => event.preventDefault()}
                    >
                      <i className="fa fa-bell" style={{ marginRight: 0 }}></i>{" "}
                      <span
                        className="badge badge-pill"
                        style={{
                          paddingLeft: 0,
                          marginLeft: -18,
                        }}
                      >
                        {notifcationData && notifcationData.length ? (
                          <div
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              // padding: 10,
                              borderRadius: "50%",
                              textAlign: "center",
                              width: 16,
                              height: 16,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>{notifcationData.length}</div>
                          </div>
                        ) : null}
                      </span>
                    </a>
                    <div className="dropdown-menu notifications show">
                      <div className="topnav-dropdown-header">
                        <span className="notification-title">
                          Notifications
                        </span>
                        <a
                          href="javascript:void(0);"
                          onClick={(event) => {
                            event.preventDefault();
                            onClickReadNotifaction({
                              msg: "Clear All",
                              created_date_time: "Clear All",
                              sent_to: loginInfo.id,
                            });
                          }}
                          className="clear-noti"
                          style={{
                            color: "#096d9c",
                          }}
                        >
                          Clear All
                        </a>
                      </div>
                      <div className="noti-content">
                        <ul className="notification-list">
                          {notifcationData && notifcationData.length ? (
                            notifcationData.map((elm, idx) => {
                              return (
                                <li
                                  className="notification-message"
                                  key={idx}
                                  onClick={onClickReadNotifaction.bind(
                                    this,
                                    elm
                                  )}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    onClick={(event) => event.preventDefault()}
                                  >
                                    <div className="media">
                                      <span className="avatar avatar-sm">
                                        <img
                                          className="avatar-img rounded-circle"
                                          alt="User Image"
                                          src={docThumb}
                                        />
                                      </span>
                                      <div className="media-body">
                                        <p className="noti-title">{elm.msg}</p>
                                        <p className="noti-time">
                                          <span className="notification-time">
                                            {displayTime(elm.created_date_time)}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              );
                            })
                          ) : (
                            <li className="notification-message col-md-12">
                              <a
                                href="#"
                                onClick={(event) => event.preventDefault()}
                              >
                                <div className="media center">
                                  <div className="media-body">
                                    <p className="noti-details">
                                      <span className="noti-title">
                                        No new notification
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="topnav-dropdown-footer">
                        <a
                          href="#"
                          onClick={toggleClass}
                          style={{
                            background: "#096d9c",
                            color: "white",
                            fontWeight: "600",
                            textAlign: "center",
                            width: "100%",
                            paddingLeft: "20px",
                          }}
                        >
                          {" "}
                          Close
                        </a>
                      </div>
                    </div>
                  </li> */}
                  {/* <Dropdown>
                                    <Dropdown.Toggle id="dropdown-custom-11">
                                        <span className="fa fa-bell"></span>
                                        {
                                            notifcationData && notifcationData.length > 1 ? (
                                                <span className="dot-red">{notifcationData && (notifcationData.length - 1)}</span>
                                            ) : null
                                        }
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="super-colors super-colors-notify">
                                        {
                                            notifcationData && notifcationData.length ? (
                                                notifcationData.map((elm, idx) => {
                                                    return (
                                                        <Dropdown.Item eventKey={idx} onClick={onClickReadNotifaction.bind(this, elm)}>
                                                            {elm.msg} <span>&nbsp;</span> <code> {displayTime(elm.created_date_time)} </code>
                                                        </Dropdown.Item>
                                                    )
                                                })

                                            )
                                                : <Dropdown.Item eventKey="100001">No New Notification</Dropdown.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown> */}

                  <DropdownButton
                    alignRight
                    title={
                      <div className="avatar avatar-online">
                        <img
                          src={
                            Constants.server +
                            loginInfo.profile_pic +
                            `?${new Date().getTime()}`
                          }
                          alt="User Image"
                          className="avatar-img rounded-circle"
                        />
                      </div>
                    }
                    id="dropdown-menu-align-right"
                  >
                    <Dropdown.Item
                      eventKey="4"
                      href={"#/profile/" + loginInfo.id}
                    >
                      <NavLink
                        to={"/profile/" + loginInfo.id}
                        title="View Profile"
                      >
                        {loginInfo.full_name}
                      </NavLink>
                    </Dropdown.Item>
                    {loginType !== 'third_party' && 
                    <Dropdown.Item eventKey="5">
                      <NavLink
                        to={"/changePassword/" + loginInfo.id}
                        title="View Profile"
                      >
                        Change Password
                      </NavLink>
                    </Dropdown.Item>}
                    <Dropdown.Item eventKey="3" onClick={logoutUser}>
                      Logout
                    </Dropdown.Item>
                  </DropdownButton>

                  {/* <li className="nav-item">
                                    {loginInfo.full_name}
                                </li>
                                <li className="nav-item" onClick={logoutUser}>
                                    Logout
                                </li> */}
                </React.Fragment>
              )}
            </ul>
          </nav>
        </header>

        {/*----------  Carousel  ----------*/}

        {/* <div style={{ backgroundColor: "black", padding: "15px" }}>
                    <Carousel
                        variant="dark"
                        indicators={false}
                        controls={false}
                    >
                        <Carousel.Item style={{ color: "black" }}>
                            <div>
                                <h1
                                    style={{
                                        "margin-top": "5px",
                                        textAlign: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "yellow",
                                            fontSize: "15px",
                                        }}
                                    >
                                        New Event :{" "}
                                    </span>
                                    <span
                                        style={{
                                            color: "white",
                                            fontSize: "15px",
                                        }}
                                    >
                                        Preoperative optimization of patients for surgery on
                                        6th November 2021{" "}
                                    </span>
                                    <NavLink
                                        to="/upcoming"
                                        style={{ color: "#0CDCFB" }}
                                        className="ml-2"
                                    >
                                        View Details
                                    </NavLink>
                                </h1>
                            </div>
                        </Carousel.Item> */}
        {/* <Carousel.Item style={{ color: "black" }}>
                            <div>
                                <h1
                                    style={{
                                        "margin-top": "5px",
                                        textAlign: "center",
                                    }}
                                >
                                    <span
                                        style={{
                                            color: "#70B26E",
                                            fontSize: "15px",
                                        }}
                                    >
                                        New Event :{" "}
                                    </span>
                                    <span
                                        style={{
                                            color: "black",
                                            fontSize: "15px",
                                        }}
                                    >
                                        Consent For Surgery on
                                        18th September 2021{" "}
                                    </span>
                                    <NavLink
                                        to="/upcoming"
                                        style={{ color: "#096D9C" }}
                                        className="ml-2"
                                    >
                                        View Details
                                    </NavLink>
                                </h1>
                            </div>
                        </Carousel.Item> */}
        {/* <Carousel.Item>
              
              <div style={{"display":"flex","flexDirection":"row","justifyContent":"center"}}>
                      <h1 style={{"margin-top":"5px"}}>
                        <span style={{"color":"#70B26E","fontSize":"15px"}}>New Event : </span>
                        <span style={{"color":"black","fontSize":"15px"}}>What's new in the Surgical World? on 28th August 2021 </span>
                        <NavLink to="/upcoming"><span style={{"color":"#096D9C"}} className="ml-2">View Details</span></NavLink>
                      </h1>
                  </div>
              </Carousel.Item>
              <Carousel.Item>
              
              <div style={{"display":"flex","flexDirection":"row","justifyContent":"center"}}>
                      <h1 style={{"margin-top":"5px"}}>
                        <span style={{"color":"#70B26E","fontSize":"15px"}}>New Event : </span>
                        <span style={{"color":"black","fontSize":"15px"}}>What's new in the Surgical World? on 28th August 2021 </span>
                        <NavLink to="/upcoming"><span style={{"color":"#096D9C"}} className="ml-2">View Details</span></NavLink>
                      </h1>
                </div>
            
              </Carousel.Item> */}
        {/* </Carousel>
                </div> */}

        {/*----------  End carousel  ----------*/}
      </div>
    </React.Fragment>
  );
};

export default withRouter(MainContent);
