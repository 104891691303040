import React, { createRef, Fragment, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import sfs1 from "../assets/img/event_banner.jpeg";
import sfs2 from "../assets/img/sfs_02.jpg";
import sfs4 from "../assets/img/SFS_EVENT-2.jpeg";
import sfs3 from "../assets/img/SFS_EVENT.jpeg";
import sfs5 from "../assets/img/upcoming_events/event1.jpeg";
import sfs6 from "../assets/img/upcoming_events/event2.jpeg";
import sfs7 from "../assets/img/upcoming_events/6-11.png";
import sfs8 from "../assets/img/upcoming_events/event3.jpeg";
import Config from "../configs/Config";
import FooterContent from "./FooterContent";
import apis from "../apis/apis";
import { format } from "date-fns";
import Constants from "../configs/Constants";

const Event = (props) => {
  const [showModalPopup, setShowModalPopup] = useState(false);
  const [secondEvent, setSecondEvent] = useState(false);
  const [eventNo, setEventNo] = useState(0);
  const [showAllEvents, setShowAllEvents] = useState(null);
  const images = [sfs8, sfs7, sfs6, sfs5, sfs4, sfs3, sfs2, sfs1];
  const handleShowModalHide = () => {
    setShowModalPopup(false);
  };
  const open_modal = () => {
    setShowModalPopup(true);
  };
  const videoSection = createRef();
  useEffect(() => {
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    if (!id || isAllDetailsFilled === 0) {
      // if (!id) {
      props.history.push("/login");
    } else {
      getAllEvents();
    }
  }, []);

  const getAllEvents = async () => {
    try {
      let data = await apis.getAllPastEvents();
      setShowAllEvents(data.data.event);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="main-wrapper" ref={videoSection}>
        <div className="banner-header text-center">
          {/* <h1 style={{ color: '#2272a1', textTransform: 'unset', fontSize: ' 3.25rem', marginTop: '25px', marginBottom: '25px' }}>
                        Media
                </h1> */}
        </div>

        <section className="section section-blogs" ref={videoSection}>
          <div className="container-fluid">
            <div className="section-header text-center">
              <h2
                style={{
                  color: "#2272a1",
                  textTransform: "unset",
                  fontSize: " 3.25rem",
                }}
              >
                Events
              </h2>

              {/* <div className="row align-items-center justify-content-center">
                                <div className="col-md-6">
                                    <div className="card-body">
                                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                                            <li className="nav-item">
                                                <NavLink
                                                    activeClassName="active"
                                                    className="nav-link"
                                                    to="/media"
                                                >
                                                    Images
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="nav-link"
                                                    to="/video"
                                                >
                                                    Videos
                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                                <NavLink
                                                    className="nav-link active"
                                                    to="/event"
                                                >
                                                    Events
                                                </NavLink>
                                            </li>
                                            {/* <li className="nav-item">
                                                <NavLink className="nav-link" to="/meeting">
                                                    Meetings
                                                </NavLink>
                                            </li> }
                                        </ul>
                                    </div>
                                </div>
                            </div> */}

              {/* <p className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua.</p> */}
            </div>

            <div className="row align-items-center justify-content-center">
              <div className="col-md-6">
                <div className="card-body">
                  <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                    {/*=============================================
                                      =            upcoming tab            =
                                      =============================================*/}

                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/upcoming"
                      >
                        Upcoming Events
                      </NavLink>
                    </li>
                    {/*=====  End of upcoming tab  ======*/}

                    <li className="nav-item">
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/event"
                      >
                        Past Events
                      </NavLink>
                    </li>
                    {/* <li className="nav-item">
                                            <NavLink className="nav-link" to="/ongoing">
                                                Ongoing Event
                                            </NavLink>
                                        </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <br></br>

            <div className="row  justify-content-center">
              {showAllEvents?.map((item) => {
                return (
                  <div className="col-md-6 col-lg-4 mb-5 " key={item?.id}>
                    <div className="blog grid-blog h-100 mb-0 ">
                      <div className="blog-image">
                        <img
                          className="img-fluid1"
                          // src={item?.banner}
                          // src={sfs3}
                          src={Constants.server + "events/" + item?.banner}
                          alt="Post Image"
                          style={{ height: "280px" }}
                        />
                      </div>
                      <div className="blog-content">
                        <ul className="entry-meta meta-item">
                          <li className="w-100">
                            <div className="post-author event-title">
                              <span className="event-title">{item?.title}</span>
                            </div>
                            {/* <p className="mb-0 event-desc-main">
                              <span
                                style={{
                                  color: "#20c0f3",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  open_modal();
                                  setSecondEvent(true);
                                  setEventNo(0);
                                }}
                              >
                                View Details
                              </span>
                            </p> */}
                            <p className="mb-0 event-desc-main">
                              <span
                                style={{
                                  color: "#20c0f3",
                                }}
                              ></span>
                            </p>
                          </li>

                          <li className="event-date">
                            <span className="eventdate">
                              <i className="far fa-calendar"></i>
                              &nbsp;{item?.date}
                            </span>
                          </li>
                        </ul>
                        <h3 className="blog-title event-desc">
                          <span class="cust-bold">Venue :</span>{" "}
                          {item?.location}
                        </h3>
                        {/* <h3 className="blog-title event-desc">
                          New York 7AM | London 12PM | Mumbai 5:30PM | Adelaide
                          10:30PM | Dubai 4PM
                        </h3> */}
                        {/* <h3 className="blog-title event-desc mt-3">
                                            <span class="cust-bold">
                                                Meeting ID
                                            </span>{" "}
                                            : 847 3672 0707
                                            <br />
                                            <span class="cust-bold">
                                                Passcode
                                            </span>{" "}
                                            : 566754
                                        </h3> */}
                        <div style={{ textAlign: "center" }}>
                          <br></br>
                          <Button
                            variant="primary"
                            className="align-self-end"
                            disabled
                          >
                            Completed
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Modal show={showModalPopup} onHide={handleShowModalHide} size="lg">
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    width: "-webkit-fill-available",
                    height: "auto",
                  }}
                  src={images[eventNo]}
                  height="400px"
                ></img>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </section>
      </div>
      <FooterContent></FooterContent>
    </Fragment>
  );
};

export default Event;
