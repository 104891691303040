import React, { useEffect, useRef, useState } from "react";
import ExploreCard from "./ExploreCard";
import apis from "../apis/apis";
import ExploreFilterModal from "./ExploreFilterModal";
import AddExploreModal from "./AddExploreModal";
import { Alert, Badge } from "react-bootstrap";
import Config from "../configs/Config";
import InfiniteScroll from "react-infinite-scroll-component";
import "../assets/css/reels.css";
import LoadingSpinner from "./LoadingSpinner";
import LoaderEmitters from "../configs/LoaderEmitters";

const Explore = (props) => {
  const [allExploreData, setAllExploreData] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [isFilterShow, setIsFilterShow] = useState(false);
  const [isAddShow, setIsAddShow] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const pageRef = useRef(1);

  useEffect(() => {
    // document.getElementById('root').style.overflow='hidden'
    // document.getElementById('root').style.height='100vh'
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    console.log(id,"get testing",isAllDetailsFilled);

    // if (!id || isAllDetailsFilled === 0) {
    //   // if (!id) {
    //   props.history.push("/login");
    // } else {
    //   getExploreData();
    //   document.body.style.overflow = "hidden";
    // }
    return () => {
      // document.getElementById('root').style.overflowY='scroll'
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (!initialLoad) {
      setHasMore(true);
      pageRef.current = 1;
      getExploreData();
    }
    setInitialLoad(false);
  }, [filterList,initialLoad]);

  const getExploreData = async () => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    console.log("here", pageRef.current);
    setIsLoading(true);
    const formattedArray = filterList.map((item) => `"${item}"`).join(",");
    console.log(formattedArray);
    try {
      let { data } = await apis.getAllExplorePost(
        pageRef.current,
        encodeURIComponent(formattedArray),
        Config.getSessionInfo("id")
      );
      setAllExploreData(data.posts);
      data?.posts?.length<10?setHasMore(false):setHasMore(true)
      console.log(hasMore)
      pageRef.current++;
    } catch (error) {
      console.log(error);
    }
    LoaderEmitters.showLoader({
      showLoader: false,
    });
    setIsLoading(false);
  };
  const getNextPage = async () => {
    console.log("here", pageRef.current);
    const formattedArray = filterList.map((item) => `"${item}"`).join(",");
    console.log(formattedArray);
    try {
      let { data } = await apis.getAllExplorePost(
        pageRef.current,
        encodeURIComponent(formattedArray),
        Config.getSessionInfo("id")
      );
      if (data.posts.length <10) setHasMore(false);
      setAllExploreData(allExploreData.concat(data.posts));
      pageRef.current++;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <InfiniteScroll
      dataLength={allExploreData?.length}
      next={getNextPage}
      hasMore={hasMore}
      loader={
        <div className="pb-5 mb-5">
          {pageRef.current !== 1 && <LoadingSpinner />}
        </div>
      }
      className="infinite-class pb-5"
      height={"100vh"}
      style={{ overflow: "scroll" }}
    >
      <div className="main-wrapper">
        {/* // <section className="section section-blogs pt-0 pb-0"> */}
        <div className="container-fluid">
          <div className="section-header pt-5 d-flex flex-wrap justify-content-between">
            <h2
              style={{
                color: "#1585B4",
                textTransform: "unset",
                fontSize: " 2rem",
              }}
            >
              Explore
            </h2>
            <div>
              <button
                type="button"
                className="btn btn-outline-dark mr-2"
                onClick={() => setIsFilterShow(true)}
              >
                <i className="fas fa-filter"></i>
                <span>
                  <span className="ml-2">Filter</span>
                  {filterList?.length !== 0 && (
                    <Badge bg="secondary">{filterList?.length}</Badge>
                  )}
                </span>
              </button>
              <button
                type="button"
                class="btn"
                style={{ backgroundColor: "#003467", color: "white" }}
                onClick={() => setIsAddShow(true)}
              >
                Add New Video
              </button>
            </div>
          </div>
          <div className="d-flex flex-wrap pb-5">
            {allExploreData?.map((item) => {
              // if (filterList.length) {
              //   if (filterList.includes(item.speciality)) {
              //     return (
              //       <ExploreCard
              //         key={item?.post_id}
              //         item={item}
              //         filterData={filterList}
              //       />
              //     );
              //   } else return;
              // } else {
              return (
                <ExploreCard
                  key={item?.post_id}
                  item={item}
                  allExploreData={allExploreData}
                />
              );
              // }
            })}
          </div>

          <AddExploreModal
            open={isAddShow}
            setShow={() => setIsAddShow(false)}
            getExploreData={getExploreData}
            setHasMore={setHasMore}
            pageRef={pageRef}
          />
          <ExploreFilterModal
            open={isFilterShow}
            setShow={() => setIsFilterShow(false)}
            allExploreData={allExploreData}
            filterList={filterList}
            setFilterList={setFilterList}
            setAllExploreData={setAllExploreData}
            getExploreData={getExploreData}
          />
          {allExploreData.length <= 0 && !isLoading ? (
            <section>
              <div
                className="insta fade-scroll"
                style={{ position: "relative" }}
              >
                <Alert
                  className="alert-no-post text-center"
                  key={1}
                  variant={"primary"}
                >
                  No Posts
                </Alert>
              </div>
            </section>
          ) : (
            ""
          )}
        </div>
        {/* </section> */}
      </div>
    </InfiniteScroll>
  );
};

export default Explore;
