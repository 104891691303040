import { Box, Button, Typography } from "@mui/material";
import React from "react";

import globalSurgeryPoster from "../../assets/Main article image.png";
import { useHistory } from "react-router";

const GlobalSurgicalCommunity = () => {
  const history = useHistory();
  return (
    <Box my={{sm:4}}>
      <Box width={"100%"} display={"flex"} justifyContent={"center"} my={2}>
        <Typography
          color={"#13375B"}
          fontSize={{ xs: "1.2rem", sm: "1.5rem", md: "2.3rem" }}
          width={{ xs: "90%", sm: "70%", md: "60%" }}
          textAlign={"center"}
          fontWeight={700}
          fontFamily={"Plus Jakarta Sans"}
        >
          The many benefits of joining surgeon-led online communities
        </Typography>
      </Box>
      <Box
        // width={"90%"}
        display={"flex"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        my={4}
        mb={{xs:6,sm:12}}
      >
        <Box
          width={{xs:'80%',sm:'60%', md: "80%" }}
          display={"flex"}
          justifyContent={{xs:'center',md:"space-between"}}
          gap={3}
          flexWrap={"wrap"}
          border={"1px solid #9FCBDF"}
          borderRadius={"8px"}
        >
          <Box>
            <Box minWidth={{md:450}} maxWidth={{md:470}} height={"100%"} borderRadius={"8px 0 0 8px"}>
              <img
                src={globalSurgeryPoster}
                height={"100%"}
                width={"100%"}
                alt=""
                style={{objectFit:'cover'}}
              />
            </Box>
          </Box>
          <Box width={{md:"45%"}}>
            <Box
              p={2}
              py={4}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Typography
                color={"#000000"}
                fontSize={{ xs: "0.9rem", sm: "1.35rem", md: "1.7rem" }}
                // width={{ xs: "90%", sm: "70%", md: "60%" }}
                // textAlign={"center"}
                fontWeight={600}
                fontFamily={"Plus Jakarta Sans"}
              >
                In today's digital age, the medical field is embracing online platforms as a means to connect, collaborate, and advance knowledge.
              </Typography>
              <Typography
                color={"#666666"}
                fontSize={{ xs: "0.8rem", sm: "1.2rem", md: "1.4rem" }}
                my={2}
                fontFamily={"Plus Jakarta Sans"}
                mb={4}
              >
                Surgeons, in particular, are finding immense value in joining online communities tailored to their profession. 
              </Typography>
              <Box>
                <Button onClick={()=>history.push('/benefit-blog')}
                  // variant="contained"
                  sx={{
                    width: {xs:"100%",sm:'auto'},
                    borderRadius: "5px",
                    backgroundColor: "#1681B1",
                    boxShadow: "none",
                    py: 1,
                    textTransform: "none",
                    display: "flex",
                    gap: 3,
                    alignItems: "center",
                    color: "#fff",
                    // border: "1px solid #BDC4CD",
                    mt: 2,
                    px: 8,
                    ":hover": {
                      backgroundColor: "#1681B1",
                      boxShadow: "none",
                    },
                  }}
                >
                  Read more
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GlobalSurgicalCommunity;
