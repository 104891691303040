import { combineReducers } from "redux";
import clinicalCases from "./clinicalCases/reducer";
import counterReducer from "./counterReducer";
import nameReducer from "./nameReducer";
import authReducer from './auth/reducer'

const rootReducer = combineReducers({
    counterReducer,
    nameReducer,
    clinicalCases,
    auth:authReducer
});

export default rootReducer;
