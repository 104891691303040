import React, { useState, memo } from "react";
// import letterImg from '../assets/img/letter.svg';
import axios from "axios";
import { useForm } from "react-hook-form";
import LoaderEmitters from "../configs/LoaderEmitters";
import EventEmitters from "../configs/EventEmitters";
import Constants from "../configs/Constants";
import Config from "../configs/Config";
import { NavLink, withRouter } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import { Box } from "@mui/material";

const SearchUserInput = memo((props) => {
  const [suggestions, setSuggestions] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [userResultList, setUserResultList] = React.useState([]);

  const arraysEqual = (a1, a2) => {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) == JSON.stringify(a2);
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    if (value && value.length > 2) {
      // if (value && value.length > 2) {
      getSuggestions(value).then((response) => {
        const { numOfResults, result } = response.data;
        if (numOfResults) {
          // if(!arraysEqual(result,suggestions)) {
          setSuggestions(result);
          // }
          return;
        } else {
          const otherArr = [];
          otherArr.push({
            id: -321,
            name: "No result found",
          });
          setSuggestions(otherArr);
        }
      });
    }
  };

  const getSuggestionValue = (suggestion) => {
    console.log("suggestion>>>>>>>", suggestion);
    if (suggestion.id === -321) {
      return suggestion.name || "";
    } else {
      if (props.sendToPath) {
        // setTimeout(()=>{
        props.history.push("/" + props.sendToPath + "/" + suggestion.id);
        props.handleClose && props.handleClose();
        // }, 5000);
      } else {
        props.history.push("/beyondSurgeryUserPost/" + suggestion.id);
      }
      // <NavLink to={"beyondSurgeryUserPost/" + elm.signup_id}>Dr. {elm.full_name}</NavLink>
    }
  };

  const renderSuggestion = (suggestion) => (
    <div className="top-insta">
      {suggestion.profile_pic ? (
        <span href="#" target="_blank">
          <img src={Constants.server + suggestion.profile_pic} />
        </span>
      ) : null}
      <span className="user">
        {suggestion.name}
        {/* <NavLink to={"beyondSurgeryUserPost/" + suggestion.id}>Dr. {suggestion.name}</NavLink> */}
      </span>
    </div>
    // <div>
    //     {suggestion.name}
    // </div>
  );

  const getSuggestions = (value) => {
    return axios.post(Constants.searchUserDetails, {
      searchKey: value,
    });
  };

  const onChangeAutocomplete = (event, val) => {
    if (val.newValue !== undefined) {
      setValue(val.newValue);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Box>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={() => {
            setSuggestions([]);
          }}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder:
              props.placeholder ||
              "Type min 3 characters to search the surgeons",
            value,
            onChange: onChangeAutocomplete,
            className: "form-control",
            name: "mbbsCity",
            autoComplete: "off",
            title: "Type min 3 characters to search the surgeons",
            style: props.style || {},
          }}
        />
      </Box>
      <i
        className="fa fa-search"
        aria-hidden="true"
        style={{
          position: "absolute",
          right: "22px",
          top: "16px",
        }}
      ></i>
    </div>
  );
});

export default withRouter(SearchUserInput);
