import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmationActionModal = ({
  modalTitle,
  actionHandler,
  isVisible,
  setVisible,
  actionBtnText,
  children,
}) => {
  return (
    <Modal show={isVisible} onHide={setVisible}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={setVisible}>
          Cancel
        </Button>
        <Button variant="primary" onClick={actionHandler}>
          {actionBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationActionModal;
