import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import indemnity from "../assets/docs/Indemnity Bond.pdf";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import apis from "../apis/apis";
import LoaderEmitters from "../configs/LoaderEmitters";
import { Link } from "react-router-dom";

const EditCaseModal = ({
  isVisible,
  setVisible,
  Case,
  setshouldRefresh,
  setCaseToBeEdited,
}) => {
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      title: Case.title,
      description: Case.description,
      specialityType: Case.speciality,
    },
  });
  useEffect(() => {
    getAllSpecialities()
  }, [])
  

  const [specialityList, setSpecialityList] = useState([])

  const getAllSpecialities = async () => {
    const { data } = await apis.getAllSpecialities();
    setSpecialityList(data.specialities);
  };

  const onSubmit = async (data) => {
    try {
      // making form data ready
      LoaderEmitters.showLoader({
        showLoader:true
      })
      let bodyFormData = new FormData();
      bodyFormData.append("title", data.title);
      bodyFormData.append("description", data.description);
      bodyFormData.append("speciality", data.specialityType);
      if (fileState.isFileChanged || fileState.isNewFile) {
        bodyFormData.append("communityfile", fileState.file);
        //bodyFormData.append("beyondfile", fileState.file);
        if (Case.file !== "nofile")
          bodyFormData.append("existingFile", Case.file);
      }
      bodyFormData.append("isNewFile", fileState.isNewFile ? "true" : "false");
      bodyFormData.append(
        "isFileChanged",
        fileState.isFileChanged ? "true" : "false"
      );
      bodyFormData.append(
        "isFileRemoved",
        fileState.isFileRemoved ? "true" : "false"
      );

      // updating data
      const response = await axios({
        method: "post",
        url: Constants.editCommunityPost + "/" + Case.id,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log(response.data);
      if (response.data.code === 200) {
        setVisible();
        resetFileState();
        setCaseToBeEdited(null);
        if (setshouldRefresh) setshouldRefresh(true);
        EventEmitters.showTost({
          type: "success",
          message: "Case updated successfully",
        });
      } else {
        EventEmitters.showTost({
          type: "error",
          message: "Error in updating case",
        });
      }
    } catch (error) {
      console.log("Edit case error", error.message);
      EventEmitters.showTost({
        type: "error",
        message: "Error in updating case",
      });
    }
    LoaderEmitters.showLoader({
      showLoader:false
    })
  };

  useEffect(() => {
    setFilePreviewSrc(
      Case?.file === "nofile"
        ? ""
        : Constants.server + "/community/" + Case?.file
    );
    return () => {};
  }, [Case]);

  const [fileState, setfileState] = useState({
    isFileRemoved: false,
    isFileChanged: false,
    isNewFile: false,
    file: null,
  });

  const caseFileRef = useRef();
  const newCaseFileRef = useRef();

  const [filePreviewSrc, setFilePreviewSrc] = useState(
    Case.file === "nofile" ? "" : Constants.server + "/community/" + Case.file
  );

  const handleFileRemove = () => {
    if (caseFileRef.current) caseFileRef.current.value = "";
    if (newCaseFileRef.current) newCaseFileRef.current.value = "";
    setFilePreviewSrc("");
    setfileState({
      isFileChanged: false,
      isFileRemoved: fileState.isNewFile == true ? false : true,
      isNewFile: false,
      file: null,
    });
  };

  const handleFileUndo = () => {
    setFilePreviewSrc(
      Case.file === "nofile" ? "" : Constants.server + "/community/" + Case.file
    );
    setfileState({
      isFileRemoved: false,
      isFileChanged: false,
      isNewFile: false,
      file: null,
    });
  };

  const handleFileAdd = (e) => {
    //filesize check
    const fileSize = Constants.getFileSizeInMB(e.target);
    if (fileSize >= Constants.fileSizeLimitCommunity) {
      EventEmitters.showTost({
        type: "error",
        message:
          "File size should be less than " +
          Constants.fileSizeLimitCommunity +
          " MB.",
      });
      return;
    }

    // filetype check
    const fileName = e.target.files[0];
    const fileNameSmallCap = fileName.name.toLocaleLowerCase();
    if (
      !(
        fileNameSmallCap.search(".png") > -1 ||
        fileNameSmallCap.search(".jpeg") > -1 ||
        fileNameSmallCap.search(".jpg") > -1 ||
        fileNameSmallCap.search(".gif") > -1
      )
    ) {
      EventEmitters.showTost({
        type: "error",
        message: "File type not supported.",
      });
      return;
    }

    // valid file
    setFilePreviewSrc(URL.createObjectURL(e.target.files[0]));
    setfileState({
      isFileChanged: false,
      isFileRemoved: false,
      isNewFile: true,
      file: e.target.files[0],
    });
  };

  const handleFileChange = (e) => {
    //filesize check
    const fileSize = Constants.getFileSizeInMB(e.target);
    if (fileSize >= Constants.fileSizeLimitCommunity) {
      EventEmitters.showTost({
        type: "error",
        message:
          "File size should be less than " +
          Constants.fileSizeLimitCommunity +
          " MB.",
      });
      return;
    }

    // filetype check
    const fileName = e.target.files[0];
    const fileNameSmallCap = fileName.name.toLocaleLowerCase();
    if (
      !(
        fileNameSmallCap.search(".png") > -1 ||
        fileNameSmallCap.search(".jpeg") > -1 ||
        fileNameSmallCap.search(".jpg") > -1 ||
        fileNameSmallCap.search(".gif") > -1
      )
    ) {
      EventEmitters.showTost({
        type: "error",
        message: "File type not supported.",
      });
      return;
    }

    // valid file
    setFilePreviewSrc(URL.createObjectURL(e.target.files[0]));
    setfileState({
      isFileRemoved: false,
      isFileChanged: true,
      isNewFile: fileState.isNewFile,
      file: e.target.files[0],
    });
  };

  const resetFileState = () => {
    setFilePreviewSrc(
      Case.file === "nofile" ? "" : Constants.server + "/community/" + Case.file
    );
    setfileState({
      isFileChanged: false,
      isFileRemoved: false,
      isNewFile: false,
      file: null,
    });
  };

  return (
    <Modal
      animation={false}
      show={isVisible}
      onHide={() => {
        setVisible();
        resetFileState();
        setCaseToBeEdited(null);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Case</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div class="form-group">
            <label for="title">Title</label>
            <input
              class="form-control"
              name="title"
              ref={register({ required: true })}
            />
            {errors.title && (
              <small className="text-danger">Title is required</small>
            )}
          </div>
          <div class="form-group">
            <label for="description">Description</label>
            <input
              type="text"
              class="form-control"
              id="description"
              name="description"
              ref={register({ required: true })}
            />
            {errors.description && (
              <small className="text-danger">Description is required</small>
            )}
          </div>
          <div class="form-group">
            <label for="specialityType">Speciality</label>
            <select
              class="form-control"
              name="specialityType"
              ref={register({ required: true })}
            >
              {specialityList?.map((speciality) => (
                <option value={speciality}>{speciality}</option>
              ))}
            </select>
          </div>
          <div class="form-group">
            <label className="d-block">Case Image</label>
            <ButtonGroup aria-label="Basic example">
              {Case.file === "nofile" && fileState.isNewFile == false ? (
                // <Button
                //   size={"sm"}
                //   variant="outline-primary"
                //   className="mr-2"
                //   onClick={() => newCaseFileRef.current.click()}
                // >
                //   Add
                <input
                  type="file"
                  name="newCaseFile"
                  accept=".jpg, .jpeg, .png, .gif"
                  id="newCaseFile"
                  // style={{ display: "none" }}
                  onChange={handleFileAdd}
                  ref={newCaseFileRef}
                />
              ) : (
                // </Button>
                <>
                  {/* {Case.file !== "nofile" && (
                    <Button
                      size={"sm"}
                      variant="outline-primary"
                      className="mr-2"
                      onClick={handleFileRemove}
                    >
                      Remove
                    </Button>
                  )} */}
                  {/* <Button
                    size={"sm"}
                    variant="outline-primary"
                    className="mr-2"
                    onClick={() => caseFileRef.current.click()}
                  >
                    Add */}
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png, .gif"
                    name="caseFile"
                    id="caseFile"
                    // style={{ display: "none" }}
                    onChange={handleFileChange}
                    ref={caseFileRef}
                  />
                  {/* </Button> */}
                </>
              )}
              {/* {(fileState.isFileChanged ||
                fileState.isFileRemoved ||
                fileState.isNewFile) && (
                <Button
                  size={"sm"}
                  variant="outline-primary"
                  className="mr-2"
                  onClick={handleFileUndo}
                >
                  Undo
                </Button>
              )} */}
            </ButtonGroup>
          </div>
          {(fileState.isFileChanged || fileState.isNewFile) && (
            <i>{fileState.file.name}</i>
          )}
          {!!filePreviewSrc && (
            <div className="position-relative mb-4" style={{ maxWidth: "300px" }}>
              <img
                src={filePreviewSrc}
                alt=""
                className="img-responsive"
                style={{ width: "300px", position: "relative" }}
              />
              <i
                class="fas fa-times text-secondary"
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  fontSize: "1rem",
                  background: "white",
                  padding: ".5rem",
                  border: ".5px solid grey",
                  cursor: "pointer",
                }}
                onClick={handleFileRemove}
              ></i>
            </div>
          )}
          {fileState.isFileRemoved && (
            <Alert variant={"info"}>Image will be removed from case.</Alert>
          )}

          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="clauses"
                  required
                  ref={register({ required: true })}
                  style={{ marginRight: "6px" }}
                />
                I hereby understand and provide the following Indemnity.
                {/* <a
                  href={indemnity}
                  target="_blank"
                  style={{ color: "#007bff" }}
                >
                  {" "}
                  Click Here to Read{" "}
                </a> */}
                  <Link
                      to="/terms_and_conditions"
                      // target="_blank"
                      rel="noopener noreferrer"
                    >
                      Tap here to read.
                    </Link>
                {errors.clauses && (
                  <div className="error-text" role="alert">
                   Please select check box for Terms/Privacy
                  </div>
                )}
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="patientDataCheckbox"
                  required
                  ref={register({ required: true })}
                  style={{ marginRight: "6px" }}
                />
                I hereby confirm that any patient details shared on this
                platform are with respect to the patients consent.
                {errors.patientDataCheckbox && (
                  <div className="error-text" role="alert">
                   Please select check box for Terms/Privacy
                  </div>
                )}
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name="platformDataCheckbox"
                  required
                  ref={register({ required: true })}
                  style={{ marginRight: "6px" }}
                />
                This data entered is relevant to the platform.
                {errors.platformDataCheckbox && (
                  <div className="error-text" role="alert">
                  Please select check box for Terms/Privacy
                  </div>
                )}
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setVisible();
              resetFileState();
              setCaseToBeEdited(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            Update
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditCaseModal;
