import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import Footer from "../login/Footer";
import blogUser1 from "../../assets/blogUser1.png";
import cuttingEdgeImg from "../../assets/Cutting-edge.png";
import apis from "../../apis/apis";
import { useHistory } from "react-router";

const Blogs = () => {
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    apis.getAllBlogs().then((res) => {
      console.log(res, "Blog response");
      setBlogData(res.data.data);
    });
  }, []);
  console.log(blogData, "Blog response");
  return (
    <Box>
      <Box p={4}>
        <Box
          // height={550}
          width={"100%"}
          // bgcolor={"#D9D9D9"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            color={"#13375B"}
            fontSize={{ xs: "1.25rem", sm: "1.5rem", md: "2rem" }}
            width={{ xs: "90%", sm: "70%", md: "60%" }}
            textAlign={"center"}
              fontFamily={"Plus Jakarta Sans"}
              fontWeight={700}
          >
            Dive deep. Check out what our fellow surgeons are thinking about
            today.
          </Typography>
        </Box>
        <Box my={2}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={2}
            flexWrap={"wrap"}
          >
            <Box
              width={"90%"}
              display={"flex"}
              justifyContent={{ xs: "center", md: "start" }}
              gap={2}
              flexWrap={"wrap"}
              mt={4}
            >
              {blogData===null? <CircularProgress/>:''}
              {blogData?.map((dt) => (
                <Cards dt={dt}/>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Blogs;

const Cards = ({ dt }) => {
  const history = useHistory();
  const convertedDate = (dateString) => {
    var date = new Date(dateString);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var formattedDate = months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
    return formattedDate;
  };

  return (
    <Box display={"flex"} flex={1} maxWidth={300} minWidth={250}>
      <Box
        sx={{
          border: "1px solid #E8E8EA",
          borderRadius: "12px",
          p: 1.5,
        }}
      >
        <Box height={200} borderRadius={"6px"} overflow={"hidden"}>
          <img src={dt.imageUrl} height={"100%"} width="100%" alt="" style={{objectFit:'cover'}}/>
        </Box>
        <Typography
          mt={1}
          width={"90%"}
          color={"#181A2A"}
          fontSize={{ xs: ".8rem", sm: ".9rem", md: "1.2rem" }}
          fontWeight={500}
          fontFamily={"Plus Jakarta Sans"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: {xs:2,md:3},
            WebkitBoxOrient: "vertical",
          }}
        >
          {dt.title}
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"start"}
          flexDirection={'column'}
          mt={2}
          pb={2}
          // gap={1}
        >
          <Box display={"flex"} gap={1} alignItems={"center"} height={"100%"}>
            {/* <Box height={35}>
              <img src={blogUser1} alt="" height={"100%"} />
            </Box> */}
            <Typography
              color={"#97989F"}
              fontSize={{ xs: ".8rem", sm: ".9rem", md: ".8rem" }}
              fontWeight={500}
              fontFamily={"Plus Jakarta Sans"}
            >
              {dt.authorName}
            </Typography>
          </Box>
          <Typography
            // mt={1}
            color={"#97989F"}
            fontSize={{ xs: ".8rem", sm: ".9rem", md: ".8rem" }}
            fontWeight={500}
            fontFamily={"Plus Jakarta Sans"}
          >
            {convertedDate(dt.dateOfUpload)}
          </Typography>
        </Box>
        <Button
          variant="contained"
          onClick={()=> history.push(`/blogs/${dt.id}`)}
          sx={{
            width: "100%",
            borderRadius: "5px",
            backgroundColor: "#13375B",
            boxShadow: "none",
            // py: 1,
            textTransform: "none",
            display: "flex",
            gap: 3,
            alignItems: "center",
            fontSize: "16px",
            color: "#fff",
            ":hover": {
              backgroundColor: "#13375B",
              boxShadow: "none",
            },
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          Read More
        </Button>
      </Box>
    </Box>
  );
};
