import { Box, Typography } from "@mui/material";
import React from "react";
import OperationRoom from "../../assets/OperationalRoom.png";
import googleLogo from "../../assets/img/googlePlay.png";
import appleLogo from "../../assets/img/appStore.png";

const OperatingRoom = () => {
  return (
    <Box my={4}>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"center"}
        bgcolor={"#13375B"}
        justifyContent={"space-evenly"}
        py={3}
        px={{ xs: 2, md: 4 }}
        gap={2}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Box maxWidth={{ xs: "100%", md: "50%" }}>
          <Typography
            sx={{
              //   width: "70%",
              color: "#ffffff",
              fontSize: { xs: "1.2rem", sm: "1.5rem", md: "2rem" },
              textAlign: { xs: "center", md: "start" },
              fontWeight: "700",
            }}
            fontFamily={"Plus Jakarta Sans"}
          >
            What are you waiting for ? <br /> Join the world’s largest operating
            room.
          </Typography>
          <Typography
            sx={{
              mt: 2,
              color: "#ffffff",
              fontSize: { xs: ".7rem", sm: ".7rem", md: "1rem" },
              textAlign: { xs: "center", md: "start" },
            }}
            fontFamily={"Plus Jakarta Sans"}
          >
            Download the app and have the ultimate surgeon's companion
          </Typography>
          <Box
            sx={{
              width: "100%",
              justifyContent: { xs: "center", md: "flex-start" },
              display: "flex",
              gap: 2,
              mt: 2,
            }}
          >
            <Box
              sx={{
                flex: 1,
                aspectRatio: 2.8,
                maxWidth: "10rem",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  `https://play.google.com/store/apps/details?id=com.sfs_mobile_app`,
                  "_blank"
                )
              }
            >
              <img src={googleLogo} height={"100%"} width={"100%"} />
            </Box>
            <Box
              sx={{
                flex: 1,
                aspectRatio: 2.8,
                maxWidth: "10rem",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  `https://apps.apple.com/mv/app/surgeons-for-surgeons/id1660956060`,
                  "_blank"
                )
              }
            >
              <img src={appleLogo} height={"100%"} width={"100%"} />
            </Box>
          </Box>
        </Box>
        <Box maxWidth={{ xs: "100%", sm: "90%", md: "50%" }}>
          <Box height={{ sm: 280, xs: 200 }}>
            <img src={OperationRoom} alt="" height={"100%"} width={"100%"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OperatingRoom;
