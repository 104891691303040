import React from "react";
import Constants from "../configs/Constants";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const ExploreCard = ({ item, allExploreData }) => {
  const history = useHistory();
  return (
    <div className="col-lg-3 col-12 col-sm-6 c p-3">
      <div
        className="embed-responsive embed-responsive-16by9 position-relative d-flex justify-content-center align-items-center rounded"
        style={{
          background: `url(${Constants.server}thumbnails/${item?.thumbnail})`,
          backgroundSize: "100%",
          cursor: "pointer",
        }}
        onClick={() =>
          history.push({
            pathname: `explore/all`,
            state: { ele: item.post_id, allExploreData },
          })
        }
      >
        <i
          className="fas fa-play fa-3x red-text "
          aria-hidden="true"
          style={{ color: "white" }}
        ></i>
      </div>
      <div className="m-2">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <p className="text-capitalize font-weight-bold m-0">{item?.title}</p>
          <div
            style={{ color: "#1585B4" }}
            className="d-flex gap-2 align-items-center"
          >
            <i className="fas fa-eye"></i>

            <p className="m-0 ml-2"> {item?.views} Views</p>
          </div>
        </div>
        <div>
          <Link to={"/profile/" + item.id} title="View Profile" >
            <p className="text-capitalize font-weight-bold m-0">
              Dr.{item?.author_name}
            </p>
          </Link>
        </div>
        <div
          style={{
            backgroundColor: "#C6E3FF",
            width: "fit-content",
            color: "#003467",
          }}
          className="rounded  d-flex p-2 align-items-center"
        >
          <i className="fas fa-tag fa-flip-horizontal"></i>
          <p className="text-capitalize font-weight-bolder m-0 ml-2">
            {/* {item?.author_name} */}
            {item?.speciality}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ExploreCard;
