import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import LoginImage from "../../assets/img/3343994.jpg";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import Constants from "../../configs/Constants";
import axios from "axios";
import EventEmitters from "../../configs/EventEmitters";
import FooterContent from "../FooterContent";
import indemnityBondSurgery from "../../assets/docs/Indemnity_Bond_Beyond_Surgery.pdf";
import termsOfUse from "../../assets/docs/Terms of Use.pdf";
import loginImage from "../../assets/loginImage.png";
import TextField from "../login/TextField";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import google from "../../assets/googleLogo.svg";
import apple from "../../assets/appleLogo.svg";
import { handleAuthLogin } from "../../reducers/auth/thunk";
import { useGoogleLogin } from "@react-oauth/google";
import { appleAuthHelpers } from "react-apple-signin-auth";
import apis from "../../apis/apis";
import { axiosInstance } from "../../apis/apis";
import LoaderEmitters from "../../configs/LoaderEmitters";
import Config from "../../configs/Config";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router";
import upgrade from "../../assets/Upgrade1.png";
import share from "../../assets/share.png";
import collaborate from "../../assets/Collaborate.png";
import grow from "../../assets/Grow.png";

const Singup = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const firstLetterCapital = (setStringName) => {
    return `${setStringName[0].toUpperCase()}${setStringName.substring(1)}`;
  };
  const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook
  const onSubmit = (response) => {
    let data={...response}
    console.log(data, "onSubmit test");
    data.firstName =
      firstLetterCapital(data.firstName.trim()) +
      " " +
      firstLetterCapital(data.lastName.trim());
    axios
      .post(Constants.signup, data)
      .then((response) => {
        reset();
        console.log("response", response);
        let tost = {
          message: "",
          type: "",
        };

        if (response.data.code === 409) {
          tost.message = response.data.msg;
          tost.type = "error";
        } else if (response.data.code === 200) {
          tost.message = response.data.msg;
          tost.type = "success";
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }

        // EventEmitters.showTost({
        //     message: "An Email has been sent to your register emailId, Please Verify your acount.",
        //     type: "success"
        // });
      })
      .catch(console.error);
  };

  const getProfileDetails = (id) => {
    axios.post(Constants.getProfileDetails, { id }).then((response) => {
      if (response.data && response.data.result) {
        let temp = {
          number: response?.data?.result?.profile?.mobile,
          college: response?.data?.result?.qualification?.mbbs_center,
        };
        window.sessionStorage.setItem(
          "session",
          JSON.stringify({
            ...Config.getSessionInfo(),
            ...temp,
          })
        );
      }
    });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First name is required")
      .min(3, "Minimum 3 characters are required")
      .test("no-blank-spaces", "Blank spaces are not allowed", (value) => {
        return value === undefined || value.trim() === value;
      }),
    lastName: Yup.string()
      .required("Last name is required")
      .min(3, "Minimum 3 characters are required")

      .test("no-blank-spaces", "Blank spaces are not allowed", (value) => {
        return value === undefined || value.trim() === value;
      }),
    emailId: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .trim()
      .required("Password is required")
      .min(7, "Password must be atleast 7 digit long")
      .test(
        "no-blank-spaces",
        "Name must not contain blank spaces",
        (value) => {
          return value === undefined || value.trim() === value;
        }
      ),
    indemnity: Yup.string().required(
      "Please select check box for Terms of use and Privacy Policy"
    ),
    clauses: Yup.string().required(
      "Please select check box for Terms of use and Privacy Policy"
    ),
  });

  const formik = useFormik({
    initialValues: {
      emailId: "",
      password: "",
      firstName: "",
      lastName: "",
      indemnity: "",
      clauses: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: onSubmit,
  });

  const handleAppleSignIn = async () => {
    console.log("inside apple signin");
    document.addEventListener("AppleIDSignInOnSuccess", (event) => {
      console.log(event);
    });
    try {
      const appleAuth = await appleAuthHelpers.signIn({
        authOptions: {
          clientId: "com.sfsapp.auth.client",
          scope: "name email",
          redirectURI: "https://aws-sfs-dev-temp.web.app",
          state: "origin:web",
          usePopup: true,
        },
        onError: (error) => console.error(error),
        onSuccess: async (data) => {
          console.log(data, data?.authorization?.code);
          let response = await apis.appleSignin({
            authCode: data?.authorization?.code,
          });
          handleLoginSuccess(response);
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleGoogleToken(tokenResponse?.access_token);
    },
    onError: (err) => console.log(err),
  });

  const handleGoogleToken = async (access_token) => {
    try {
      let response = await apis.googleSignin({ authCode: access_token });
      handleLoginSuccess(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLoginSuccess = async (response) => {
    console.log("response", response);
    let tost = {
      message: "",
      type: "",
    };
    if (response.data.code === 404) {
      tost.message = response.data.msg;
      tost.type = "error";
    } else if (response.data.code === 422) {
      tost.message = response.data.msg;
      tost.type = "error";
    } else if (response.data.code === 202) {
      tost.message = response.data.msg;
      tost.type = "success";
      sessionStorage.setItem("session", JSON.stringify(response.data.records));
      getProfileDetails(response.data.records.id);
      EventEmitters.loginLogutService();
      dispatch(handleAuthLogin(true));
      history.push(Constants[response.data.records.till_where_filled + 1]);
    } else if (response.data.code === 200) {
      tost.message = response.data.msg;
      tost.type = "success";
      sessionStorage.setItem("session", JSON.stringify(response.data.records));
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.records.jwt}`;
      getProfileDetails(response.data.records.id);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.records.jwt}`;
      getProfileDetails(response.data.records.id);
      EventEmitters.loginLogutService();
      dispatch(handleAuthLogin(true));
      history.push("/chat");
    }

    if (tost.message) {
      tost.autoClose = 2000;
      EventEmitters.showTost(tost);
    }

    LoaderEmitters.showLoader({
      showLoader: false,
    });
    setLoading(false);
  };

  return (
    <Box>
      <Box px={{ xs: 2, sm: 3, md: 4 }} display={"flex"}>
        <Grid container display={'flex'} flexDirection={{xs:'column-reverse',md:'row'}}>
          <Grid
            item
            md={6}
            display={{ xs: "flex", md: "flex" }}
            flexDirection={"column"}
            my={{ xs: 3, md: 0 }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              flexWrap={"wrap"}
              gap={2}
              my={{xs:2,md:4}}
            >
              {data.map((dt) => (
                <Box
                  width={{ sm: "48.5%" }}
                  sx={{
                    borderRadius: 2,
                    bgcolor: "#1681b11c",
                    minHeight: "278px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    px:1,
                    gap:1,
                    minWidth:{xs:'250px',sm:'337px'},
                    flex:1
                    // maxWidth:'100%',
                    // width: "100%",
                  }}
                >
                  <Box
                    height={{xs:70,sm:100}}
                    width={{xs:70,sm:100}}
                    bgcolor={"#fff"}
                    borderRadius={"50%"}
                    p={2}
                  >
                    <img src={dt.image} height={"100%"} width={"100%"} alt="" />
                  </Box>
                  <Typography
                    fontSize={{ xs: ".9rem", sm: "1rem", md: "1.25rem" }}
                    fontWeight={600}
                    textAlign={'center'}
                    fontFamily={"Plus Jakarta Sans"}
                  >
                    {dt.heading}
                  </Typography>
                  <Typography
                    fontSize={{ xs: "0.75rem", sm: "0.9rem", md: "0.9rem" }}
                    textAlign={'center'}
                    // fontWeight={600}
                    fontFamily={"Plus Jakarta Sans"}
                  >
                    {dt.subheading}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item md={6} px={{ xs: 0, sm: 4 }} width={"100%"}>
            <Box px={{ sm: 4 }}>
              <Typography
                color={"#13375B"}
                fontSize={{ xs: "1.35rem", sm: "1.45rem", md: "2.1rem" }}
                fontWeight={600}
                fontFamily={"Plus Jakarta Sans"}
              >
                Join Now
              </Typography>
              {/* <Typography
                color={"#96A0AD"}
                fontSize={{ xs: ".8rem", sm: ".9rem", md: "1rem" }}
                fontFamily={"Plus Jakarta Sans"}
              >
                We've missed you! Please sign in to catch up on what you've
                missed
              </Typography> */}
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"wrap"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box mt={2} width={{ xs: "100%", md: "49%" }}>
                  <TextField
                    label="First Name"
                    placeholder="Enter your First Name"
                    name="firstName"
                    type="text"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.firstName}
                    touched={formik.touched.firstName}
                  />
                </Box>
                <Box mt={2} width={{ xs: "100%", md: "49%" }}>
                  <TextField
                    label="Last Name"
                    placeholder="Enter your Last Name"
                    name="lastName"
                    type="text"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.lastName}
                    touched={formik.touched.lastName}
                  />
                </Box>
              </Box>
              <Box mt={2}>
                <TextField
                  label="Email"
                  placeholder="Enter your email"
                  name="emailId"
                  type="email"
                  value={formik.values.emailId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.emailId}
                  touched={formik.touched.emailId}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  label="Password"
                  placeholder="Enter your password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  // icon={hide}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                />
              </Box>
              {/* <Box
                // display={"flex"}
                // alignItems={"center"}
                // justifyContent={"space-between"}
                mt={1}
              >
                <FormControlLabel
                  control={<Checkbox />}
                  label="I hereby understand and agree to Terms of Use & Company Policy. "
                  sx={{ fontFamily: "Plus Jakarta Sans" }}
                  // value={remeberMe}
                  // checked={remeberMe}
                  // onClick={handleRemeberMe}
                  // onChange={handleRemeberMe}
                />
                <a
                  href={indemnityBondSurgery}
                  target="_blank"
                  style={{ color: "#007bff" }}
                >
                  <small>Click Here to Read</small>
                </a>
              </Box> */}
              <Box mt={2} mb={0}>
                <div className="checkbox">
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <input
                      type="checkbox"
                      name="indemnity"
                      required
                      value={formik.values.indemnity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // icon={hide}
                      error={formik.errors.indemnity}
                      touched={formik.touched.indemnity}
                    />
                    <small>
                      I hereby understand and provide the following Indemnity.
                    </small>
                    <a
                      href={indemnityBondSurgery}
                      target="_blank"
                      style={{ color: "#007bff" }}
                    >
                      <small>Click Here to Read</small>
                    </a>
                  </Box>
                  {formik.errors &&
                  formik.errors.indemnity &&
                  formik.touched &&
                  formik.touched.indemnity ? (
                    <Typography
                      fontFamily={"Plus Jakarta Sans"}
                      sx={{ fontSize: ".8rem", color: "red", mt: ".2rem" }}
                    >
                      {formik.errors.indemnity}
                    </Typography>
                  ) : null}
                </div>
              </Box>
              <Box mb={0} mt={2}>
                <div className="checkbox">
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <input
                      type="checkbox"
                      name="clauses"
                      required
                      value={formik.values.clauses}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // icon={hide}
                      error={formik.errors.clauses}
                      touched={formik.touched.clauses}
                    />
                    <small>
                      I hereby understand and agree to Terms of Use & Company
                      Policy.
                    </small>
                    <a
                      href={termsOfUse}
                      target="_blank"
                      style={{ color: "#007bff" }}
                    >
                      <small>Click Here to Read</small>
                    </a>
                  </Box>
                  {formik.errors &&
                  formik.errors.clauses &&
                  formik.touched &&
                  formik.touched.clauses ? (
                    <Typography
                      fontFamily={"Plus Jakarta Sans"}
                      sx={{ fontSize: ".8rem", color: "red", mt: ".2rem" }}
                    >
                      {formik.errors.clauses}
                    </Typography>
                  ) : null}
                </div>
              </Box>
              <Box mt={2}>
                <Button
                  disabled={loading}
                  variant="contained"
                  sx={{
                    fontFamily: "Plus Jakarta Sans",
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#13375B",
                    py: 1.3,
                    textTransform: "none",
                    ":hover": {
                      backgroundColor: "#13375B",
                      boxShadow: "none",
                    },
                  }}
                  onClick={formik.handleSubmit}
                >
                  {loading?(
                    <CircularProgress/>
                  )
                  :"Sign up"}
                </Button>
                <Typography
                  mt={1}
                  sx={{
                    // color: "#1681B1",
                    fontSize: ".9rem",
                  }}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  By clicking Continue, you agree to SFS’s{" "}
                  <span style={{ fontWeight: "600" }}>
                    <Link to="/terms_and_conditions">User Agreement</Link>{" "}
                  </span>{" "}
                  and{" "}
                  <span style={{ fontWeight: "600" }}>
                    <Link to="/privacy-policy">Privacy Policy</Link>{" "}
                  </span>
                </Typography>
                <Typography
                  mt={1}
                  sx={{
                    display: { xs: "none", sm: "block" },
                    color: "#9FA8B2",
                    fontSize: "1.2rem",
                    textAlign: "center",
                  }}
                  fontFamily={"Plus Jakarta Sans"}
                >
                  or
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    py: 1,
                    textTransform: "none",
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    color: "#000",
                    border: "1px solid #BDC4CD",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    mt: 2,
                    ":hover": {
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handleGoogleSignIn}
                >
                  <img src={google} alt="" /> Continue with Google
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    py: 1,
                    textTransform: "none",
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    color: "#000",
                    border: "1px solid #BDC4CD",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    mt: 2,
                    ":hover": {
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    },
                  }}
                  onClick={handleAppleSignIn}
                >
                  <img src={apple} alt="" /> Continue with Apple
                </Button>
                <Typography
                  fontFamily={"Plus Jakarta Sans"}
                  mt={1}
                  sx={{
                    // color: "#1681B1",
                    fontSize: {xs:'1.1rem',md:"1.2rem"},
                    textAlign: "center",
                    fontWeight: "600",
                    // letterSpacing: 1,
                  }}
                >
                  Already on SFS?{" "}
                  <span style={{ color: "#0D587A" }}>
                    <Link to="/login" style={{ color: "#13375B" }}>
                      Log in
                    </Link>
                  </span>{" "}
                </Typography>
                {/* <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    boxShadow: "none",
                    py: 1,
                    textTransform: "none",
                    display: "flex",
                    gap: 2,
                    alignItems: "center",
                    color: "#000",
                    border: "1px solid #BDC4CD",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "600",
                    mt: 2,
                    ":hover": {
                      backgroundColor: "#fff",
                      boxShadow: "none",
                    },
                    }}
                    onClick={() => history.push("/login")}
                  >
                    {" "}
                    Log in
                  </Button>
                </Box> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Singup;

const data = [
  {
    image: collaborate,
    heading: "Improve outcomes",
    subheading:
      "Get complex cases solved. Ask for second opinions. Meet up when you travel... Anything is possible once you get connected.",
  },
  {
    image: share,
    heading: "Share cases  effortlessly. ",
    subheading:
      "Upload case photos and videos. Add a case description. Click submit. And the community is enriched.",
  },
  {
    image: upgrade,
    heading: "Upgrade your knowledge.",
    subheading:
      "Watch videos. Read posts. Take part in discussions. Attend events. A world of surgical knowledge is just a click away.",
  },
  {
    image: grow,
    heading: "Build your reputation",
    subheading:
      "Share your expertise via videos, posts or articles. Become a speaker. Make a mark in the global surgical community.",
  },
];
