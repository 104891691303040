import React, { Fragment, useEffect, createRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import FooterContent from "./FooterContent";
import { FormControl, Badge } from "react-bootstrap";
import Config from "../configs/Config";
import apis from "../apis/apis";
import { format } from "date-fns";
import Constants from "../configs/Constants";

const videos_list = [
  {
    title:
      "Caudate : Robotic Caudate Lobe HEPATO CELLULAR CARCINOMA ( HCC) Excision",
    speciality: "GI Surgery and Oncosurgery",
    surgeon: "Dr. Mahesh Patel",
    video_src: "",
    href: "https://surgeonsforsurgeons.com/#/profile/150",
  },
  {
    title: "Trackeal : Robotic Tracheal resection under ECMO",
    speciality: "Thoracic Surgery and Oncosurgery",
    surgeon: "Dr. Mahesh Patel",
    video_src: "",
    href: "https://surgeonsforsurgeons.com/#/profile/150",
  },
  {
    title: "Tamis : Robotic TAMIS for large rectal villous adenoma",
    speciality: "GI Surgery and Oncosurgery",
    surgeon: "Dr. Mahesh Patel",
    video_src: "",
    href: "https://surgeonsforsurgeons.com/#/profile/150",
  },
  {
    title:
      "y2mate robotic : Robotic Giant Oesophageal LEIOMYOMA Enucleation(right side approach)",
    speciality: "Thoracic Surgery & Oncosurgery",
    surgeon: "Dr. Mahesh Patel",
    video_src: "",
    href: "https://surgeonsforsurgeons.com/#/profile/150",
  },
  {
    title:
      "y2mate robotic : RETROPERITONEAL MASS EXCISION WITH INTRA ABDOMINAL IVC RESECTION WITH RIGHT NEPHRECTOMY",
    speciality: "Oncosurgery",
    surgeon: "Dr. Mahesh Patel",
    video_src: "",
    href: "https://surgeonsforsurgeons.com/#/profile/150",
  },
];

const Video = (props) => {
  const videoSection = createRef();
  const [value, setValue] = useState("");
  const [showAllVideos, setShowAllVideos] = useState(null);
  useEffect(() => {
    const id = Config.getSessionInfo("id");
    const isAllDetailsFilled = Config.getSessionInfo("is_all_details_filled");

    if (!id || isAllDetailsFilled === 0) {
      // if (!id) {
      props.history.push("/login");
    } else {
      getAllVideos();
    }
  }, []);

  const getAllVideos = async () => {
    try {
      let data = await apis.getAllLearnVideos();
      setShowAllVideos(data.data.posts);
    } catch (error) {
      console.log(error);
    }
  };

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  return (
    <Fragment>
      <div className="main-wrapper" ref={videoSection}>
        <div className="banner-header text-center">
          {/* <h1 style={{ color: '#2272a1', textTransform: 'unset', fontSize: ' 3.25rem', marginTop: '25px', marginBottom: '25px' }}>
                        Media
                </h1> */}
        </div>

        <section className="section section-blogs" ref={videoSection}>
          <div className="container-fluid">
            <div className="section-header text-center">
              <h2
                style={{
                  color: "#2272a1",
                  textTransform: "unset",
                  fontSize: " 3.25rem",
                }}
              >
                {" "}
                Academics{" "}
              </h2>

              {/* <div className="row align-items-center justify-content-center">
                <div className="col-md-6">
                  <div className="card-body">
                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                      {/* <li className="nav-item">
                        <NavLink
                          activeClassName="active"
                          className="nav-link"
                          to="/media"
                        >
                          Images
                        </NavLink>
                      </li> */}
                      {/* <li className="nav-item">
                        <NavLink className="nav-link" to="/video">
                          Videos
                        </NavLink>
                      </li> */}
                      {/* <li className="nav-item">
                        <NavLink className="nav-link" to="/event">
                          Events
                        </NavLink>
                      </li> */}
                      {/* <li className="nav-item">
                                                <NavLink className="nav-link" to="/meeting">
                                                    Meetings
                                                        </NavLink>
                                            </li> }
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-3"></div>

                {/* <div className="col-lg-3 col-sm-12">
                                    <Dropdown className="col-12">
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" style={{ color: '#0ddbfb' }}>
                                            Select Alphabet
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu} style={{ overflowY: 'auto', height: '280px' }}>
                                            <Dropdown.Item eventKey="1">A</Dropdown.Item>
                                            <Dropdown.Item eventKey="2">B</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" active>
                                                C
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="1">D</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">E</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">F</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">G</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">H</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">I</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">J</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">K</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">L</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">M</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">N</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">O</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">P</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">Q</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">R</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">S</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">T</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">U</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">V</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">W</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">X</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">Y</Dropdown.Item>
                                            <Dropdown.Item eventKey="1">Z</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}

                {/* <div className="col-lg-3 col-sm-12">
                                    <Dropdown className="col-12">
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" style={{ color: '#0ddbfb' }}>
                                            Select Organ
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as={CustomMenu} >
                                            <Dropdown.Item eventKey="1" active>Open</Dropdown.Item>
                                            <Dropdown.Item eventKey="2">Laparoscopic</Dropdown.Item>
                                            <Dropdown.Item eventKey="3" >
                                                Thoracoscopic
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="4">
                                                MAS
                                            </Dropdown.Item>
                                            <Dropdown.Item eventKey="4">
                                                Robotic
                                            </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
              </div>

              {/* <p className="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua.</p> */}
            </div>

            <div className="row align-items-center justify-content-center">
              {showAllVideos?.map((item) => {
                console.log(item);
                return (
                  <div className="col-md-4">
                    <div className="blog grid-blog">
                      <div className="blog-image">
                        <div class="embed-responsive embed-responsive-16by9">
                          <video
                            class="embed-responsive-item"
                            poster={
                              Constants.server +
                              "lwsfs/thumbnails/" +
                              item?.thumbnail
                            }
                            src={Constants.server + "lwsfs/" + item?.attachment}
                            controls
                            controlsList="nodownload"
                            playsInline
                          >
                            {/* <source src={RoboticCaudate} type="video/mp4"></source> */}
                            <p>Your browser does not support HTML5 video.</p>
                          </video>
                        </div>
                      </div>
                      <div className="blog-content">
                        <ul className="entry-meta meta-item">
                          <li>
                            <div className="post-author">
                              <span className="namedoctor">
                                <Link to="/profile/150">
                                  Dr. {item.full_name}
                                </Link>
                              </span>
                              <div className="community-badge">
                                <Badge
                                  style={{
                                    color: "white",
                                    fontSize: "small",
                                    margin: "10px 0",
                                  }}
                                  pill
                                  variant="info"
                                >
                                  {item?.speciality}
                                </Badge>
                              </div>
                            </div>
                          </li>
                          <li>
                            <i className="far fa-clock"></i>{" "}
                            {format(new Date(item?.createdAt), "d MMM yyyy")}
                          </li>
                        </ul>
                        <a
                          href="https://www.youtube.com/watch?v=-8Si7Lq3q0w&ab_channel=MaheshDpatel"
                          target="_blank"
                        >
                          <h3 className="blog-title">{item?.title}</h3>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>
      <div className="alert alert-info text-center">
        Are you interested in sharing your surgical images, videos and events on
        the Academics section? Please send us an e-mail on{" "}
        <a href="mailto:support@surgeonsforsurgeons.com">
          support@surgeonsforsurgeons.com
        </a>
        .
      </div>
      <FooterContent></FooterContent>
    </Fragment>
  );
};

export default Video;
