import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "../assets/css/reels.css";
import Constants from "../configs/Constants";
import { Image, Button, Dropdown, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import Config from "../configs/Config";
import apis from "../apis/apis";
import LoaderEmitters from "../configs/LoaderEmitters";
import LoadingSpinner from "./LoadingSpinner";
import EventEmitters from "../configs/EventEmitters";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const ReelsCard = ({
  data,
  current,
  savedPosts,
  scrollUp,
  scrollDown,
  allExploreData,
  setAllExploreData,
}) => {
  const [isSaved, setIsSaved] = useState(savedPosts?.includes(data.post_id));
  const [deleteShow, setDeleteShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVideoPaused, setisVideoPaused] = useState(false);
  const [inView, setInView] = useState(false);
  const [viewCount, setViewCount] = useState(data?.views);

  const videoRef = useRef(null);
  const handlePlayVideo = () => {
    if (videoRef.current.paused) {
      setisVideoPaused(false);
      videoRef.current.play();
    } else {
      setisVideoPaused(true);
      videoRef.current.pause();
    }
  };

  useLayoutEffect(() => {
    if (current == data.post_id) {
      // setInView(true)
      document.getElementById(current).scrollIntoView({
        behavior: "instant",
        block: "center",
        inline: "center",
      });
    }

    return () => {};
  }, []);

  useEffect(() => {
    console.log(current)
    if (current == data.post_id) {
      if (!inView) {
        getVideoDetails(data.post_id);
      }
      setInView(true);
      console.log('in view')
      videoRef.current.play();
      setisVideoPaused(false);
    } else {
      videoRef.current.pause();
      setInView(false);
      // setisVideoPaused(true)
    }
  }, [current]);

  let handleSave = async (postId) => {
    try {
      let { data } = await apis.saveExplorePost(
        Config.getSessionInfo("id"),
        postId
      );
      console.log(data);
      setIsSaved(!isSaved);
    } catch (error) {
      console.log(error);
    }
  };

  const getVideoDetails = async (postId) => {
    let { data } = await apis.getExplorePostDetails(postId);
    console.log(data);
    setViewCount(data?.post?.views);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: data?.title,
          text: data?.description,
          url: window.location.href,
        })
        .then(() => {
          console.log("Shared successfully");
        })
        .catch((error) => {
          console.error("Error sharing:", error);
        });
    } else {
      // Fallback for browsers that don't support the share API
      alert("Sharing is not supported on this device/browser.");
    }
  };

  const handleDelete = async (userId, postId) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    try {
      let { data } = await apis.deleteExplorePost(userId, postId);
      let tost = {
        message: "",
        type: "",
      };
      console.log(data);
      setAllExploreData((prev) =>
        prev.filter((item) => item.post_id !== postId)
      );
      if (data.message) {
        tost.type = "info";
        tost.message = data.message;
      }
      if (tost.message) {
        EventEmitters.showTost(tost);
      }
    } catch (error) {
      console.log(error);
    }
    LoaderEmitters.showLoader({
      setLoader: false,
    });

    setDeleteShow();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); 
    const year = date.getFullYear();

    // Format date as "dd/mm/yyyy"
    return`${day}/${month}/${year}`;
  };

  return (
    <>
      <div className="reel-card childElement" id={data.post_id}>
        {isLoading && (
          <div className="parent-loader">
            <LoadingSpinner />
          </div>
        )}
        {isVideoPaused ? (
          <div className="parent-play-pause">
            <i class="fas fa-play light-text fa-3x"></i>
          </div>
        ) : null}
        <video
          width="100%"
          height="100%"
          controls={false}
          // autoPlay={true}
          loop={true}
          onClick={handlePlayVideo}
          // style={{ borderRadius: "10px",objectFit:'cover' }}
          style={{ borderRadius: "10px" }}
          ref={videoRef}
          src={Constants.server + "explore/" + data?.video}
          poster={Constants.server + "thumbnails/" + data?.thumbnail}
          onLoadedData={() => setIsLoading(false)}
        ></video>

        <div className="all-function-container">
          <div className="func-container">
            <i class="fas fa-eye func-icon"></i>
            <p className="func-title">{viewCount} views</p>
          </div>
          {/* {isSaved ? (
            <div className="func-container">
              <i
                class="fas fa-bookmark func-icon"
                onClick={() => handleSave(data?.post_id)}
              ></i>{" "}
              <p className="func-title">saved</p>
            </div>
          ) : (
            <div className="func-container">
              <i
                class="far fa-bookmark func-icon"
                onClick={() => handleSave(data?.post_id)}
              ></i>
              <p className="func-title">save</p>
            </div>
          )} */}
          {/* <div className="func-container">
            <i class="fas fa-share func-icon" onClick={handleShare}></i>
            <p className="func-title">share</p>
          </div> */}
          {data.id === Config.getSessionInfo("id") && (
            <div className="func-container">
              <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic">
                  <i className="fas fa-ellipsis-v func-icon"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ transform: "translate(0px, 105%);" }}
                    onClick={() => setDeleteShow(true)}
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <div className="reel-footer">
          <p className="footer-title">{data?.title}</p>
          <div className="footer-details">
            <div className="footer-img ">
            <Link to={"/profile/" + data.id} title="View Profile" >
              <Image
                style={{ width: "100%", height: "100%" }}
                src={Constants.server + data?.author_profile_pic}
              />
              </Link>
            </div>
            <div className="user-detail">
            <Link to={"/profile/" + data.id} title="View Profile" >
              <p className="user-name-reel">{data?.author_name}</p>
              </Link>
              <p className="user-speciality"> {data?.speciality}</p>
              <p className="created-at">
                {" "}
                {formatDate(data?.created_date)}
              </p>
            </div>
          </div>
        </div>
        {current == data.post_id ? (
          <Button
            className="down-arrow"
            onClick={() => scrollDown()}
            disabled={
              allExploreData[allExploreData.length - 1].post_id === data.post_id
            }
          >
            <i className="fas fa-chevron-down"></i>
          </Button>
        ) : (
          <Button className="down-arrow" onClick={() => scrollUp()}>
            <i className="fas fa-chevron-down fa-flip-vertical"></i>
          </Button>
        )}
        <DeleteModal
          deleteShow={deleteShow}
          setDeleteShow={setDeleteShow}
          handleDelete={handleDelete}
          postId={data.post_id}
        />
      </div>
    </>
  );
};

const DeleteModal = ({ deleteShow, setDeleteShow, postId, handleDelete }) => {
  return (
    <Modal show={deleteShow} onHide={() => setDeleteShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Post</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: "1rem" }}>
          Are you sure you want to delete this Post ?
        </div>{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary " onClick={() => setDeleteShow(false)}>
          Close
        </Button>
        <Button
          variant="danger"
          onClick={() => handleDelete(Config.getSessionInfo("id"), postId)}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReelsCard;
