import axios from "axios";
import moment from "moment";
import Config from "../../configs/Config";
import Constants from "../../configs/Constants";
import EventEmitters from "../../configs/EventEmitters";
import {
    setClinicalCases,
    setClinicalCasesCollection,
    setSelfClinicalCases,
    setSelectedClCase
} from "./actions";

export const fetchClinicalCases = () => async (dispatch) => {
    await axios.get(Constants.getAllClinicalCases).then((res) => {
        const rawCases = res.data;
        const filteredArray = rawCases.reduce((acc, curr) => {
            const clCase = acc.find((ele) => ele.name === curr.speciality);
            if (typeof clCase === "undefined") {
                acc.push({
                    name: curr.speciality,
                    cases: [
                        {
                            ...curr,
                            isActive: Constants.getDifferenceBtnDates(
                                curr.created_at,
                                7
                            ),
                        },
                    ],
                });
                return acc;
            }
            clCase.cases.push({
                ...curr,
                isActive: Constants.getDifferenceBtnDates(curr.created_at, 7),
            });
            return acc;
        }, []);
        dispatch(setClinicalCases(filteredArray));
    });
};

export const fetchClinicalCasesCollection = () => async (dispatch) => {
    await axios
        .post(Constants.getClinicalCaseCollection, {
            signup_id: Config.getSessionInfo("id"),
        })
        .then((res) => {
            const rawCases = res.data;
            let cases = rawCases[0];
            let caseFiles = rawCases[1];

            cases = cases
                .map((clCase) => ({
                    ...clCase,
                    isAddedInCollection: 1,
                    files: caseFiles.filter(
                        (file) => file.cl_case_id === clCase.id
                    ),
                }))
                .sort((a, b) =>
                    moment(b.created_at).diff(moment(a.created_at))
                );

            dispatch(setClinicalCasesCollection(cases));
        })
        .catch((error) => {
            EventEmitters.showTost({
                type: "error",
                message: "Error in fetching cases",
            });
        });
};

export const fetchSelfClinicalCases = () => async (dispatch) => {
    await axios
        .post(Constants.getClinicalCasesBySignupId, {
            signup_id: Config.getSessionInfo("id"),
        })
        .then((res) => {
            const rawCases = res.data;
            let cases = rawCases[0];
            let caseFiles = rawCases[1];

            cases = cases
                .map((clCase) => ({
                    ...clCase,

                    files: caseFiles.filter(
                        (file) => file.cl_case_id === clCase.id
                    ),
                }))
                .sort((a, b) =>
                    moment(b.created_at).diff(moment(a.created_at))
                );

            dispatch(setSelfClinicalCases(cases));
        })
        .catch((error) => {
            console.log(error);
            EventEmitters.showTost({
                type: "error",
                message: "Error in fetching cases",
            });
        });
};
 
export const fetchSelectedClCase = (caseId) => async (dispatch) =>{
    await axios
      .get(Constants.getClinicalCaseById + "/" + caseId, {
        params: {
          signup_id: Config.getSessionInfo("id"),
        },
      })
      .then((res) => {
        console.log(res.data, "clinical case");
        // setClCase(res.data);
        dispatch(setSelectedClCase(res.data))
      })
      .catch((err) => {
        console.log(err);
      });
}
