import { Box } from "@mui/material";
import React from "react";
import Login from "./Login";
import Founder from "./Founder";
import BenefitsCarousel from "./BenefitsCarousel";
import DiveDeepSection from "./DiveDeepSection";
import OperatingRoom from "./OperatingRoom";
import SurgeonsTestimonials from "./SurgeonsTestimonials";
import GlobalSurgicalCommunity from "./GlobalSurgicalCommunity";
import Navbar from "./Navbar";
import Footer from "./Footer";
import './login.css'

const Home = () => {
  return (
    <Box>
      {/* <Navbar/> */}
      <Login />
      <Founder/>
      <BenefitsCarousel/>
      <GlobalSurgicalCommunity />
      <SurgeonsTestimonials/>
      <DiveDeepSection/>
      <OperatingRoom/>
      <Footer/>
    </Box>
  );
};

export default Home;
