import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Image, ListGroup, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import LoaderEmitters from "../configs/LoaderEmitters";
import { fetchClinicalCases } from "../reducers/clinicalCases/thunk";
import CaseDetailTabs from "./CaseDetailTabs";
import Comment from "./Comment";
import ConfirmationActionModal from "./ConfirmationActionModal";
import EditClinicalCase from "./EditClinicalCase";

const ClinicalCaseDetail = ({
  onEdit = () => {},
  clCase,
  showCollectionToggle = true,
  onCollectionToggle = async () => {},
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [clinicalCaseDetails, setClinicalCaseDetails] = useState(null);
  const [deleteCaseModal, setDeleteCaseModal] = useState(false);
  const [editCaseModal, setEditCaseModal] = useState(false);

  const [isInCollection, setIsInCollection] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const { register, handleSubmit, errors, reset } = useForm();
  const [show, setShow] = useState(false);
  const [showLikeList, setShowLikeList] = useState(false);
  const [flag, setFlag] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  //   const [clCase, setClCase] = useState(null);
  const [call, setCall] = useState(true);
  const [commentCount, setCommentCount] = useState(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalListLikeData, setModalListLikeData] = useState([]);
  const [commentToDeleted, setCommentToDeleted] = useState({});
  const [curruntIdForReport, setCurruntIdForReport] = useState(null);
  const handleCloseLikeModal = () => setShowLikeList(false);
  const [caseActive, setCaseActive] = useState(false);
  const handleShow = () => setShow(true);
  const id = Config.getSessionInfo("id");
  const handleCloseReportModal = () => {
    setShowReportModal(false);
    setCurruntIdForReport(null);
  };
  const handleShowReportModal = () => {
    setShowReportModal(true);
    setCurruntIdForReport(null);
  };
  useEffect(() => {
    setIsInCollection(clCase?.isAddedInCollection === 1);
    setClinicalCaseDetails(null);
    return () => {};
  }, [clCase]);

  useEffect(() => {
    setCaseActive(Constants.getDifferenceBtnDates(clCase.created_at, 7));
  }, []);

  const addCollectionToggle = async () => {
    try {
      let signup_id = Config.getSessionInfo("id");
      console.log("sadjfhskjdfh");
      if (!isInCollection) {
        await axios
          .post(Constants.addClinicalCaseCollection, {
            cl_case_id: clCase.id,
            signup_id: signup_id,
          })
          .then((res) => setIsInCollection(true));
      } else {
        await axios
          .post(Constants.deleteClinicalCaseCollection, {
            cl_case_id: clCase.id,
            signup_id: signup_id,
          })
          .then((res) => setIsInCollection(false));
      }
      await onCollectionToggle();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    axios.get(Constants.getClinicalCaseLikes + "/" + clCase.id).then((res) => {
      // console.log(res);
      if (res.data) {
        const signup_id = Config.getSessionInfo("id");
        setLikeCount(res.data.length);
        const fil = res.data.filter((fil) => {
          return fil.signup_id == signup_id;
        });
        // console.log(fil)
        if (fil.length > 0) {
          setFlag(true);
        } else {
          setFlag(false);
        }
      } else {
        setFlag(false);
      }
    });
  }, [call]);

  const onSubmitReportReason = (data) => {
    console.log("onSubmit data>>>>>", data);
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    // debugger;
    axios
      .post(Constants.addClinicalCaseReport, {
        signupId: Config.getSessionInfo("id"),
        clinicalCaseId: clCase.id,
        reason: data.reason,
        case: clCase,
        reportingUser: userDetails,
      })
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });

        let tost = {
          message: "",
          type: "",
        };
        console.log("response>>>>>>", response);
        if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          // loadDataOfCommunity(Config.getSessionInfo("id"));
          // handleClose();
        } else {
          tost.type = "error";
          tost.message = response.data.msg;
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }

        // reset();
        handleCloseReportModal();
      })
      .catch(console.error);
  };
  const onClickLike = () => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    if (flag == false) {
      axios
        .post(Constants.likeClinicalCase, {
          signupId: Config.getSessionInfo("id"),
          clinicalCaseId: clCase.id,
        })
        .then((response) => {
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          setCall(!call);
          // reset();
          let tost = {
            message: "",
            type: "",
          };
          console.log("response>>>>>>", response);
          if (response.data.code === 200) {
            tost.type = "success";
            tost.message = response.data.msg;

            // loadDataOfCommunity(Config.getSessionInfo("id"));
            // loadDataOfCommunity(Config.getSessionInfo('id'));
            // handleClose();
            // props.history.push('/qualification');
          } else {
            tost.type = "error";
            tost.message = response.data.msg;
          }

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
        })
        .catch(console.error);
    } else {
      axios
        .post(Constants.dislikeClinicalCase, {
          signupId: Config.getSessionInfo("id"),
          clinicalCaseId: clCase.id,
        })
        .then((response) => {
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          setCall(!call);
          // reset();
          let tost = {
            message: "",
            type: "",
          };
          console.log("response>>>>>>", response);
          if (response.data.code === 200) {
            tost.type = "success";
            tost.message = response.data.msg;
          } else {
            tost.type = "error";
            tost.message = response.data.msg;
          }

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
        })
        .catch(console.error);
    }
  };

  const seeLikeList = () => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    axios
      .get(Constants.getClinicalCaseLikesList + "/" + clCase.id)
      .then((response) => {
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        console.log(response);
        // let tost = {
        //   message: "",
        //   type: "",
        // };
        console.log("response>>>>>>", response);
        // if (response.data.code === 200) {
        // tost.type = "success";
        // tost.message = response.data.msg;
        setShowLikeList(true);
        setModalListLikeData([...response.data]);
        // } else {
        //   tost.type = "error";
        //   tost.message = response.data.msg;
        // }
      })
      .catch(console.error);
  };
  useEffect(() => {
    axios
      .post(Constants.deleteClinicalComment, {
        comment_id: commentToDeleted.comment_id,
        signup_id: id,
      })
      .then((response) => {
        console.log(response);
      });
  }, [commentToDeleted]);
  const comment = function (id, elm, flag) {
    if (!flag) {
      return;
    }

    if (!id) return;
    var x = document.getElementById(id);
    // setCurruntComment("");
    if (x.style.display === "none") {
      x.style.display = "block";
      // getCommentsOnClinicalCase(elm);
    } else {
      x.style.display = "none";
    }
  };

  const deletCaseFromCollection = async () => {
    try {
      await axios
        .post(Constants.deleteClinicalCaseById, {
          id: clCase.id,
        })
        .then((res) => {
          dispatch(fetchClinicalCases());
          setDeleteCaseModal(false);
          history.push("/community");
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="doc-review review-listing">
      <ul className="comments-list">
        <li>
          <div className="comment">
            <img
              className="avatar rounded-circle"
              alt="User Image"
              src={Constants.server + clCase.profile_pic}
            />
            <div className="comment-body full-width-100">
              <div className="meta-data">
                <span className="comment-author d-flex justify-content-between align-items-center">
                  {/* Dr. {elm.full_name} */}
                  <NavLink to={`/profile/${clCase.signup_id}`}>
                    Dr. {clCase.full_name}
                  </NavLink>
                  {clCase.signup_id == Config.getSessionInfo("id") && (
                    <Dropdown>
                      <Dropdown.Toggle
                        as={"span"}
                        className="case-dropdown-toggle"
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          style={{ color: "#757575" }}
                          onClick={() => {
                            setDeleteCaseModal(true);
                          }}
                        >
                          <i class="far fa-trash-alt"></i> Delete
                        </Dropdown.Item>

                        {/* ============ Edit button ===============  */}
                        <Dropdown.Item
                          style={{ color: "#757575" }}
                          onClick={() => {
                            setEditCaseModal(true);
                            setClinicalCaseDetails(clCase);
                          }}
                        >
                          <i class="far fa-edit"></i> Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </span>
                {/* Dr. {userDetails.full_name} */}

                <span className="comment-date">
                  Posted On: {moment(clCase.created_at).format("LL")}
                </span>
                <div className="community-badge">
                  <Badge
                    style={{
                      color: "white",
                      fontSize: "small",
                      margin: "10px 0",
                      textAlign: "center",
                    }}
                    pill
                    variant="info"
                  >
                    {clCase.speciality}
                  </Badge>
                </div>
              </div>
              <h3 className="patient__details__head">Patient's Details</h3>
              <div className="case__details">
                <div className="case__age">
                  <p className="color-blue">
                    Age:{" "}
                    <span style={{ color: "#272b41" }}>
                      {clCase.patient_age}
                    </span>
                  </p>
                </div>
                <div className="case__gender  ml-4">
                  <p className="color-blue">
                    Gender:{" "}
                    <span
                      style={{ color: "#272b41", textTransform: "capitalize" }}
                    >
                      {clCase.patient_gender.toLowerCase()}
                    </span>
                  </p>
                </div>
                <div
                  className="case__status  ml-4"
                  style={{
                    display: "flex",
                  }}
                >
                  <p className="color-blue">Status:</p>
                  <p
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    {caseActive ? (
                      <p className="color-green">Active</p>
                    ) : (
                      <p className="case-closed">Closed</p>
                    )}
                  </p>
                </div>
              </div>
              <div className="">
                <CaseDetailTabs
                  diagnosis={clCase.diagnosis}
                  questions={clCase.clinical_questions}
                  history={clCase.history}
                  images={clCase.files.filter(
                    (file) =>
                      file.category === "IMAGE" &&
                      file.mime_type.includes("image")
                  )}
                  videos={clCase.files.filter(
                    (file) =>
                      file.category === "VIDEO" &&
                      file.mime_type.includes("video")
                  )}
                  reports={clCase.files.filter(
                    (file) => file.category === "REPORT"
                  )}
                  scans={clCase.files.filter(
                    (file) => file.category === "SCAN"
                  )}
                />
              </div>
              <div className="all-media">
                <span
                  href="#"
                  className="like-btn liked pointer"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <i
                    style={{ fontSize: "20px" }}
                    title="Like"
                    className="far fa-heart"
                    onClick={caseActive ? onClickLike : null}
                  ></i>
                  <small
                    title="Click here to view like members"
                    onClick={seeLikeList}
                    className="likes-count"
                  >
                    {console.log(likeCount)}
                    {likeCount} {likeCount ? "-like" : null}
                  </small>
                </span>

                <a
                  className="dislike-btn pointer"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Comment"
                  onClick={
                    caseActive
                      ? comment.bind(
                          this,
                          "comment-d" + clCase.id,
                          clCase.id,
                          true
                        )
                      : null
                  }
                >
                  <span
                    style={{ fontSize: "20px" }}
                    className="far fa-comment"
                  ></span>
                  <span
                    style={{ fontFamily: "inherit", marginLeft: "5px" }}
                    className="like"
                  >
                    {" "}
                    {commentCount}{" "}
                  </span>
                </a>

                {/* {differenceBtnDates && ( */}
                <>
                  {showCollectionToggle && (
                    <span
                      className="collection-btn float-right pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={addCollectionToggle}
                      title="Add To Collection"
                    >
                      <i
                        className={
                          isInCollection ? "fa fa-folder liked" : "fa fa-folder"
                        }
                      ></i>{" "}
                      {isInCollection ? "Remove from" : "Add to"} Collection
                    </span>
                  )}

                  <a
                    className="dislike-btn float-right pointer"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Report"
                    onClick={handleShowReportModal.bind(
                      this,

                      true
                    )}
                  >
                    <i className="fa fa-flag"></i> Report
                  </a>
                </>
                {/* )} */}
              </div>
            </div>
          </div>
          {caseActive ? (
            <Comment
              setPostData={null}
              setModalVisible={() => setIsModalVisible(!isModalVisible)}
              setCommentToDeleted={setCommentToDeleted}
              // CommentData={communityCommentData}
              CommentType={"clinicalcase"}
              elm={clCase.id}
              userID={id}
              setCommentCount={setCommentCount}
              // handleCommentDelete={handleCaseCommentDelete}
              isInputVisible={true}
            />
          ) : null}
        </li>
      </ul>
      <Modal show={showLikeList} onHide={handleCloseLikeModal}>
        <Modal.Header closeButton>
          <Modal.Title> Surgeons who liked. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {modalListLikeData} */}

          <ListGroup>
            {modalListLikeData &&
              modalListLikeData.map(
                (elm) => (
                  // if (elm.is_like === 0) return null;
                  // return (
                  <ListGroup.Item>
                    <Image
                      className="like-modal-img"
                      src={Constants.server + elm.profile_pic}
                      roundedCircle
                    />
                    <h6
                      style={{
                        display: "inline",
                      }}
                    >
                      <NavLink to={"profile/" + elm.owner_id}>
                        {elm.full_name}
                      </NavLink>
                    </h6>
                  </ListGroup.Item>
                )
                // );
              )}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal show={showReportModal} onHide={handleCloseReportModal}>
        <Modal.Header closeButton>
          <Modal.Title> Please fill the reason. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={handleSubmit(onSubmitReportReason)}
            noValidate
            autoComplete="off"
          >
            <div className="form-group row">
              <label
                for="email_address"
                className="col-md-4 col-form-label text-md-right"
              >
                Reason
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="reason"
                  required
                  ref={register({ required: true })}
                />
                {errors.title && (
                  <div className="error-text" role="alert">
                    Reason is required.
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 offset-md-4">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <div>
        <ConfirmationActionModal
          modalTitle={"Delete Case"}
          actionHandler={deletCaseFromCollection}
          isVisible={deleteCaseModal}
          setVisible={() => setDeleteCaseModal((oldState) => !oldState)}
          actionBtnText={"Delete"}
        >
          <div style={{ fontSize: "1rem" }}>
            Are you sure you want to delete the case?
          </div>
        </ConfirmationActionModal>
      </div>
      <div>
        {!!clinicalCaseDetails && (
          <EditClinicalCase
            onEdit={onEdit}
            editData={clinicalCaseDetails}
            show={editCaseModal}
            handleClose={() => setEditCaseModal((oldState) => !oldState)}
          />
        )}
      </div>
    </div>
  );
};

export default ClinicalCaseDetail;
