import React, { useRef, useState } from "react";
import UserIcon from "../../assets/img/user-male.svg";
import "./index.css";

const ChatSupport = ({ phoneNumber }) => {
  const wrapRef = useRef(null);
  const [message, setMessage] = useState("");

  const handleWrapToggle = () => {
    if (wrapRef.current) {
      wrapRef.current.classList.toggle("ChatSupport__container-wrap--close");
      wrapRef.current.classList.toggle("ChatSupport__container-wrap--open");
    }
  };
  return (
    <div className="ChatSupport">
      <div className="ChatSupport__container">
        <div
          ref={wrapRef}
          className={
            "ChatSupport__container-wrap ChatSupport__container-wrap--close"
          }
        >
          <button className="ChatSupport__cross-btn" onClick={handleWrapToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </button>
          <div className="ChatSupport__content">
            <img
              alt="user icon"
              src={UserIcon}
              style={{
                width: 60,
                height: 60,
              }}
              className="usericon"
            />
            <div className="ChatSupport__content-container">
              <div className="ChatSupport__content-wrapper">
                <div className="ChatSupport__content-head">
                  <p style={{ display: "inline-block" }}>
                    The SFS Tech Support Team is available to help. Ask us
                    anything about how this website works.
                  </p>
                </div>
                <div className="ChatSupport__content-footer">
                  Hello, can we help you?
                </div>
              </div>
              <div className="ChatSupport__chat">
                <input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  type="text"
                  name="message"
                  placeholder="Type your query here..."
                  id=""
                  className="ChatSupport__chat-input"
                />
                <button
                  className="ChatSupport__send-btn"
                  onClick={() => {
                    let sendMessage = message;
                    !!sendMessage &&
                      window.open(
                        `https://wa.me/91${phoneNumber}?text=${encodeURI(
                          sendMessage
                        )}`
                      ) &&
                      setMessage("");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#22c15e"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path d="M3.4 20.4l17.45-7.48c.81-.35.81-1.49 0-1.84L3.4 3.6c-.66-.29-1.39.2-1.39.91L2 9.12c0 .5.37.93.87.99L17 12 2.87 13.88c-.5.07-.87.5-.87 1l.01 4.61c0 .71.73 1.2 1.39.91z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <button className="ChatSupport__cta-btn" onClick={handleWrapToggle}>
          <svg
            fill="#FFF"
            height="24px"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M16.75 13.96c.25.13.41.2.46.3.06.11.04.61-.21 1.18-.2.56-1.24 1.1-1.7 1.12-.46.02-.47.36-2.96-.73-2.49-1.09-3.99-3.75-4.11-3.92-.12-.17-.96-1.38-.92-2.61.05-1.22.69-1.8.95-2.04.24-.26.51-.29.68-.26h.47c.15 0 .36-.06.55.45l.69 1.87c.06.13.1.28.01.44l-.27.41-.39.42c-.12.12-.26.25-.12.5.12.26.62 1.09 1.32 1.78.91.88 1.71 1.17 1.95 1.3.24.14.39.12.54-.04l.81-.94c.19-.25.35-.19.58-.11l1.67.88M12 2a10 10 0 0 1 10 10 10 10 0 0 1-10 10c-1.97 0-3.8-.57-5.35-1.55L2 22l1.55-4.65A9.969 9.969 0 0 1 2 12 10 10 0 0 1 12 2m0 2a8 8 0 0 0-8 8c0 1.72.54 3.31 1.46 4.61L4.5 19.5l2.89-.96A7.95 7.95 0 0 0 12 20a8 8 0 0 0 8-8 8 8 0 0 0-8-8z"></path>
          </svg>{" "}
          &nbsp; Hello, can we help you?
        </button>
      </div>
    </div>
  );
};

export default ChatSupport;
