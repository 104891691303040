import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Redirect, useHistory } from "react-router";
import Select from "react-select";
import Config from "../configs/Config";
import Constants from "../configs/Constants";
import EventEmitters from "../configs/EventEmitters";
import LoaderEmitters from "../configs/LoaderEmitters";
import Constant from "../configs/Constants";
// import {otherGroup as OG} from './Signup/qualification'

// import termsOfUse from "../../assets/docs/Terms of Use.pdf";
// import companyPolicy from "../../assets/docs/Company Policy.pdf";
// console.log(OG);
// onHide={()=>{setShowModal(false)}}
function PersonalEdit(props) {
  const history = useHistory();
  const ref = useRef(null);
  const [countryCode, setCountryCode] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const { register, handleSubmit, errors, reset, setValue } = useForm();
  const profileData = props.profile;
  const [startDate, setStartDate] = React.useState(
    moment(profileData.profile.dob).format("YYYY-MM-DD")
  );
  const [selectedState, setSelectedState] = React.useState(
    profileData.profile.current_location
  );
  const [country, setCountry] = React.useState(0);
  const [region, setRegion] = React.useState(0);
  const [adddata, setAddData] = React.useState("");
  const [otherGroup, setOtherGroup] = React.useState();
  const [mediRegistration, setMediRegistration] = React.useState(null);

  const [mobile, setMobile] = React.useState();
  const [regionsList, setRegionsList] = React.useState([]);
  const [countries, setCountries] = React.useState(false);
  const [other, setOther] = React.useState();
  const [checkedboxone, setCheckedboxone] = React.useState(false);
  const [checkedboxtwo, setCheckedboxtwo] = React.useState(false);
  const [checkedboxthree, setCheckedboxthree] = React.useState(false);
  const [checkedboxfour, setCheckedboxfour] = React.useState(false);
  const [checkedboxfive, setCheckedboxfive] = React.useState(false);
  const [checkedboxsix, setCheckedboxsix] = React.useState(false);
  const [addMoreList, setAddMoreList] = React.useState({
    country: [],
    region: [],
    mediRegistration: [],
  });
  const [countryCodeUser, setcountryCodeUser] = React.useState();
  var chat_room = profileData.addGroup.map((res) =>
    res.chat_room_center_id.trim()
  );
  // console.log(chat_room)
  var options_group = Constant.specialityList
    .filter((res) => !chat_room.includes(res))
    .map((res) => {
      return { value: res, label: res };
    });
  // console.log(options_group)

  React.useEffect(() => {
    // profileData.mediRegistration.mediRegistrationCountry.map(
    //   (elm, idx) => {
    if (profileData.mediRegistration.mediRegistrationCountry != "") {
      setAddMoreList({
        country: profileData.mediRegistration.mediRegistrationCountry,
        region: profileData.mediRegistration.mediRegistrationState,
        mediRegistration: profileData.mediRegistration.mediRegistrationNumber,
      });
    }
    if (profileData.membershipAssociation) {
      setOther(profileData.membershipAssociation.other_international);
    }

    //   }
    // )
  }, []);

  console.log(">>>>>", profileData);
  React.useEffect(() => {
    const temp = profileData.profile.mobile;
    // console.log("temp", temp);
    if (!!temp) {
      const patt1 = /\r/;
      const no = temp.search(patt1);

      const temp1 = temp.substring(0, no + 1);

      let countrycodeuser = countryCode.filter((obj) => {
        // console.log("obj phonecode",JSON.stringify(obj.phone_code) == JSON.stringify(temp1))

        return JSON.stringify(obj.phone_code) == JSON.stringify(temp1);
        // return true;
      });

      const mobno = profileData.profile.mobile.substring(no);

      setMobile(mobno);
      setcountryCodeUser(
        countrycodeuser.map(function (obj) {
          return obj.phone_code;
        })
      );
    }
  }, [profileData, countryCode]);

  React.useEffect(() => {
    if (profileData.qualification != null) {
      setAddData(profileData.qualification.additionalQualifications);
    } else {
      setAddData("");
    }

    loadAllCountries();
    loadAllCities();
    handler();
  }, []);

  const handleChange = (date) => {
    setStartDate(date.target.value);
    {
      console.log(date.target.value);
    }
  };

  const loadAllCountries = () => {
    axios
      .post(Constants.getAllCountries, {})
      .then((response) => {
        console.log(response);
        setCountryCode(
          [...response.data.result].map((data) => {
            data.phone_code.replace(/[\r\n]+/gm, "");
            return data;
          })
        );
        setCountries([...response.data.result]);
      })
      .catch(console.error);
  };

  const loadAllCities = () => {
    axios
      .post(Constants.getAllCities, {})
      .then((response) => {
        setCities([...response.data.result]);
      })
      .catch(console.error);
  };

  const specialityGroupPresent = (checkId) => {
    for (let i = 0; i < profileData.specialtyGroups.length - 1; i++) {
      if (profileData.specialtyGroups[i].chat_room_center_id == checkId) {
        return false;
      }
    }
    return true;
  };

  const onSubmitPersonalDetails = (data) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });

    data.dob = moment(startDate).format("YYYY-MM-DD");
    if (
      data.dob === moment(profileData.profile.dob).format("YYYY-MM-DD") &&
      selectedState.trim() === profileData.profile.current_location &&
      data.country_code + data.phone.trim() === profileData.profile.mobile
    ) {
      EventEmitters.showTost({
        type: "error",
        message: "No changes made",
      });
      LoaderEmitters.showLoader({
        showLoader: false,
      });
      return false;
    }
    data.city = selectedState;
    data.phone = data.country_code + data.phone.trim();
    if (data.city === "") {
      data.city = profileData.profile.current_location;
    }
    if (data.phone === "") {
      data.phone = profileData.profile.mobile;
    }
    if (data.dob === "") {
      data.dob = profileData.profile.dob;
    }
    const config = {
      headers: {
        id: Config.getSessionInfo("id"),
      },
    };
    axios
      .post(Constants.updatePersonalDetailFirst, data, config)
      .then((response) => {
        console.log("catches here");
        console.log("response of personal edit >>>", response);
        const { params } = props.match;
        props.getProfileDetailsFun(params.id);
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        EventEmitters.showTost({
          type: "success",
          message: "Personal Details updated successfully",
        });
      });
    console.log("success");
  };

  const onSubmitMembership = (data) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    console.log("data>>>>>>>>>>>", data);

    data.id = Config.getSessionInfo("id");
    data.curruentTab = 4;
    if (data.otherInternational) {
      if (data.memberShip.length) {
        data.memberShip.push(data.otherInternational);
      } else {
        data.memberShip = [data.otherInternational];
      }
    }

    if (!profileData.membershipAssociation) {
      axios
        .post(Constants.membershipAssociation, data)
        .then((response) => {
          const { params } = props.match;
          props.getProfileDetailsFun(params.id);
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          EventEmitters.showTost({
            type: "success",
            message: "Membership Details updated successfully",
          });
          let tost = {
            message: "",
            type: "",
          };
          if (response.data.code === 404) {
            tost.message = response.data.msg;
            tost.type = "error";
          }
          //  else if (response.data.code === 202) {
          //     tost.message = response.data.msg;
          //     tost.type = "success";
          //     props.history.push('/signup');
          // } else if (response.data.code === 200) {
          //     tost.type = "success";
          //     tost.message = response.data.msg;
          //     props.history.push('/mediRegistration');
          // }

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
        })
        .catch(console.error);
      return true;
    }

    if (
      data.otherInternational ==
        profileData.membershipAssociation.other_international &&
      data.memberShip == profileData.membershipAssociation.association
    ) {
      EventEmitters.showTost({
        type: "error",
        message: "No changes made",
      });
      LoaderEmitters.showLoader({
        showLoader: false,
      });
      return false;
    }
    // else
    // {
    //   data.membershipAssociation.push(null);
    // }
    axios
      .post(Constants.updatemembershipAssociation, data)
      .then((response) => {
        // LoaderEmitters.showLoader({
        //   showLoader: false,
        // });
        const { params } = props.match;
        props.getProfileDetailsFun(params.id);
        EventEmitters.showTost({
          type: "success",
          message: "Membership Details updated successfully",
        });
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        let tost = {
          message: "",
          type: "",
        };
        if (response.data.code === 404) {
          tost.message = response.data.msg;
          tost.type = "error";
        } else if (response.data.code === 202) {
          tost.message = response.data.msg;
          tost.type = "success";
          props.history.push("/signup");
        } else if (response.data.code === 200) {
          tost.type = "success";
          tost.message = response.data.msg;
          props.history.push("/mediRegistration");
        }

        if (tost.message) {
          EventEmitters.showTost(tost);
        }
      })
      .catch(console.error);
  };

  const onSubmitAdditionalQualification = (data) => {
    // if(profileData.qualification.additionalQualifications)
    // {
    //     data.additionalQualifications += profileData.qualification.additionalQualifications
    // }
    if (
      data.additionalQualifications ==
      profileData.qualification.additionalQualifications
    ) {
      EventEmitters.showTost({
        type: "error",
        message: "No changes made",
      });
      return false;
    }
    data.id = Config.getSessionInfo("id");

    LoaderEmitters.showLoader({
      showLoader: true,
    });
    axios
      .post(Constants.updateAdditionalQualification, data)
      .then((response) => {
        const { params } = props.match;
        props.getProfileDetailsFun(params.id);
        // handler()
        LoaderEmitters.showLoader({
          showLoader: false,
        });
        EventEmitters.showTost({
          type: "success",
          message: "Additional Qualification updated successfully",
        });
        console.log("response>>>>", response);
      })
      .catch(console.error);
  };

  const handler = () => {
    // ref.current.value = profileData.membershipAssociation.other_international;
    {
      profileData &&
        profileData.membershipAssociation &&
        profileData.membershipAssociation.association.split(",").map((res) => {
          if (res === "ACS") {
            setCheckedboxone(true);
          } else if (res === "IHPBA") {
            setCheckedboxtwo(true);
          } else if (res === "SAGES") {
            setCheckedboxthree(true);
          } else if (res === "ASCRS") {
            setCheckedboxfour(true);
          } else if (res === "ASGBI") {
            setCheckedboxfive(true);
          } else {
            setCheckedboxsix(true);
          }
        });
    }
  };

  // meddical registration

  const onChange = (name, e) => {
    const { value } = e.target;
    if (name === "country") {
      setCountry(
        // ...country,
        value
      );
      axios
        .post(Constants.searchRegionByCountry, {
          countryId: value,
        })
        .then((response) => {
          const { numOfResults, result } = response.data;
          if (numOfResults) {
            setRegionsList(result);
          } else {
            setRegionsList([]);
          }
        });
    } else if (name === "region") {
      setRegion(
        // ...region,
        value
      );
    } else if (name === "medicalRegistrationNumber") {
      setMediRegistration(
        // ...region,
        value
      );
    }
  };
  // console.log("here it is",profileData.mediRegistration.keys(obj).length === 0)

  const updateAddMoreList = (list) => {
    return list && list.country && list.country.length
      ? list.country.map((elm) => {
          return isNaN(elm) ? elm : getCountryNameByList(elm);
        })
      : [];
  };
  const onSubmitAdditionalGroup = (data) => {
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    if (otherGroup) {
      data.otherGroup = otherGroup.map((res) => {
        return res.value + "#";
      });
    }
    const { params } = props.match;
    data.id = params.id;
    console.log("here is true in additional 2");
    axios
      .post(Constants.updateChatOtherRooms, data)
      .then((res) => {
        console.log("here is true in additional 3");
        console.log(res);
        const { params } = props.match;
        props.getProfileDetailsFun(params.id);
        EventEmitters.showTost({
          type: "success",
          message: "Group Added Successfully",
        });
        LoaderEmitters.showLoader({
          showLoader: false,
        });
      })
      .catch(console.error());
  };
  // console.log("profileData.mediRegistration>>>>",profileData.mediRegistration)
  const onSubmitMedicalRegistration = (data) => {
    data.id = Config.getSessionInfo("id");
    LoaderEmitters.showLoader({
      showLoader: true,
    });
    // if (
    //   addMoreList &&
    //   addMoreList.country &&
    //   addMoreList.country.length &&
    //   addMoreList.mediRegistration.length
    // ) {

    // } else {
    //   EventEmitters.showTost({
    //     message: "Click on Add to record the entry.",
    //     type: "error",
    //   });
    //   return;
    // }

    data.list = { ...addMoreList };
    data.list.countryNames = updateAddMoreList({ ...addMoreList });
    if (!profileData.mediRegistration.mediRegistrationCountry) {
      data.curruentTab = 4;
      axios
        .post(Constants.mediRegistration, data)
        .then((response) => {
          const { params } = props.match;
          props.getProfileDetailsFun(params.id);
          EventEmitters.showTost({
            type: "success",
            message: "Medical Registration updated successfully",
          });
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          let tost = {
            message: "",
            type: "",
          };
        })
        .catch(console.error);
      return true;
    } else {
      axios
        .post(Constants.updateMediRegistration, data)
        .then((response) => {
          const { params } = props.match;
          props.getProfileDetailsFun(params.id);
          LoaderEmitters.showLoader({
            showLoader: false,
          });
          EventEmitters.showTost({
            type: "success",
            message: "Medical Registration updated successfully",
          });
          let tost = {
            message: "",
            type: "",
          };

          if (tost.message) {
            EventEmitters.showTost(tost);
          }
        })
        .catch(console.error);
    }
  };

  const addMedicalReg = () => {
    // debugger;
    if (country && region && mediRegistration) {
      if (addMoreList.country) {
        setAddMoreList({
          country: [...addMoreList.country, country],
          region: [...addMoreList.region, region],
          mediRegistration: [...addMoreList.mediRegistration, mediRegistration],
        });
      } else {
        setAddMoreList({
          country: [country],
          region: [region],
          mediRegistration: [mediRegistration],
        });
      }
      // setMediRegistration("");
      setCountry(null);
      setRegionsList([]);
      // reset();
      document.getElementsByName("medicalRegistrationNumber")[0].value = "";
      document.getElementsByName("country")[0].value = 0;
    }
  };

  const removeRecordFromList = (elm, idx) => {
    console.log("addMoreList>>>>>>>>", addMoreList);

    const listTemp = { ...addMoreList };
    listTemp.country.splice(idx, 1);
    listTemp.region.splice(idx, 1);
    listTemp.mediRegistration.splice(idx, 1);
    setAddMoreList({
      ...listTemp,
    });
  };

  const getCountryNameByList = (elm) => {
    const countriesTemp = [...countries];
    console.log(elm);
    var filterName = countriesTemp.filter((e) => {
      return e.id == elm;
    });
    console.log("filterName.name?>>>>>>>>>>>>>>>>>>", filterName);
    return filterName && filterName[0].name;
  };

  const option = cities.map((row) => {
    return { value: row.id, label: row.name };
  });

  return (
    <Modal
      show={props.show}
      onHide={props.hide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      id="myModal"
      centered
    >
      {/* {console.log(a)} */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.perosonalEditPopup && "Edit Personal details"}
          {props.membershipAssociationPopup && "Edit Membership Association"}
          {props.medicalRegistrationPopup && "Edit Medical registration"}
          {props.additionalQualificationsPopup &&
            "Edit Additional qualification"}
          {props.specialityGroupPopup && "Add Speciality Group"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.perosonalEditPopup ? (
          <form
            onSubmit={handleSubmit(onSubmitPersonalDetails)}
            noValidate
            autoComplete="off"
          >
            <label className="labelpersonaledit">Country Code</label>
            <select
              name="country_code"
              ref={register()}
              className="select form-control"
              value={countryCodeUser}
              onChange={(e) => setcountryCodeUser(e.target.value)}
            >
              {/* <option value=" " selected="selected">
                {countrycodeuser}
                {console.log(countrycodeuser)}
              </option> */}
              {countryCode &&
                countryCode.length &&
                countryCode.map((opt) => {
                  return (
                    <option value={opt.phone_code}>
                      {opt.name + " (" + opt.phone_code + ")"}
                    </option>
                  );
                })}
            </select>
            <label className="labelpersonaledit">Phone</label>

            <input
              readOnly
              type="text"
              class="form-control"
              name="phone"
              onChange={(event) => {
                setMobile(event.target.value);
              }}
              value={!!mobile ? mobile.trim() : mobile}
              ref={register()}
            />
            {/* {console.log(temp1)} */}
            <label className="labelpersonaledit">Date Of Birth</label>
            <input
              type="date"
              className="form-control datetimepicker datetimepicker-input"
              name="dob"
              onChange={handleChange}
              placeholder="Date of Birth"
              value={startDate}
            />
            {console.log()}
            <label className="labelpersonaledit">
              Current Location
              <small> (Please select "other", if city is not present)</small>
            </label>

            {/* <select name="city" ref={register()} className="select form-control">

                                                           
                  <option value="" selected="selected">Current Location (City) *</option>
                 
                  {
                      cities &&
                      cities.length &&
                      cities.map((opt) => {
                          return (
                              
                              <option value={opt.name}>{opt.name}</option>
                          )
                      })
                  }


              </select> */}
            <Select
              value={option.label}
              onChange={(option) => setSelectedState(option.label)}
              options={option}
              defaultInputValue={selectedState}
            />
            <br />
            {console.log(selectedState)}
            {/*  */}
            <button
              onClick={props.hide}
              type="submit"
              class="btn btn-primary"
              centered
            >
              Save
            </button>
          </form>
        ) : null}
        {props.specialityGroupPopup ? (
          <form
            onSubmit={handleSubmit(onSubmitAdditionalGroup)}
            noValidate
            autoComplete="off"
          >
            {console.log(options_group)}
            <Select
              name="otherGroup"
              isMulti
              ref={register({ required: false })}
              // name="colors"
              options={options_group.filter((item) =>
                specialityGroupPresent(item.value)
              )} // options will object of label and value
              className="basic-multi-select"
              onChange={(e) => {
                setOtherGroup(e);
              }}
              class="form-control"
            ></Select>
            <br></br>
            <div className="form-group">
              <ul>
                {profileData &&
                  profileData.specialtyGroups &&
                  profileData.specialtyGroups
                    .filter(
                      (res) => res.chat_room_center_name.includes("#") == true
                    )
                    .map((elm, idx) => {
                      return (
                        <li key={idx}>
                          {elm.chat_room_center_name.slice(
                            0,
                            elm.chat_room_center_name.length - 1
                          )}
                        </li>
                      );
                    })}
              </ul>
            </div>
            <br></br>
            <button
              type="submit"
              onClick={props.hide}
              class="btn btn-primary"
              centered
            >
              Join
            </button>
          </form>
        ) : null}

        {props.membershipAssociationPopup ? (
          <form onSubmit={handleSubmit(onSubmitMembership)}>
            <label>Membership Association</label>

            <div className="col-12 col-md-6 col-lg-6">
              <div className="form-group">
                <select className="select form-control">
                  <option disabled selected="selected">
                    International*
                  </option>
                </select>
              </div>
              <div className="row form-row">
                <div className="col-12">
                  <div>
                    <label>
                      <input
                        name="memberShip"
                        ref={register({ required: false })}
                        value="ACS"
                        defaultChecked={checkedboxone}
                        type="checkbox"
                        onChange={(ev) => {
                          setCheckedboxone(!ev.target.defaultChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="mr-6">ACS</span>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <label>
                      <input
                        ref={register({ required: false })}
                        type="checkbox"
                        name="memberShip"
                        defaultChecked={checkedboxtwo}
                        value="IHPBA"
                        onChange={(ev) => {
                          setCheckedboxtwo(!ev.target.defaultChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="mr-6">IHPBA</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">
                  <div>
                    <label>
                      <input
                        name="memberShip"
                        ref={register({ required: false })}
                        value="SAGES"
                        defaultChecked={checkedboxthree}
                        type="checkbox"
                        onChange={(ev) => {
                          setCheckedboxthree(!ev.target.defaultChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="mr-6">SAGES</span>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <label>
                      <input
                        name="memberShip"
                        ref={register({ required: false })}
                        value="ASCRS"
                        defaultChecked={checkedboxfour}
                        type="checkbox"
                        onChange={(ev) => {
                          setCheckedboxfour(!ev.target.defaultChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="mr-6">ASCRS</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">
                  <div>
                    <label>
                      <input
                        name="memberShip"
                        ref={register({ required: false })}
                        value="ASGBI"
                        defaultChecked={checkedboxfive}
                        type="checkbox"
                        onChange={(ev) => {
                          setCheckedboxfive(!ev.target.defaultChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="mr-6">ASGBI</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">
                  <div>
                    <label>
                      <input
                        name="memberShip"
                        ref={register({ required: false })}
                        value="SSAT"
                        defaultChecked={checkedboxsix}
                        type="checkbox"
                        onChange={(ev) => {
                          setCheckedboxsix(!ev.target.defaultChecked);
                        }}
                      />
                      <span className="checkmark"></span>
                      <span className="mr-6">SSAT</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row form-row">
                <div className="col-12">
                  <div className="form-group">
                    <label>Other &nbsp;</label>
                    <input
                      type="text"
                      ref={ref}
                      value={other}
                      onChange={(ev) => {
                        setOther(ev.target.value);
                      }}
                      name="otherInternational"
                      className="form-control"
                      ref={register()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <button
              type="submit"
              onClick={() => {
                // onSubmitMembership()
                props.hide();
                // window.location.reload();
              }}
              class="btn btn-primary"
              centered
            >
              Save
            </button>
          </form>
        ) : null}

        {props.medicalRegistrationPopup ? (
          <form
            onSubmit={handleSubmit(onSubmitMedicalRegistration)}
            noValidate
            autoComplete="off"
          >
            <div className="row form-row">
              <div className="col-12 col-md-3 col-lg-3">
                <div className="form-group">
                  <label>Country *</label>
                  <select
                    name="country"
                    ref={register({ required: true })}
                    className="select form-control"
                    onChange={onChange.bind(this, "country")}
                  >
                    <option value="0" selected="selected">
                      Select Country
                    </option>
                    {countries &&
                      countries.length &&
                      countries.map((opt) => {
                        return <option value={opt.id}>{opt.name}</option>;
                      })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <div className="form-group">
                  <label>State *</label>
                  <select
                    name="region"
                    ref={register({ required: true })}
                    className="select form-control"
                    onChange={onChange.bind(this, "region")}
                  >
                    <option selected="selected" value={0}>
                      Select State*
                    </option>
                    {regionsList &&
                      regionsList.length &&
                      regionsList.map((elm, idx) => {
                        return (
                          <option key={idx} value={elm.name}>
                            {elm.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-3 col-lg-3">
                <div className="form-group">
                  <label>Medical Registration Number *</label>
                  <input
                    onChange={onChange.bind(this, "medicalRegistrationNumber")}
                    type="text"
                    className="form-control"
                    name="medicalRegistrationNumber"
                    placeholder="Medical Registration Number"
                    ref={register({ required: false })}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2 col-lg-2">
                <div className="form-group">
                  <div>
                    <br />
                  </div>
                  <button
                    style={{ marginTop: "10px", marginLeft: "20px" }}
                    type="button"
                    onClick={addMedicalReg}
                    className="btn btn-primary submit-btn"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group">
              <ul>
                {/* {addMoreList.country.length == 0 ? <li>No Data Added</li> : null} */}
                {console.log("list>>>", addMoreList)}
                {addMoreList &&
                addMoreList.country &&
                addMoreList.country.length
                  ? addMoreList.country.map((elm, idx) => {
                      return (
                        // <li key={idx}> {countries[elm]}  - {addMoreList.region[idx]}</li>
                        <li key={idx}>
                          {console.log(isNaN(elm))}
                          {isNaN(elm) ? elm : getCountryNameByList(elm)} -
                          {addMoreList.region[idx]} -
                          {addMoreList.mediRegistration[idx]}
                          <sup
                            onClick={removeRecordFromList.bind(this, elm, idx)}
                            title="Click here to remove the record."
                            style={{
                              marginLeft: "12px",
                              marginBottom: "12px",
                              color: "#F44336",
                              cursor: "pointer",
                            }}
                          >
                            Delete
                          </sup>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>

            <div className="form-group">
              <div className="row form-row">
                <div className="submit-section col-6">
                  <br />
                  <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    // onClick={() => {
                    //   window.location.reload();
                    // }}
                    onClick={props.hide}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        ) : null}

        {props.additionalQualificationsPopup ? (
          <form onSubmit={handleSubmit(onSubmitAdditionalQualification)}>
            <textarea
              tabIndex="14"
              rows="3"
              onChange={(e) => {
                setAddData(e.target.value);
              }}
              value={adddata}
              className="form-control"
              ref={register({ required: false })}
              name="additionalQualifications"
            />
            <br />
            <button
              type="submit"
              onClick={() => {
                props.hide();
              }}
              className="btn btn-primary submit-btn"
            >
              Save
            </button>
          </form>
        ) : null}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default PersonalEdit;
