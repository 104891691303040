import React from "react";
import Modal from "react-bootstrap/Modal";

const UploadProgressModal = ({
  open,
  setShow,
  progress,
  estimatedUploadTime,
}) => {
  return (
    <Modal
      show={open}
      // onHide={setShow}
      size="lg"
      className="d-flex align-items-center justify-content-center"
    >
      <Modal.Body style={{maxWidth:'25rem',width:'90vw'}}>
      <div
        className="d-flex align-items-center justify-content-center w-100"
      >
        <div className="p-3 w-100 ">
          <h1 className="text-center  font-weight-bold " style={{fontSize:'2rem'}}>
            Uploading....
          </h1>
          <div className="mt-5 mb-1 ">
            <div
              className="w-100"
              style={{ backgroundColor: "grey", height: ".5rem" }}
            >
              <div
                className=""
                style={{
                  backgroundColor: " #61dafb",
                  height: ".5rem",
                  width: `${progress}%`,
                }}
              ></div>
            </div>
          </div>
          <p className="font-weight-bold text-center">
            {estimatedUploadTime
              ? `Estimated time remaining: ${estimatedUploadTime}`
              : "Processing"}
          </p>
        </div>
      </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadProgressModal;
