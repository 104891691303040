import React, { useState, useEffect } from 'react';
// import letterImg from '../assets/img/letter.svg';
import axios from 'axios';
import Constants from '../configs/Constants';
import Config from '../configs/Config';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
import DummyProfile from '../assets/img/dummy-profile.png';
import BannerImage from '../assets/img/01_a_Banner.jpg';
import DocBanner from '../assets/img/Doccare_Banner.jpg';
import Banner3 from '../assets/img/banner3.jpeg';
import Banner2 from '../assets/img/banner2.jpeg';
import Banner1 from '../assets/img/banner1.jpeg';
import '../assets/css/posts.css';
import '../assets/css/custome.css';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import EventEmitters from '../configs/EventEmitters';
import moment from 'moment';
import LoaderEmitters from '../configs/LoaderEmitters';
import _ from 'lodash';
import ReadMoreReact from 'read-more-react';

const CommunityNonRegisterView = (props) => {

    const comment = function (id, elm) {
        if (!id) return;
        var x = document.getElementById(id);
        setCurruntComment('');
        if (x.style.display === "none") {
            x.style.display = "block";
            getCommentForCommunityId(elm);
        } else {
            x.style.display = "none";
        }
    }

    const [show, setShow] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);

    const [communityData, setCommunityData] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [userLikesDetails, setUserLikesDetails] = useState([]);
    const [curruntComment, setCurruntComment] = useState([]);
    const [communityCommentData, setCommunityCommentData] = useState([]);
    const [curruntIdForReport, setCurruntIdForReport] = useState(null);
    // const [loginDetails, setLoginDetails] = useState({});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { register, handleSubmit, errors, reset } = useForm(); // initialise the hook

    const handleCloseReportModal = (elm) => {
        setShowReportModal(false);
        setCurruntIdForReport(null);
    }
    const handleShowReportModal = (elm) => {
        setShowReportModal(true);
        setCurruntIdForReport(elm);
    }

    // const communityFile = useRef(null);

    useEffect(() => {

        // setLoginDetails(Config.getSessionInfo());
        const id = Config.getSessionInfo('id');

        if (!id) {
            const idReister = Config.getSessionInfoRegister('name');
            if (idReister) {
                loadDataOfCommunity(-1111);
            } else {
                props.history.push('/communityNonRegister');
            }
        } else {
            props.history.push('/community');
        }


        // if (Config.getSessionInfo('id')) {
        //     console.log("Config.getSessionInfo()>>>>>>>>", Config.getSessionInfo());
        //     setUserDetails({
        //         ...Config.getSessionInfo()
        //     });
        //     loadDataOfCommunity(Config.getSessionInfo('id'));
        // }
    }, []);

    const getCommentForCommunityId = (elm) => {
        setCommunityCommentData();

        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.getCommentForCommunityId, {
            community_id: elm.id
        })
            .then((response) => {
                // reset();
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                console.log("loadDataOfCommunity response>>>>>>", response);
                setCommunityCommentData([...response.data.result]);

            })
            .catch(console.error);
    }

    const mergeById = (arr) => {
        const merged = _.merge(
            _.keyBy(arr[0], 'id'),
            _.keyBy(arr[1], 'id'),
            _.keyBy(arr[2], 'id'),
            _.keyBy(arr[3], 'id'));
        let values = _.values(merged);
        values = _.orderBy(merged, ['id'], ['desc']);
        return values;
    }

    const loadDataOfLikes = (id) => {
        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.getCommunityPostLike, {
            id: Config.getSessionInfo('id')
        })
            .then((response) => {
                // reset();
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                console.log("loadDataOfCommunity response>>>>>>", response);
                setUserLikesDetails([...response.data.result]);

            })
            .catch(console.error);
    }

    const loadDataOfCommunity = (id) => {
        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.getCommunityPost, {
            id: id
        })
            .then((response) => {
                // reset();
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                const newMergedArr = mergeById(response.data.result);
                setCommunityData([...newMergedArr]);
                // setUserLikesDetails([...response.data.result[1]]);
                console.log("newMergedArr?>>>>>>>>>>>>>>", newMergedArr);
            })
            .catch(console.error);
    }

    const displayTime = (time) => {
        return moment(time).calendar();
    }

    const onSubmit = data => {

        // data.id = Config.getSessionInfo('id');
        console.log("onSubmit data>>>>>", data);

        var xx = document.getElementById('attachFile');
        const formData = new FormData();
        formData.append('cummunityfile', xx.files[0]);
        // formData.append('title', data.title);
        // formData.append('description', data.description);
        // formData.append('medicalRegistration', data.medicalRegistration);

        console.log("formData>>>>>>>>>>>", formData);

        LoaderEmitters.showLoader({
            showLoader: true
        });
        handleClose();

        const config = {
            headers: {
                'title': data.title,
                'description': data.description,
                'medicalregistration': data.medicalRegistration,
                'id': Config.getSessionInfo('id')
            }
        }

        axios.post(Constants.addCommunityPost, formData, config)
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                // reset();
                let tost = {
                    message: "",
                    type: ""
                };
                console.log("response>>>>>>", response);
                if (response.data.code === 200) {
                    tost.type = "success";
                    tost.message = response.data.msg;
                    loadDataOfCommunity(Config.getSessionInfo('id'));
                    handleClose();
                    // props.history.push('/qualification');
                } else {
                    tost.type = "error";
                    tost.message = response.data.msg;
                }

                if (tost.message) {
                    EventEmitters.showTost(tost);
                }
                reset();
            })
            .catch(console.error);

    };

    const onSubmitReportReason = data => {
        console.log("onSubmit data>>>>>", data);
        LoaderEmitters.showLoader({
            showLoader: true
        });
        debugger;
        axios.post(Constants.addCommunityReportReason, {
            signup_id: Config.getSessionInfo('id'),
            community_id: curruntIdForReport.id,
            reason: data.reason
        })
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                let tost = {
                    message: "",
                    type: ""
                };
                console.log("response>>>>>>", response);
                if (response.data.code === 200) {
                    tost.type = "success";
                    tost.message = response.data.msg;
                    loadDataOfCommunity(Config.getSessionInfo('id'));
                    handleClose();
                } else {
                    tost.type = "error";
                    tost.message = response.data.msg;
                }

                if (tost.message) {
                    EventEmitters.showTost(tost);
                }

                reset();
                handleCloseReportModal();
            })
            .catch(console.error);

    };

    const addComment = (elm) => {

        if (curruntComment) {
            LoaderEmitters.showLoader({
                showLoader: true
            });
            console.log("add Comment>>>>>>>>", curruntComment);
            axios.post(Constants.addCommunityPostAddComment, {
                signup_id: Config.getSessionInfo('id'),
                community_id: elm.id,
                curruntComment
            })
                .then((response) => {
                    LoaderEmitters.showLoader({
                        showLoader: false
                    });
                    setCurruntComment('');
                    getCommentForCommunityId(elm);
                    // reset();
                    let tost = {
                        message: "",
                        type: ""
                    };
                    console.log("response>>>>>>", response);
                    if (response.data.code === 200) {
                        tost.type = "success";
                        tost.message = response.data.msg;
                        loadDataOfCommunity(Config.getSessionInfo('id'));
                        // loadDataOfCommunity(Config.getSessionInfo('id'));
                        // handleClose();
                        // props.history.push('/qualification');
                    } else {
                        tost.type = "error";
                        tost.message = response.data.msg;
                    }

                    if (tost.message) {
                        EventEmitters.showTost(tost);
                    }
                })
                .catch(console.error);
        }

    }

    const onClickLike = (elm) => {

        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.addCommunityPostLike, {
            signup_id: Config.getSessionInfo('id'),
            community_id: elm.id,
            isLike: 1
        })
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                // reset();
                let tost = {
                    message: "",
                    type: ""
                };
                console.log("response>>>>>>", response);
                if (response.data.code === 200) {
                    tost.type = "success";
                    tost.message = response.data.msg;
                    loadDataOfCommunity(Config.getSessionInfo('id'));
                    // loadDataOfCommunity(Config.getSessionInfo('id'));
                    // handleClose();
                    // props.history.push('/qualification');
                } else {
                    tost.type = "error";
                    tost.message = response.data.msg;
                }

                if (tost.message) {
                    EventEmitters.showTost(tost);
                }
            })
            .catch(console.error);
    }

    const commentBoxInput = (e) => {
        const { value } = e.target;
        setCurruntComment(value);
        console.log(value);
    }


    const addToCollection = (elm) => {

        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.addCommunityPostToCollection, {
            signup_id: Config.getSessionInfo('id'),
            community_id: elm.id
        })
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                let tost = {
                    message: "",
                    type: ""
                };
                console.log("response>>>>>>", response);
                if (response.data.code === 200) {
                    tost.type = "success";
                    tost.message = response.data.msg;

                } else {
                    tost.type = "error";
                    tost.message = response.data.msg;
                }

                if (tost.message) {
                    EventEmitters.showTost(tost);
                }
            })
            .catch(console.error);
    }

    return (
        <React.Fragment>
            {/* <nav className="navbar navbar-expand-lg header-nav">
                <ul className="nav text-center sub-head">
                    <li className="nav-item">
                        <NavLink className="nav-link header-login" to={'/communityNonRegister'} style={{ margin: '0 10px' }}>Non Register user</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link header-login" to={'/community'} style={{ margin: '0 10px' }}> Registered user </NavLink>
                    </li>
                </ul>
            </nav> */}

            <div className="content">
                <div className="container-fluid" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    <div className="row">

                        <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
                            {/* <div className="profile-sidebar">
                                <div className="widget-profile pro-widget-content">
                                    <div className="profile-info-widget">
                                        <a href="#" className="booking-doc-img">
                                            <img src={Constants.server + userDetails.profile_pic} alt="User Image" />
                                        </a>
                                        <div className="profile-det-info">
                                            <h3>Dr. {userDetails.full_name}</h3>

                                            <div className="patient-details">
                                                <h5 className="mb-0">BDS, MDS - Oral & Maxillofacial Surgery</h5>
                                                <a className="nav-link header-login" onClick={handleShow} style={{ backgroundColor: '#096d9c', color: '#fff', border: '2px solid #096d9c', margin: '0 10px' }}> Add a Case </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-widget">
                                    <nav className="dashboard-menu">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="fas fa-columns"></i>
                                                    <span>Cases</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fas fa-calendar-check"></i>
                                                    <span>Collection</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fas fa-user-injured"></i>
                                                    <span>Following</span>
                                                </a>
                                            </li>

                                        </ul>
                                    </nav>
                                </div>
                            </div> */}

                        </div>


                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="doc-review review-listing">
                                <ul className="comments-list">
                                    {
                                        communityData && communityData.map((elm, key) => {
                                            return (
                                                <li>
                                                    <div className="comment">
                                                        <img className="avatar rounded-circle" alt="User Image" src={Constants.server + elm.profile_pic} />
                                                        <div className="comment-body">
                                                            <div className="meta-data">
                                                                {/* <span className="comment-author">{elm.full_name}</span> */}
                                                                <NavLink to={"/profile/" + elm.owner_id}>Dr. {elm.full_name}</NavLink>
                                                                <span className="comment-date">Posted {displayTime(elm.created_time_date)}</span>
                                                                {/* <div className="review-count rating">
                                                                    <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="follow">
                                                                        <i className="fas fa-plus"></i> follow
                                                                    </a>
                                                                </div> */}
                                                            </div>
                                                            {/* <p className="recommended"><i className="far fa-thumbs-up"></i> I recommend the doctor</p> */}

                                                            {
                                                                elm.file !== 'nofile' ? (
                                                                <img
                                                                    className="img-responsive"
                                                                    src={Constants.server + "community/" + elm.file}
                                                                    width="100%"
                                                                />
                                                                ) : null
                                                            }

                                                            <p className="comment-content">
                                                                {/* {elm.description} */}
                                                                {
                                                                    elm.description ?
                                                                        <ReadMoreReact text={elm.description}
                                                                            min={10}
                                                                            ideal={200}
                                                                            max={250}
                                                                            readMoreText="Read more"
                                                                        /> : ""
                                                                }
                                                            </p>
                                                            <div className="all-media">

                                                                <span href="#" className="like-btn liked pointer" data-toggle="tooltip" data-placement="top" title="Like">
                                                                    <i className="far fa-thumbs-up" ></i> {elm.like_count}
                                                                </span>

                                                                <a className="dislike-btn pointer" data-toggle="tooltip" data-placement="top" title="Comment" onClick={comment.bind(this, "comment-d" + elm.id, elm)}>
                                                                    <i className="fa fa-comments"></i> {elm.comment_count}
                                                                </a>

                                                                {/* <a className="collection-btn float-right pointer" data-toggle="tooltip" data-placement="top" onClick={addToCollection.bind(this, elm)} title="Add To Collection">
                                                                    <i className="fa fa-folder"></i> Add to Collection
                                                                </a> */}

                                                                {/* <a className="share-btn float-right pointer" data-toggle="tooltip" data-placement="top" title="Share">
                                                                    <i className="fa fa-share-alt"></i> Share
                                                                </a> */}

                                                                {/* <a className="dislike-btn float-right pointer" data-toggle="tooltip" data-placement="top" title="Report" onClick={handleShowReportModal.bind(this, elm)}>
                                                                    <i className="far fa-flag"></i> Report
                                                                </a> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <ul className="comments-reply" id={"comment-d" + elm.id} style={{ display: 'none' }}>
                                                        <li>
                                                            {/* <div className="row" style={{ marginBottom: '15px' }}>
                                                                <div className="col-md-8">
                                                                    <input type="text" className="form-control" id="add-cmt" placeholder="Add a Comment" value={curruntComment} onChange={commentBoxInput.bind(this)} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <button id="add-cmt" className="btn-facebook" onClick={addComment.bind(this, elm)}>Add Comment</button>
                                                                </div>
                                                            </div> */}

                                                            {
                                                                communityCommentData && communityCommentData.map((element) => {
                                                                    return (
                                                                        <div className="comment">
                                                                            <img className="avatar rounded-circle" alt="User Image" src={Constants.server + element.profile_pic} />
                                                                            <div className="comment-body">
                                                                                <div className="meta-data">
                                                                                    {/* <span className="comment-author">Dr. {element.full_name}</span> */}
                                                                                    <NavLink to={"/profile/" + element.signup_id}>Dr. {element.full_name}</NavLink>
                                                                                    <span className="comment-date"> <small>Reviewed {displayTime(element.created_time_date)}  ago</small> </span>
                                                                                </div>
                                                                                <p className="comment-content">
                                                                                    {element.comment}
                                                                                </p>
                                                                                {/* <div className="comment-reply">
                                                                                    <a className="comment-btn" href="#">
                                                                                        <i className="fas fa-reply"></i> Reply
                                                                                    </a>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }


                                                        </li>
                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }


                                    {/* <li>
                                        <div className="comment">
                                            <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <span className="comment-author">Travis Trimble</span>
                                                    <span className="comment-date">Reviewed 4 Days ago</span>
                                                    <div className="review-count rating">
                                                        <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                            <i className="fas fa-plus"></i> follow
                                                    </a>
                                                    </div>
                                                </div>

                                                <img className="img-responsive" src={DocBanner} width="100%" />
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <div className="all-media">
                                                    <a href="#" className="like-btn" data-toggle="tooltip" data-placement="top" title="Like">
                                                        <i className="far fa-thumbs-up"></i> 10
                                                </a>
                                                    <a className="dislike-btn" data-toggle="tooltip" data-placement="top" title="Comment" >
                                                        <i className="fa fa-comments"></i> 1
                                                </a>
                                                    <a href="#" className="collection-btn float-right" data-toggle="tooltip" data-placement="top" title="Add To Collection">
                                                        <i className="fa fa-folder"></i> Add to Collection
                                                </a>
                                                    <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                        <i className="fa fa-share-alt"></i> Share
                                                </a>
                                                    <a href="#" className="dislike-btn float-right" data-toggle="tooltip" data-placement="top" title="Report">
                                                        <i className="far fa-flag"></i> Report
                                                </a>
                                                </div>
                                            </div>
                                        </div>

                                        <ul className="comments-reply" id="comment-d1">

                                            <li>
                                                <div className="row" style={{ marginBottom: '15px' }}>
                                                    <div className="col-md-8">
                                                        <input type="text" className="form-control" id="add-cmt" placeholder="Add a Comment" />
                                                    </div>

                                                    <div className="col-md-4">
                                                        <button id="add-cmt" className="btn-facebook">Add Comment</button>
                                                    </div>
                                                </div>

                                                <div className="comment">

                                                    <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                                    <div className="comment-body">
                                                        <div className="meta-data">
                                                            <span className="comment-author">Dr. Darren Elder</span>
                                                            <span className="comment-date">Reviewed 3 Days ago</span>
                                                        </div>
                                                        <p className="comment-content">
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                            Ut enim ad minim veniam.
                                                            Curabitur non nulla sit amet nisl tempus
                                                    </p>
                                                        <div className="comment-reply">
                                                            <a className="comment-btn" href="#">
                                                                <i className="fas fa-reply"></i> Reply
                                                        </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="comment">
                                            <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <span className="comment-author">Carl Kelly</span>
                                                    <span className="comment-date">Reviewed 5 Days ago</span>
                                                    <div className="review-count rating">
                                                        <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                            <i className="fas fa-plus"></i> follow
                                                    </a>
                                                    </div>
                                                </div>
                                                <img className="img-responsive" src={Banner1} width="100%" />
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <div className="all-media">
                                                    <a href="#" className="like-btn" data-toggle="tooltip" data-placement="top" title="Like">
                                                        <i className="far fa-thumbs-up"></i> 10
                                                </a>
                                                    <a className="dislike-btn" data-toggle="tooltip" data-placement="top" title="Comment" >
                                                        <i className="fa fa-comments"></i> 1
                                                </a>
                                                    <a href="#" className="collection-btn float-right" data-toggle="tooltip" data-placement="top" title="Add To Collection">
                                                        <i className="fa fa-folder"></i> Add to Collection
                                                </a>
                                                    <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                        <i className="fa fa-share-alt"></i> Share
                                                </a>
                                                    <a href="#" className="dislike-btn float-right" data-toggle="tooltip" data-placement="top" title="Report">
                                                        <i className="far fa-flag"></i> Report
                                                </a>
                                                </div>
                                            </div>
                                        </div>

                                        <ul className="comments-reply" id="comment-d2">
                                            <li>
                                                <div className="row" style={{ marginBottom: '15px' }}>
                                                    <div className="col-md-8">
                                                        <input type="text" className="form-control" id="add-cmt" placeholder="Add a Comment" />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <button id="add-cmt" className="btn-facebook">Add Comment</button>
                                                    </div>
                                                </div>
                                                <div className="comment">
                                                    <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                                    <div className="comment-body">
                                                        <div className="meta-data">
                                                            <span className="comment-author">Dr. Darren Elder</span>
                                                            <span className="comment-date">Reviewed 3 Days ago</span>
                                                        </div>
                                                        <p className="comment-content">
                                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                            Ut enim ad minim veniam.
                                                            Curabitur non nulla sit amet nisl tempus
                                                    </p>
                                                        <div className="comment-reply">
                                                            <a className="comment-btn" href="#">
                                                                <i className="fas fa-reply"></i> Reply
                                                        </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>

                                    <li>
                                        <div className="comment">
                                            <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <span className="comment-author">Michelle Fairfax</span>
                                                    <span className="comment-date">Reviewed 6 Days ago</span>
                                                    <div className="review-count rating">
                                                        <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                            <i className="fas fa-plus"></i> follow
                                                    </a>
                                                    </div>
                                                </div>
                                                <img className="img-responsive" src={Banner2} width="100%" />
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <div className="all-media">
                                                    <a href="#" className="like-btn" data-toggle="tooltip" data-placement="top" title="Like">
                                                        <i className="far fa-thumbs-up"></i> 10
                                                </a>
                                                    <a className="dislike-btn" data-toggle="tooltip" data-placement="top" title="Comment" >
                                                        <i className="fa fa-comments"></i> 1
                                                </a>
                                                    <a href="#" className="collection-btn float-right" data-toggle="tooltip" data-placement="top" title="Add To Collection">
                                                        <i className="fa fa-folder"></i> Add to Collection
                                                </a>
                                                    <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                        <i className="fa fa-share-alt"></i> Share
                                                </a>
                                                    <a href="#" className="dislike-btn float-right" data-toggle="tooltip" data-placement="top" title="Report">
                                                        <i className="far fa-flag"></i> Report
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="comment">
                                            <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <span className="comment-author">Gina Moore</span>
                                                    <span className="comment-date">Reviewed 1 Week ago</span>
                                                    <div className="review-count rating">
                                                        <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                            <i className="fas fa-plus"></i> follow
                                                    </a>
                                                    </div>
                                                </div>
                                                <img className="img-responsive" src={Banner3} width="100%" />
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <div className="all-media">
                                                    <a href="#" className="like-btn" data-toggle="tooltip" data-placement="top" title="Like">
                                                        <i className="far fa-thumbs-up"></i> 10
                                                </a>
                                                    <a className="dislike-btn" data-toggle="tooltip" data-placement="top" title="Comment" >
                                                        <i className="fa fa-comments"></i> 1
                                                </a>
                                                    <a href="#" className="collection-btn float-right" data-toggle="tooltip" data-placement="top" title="Add To Collection">
                                                        <i className="fa fa-folder"></i> Add to Collection
                                                </a>
                                                    <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                        <i className="fa fa-share-alt"></i> Share
                                                </a>
                                                    <a href="#" className="dislike-btn float-right" data-toggle="tooltip" data-placement="top" title="Report">
                                                        <i className="far fa-flag"></i> Report
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="comment">
                                            <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <span className="comment-author">Walter Roberson</span>
                                                    <span className="comment-date">Reviewed 1 Week ago</span>
                                                    <div className="review-count rating">
                                                        <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                            <i className="fas fa-plus"></i> follow
                                                    </a>
                                                    </div>
                                                </div>
                                                <img className="img-responsive" src={Banner3} width="100%" />
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <div className="all-media">
                                                    <a href="#" className="like-btn" data-toggle="tooltip" data-placement="top" title="Like">
                                                        <i className="far fa-thumbs-up"></i> 10
                                                </a>
                                                    <a className="dislike-btn" data-toggle="tooltip" data-placement="top" title="Comment" >
                                                        <i className="fa fa-comments"></i> 1
                                                </a>
                                                    <a href="#" className="collection-btn float-right" data-toggle="tooltip" data-placement="top" title="Add To Collection">
                                                        <i className="fa fa-folder"></i> Add to Collection
                                                </a>
                                                    <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                        <i className="fa fa-share-alt"></i> Share
                                                </a>
                                                    <a href="#" className="dislike-btn float-right" data-toggle="tooltip" data-placement="top" title="Report">
                                                        <i className="far fa-flag"></i> Report
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li>
                                        <div className="comment">
                                            <img className="avatar rounded-circle" alt="User Image" src={DummyProfile} />
                                            <div className="comment-body">
                                                <div className="meta-data">
                                                    <span className="comment-author">Daniel Griffing</span>
                                                    <span className="comment-date">Reviewed on 1 Nov 2019</span>
                                                    <div className="review-count rating">
                                                        <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                            <i className="fas fa-plus"></i> follow
                                                    </a>
                                                    </div>
                                                </div>
                                                <img className="img-responsive" src={BannerImage} width="100%" />
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <p className="comment-content">
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation.
                                                    Curabitur non nulla sit amet nisl tempus
                                            </p>
                                                <div className="all-media">
                                                    <a href="#" className="like-btn" data-toggle="tooltip" data-placement="top" title="Like">
                                                        <i className="far fa-thumbs-up"></i> 10
                                                </a>
                                                    <a className="dislike-btn" data-toggle="tooltip" data-placement="top" title="Comment" >
                                                        <i className="fa fa-comments"></i> 1
                                                </a>
                                                    <a href="#" className="collection-btn float-right" data-toggle="tooltip" data-placement="top" title="Add To Collection">
                                                        <i className="fa fa-folder"></i> Add to Collection
                                                </a>
                                                    <a href="#" className="share-btn float-right" data-toggle="tooltip" data-placement="top" title="Share">
                                                        <i className="fa fa-share-alt"></i> Share
                                                </a>
                                                    <a href="#" className="dislike-btn float-right" data-toggle="tooltip" data-placement="top" title="Report">
                                                        <i className="far fa-flag"></i> Report
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Fill The Following Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className="form-group row">
                            <label for="email_address" className="col-md-4 col-form-label text-md-right">Title</label>
                            <div className="col-md-6">
                                <input type="text" className="form-control" name="title" required ref={register({ required: true })} />
                                {errors.title &&
                                    <div className="error-text" role="alert">
                                        Title is required.
                                                </div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="email_address" className="col-md-4 col-form-label text-md-right">Description</label>
                            <div className="col-md-6">
                                <textarea className="form-control" name="description" ref={register({ required: true })} ></textarea>
                                {errors.description &&
                                    <div className="error-text" role="alert">
                                        Description is required.
                                                </div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label for="attachFile" className="col-md-4 col-form-label text-md-right">Attach File</label>
                            <div className="col-md-6">
                                {/*  */}
                                {/* onChange={onChangeUploadFile} */}
                                <input type="file" id="attachFile" name="cummunityFile" required ref={register({ required: true })} />
                                {errors.cummunityFile &&
                                    <div className="error-text" role="alert">
                                        File is required.
                                                </div>}
                            </div>
                        </div>


                        <div className="form-group row">
                            <label for="email_address" className="col-md-4 col-form-label text-md-right">Enter Medical Registration Number</label>
                            <div className="col-md-6">
                                <input type="text" className="form-control" name="medicalRegistration" required ref={register({ required: true })} />

                                {errors.medicalRegistration &&
                                    <div className="error-text" role="alert">
                                        Medical Registration is required.
                                                </div>}
                            </div>
                        </div>

                        {/* <div className="form-group row">
                            <div className="col-md-6 offset-md-4">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" name="remember" style={{ marginRight: '6px' }} />
                                                Enter Medical Registration Number
                                                <input type="text" className="form-control" name="medicalRegistration" required ref={register({ required: true })} />
                                    </label>
                                </div>
                            </div>
                        </div> */}

                        <div className="form-group row">
                            <div className="col-md-6 offset-md-4">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" name="remember" name="checkbox" required ref={register({ required: true })} style={{ marginRight: '6px' }} />
                                        This data entered is relevant to the platform.
                                        {errors.checkbox &&
                                            <div className="error-text" role="alert">
                                                Please select check box for Terms/Privacy
                                                </div>}
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 offset-md-4">
                            <button type="submit" className="btn btn-primary" >
                                Submit
                            </button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer style={{ display: 'block' }}>
                    <div className="alert alert-info text-center">
                        Your case will be closed after 7 days from the post date.
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={showReportModal} onHide={handleCloseReportModal}>
                <Modal.Header closeButton>
                    <Modal.Title> Please fill the reason.  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmitReportReason)} noValidate autoComplete="off">
                        <div className="form-group row">
                            <label for="email_address" className="col-md-4 col-form-label text-md-right">Reason</label>
                            <div className="col-md-6">
                                <input type="text" className="form-control" name="reason" required ref={register({ required: true })} />
                                {errors.title &&
                                    <div className="error-text" role="alert">
                                        Reason is required.
                                                </div>}
                            </div>
                        </div>

                        <div className="col-md-6 offset-md-4">
                            <button type="submit" className="btn btn-primary" >
                                Submit
                            </button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>


            <FooterContent></FooterContent>
        </React.Fragment>
    );
}
export default CommunityNonRegisterView;