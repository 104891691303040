import React, { useState, useEffect } from 'react';
// import letterImg from '../assets/img/letter.svg';
import axios from 'axios';
import Constants from '../configs/Constants';
import Config from '../configs/Config';
import { NavLink } from 'react-router-dom';
import FooterContent from './FooterContent';
import DummyProfile from '../assets/img/dummy-profile.png';
import BannerImage from '../assets/img/01_a_Banner.jpg';
import DocBanner from '../assets/img/Doccare_Banner.jpg';
import Banner3 from '../assets/img/banner3.jpeg';
import Banner2 from '../assets/img/banner2.jpeg';
import Banner1 from '../assets/img/banner1.jpeg';
import '../assets/css/posts.css';
import '../assets/css/custome.css';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import EventEmitters from '../configs/EventEmitters';
import moment from 'moment';
import LoaderEmitters from '../configs/LoaderEmitters';
import _ from 'lodash';

const CommunityFollowing = (props) => {

    const [friendList, setFriendList] = React.useState([]);
    const [loginDetails, setLoginDetails] = useState({});

    useEffect(() => {
        setLoginDetails(Config.getSessionInfo());
        const id = Config.getSessionInfo('id');
        const isAllDetailsFilled = Config.getSessionInfo('is_all_details_filled');

        if (!id || isAllDetailsFilled === 0) {
            props.history.push('/home');
        } else {
            getCommunityFriendRequestList(id);
        }

    }, []);

    const getCommunityFriendRequestList = (id) => {

        axios.post(Constants.getCommunityFriendRequestList, {
            signup_id: id
        })
            .then((response) => {
                // reset();
                LoaderEmitters.showLoader({
                    showLoader: false
                });
                console.log("loadDataOfCommunity response>>>>>>", response);
                setFriendList([...response.data.result]);
            })
            .catch(console.error);
    }

    const displayTime = (time) => {
        return moment(time).calendar();
    }

    const onClickAccept = (elm) => {
        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.updateFriendList, {
            follow_id: elm.community_follow_id,
            status: 1
        })
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                let tost = {
                    message: "",
                    type: ""
                };
                if (response.data.code === 200) {
                    tost.type = "success";
                    tost.message = response.data.msg;
                    getCommunityFriendRequestList(Config.getSessionInfo('id'));
                } else {
                    tost.type = "error";
                    tost.message = response.data.msg;
                }

                if (tost.message) {
                    EventEmitters.showTost(tost);
                }


            })
            .catch(console.error);
    }

    const onClickCancel = (elm) => {

        LoaderEmitters.showLoader({
            showLoader: true
        });

        axios.post(Constants.cancleFriendList, {
            follow_id: elm.community_follow_id,
        })
            .then((response) => {
                LoaderEmitters.showLoader({
                    showLoader: false
                });

                let tost = {
                    message: "",
                    type: ""
                };
                if (response.data.code === 200) {
                    tost.type = "success";
                    tost.message = response.data.msg;
                    getCommunityFriendRequestList(Config.getSessionInfo('id'));
                } else {
                    tost.type = "error";
                    tost.message = response.data.msg;
                }

                if (tost.message) {
                    EventEmitters.showTost(tost);
                }


            })
            .catch(console.error);

    }
    return (
        <React.Fragment>
            <div className="row align-items-center justify-content-center">
                <div className="col-md-4">
                    <div className="card-body">
                        <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                            <li className="nav-item">
                                <NavLink activeClassName="active" className="nav-link" to="/communityFollowing">
                                    Request
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/communityFollowingFriendList">
                                    Friend List
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <br />
            <div className="content">
                <div className="container-fluid" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                    <div className="row">
                        <div className="col-md-9 col-lg-9 col-xl-9">
                            <div className="appointments">

                                {
                                    friendList && friendList.map((elm) => {
                                        return (
                                            <div className="appointment-list">
                                                <div className="profile-info-widget">
                                                    <span className="booking-doc-img">
                                                        <img src={Constants.server + elm.profile_pic} alt="User Image" style={{ width: '120px' }} />
                                                    </span>
                                                    <div className="profile-det-info">
                                                        <h3><span>Dr. {elm.full_name}</span></h3>
                                                        <div className="patient-details">
                                                            <h5><i className="fas fa-map-marker-alt"></i> {elm.email}</h5>
                                                            <h5><i className="far fa-clock"></i> {displayTime(elm.created_time_date)}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="appointment-action">
                                                    {
                                                        loginDetails.id != elm.sent_by ? (<a onClick={onClickAccept.bind(this, elm)} href="javascript:void(0);" className="btn btn-sm bg-success-light">
                                                            <i className="fas fa-check"></i> Accept
                                                        </a>) : null
                                                    }

                                                    <a onClick={onClickCancel.bind(this, elm)} href="javascript:void(0);" className="btn btn-sm bg-danger-light">
                                                        <i className="fas fa-times"></i> Cancel
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterContent></FooterContent>
        </React.Fragment>
    );
}
export default CommunityFollowing;